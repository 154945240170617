import React, { useState, useMemo, useEffect } from 'react'
import CommonPage from 'components/Pages/CommonPage'

import DocumentTitle from 'components/Pages/DocumentTitle'
import { withStyles } from '@material-ui/core'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'
import { Icon, Grid } from '@material-ui/core'
import { goTo } from 'utils/utils'
import FilterBox from './components/FilterBox'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'
import ListCustomers from './components/ListCustomers'

const breadcrumbItems = [{ name: 'Home' }, { name: 'Clientes', last: true }]

function Customers({ classes }) {
	const user = JSON.parse(localStorage.getItem('user'))
	const hasWritePermission = user.claims.includes('beWeather.customer.write')
	const hasDeletePermission = user.claims.includes(
		'beWeather.customer.delete',
	)
	const [filter, setFilter] = useState({
		fullName: '',
		active: '',
		email: '',
		phoneNumber: '',
		filterRefresh: false,
	})

	return (
		<CommonPage>
			<DocumentTitle pageTitle={'Clientes'} />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<Breadcrumbs items={breadcrumbItems} />
					<Card>
						<CardBody>
							<Grid container>
								<Grid item xs={12} container>
									<Grid item xs={4}>
										<Section
											title="CLIENTES"
											icon="icon-ico_cliente"
										/>
									</Grid>
									<Grid
										item
										xs={8}
										container
										justify="flex-end"
									>
										<Button
											size="sm"
											color="primary"
											disabled={!hasWritePermission}
											onClick={() =>
												goTo('customer/crud')
											}
											style={{ marginRight: '45px' }}
										>
											<Icon
												style={{
													fontSize: '18px',
													width: '30px',
												}}
												className={
													'icon-ico_usuario_novo'
												}
											/>
											Novo cliente
										</Button>
									</Grid>
								</Grid>
								<FilterBox
									classes={classes}
									filter={filter}
									clear
									setFilter={setFilter}
								/>
								<Grid
									item
									xs={12}
									style={{ mergingTop: '20px' }}
								>
									<ListCustomers
										mutate={filter.filterRefresh}
										filter={filter}
										classes={classes}
										setFilter={setFilter}
										hasDeletePermission={
											hasDeletePermission
										}
										hasWritePermission={hasWritePermission}
									/>
								</Grid>
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</CommonPage>
	)
}

export default withStyles(sweetAlertStyle)(Customers)
