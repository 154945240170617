import * as actionTypes from './actionsTypes';
import {
    userService
} from "../services";
import {
    push
} from "connected-react-router";

export const login = (username, password) => {
    return dispatch => {
        dispatch(request({
            username
        }));

        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(push('/'))
                },
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {
            type: actionTypes.LOGIN_REQUEST,
            user
        }
    }

    function success(user) {
        return {
            type: actionTypes.LOGIN_SUCCESS,
            user
        }
    }

    function failure(error) {
        return {
            type: actionTypes.LOGIN_FAILURE,
            error
        }
    }
}

export const logout = () => {
    userService.logout();
    return {
        type: actionTypes.LOGOUT,
        user: null
    };
}