import { Grid } from "@material-ui/core";
import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react";
import styled from "styled-components";

export const SearchBox = styled(Grid)`
	margin: 20px 20px 20px 0px;
	padding: 10px 0px;

	h5 {
		font-size: 14px;
	}

	.MuiFormLabel-root {
		font-size: 14px;
	}

	.MuiSelect-root {
		margin-top: 1px;
	}
`;
