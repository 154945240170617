import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';

export const PrivateRoute = ({ component: Component, ...rest }) => {

    const user = useSelector(state => state.authentication.user);

    return (
        <Route {...rest} render={props => {
            return (
                user && localStorage.getItem('user')
                    ? <Component {...props} />
                    : <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
            )
        }} />
    )
}