import axios from 'axios';

// GET
export const GetFirmwareData = () => {
    const url = `/api/FirmwareManager/GetAll`;
    return axios.get(url).then(response => response.data);
}

export const PostFirmware = (obj) => {
    return axios.post(`/api/FirmwareManager/Upsert`, obj).then(response => response.data);
}

export const UploadFile = (id, formFile) => {
    return axios.post(`/api/FirmwareManager/Upload/${id}`, formFile).then(response => response.data);
}

export const DeleteFirmware = (id) => {
    return axios.delete(`/api/FirmwareManager/Delete/?id=${id}`).then(response => response.data);
}
