import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react";

export const inputsList = [
	{
		inputData: {
			icon: "icon-ativo",
			iconMessage: "Ativo",
			iconColor: germiniDefaultColors[0],
		},
	},
	{
		inputData: {
			icon: "icon-atencao",
			iconMessage: "Atenção",
			iconColor: germiniDefaultColors[13],
			firstMessage: "A estação está a ",
			secondMessage: "sem receber dados.",
			firstInput: {
				name: "stationStatus.warning",
				label: "horas",
			},
		},
	},
	{
		inputData: {
			icon: "icon-critico",
			iconMessage: "Crítico",
			iconColor: germiniDefaultColors[10],
			firstMessage: "A estação está a ",
			secondMessage: "sem receber dados.",
			firstInput: {
				name: "stationStatus.critical",
				label: "horas",
			},
		},
	},
	{
		inputData: {
			icon: "icon-offline",
			iconMessage: "Offline",
			iconColor: germiniDefaultColors[6],
			iconSubtitle: "Não há dados",
			firstMessage: "A estação está a ",
			secondMessage: "sem receber dados.",
			firstInput: {
				name: "stationStatus.offline",
				label: "horas",
			},
		},
	},
	{
		inputData: {
			icon: "icon-offline",
			iconMessage: "Offline",
			iconColor: germiniDefaultColors[14],
			iconSubtitle: "Coleta manual",
			firstMessage: "A estação está a ",
			secondMessage: "sem receber dados.",
			firstInput: {
				name: "stationStatus.offlineManual",
				label: "horas",
			},
		},
	},
	{
		inputData: {
			icon: "icon-desativado",
			iconMessage: "Desativado",
			iconColor: germiniDefaultColors[11],
			firstMessage: "A estação está desativada a ",
			firstInput: {
				name: "stationStatus.disabled",
				label: "horas",
			},
		},
	},
];
