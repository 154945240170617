import { Icon } from '@material-ui/core'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import { Grid } from '@material-ui/core'
import CustomTable, {
	initialPagination,
	initialSort,
} from 'components/CustomTable'
import React, { useState, useEffect } from 'react'
import {
	getMessageManager,
	GET_MESSAGE_USER_ALL,
} from 'services/api/MessageManager/api'
import useSWR from 'swr'

function TableModal({ id }) {
	const [pagination, setPagination] = useState({
		currentPage: 0,
		page: 0,
		pageCount: 1,
		pageSize: 5,
		recordCount: 1,
		filter: {
			startDate: null,
			endDate: null,
		},
	})
	const [loading, setLoading] = useState(true)
	const [sort, setSort] = useState(initialSort)
	const { data } = useSWR(
		`${GET_MESSAGE_USER_ALL}?$skip=${pagination.page}&$top=${pagination.pageSize}&$filter=MessageId eq '${id}'`,

		(url) => getMessageManager(url),
	)

	useEffect(() => {
		setLoading(true)

		if (data?.items) {
			setPagination((prevState) => ({
				...prevState,
				recordCount: data.count,
			}))
			setLoading(false)
		}
	}, [data])

	const columns = [
		{
			title: 'Visualizados',
			// cellStyle: { maxWidth: "100px", minWidth: "100px" },
			// headerStyle: { maxWidth: "100px", minWidth: "100px" },
			render: (rowData) => (
				<Icon
					style={{
						fontSize: rowData?.read ? '18px' : '15px',
						width: '30px',
						color: germiniDefaultColors[1],
					}}
					className={
						rowData?.read ? 'icon-Tab_lido' : 'icon-Tab_naoLido'
					}
				/>
			),
		},
		{
			title: 'Cliente CNPJ/CPF',
			field: 'date',
			// cellStyle: { maxWidth: "100px", minWidth: "100px" },
			// headerStyle: { maxWidth: "100px", minWidth: "100px" },
			render: (rowData) => (
				<div>
					<p>{rowData?.user?.fullName}</p>
					<p>
						{rowData?.user?.document
							? rowData?.user?.document
							: 'Sem Dados'}
					</p>
				</div>
			),
		},
		{
			title: 'Email',
			field: 'user.email',
			// cellStyle: { maxWidth: "100px", minWidth: "100px" },
			// headerStyle: { maxWidth: "100px", minWidth: "100px" },
			type: 'email',
		},
		{
			title: 'Data',
			field: 'readAt',
			// cellStyle: { maxWidth: "100px", minWidth: "100px" },
			// headerStyle: { maxWidth: "100px", minWidth: "100px" },
			type: 'date',
		},
	]
	return (
		<Grid container style={{ marginTop: 20 }}>
			<Grid
				item
				xs={12}
				style={{ overflowY: 'auto', maxHeight: '400px' }}
			>
				<CustomTable
					toolbar
					columns={columns}
					actions={[]}
					pagination={pagination}
					setPagination={setPagination}
					sort={sort}
					setSort={setSort}
					data={data?.items}
					loading={loading}
				/>
			</Grid>
		</Grid>
	)
}

export default TableModal
