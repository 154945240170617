import React, { useEffect, useState } from "react";
import CommonPage from "components/Pages/CommonPage";

import DocumentTitle from "components/Pages/DocumentTitle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Section from "components/Section";

import { useTranslation } from "react-i18next";
import { Icon, withStyles, Grid } from "@material-ui/core";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import NotificationModal from "components/NotificationModal";
import { CustomForm } from "./styles";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Search } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput";
import { contactValidation, regexPhone } from "./validation";
import ModalContact from "./ModalContact";
import {
	getConfigurationManager,
	postConfigurationManager,
} from "services/api/ConfigurationManager/api";
import { HideLoader, ShowLoader } from "redux/actions";
import Loader from "components/Loader/Loader";
import { useDispatch } from "react-redux";
import {
	FailedNotificationComponent,
	SuccessNotificationComponent,
} from "redux/react-notifications";
import { showNotification } from "redux/actions/notification";
import { PhoneMaskCustom } from "utils/utils";

const breadcrumbItems = [
	{ name: "Home" },
	{ name: "Configurações" },
	{ name: "Contato", last: true },
];

function Contact({ classes }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const {
		handleSubmit,
		control,
		reset,
		watch,
		formState: { errors, isDirty },
	} = useForm({
		defaultValues: {
			contact: {
				email: "",
				phone: "",
				whatsapp: "",
				description: "",
			},
		},
		resolver: yupResolver(contactValidation),
	});

	const [edit, setEdit] = useState(false);
	const [id, setId] = useState("");

	const [showModal, setShowModal] = useState({
		loading: false,
		open: false,
		content: () => null,
		title: "",
		headerIcon: false,
	});

	useEffect(() => {
		async function getContact() {
			try {
				dispatch(ShowLoader(<Loader />));
				const data = await getConfigurationManager();
				const item = data?.items?.[0];
				reset({ contact: { ...item?.contact } });
				setId(item?.id);
			} catch (error) {
			} finally {
				dispatch(HideLoader());
			}
		}
		getContact();
	}, [dispatch, reset, edit]);

	async function sendContact(values) {
		try {
			dispatch(ShowLoader(<Loader />));
			const { phone, whatsapp } = values.contact;
			const rawPhone = phone?.replace(regexPhone, "");
			const rawWhatapp = whatsapp?.replace(regexPhone, "");

			let obj = {
				contact: {
					...values.contact,
					phone: rawPhone !== "" ? phone : "",
					whatsapp: rawWhatapp !== "" ? whatsapp : "",
				},
			};

			if (id) obj = { ...obj, id };
			const data = await postConfigurationManager({ ...obj });

			if (data) {
				dispatch(
					showNotification(
						SuccessNotificationComponent(
							"Contato salvo com sucesso!"
						)
					)
				);
				return setEdit(false);
			}
			return dispatch(
				showNotification(
					FailedNotificationComponent("Falha no envio de dados!")
				)
			);
		} catch (error) {
		} finally {
			dispatch(HideLoader());
		}
	}

	function showContacts() {
		setShowModal({
			loading: false,
			open: true,
			title: "Visualização de contato",
			headerIcon: false,
		});
	}

	function handleCancel() {
		setEdit(false);
	}

	return (
		<CommonPage>
			<DocumentTitle pageTitle={t("MENU_CONTACT")} />
			<NotificationModal
				headerTitle={showModal.title}
				width="400px"
				height="530px"
				headerIcon={showModal.headerIcon}
				closeByClick={() =>
					setShowModal((prevState) => ({ ...prevState, open: false }))
				}
				onClose={() =>
					setShowModal((prevState) => ({ ...prevState, open: false }))
				}
				open={showModal.open}
				buttonTitle="BUTTON_CLOSE"
			>
				<ModalContact content={watch().contact} />
			</NotificationModal>

			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<Breadcrumbs items={breadcrumbItems} />
					<Card>
						<CardBody>
							<CustomForm onSubmit={handleSubmit(sendContact)}>
								<Grid container>
									<Grid item xs={12} container>
										<Grid item xs={4}>
											<Section
												title="Contato"
												icon="icon-ico_whatsapp"
											/>
										</Grid>
										<Grid
											item
											xs={8}
											container
											justify="flex-end"
										>
											<Button
												size="sm"
												color="greenBorderButtonColor"
												// disabled={!isDirty}
												onClick={
													edit
														? () => handleCancel()
														: () => showContacts()
												}
												style={{ marginRight: 10 }}
											>
												{edit ? (
													""
												) : (
													<Search
														className={
															classes.icons
														}
													/>
												)}

												{edit
													? "Cancelar"
													: "Visualizar"}
											</Button>
											<Button
												size="sm"
												color="primary"
												onClick={
													edit
														? handleSubmit(
																sendContact
														  )
														: () => setEdit(true)
												}
											>
												<Icon
													style={{
														fontSize: "18px",
														width: "30px",
													}}
													className={
														edit
															? "icon-salvar"
															: "icon-editar"
													}
												/>
												{edit ? "Salvar" : "Editar"}
											</Button>
										</Grid>
									</Grid>
									<Grid item xs={8} container spacing={4}>
										<Grid item xs={12}>
											<Controller
												name="contact.email"
												control={control}
												render={({
													field: {
														onChange,
														onBlur,
														value,
													},
												}) => (
													<CustomInput
														error={
															!!errors?.contact
																?.email
														}
														helpText={
															errors?.contact
																?.email?.message
														}
														labelText="E-mail*"
														formControlProps={{
															fullWidth: true,
														}}
														inputProps={{
															onChange,
															onBlur,
															value,
															disabled: !edit,
														}}
													/>
												)}
											/>
										</Grid>
										<Grid item xs={6}>
											<Controller
												name="contact.phone"
												control={control}
												render={({
													field: {
														onChange,
														onBlur,
														value,
													},
												}) => (
													<CustomInput
														error={
															!!errors?.contact
																?.phone
														}
														helpText={
															errors?.contact
																?.phone?.message
														}
														labelText="Telefone"
														disabled={!edit}
														formControlProps={{
															fullWidth: true,
														}}
														inputProps={{
															onChange,
															onBlur,
															value,
															inputComponent:
																PhoneMaskCustom,
															disabled: !edit,
														}}
													/>
												)}
											/>
										</Grid>
										<Grid item xs={6}>
											<Controller
												name="contact.whatsapp"
												control={control}
												render={({
													field: {
														onChange,
														onBlur,
														value,
													},
												}) => (
													<CustomInput
														error={
															!!errors?.contact
																?.whatsapp
														}
														helpText={
															errors?.contact
																?.whatsapp
																?.message
														}
														labelText="WhatsApp*"
														disabled={!edit}
														formControlProps={{
															fullWidth: true,
														}}
														inputProps={{
															onChange,
															onBlur,
															value,
															disabled: !edit,
															inputComponent:
																PhoneMaskCustom,
														}}
													/>
												)}
											/>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Controller
											name="contact.description"
											control={control}
											render={({
												field: {
													onChange,
													onBlur,
													value,
												},
											}) => (
												<CustomInput
													error={
														!!errors?.contact
															?.description
													}
													helpText={
														errors?.contact
															?.description
															?.message
													}
													labelText="Texto descritivo do atendimento"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														onChange,
														onBlur,
														value,
														disabled: !edit,
													}}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</CustomForm>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</CommonPage>
	);
}

export default withStyles(sweetAlertStyle)(Contact);
