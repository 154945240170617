import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Alert from 'react-bootstrap-sweetalert'
import { Formik, Form, Field } from 'formik'
import * as moment from 'moment'
import { useDispatch } from 'react-redux'

// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { withStyles } from '@material-ui/core/styles'
import { Icon, FormLabel, FormControl } from '@material-ui/core'

import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

// layout components
import GridContainer from 'components/Grid/GridContainer.jsx'
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import FieldInput from 'components/FieldForm/FieldInput.jsx'
import FileInput from 'components/FileInput/FileInput.jsx'
import CommonPage from 'components/Pages/CommonPage'
import DocumentTitle from 'components/Pages/DocumentTitle'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Section from 'components/Section'
import Loader from 'components/Loader/Loader.jsx'

import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'

import {
	SweetAlert,
	HideAlert,
	GetFirmware,
	ShowLoader,
	HideLoader,
	PostFirmware,
	UploadFile,
	DeleteFirmware,
} from 'redux/actions'

//utils
import compose from 'utils/compose'
import { isAllowed } from 'utils/auth'
import CustomTable from 'components/CustomTable'

class Firmware extends Component {
	constructor(props) {
		super(props)
		this.state = {
			breadcrumbItems: [
				{ name: 'Home' },
				{ name: 'Configurações' },
				{ name: 'Gestão de firmware', last: true },
			],
			firmwares: [],
			hasDeletePermission: false,
			hasWritePermission: false,
		}
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />)
		this.props.GetFirmware()
		let user = this.props.user
		if (user) {
			if (isAllowed(user, ['beWeather.firmware.write'])) {
				this.setState({ hasWritePermission: true })
			}
			if (isAllowed(user, ['beWeather.firmware.delete'])) {
				this.setState({ hasDeletePermission: true })
			}
		} else {
			this.props.history.push('/404')
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.lsFirmwares !== this.props.lsFirmwares) {
			this.setState({ firmwares: this.props.lsFirmwares })
			this.props.HideLoader()
		}
		// if (prevProps.deleteFirmwareData !== this.props.deleteFirmwareData) {
		//     this.props.ShowLoader(<Loader />);
		// }
	}

	handleNew = () => {
		this.props.SweetAlert(
			<Alert
				style={{ width: '680px', padding: '0 17px' }}
				showConfirm={false}
				showCancel={false}
				title={
					<Formikin
						classes={this.props.classes}
						onCancel={() => this.props.HideAlert()}
					/>
				}
			></Alert>,
		)
	}

	handleDelete = (id) => {
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title={null}
				onConfirm={() => {
					this.props.HideAlert()
					this.props.ShowLoader(<Loader />)
					this.props.DeleteFirmware(id).then((resp) => {
						this.props.GetFirmware()
					})
				}}
				onCancel={() => {
					this.props.HideAlert()
				}}
				confirmBtnCssClass={
					this.props.classes.button + ' ' + this.props.classes.danger
				}
				cancelBtnCssClass={
					this.props.classes.button +
					' ' +
					this.props.classes.greenBorderButtonColor
				}
				confirmBtnText={'Confirmar'}
				cancelBtnText={'Cancelar'}
				showCancel
			>
				Deseja realmente excluir esse firmware?
			</Alert>,
		)
	}

	render() {
		const { t, classes } = this.props
		const {
			breadcrumbItems,
			firmwares,
			hasDeletePermission,
			hasWritePermission,
		} = this.state
		return (
			<CommonPage>
				<DocumentTitle pageTitle={t('MENU_FIRMWARE')} />
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<Breadcrumbs items={breadcrumbItems} />
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<Section
											title="GESTÃO DE FIRMWARE"
											icon="icon-ico_firmware"
										/>
									</GridItem>
									<GridItem
										xs={12}
										sm={6}
										md={6}
										lg={6}
										style={{ textAlign: 'right' }}
										className={classes.noPaddingRight}
									>
										<Button
											size="sm"
											color="primary"
											disabled={!hasWritePermission}
											onClick={
												hasWritePermission
													? () => this.handleNew()
													: () =>
															console.log(
																'Sem permissão',
															)
											}
										>
											<Icon
												style={{
													fontSize: '18px',
													width: '30px',
												}}
												className={
													'icon-bot_carregar_firmware'
												}
											/>
											Carregar novo
										</Button>
									</GridItem>
									<GridItem xs={12}>
										<MaterialTable
											search={true}
											title={false}
											columns={[
												{
													title: 'Versão do firmware',
													field: 'version',
													cellStyle: {
														whiteSpace: 'nowrap',
													},
												},
												{
													title: 'Nome do arquivo',
													field: 'fileName',
													cellStyle: {
														whiteSpace: 'nowrap',
													},
												},
												{
													title: 'Tipo',
													field: 'type',
													cellStyle: {
														whiteSpace: 'nowrap',
													},
												},
												{
													title: 'Data de Upload',
													field: 'uploadedAt',
													cellStyle: {
														whiteSpace: 'nowrap',
													},
												},
												{
													title: 'Downloads ',
													field: 'downloadCount',
													cellStyle: {
														whiteSpace: 'nowrap',
													},
													width: '5%',
												},
												{
													title: 'Ações',
													field: 'actions',
													headerStyle: {
														textAlign: 'right',
													},
													cellStyle: {
														whiteSpace: 'nowrap',
													},
												},
											]}
											data={firmwares.map((item) => {
												let obj = {
													version:
														item.firmwareVersion,
													fileName: item.name,
													type: t(
														item.type.toUpperCase(),
													),
													uploadedAt: moment(
														item.createdAt,
													).format(
														'DD/MM/YYYY - HH:mm',
													),
													downloadCount:
														item.downloadCount,
													actions: (
														<div
															style={{
																textAlign:
																	'right',
																paddingRight:
																	'28px',
															}}
														>
															<span
																className={cx(
																	'icon-bot_close',
																	classes.actionButton,
																	{
																		[classes.redButton]:
																			hasDeletePermission
																				? true
																				: false,
																		[classes.greyButton]:
																			!hasDeletePermission
																				? true
																				: false,
																	},
																)}
																title="Excluir"
																onClick={
																	hasDeletePermission
																		? () =>
																				this.handleDelete(
																					item.id,
																				)
																		: () =>
																				console.log(
																					'Sem permissão',
																				)
																}
															></span>
														</div>
													),
												}
												return obj
											})}
											options={{
												headerStyle: {
													// backgroundColor: '#01579b',
													// color: '#FFF',
													fontSize: 16,
													paddingTop: 12,
													paddingBottom: 12,
													paddingLeft: 15,
													paddingRight: 0,
													whiteSpace: 'nowrap',
												},
											}}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</CommonPage>
		)
	}
}

Firmware.propTypes = {
	classes: PropTypes.object.isRequired,
}

/**
 *  @author Ayrton Gomes
 *  Function component using formik to render the form to submit the values
 */
const Formikin = ({ classes, history, onCancel, ...props }) => {
	const dispatch = useDispatch()

	const callbackFromChild = (file, setFieldValue) => {
		const formData = new FormData()
		formData.append('formFile', file)
		setFieldValue('formFile', formData)
		setFieldValue('name', file.name)
	}

	const handleSave = (values) => {
		dispatch(HideAlert())
		dispatch(ShowLoader(<Loader />))
		dispatch(PostFirmware(values)).then((resp) => {
			if (resp) {
				if (resp.id != null && resp.id !== '') {
					dispatch(UploadFile(resp.id, values.formFile)).then(
						(resp) => {
							if (resp) {
								dispatch(GetFirmware())
							}
						},
					)
				}
			}
		})
	}

	return (
		<Formik
			onSubmit={(values) => handleSave(values)}
			initialValues={{
				id: '',
				name: '',
				firmwareVersion: '',
				type: '',
				formFile: '',
			}}
		>
			{({ handleSubmit, setFieldValue, values }) => (
				<Form>
					<GridContainer>
						<GridItem
							xs={12}
							sm={6}
							md={6}
							lg={6}
							style={{ textAlign: 'left' }}
						>
							<Section title="CARREGAR ARQUIVO" />
						</GridItem>
						<GridItem
							xs={12}
							sm={6}
							md={6}
							lg={6}
							style={{ textAlign: 'right' }}
							className={classes.noPaddingRight}
						>
							<Button
								size="sm"
								color="primary"
								style={{ marginRight: '10px' }}
								onClick={() => handleSubmit()}
							>
								<Icon
									style={{ fontSize: '14px', width: '20px' }}
									className={'icon-salvar'}
								></Icon>
								Salvar
							</Button>
							<Button
								size="sm"
								color="greenBorderButtonColor"
								onClick={() => {
									onCancel()
									dispatch(HideLoader())
								}}
							>
								<Icon
									style={{ fontSize: '17px', width: '20px' }}
									className={'icon-cancelar'}
								></Icon>
								Cancelar
							</Button>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12} sm={12} md={7} lg={7} xl={7}>
							<FormControl fullWidth>
								<CustomInput
									labelText={'Arquivo a ser importado'}
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										disabled: true,
										value: values.name,
									}}
								/>
							</FormControl>
						</GridItem>
						<Field name="formFile">
							{({ field, form }) => (
								<GridItem
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									style={{ textAlign: 'left' }}
								>
									<FileInput
										name={'formFile'}
										callFromParent={callbackFromChild}
										classes={classes}
										setFieldValue={setFieldValue}
									/>
								</GridItem>
							)}
						</Field>
						<Field name="firmwareVersion">
							{({ field, form }) => (
								<FieldInput
									touched={form.touched.firmwareVersion}
									error={form.errors.firmwareVersion}
									form={form}
									field={field}
									label={'*Nome da versão'}
									inputGrid={7}
								/>
							)}
						</Field>
						<Field name="type">
							{({ field, form }) => (
								<div
									style={{
										marginTop: '30px',
										display: 'flex',
									}}
								>
									<FormLabel
										style={{
											lineHeight: 2,
											margin: '0 10px',
										}}
									>
										Tipo:
									</FormLabel>
									<div
										className={
											classes.checkboxAndRadio +
											' ' +
											classes.checkboxAndRadioHorizontal
										}
									>
										<FormControlLabel
											control={
												<Radio
													checked={
														values.type ===
														'Development'
													}
													onChange={(e) =>
														setFieldValue(
															'type',
															e.target.value,
														)
													}
													value="Development"
													name="Type"
													aria-label="Desenvolvimento"
													icon={
														<FiberManualRecord
															className={
																classes.radioUnchecked
															}
														/>
													}
													checkedIcon={
														<FiberManualRecord
															className={
																classes.radioChecked
															}
														/>
													}
													classes={{
														checked: classes.radio,
														root: classes.radioRoot,
													}}
												/>
											}
											classes={{
												label: classes.label,
												root: classes.labelRoot,
											}}
											label="Desenvolvimento"
										/>
									</div>
									<div
										className={
											classes.checkboxAndRadio +
											' ' +
											classes.checkboxAndRadioHorizontal
										}
									>
										<FormControlLabel
											control={
												<Radio
													checked={
														values.type ===
														'Testing'
													}
													onChange={(e) =>
														setFieldValue(
															'type',
															e.target.value,
														)
													}
													value="Testing"
													name="Type"
													aria-label="Teste"
													icon={
														<FiberManualRecord
															className={
																classes.radioUnchecked
															}
														/>
													}
													checkedIcon={
														<FiberManualRecord
															className={
																classes.radioChecked
															}
														/>
													}
													classes={{
														checked: classes.radio,
														root: classes.radioRoot,
													}}
												/>
											}
											classes={{
												label: classes.label,
												root: classes.labelRoot,
											}}
											label="Teste"
										/>
									</div>
									<div
										className={
											classes.checkboxAndRadio +
											' ' +
											classes.checkboxAndRadioHorizontal
										}
									>
										<FormControlLabel
											control={
												<Radio
													checked={
														values.type ===
														'Production'
													}
													onChange={(e) =>
														setFieldValue(
															'type',
															e.target.value,
														)
													}
													value="Production"
													name="Type"
													aria-label="Produção"
													icon={
														<FiberManualRecord
															className={
																classes.radioUnchecked
															}
														/>
													}
													checkedIcon={
														<FiberManualRecord
															className={
																classes.radioChecked
															}
														/>
													}
													classes={{
														checked: classes.radio,
														root: classes.radioRoot,
													}}
												/>
											}
											classes={{
												label: classes.label,
												root: classes.labelRoot,
											}}
											label="Produção"
										/>
									</div>
								</div>
							)}
						</Field>
					</GridContainer>
				</Form>
			)}
		</Formik>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			SweetAlert,
			HideAlert,
			GetFirmware,
			ShowLoader,
			HideLoader,
			DeleteFirmware,
		},
		dispatch,
	)

const mapStateToProps = (state) => {
	return {
		lsFirmwares: state.firmware.lsFirmwares,
		firmwareDataFailed: state.firmware.firmwareDataFailed,

		deleteFirmwareData: state.firmware.deleteFirmwareData,
		deleteFirmwareDataFailed: state.firmware.deleteFirmwareDataFailed,

		user: state.authentication.user,
	}
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(sweetAlertStyle),
	withTranslation(),
)(Firmware)
