import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react";
import { Tabs } from "react-tabs";
import styled from "styled-components";

export const TabContainer = styled(Tabs)`
	ul {
		display: flex;
		&.react-tabs__tab-list {
			border-bottom: 2px solid #2e88d4;
			padding-bottom: 1px;
		}

		li {
			padding: 10px 10px;
			padding-right: 100px;
			display: flex;
			align-items: center;
			margin-right: 5px;
			background-color: #f3f3f3;
			border-radius: 10px 10px 0 0;

			i {
				margin-right: 10px;
			}

			i,
			b {
				color: #2e88d4;
			}
		}

		.react-tabs__tab--selected {
			background-color: #2e88d4;
			border: none;

			i,
			b {
				color: #fff;
			}
		}
	}

	@media only screen and (max-width: 426px) {
		ul {
			li {
				i {
					margin-right: 0px;
				}
			}
		}
	}
`;
