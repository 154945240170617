import { Grid } from "@material-ui/core";
import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react";
import styled, { css } from "styled-components";

export const SketchContainer = styled(Grid)`
	> div {
		:first-child {
			margin-top: 12px;
		}
	}

	header {
		font-weight: bold;
		color: ${germiniDefaultColors[5]};
		padding: 0 !important;
		font-size: 12px;
	}

	${({ no_title }) =>
		!no_title &&
		css`
			> div:first-child {
				/* margin-top: 24px; */
			}

			small {
				color: ${germiniDefaultColors[5]};
				font-size: 10px;
			}

			.title-small {
				font-weight: bold;
				font-size: 12px;
				color: black;
			}

			.render-html {
				margin-top: 12px;
			}
		`}

	.render-html {
		max-height: 230px;
		overflow: auto;

		::-webkit-scrollbar {
			background-color: #fff;
			width: 16px;
		}

		::-webkit-scrollbar-track {
			background-color: #fff;
			border-radius: 3px;
		}

		::-webkit-scrollbar-thumb {
			background-color: #d1d1d4;
			border-radius: 16px;
			border: 4px solid #fff;

			:hover {
				background-color: #babac0;
			}
		}

		::-webkit-scrollbar-button {
			display: none;
		}
	}
`;
