import { Grid } from "@material-ui/core";
import Section from "components/Section/Section";
import React from "react";

import LinkGroup from "./components/LinkGroup";

function CrudLink() {
	return (
		<Grid item xs={12} container>
			<Grid item xs={4} style={{ marginTop: 30 }}>
				<Section title="Vincular" icon="icon-agrupamento_estacoes" />
			</Grid>
			<Grid item xs={12} style={{ marginLeft: 30 }}>
				<LinkGroup />
			</Grid>
		</Grid>
	);
}

export default CrudLink;
