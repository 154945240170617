import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../utility';

const initialState = {
    userPwd: false,
    userPwdFailed: false,

    emailForgetPwd: null,

    userForgetPwd: false,
    userForgetPwdFailed: false,

    putCUser: null,
    putCUserFailed: false,

    profileData: null,
    profileDataFailed: false,

    sendTokenToEmail: null,
    sendTokenToEmailFailed: false,

    validateToken: null,
    validateTokenFailed: false,
};

const putCUserData = (state, action) => {
    return updateObject(state, {
        putUser: action.putData
    });
};

const fetchChangePassword = (state, action) => {
    return updateObject(state, {
        userPwd: action.userPwd
    });
};

const fetchChangePasswordFailed = (state, action) => {
    return updateObject(state, {
        userPwdFailed: true
    });
};

const putCUserDataFailed = (state, action) => {
    return updateObject(state, {
        putUserFailed: true
    });
};

const fetchProfileData = (state, action) => {
    return updateObject(state, {
        profileData: action.profileData
    });
};

const fetchProfileDataFailed = (state, action) => {
    return updateObject(state, {
        profileDataFailed: true
    });

}

const sendTokenToEmail = (state, action) => {
    return updateObject(state, {
        sendTokenToEmail: action.sendToken,
        emailForgetPwd: action.email
    });
};

const sendTokenToEmailFailed = (state, action) => {
    return updateObject(state, {
        sendTokenToEmailFailed: true
    });

}

const validateToken = (state, action) => {
    return updateObject(state, {
        validateToken: action.sendToken
    });
};

const validateTokenFailed = (state, action) => {
    return updateObject(state, {
        validateTokenFailed: true
    });

}


const fetchForgetPassword = (state, action) => {
    return updateObject(state, {
        userForgetPwd: action.userForgetPwd
    });
};

const fetchForgetPasswordFailed = (state, action) => {
    return updateObject(state, {
        userForgetPwdFailed: true
    });
};

//
const profileReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.PUT_CUSER_DATA:
            return putCUserData(state, action);
        case actionTypes.PUT_CUSER_DATA_FAILED:
            return putCUserDataFailed(state, action);

        case actionTypes.FETCH_CHANGE_PASSWORD_DATA:
            return fetchChangePassword(state, action);
        case actionTypes.FETCH_CHANGE_PASSWORD_FAILED:
            return fetchChangePasswordFailed(state, action);

        case actionTypes.FETCH_PROFILE_DATA:
            return fetchProfileData(state, action);
        case actionTypes.FETCH_PROFILE_DATA_FAILED:
            return fetchProfileDataFailed(state, action);

        case actionTypes.POST_SEND_TOKEN_CUSER:
            return sendTokenToEmail(state, action);
        case actionTypes.POST_SEND_TOKEN_CUSER_FAILED:
            return sendTokenToEmailFailed(state, action);

        case actionTypes.POST_VALIDATE_TOKEN_CUSER:
            return validateToken(state, action);
        case actionTypes.POST_VALIDATE_TOKEN_CUSER_FAILED:
            return validateTokenFailed(state, action);

        case actionTypes.FETCH_FORGET_PASSWORD_DATA:
            return fetchForgetPassword(state, action);
        case actionTypes.FETCH_FORGET_PASSWORD_DATA_FAILED:
            return fetchForgetPasswordFailed(state, action);

        default:
            return state;
    }
};

export default profileReducer;