import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import MaskedInput from "react-text-mask";
import * as Yup from "yup";
import Alert from "react-bootstrap-sweetalert";

// REDIX INIT
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

//material-UI components
import { Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

// layout components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CommonPage from "components/Pages/CommonPage";
import FieldInput from "components/FieldForm/FieldInput.jsx";
import Button from "components/CustomButtons/Button";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import Section from "components/Section";
import DocumentTitle from "components/Pages/DocumentTitle";
import Loader from "components/Loader/Loader.jsx";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import {
	SweetAlert,
	ChangePassword,
	HideAlert,
	UpdateProfile,
	GetAccountInfo,
	ShowLoader,
	HideLoader,
} from "redux/actions";

//utils
import compose from "utils/compose";

function PhoneMaskCustom(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[
				"(",
				/[1-9]/,
				/\d/,
				")",
				" ",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
			]}
			placeholderChar={"\u2000"}
			showMask
		/>
	);
}

function CPFMaskCustom(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[
				/\d/,
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
			]}
			placeholderChar={"\u2000"}
			showMask
		/>
	);
}

class EditProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumbItems: [
				{ name: "Home" },
				{ name: "Usuário" },
				{ name: "Meu perfil" },
				{ name: "Editar", last: true },
			],
			iniValues: {
				id: "",
				fullName: "",
				document: "",
				email: "",
				phoneNumber: "",
				mobilePhoneNumber: "",
			},
		};
	}

	componentDidMount() {
		if (this.props.user == null) {
			this.props.history.push("/404");
		} else {
			if (this.props.profileData == null) {
				this.props.GetAccountInfo();
			} else {
				this.setState({
					iniValues: { ...this.props.profileData.user },
				});
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.profileData !== this.props.profileData) {
			this.setState({ iniValues: { ...this.props.profileData.user } });
		}
	}

	handleResetPsswd = () => {
		this.props.SweetAlert(
			<Alert
				style={{ width: "680px", padding: "0 17px" }}
				showConfirm={false}
				showCancel={false}
				title={
					<ResetPasswordForm
						classes={this.props.classes}
						onCancel={() => this.props.HideAlert()}
					/>
				}
			/>
		);
	};

	render() {
		const { classes } = this.props;
		const { breadcrumbItems, iniValues } = this.state;

		return (
			<CommonPage>
				<DocumentTitle pageTitle={"Meu perfil"} />
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<Breadcrumbs items={breadcrumbItems} />
						<Card>
							<CardBody>
								<GridContainer>
									<Formikin
										iniValues={iniValues}
										history={this.props.history}
										classes={this.props.classes}
									/>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</CommonPage>
		);
	}
}

EditProfile.propTypes = {
	classes: PropTypes.object.isRequired,
};

const SchemaPsswd = Yup.object().shape({
	currentPassword: Yup.string().required("This field is required"),
	newPassword: Yup.string().min(6).required("This field is required"),
	newPasswordConfirm: Yup.string().when("newPassword", {
		is: (val) => (val && val.length > 0 ? true : false),
		then: Yup.string().oneOf(
			[Yup.ref("newPassword")],
			"As senhas não coincidem"
		),
	}),
});

/**
 *  @author Ayrton Gomes
 *  Function component using formik to render the form to submit the values
 */
const ResetPasswordForm = ({ classes, history, id, ...props }) => {
	const dispatchHook = useDispatch();

	const handleSave = (values) => {
		dispatchHook(ShowLoader(<Loader />));
		dispatchHook(ChangePassword(values)).then((resp) => {
			if (resp) {
				if (resp === true) {
					dispatchHook(HideLoader());
					dispatchHook(HideAlert());
				}
			}
		});
	};

	return (
		<Formik
			onSubmit={(values) => handleSave(values)}
			initialValues={{
				id: id,
				currentPassword: "",
				newPassword: "",
				newPasswordConfirm: "",
			}}
			validationSchema={SchemaPsswd}
		>
			{({ handleSubmit }) => (
				<Form>
					<GridContainer>
						<GridItem
							xs={12}
							sm={6}
							md={6}
							lg={6}
							style={{ textAlign: "left" }}
						>
							<Section title="REDEFINIR SENHA" />
						</GridItem>
						<GridItem
							xs={12}
							sm={6}
							md={6}
							lg={6}
							style={{ textAlign: "right" }}
							className={classes.noPaddingRight}
						>
							<Button
								size="sm"
								color="primary"
								style={{ marginRight: "10px" }}
								onClick={() => handleSubmit()}
							>
								<Icon
									style={{ fontSize: "14px", width: "20px" }}
									className={"icon-salvar"}
								></Icon>
								Salvar
							</Button>
							<Button
								size="sm"
								color="greenBorderButtonColor"
								style={{ marginRight: "10px" }}
								onClick={() => dispatchHook(HideAlert())}
							>
								<Icon
									style={{ fontSize: "14px", width: "20px" }}
									className={"icon-cancelar"}
								></Icon>
								Cancelar
							</Button>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<Field name="currentPassword">
							{({ field, form }) => (
								<FieldInput
									touched={form.touched.currentPassword}
									error={form.errors.currentPassword}
									form={form}
									field={field}
									label={"Senha antiga"}
									inputGrid={12}
									inputType={"password"}
								/>
							)}
						</Field>
						<Field name="newPassword">
							{({ field, form }) => (
								<FieldInput
									touched={form.touched.newPassword}
									error={form.errors.newPassword}
									form={form}
									field={field}
									label={"Nova senha"}
									inputGrid={12}
									inputType={"password"}
								/>
							)}
						</Field>
						<Field name="newPasswordConfirm">
							{({ field, form }) => (
								<>
									<FieldInput
										touched={
											form.touched.newPasswordConfirm
										}
										error={form.errors.newPasswordConfirm}
										form={form}
										field={field}
										label={"Confirmar nova senha"}
										inputGrid={12}
										inputType={"password"}
									/>
									<span
										className={classes.error}
										style={{
											color: "#f44336",
											fontSize: "12px",
											marginLeft: "16px",
											lineHeight: 0,
										}}
									>
										{form.errors.newPasswordConfirm}
									</span>
								</>
							)}
						</Field>
					</GridContainer>
				</Form>
			)}
		</Formik>
	);
};

/**
 *  @author Ayrton Gomes
 *  Function component using formik to render the form to submit the values
 */
const Formikin = ({ classes, history, iniValues, ...props }) => {
	const dispatchHook = useDispatch();

	const handleSave = (values) => {
		dispatchHook(ShowLoader(<Loader />));
		dispatchHook(UpdateProfile(values)).then((resp) => {
			if (resp) {
				dispatchHook(GetAccountInfo());
				history.push("/admin/my_profile");
			}
		});
	};

	const handleResetPsswd = () => {
		dispatchHook(
			SweetAlert(
				<Alert
					style={{ width: "680px", padding: "0 17px" }}
					showConfirm={false}
					showCancel={false}
					title={
						<ResetPasswordForm
							classes={classes}
							id={iniValues.id}
						/>
					}
				/>
			)
		);
	};

	return (
		<Formik
			onSubmit={(values) => handleSave(values)}
			initialValues={iniValues}
			enableReinitialize
		>
			{({ handleSubmit }) => (
				<Form>
					<GridContainer style={{ margin: "0px -5px" }}>
						<GridItem xs={12} sm={6} md={6} lg={6}>
							<Section
								style={{ marginLeft: "15px" }}
								title="DADOS DA CONTA"
								icon="icon-ico_cliente"
							/>
						</GridItem>
						<GridItem
							xs={12}
							sm={6}
							md={6}
							lg={6}
							style={{ textAlign: "right" }}
						>
							<Button
								size="sm"
								color="primary"
								style={{ marginRight: "10px" }}
								onClick={() => handleSubmit()}
							>
								<Icon
									style={{ fontSize: "14px", width: "20px" }}
									className={"icon-salvar"}
								></Icon>
								Salvar
							</Button>
							<Button
								size="sm"
								color="greenBorderButtonColor"
								onClick={() => history.goBack()}
								style={{ marginRight: "10px" }}
							>
								<Icon
									style={{ fontSize: "17px", width: "20px" }}
									className={"icon-cancelar"}
								></Icon>
								Cancelar
							</Button>
							<Button
								size="sm"
								color="primary"
								style={{ marginRight: "10px" }}
								onClick={() => handleResetPsswd()}
							>
								<Icon
									style={{ fontSize: "17px", width: "20px" }}
									className={"icon-senha"}
								></Icon>
								Alterar minha senha
							</Button>
						</GridItem>
						<GridItem xs={12} sm={12} md={3}>
							<PictureUpload disabled />
						</GridItem>
						<GridItem xs={12} sm={12} md={9}>
							<GridContainer>
								<Field name="fullName">
									{({ field, form }) => (
										<FieldInput
											touched={form.touched.fullName}
											error={form.errors.fullName}
											form={form}
											field={field}
											label={"Nome"}
											inputGrid={8}
										/>
									)}
								</Field>
								<Field name="document">
									{({ field, form }) => (
										<FieldInput
											touched={form.touched.document}
											error={form.errors.document}
											form={form}
											field={field}
											label={"CPF"}
											inputGrid={4}
											maskCustom={CPFMaskCustom}
										/>
									)}
								</Field>
								<Field name="mobilePhoneNumber">
									{({ field, form }) => (
										<FieldInput
											touched={
												form.touched.mobilePhoneNumber
											}
											error={
												form.errors.mobilePhoneNumber
											}
											form={form}
											field={field}
											label={"Celular"}
											inputGrid={4}
											maskCustom={PhoneMaskCustom}
										/>
									)}
								</Field>
								<Field name="phoneNumber">
									{({ field, form }) => (
										<FieldInput
											touched={form.touched.phoneNumber}
											error={form.errors.phoneNumber}
											form={form}
											field={field}
											label={"Telefone"}
											inputGrid={4}
											maskCustom={PhoneMaskCustom}
										/>
									)}
								</Field>
								<Field name="email">
									{({ field, form }) => (
										<FieldInput
											touched={form.touched.email}
											error={form.errors.email}
											form={form}
											field={field}
											label={"E-mail"}
											inputGrid={4}
										/>
									)}
								</Field>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</Form>
			)}
		</Formik>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.authentication.user,
		profileData: state.profile.profileData,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ SweetAlert, HideAlert, GetAccountInfo }, dispatch);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(sweetAlertStyle),
	withTranslation()
)(EditProfile);
