import React from "react";
import PropTypes from "prop-types";
import { ceil } from "lodash";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "90px",
		display: "flex",
	},
	battery: {
		border: "2px solid #454545",
		width: "100%",
		height: "25px",
		padding: "3px",
		borderRadius: "5px",
		position: "relative",
		"&:after": {
			content: '""',
			width: "12px",
			height: "10px",
			background: "#454545",
			display: "block",
			position: "absolute",
			right: "-12px",
			top: "3px",
			borderRadius: "0 4px 4px 0",
		},
	},
	level: {
		display: "block",
		background: "#30b455",
		height: "100%",
	},
	textLevel: {
		verticalAlign: "middle",
		lineHeight: "25px",
		fontWeight: "500",
		marginLeft: "10px",
	},
	label: {
		margin: "3px 0",
		fontSize: "14px",
		color: "#455a64",
		opacity: 0.9,
	},
}));

const BatteryLevel = ({ level, isActive, ...props }) => {
	const classes = useStyles();

	const getPercentColors = (isActive, level) => {
		if (!isActive) {
			return "#ccc";
		} else {
			if (level >= 0 && level < 32) {
				return "#e81309";
			} else if (level >= 33 && level < 65) {
				return "#ffd600";
			} else if (level >= 66 && level < 100) {
				return "#30b455";
			} else {
				return "#30b455";
			}
		}
	};

	return (
		<div style={{ marginTop: "7px" }}>
			<small className={classes.label}>Nível da bateria</small>
			<div className={classes.container}>
				<div className={classes.battery}>
					<span
						className={classes.level}
						style={{
							width: ceil(level) + "%",
							background: getPercentColors(isActive, level),
						}}
					></span>
				</div>
				<small className={classes.textLevel}>{level + "%"}</small>
			</div>
			{!isActive && (
				<small className={classes.textLevel}>Estação inativa</small>
			)}
		</div>
	);
};

BatteryLevel.propTypes = {
	level: PropTypes.number.isRequired,
};

export default BatteryLevel;
