import React from 'react'

import * as Styled from './styles'

function CustomIcon({ className, color, size, disabled = false }) {
	return (
		<Styled.Icon
			className={className}
			color={color}
			size={size}
			disabled={disabled}
		/>
	)
}

export default CustomIcon
