import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react";
import styled from "styled-components";

export const ContactModalBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	p {
		text-align: center;
		margin: 0;
		color: ${germiniDefaultColors[1]};
		font-weight: 500;
	}

	div {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 50px;
		margin-left: -5px;

		section {
			margin-bottom: 25px;
			align-items: center;
			justify-content: center;
		}
	}

	footer {
		display: flex;
		margin-top: 120px;
		width: 100%;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
`;
