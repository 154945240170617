import axios from "axios";

//GET
export const GET_ALL = "/api/weather/export/getall";
export const GET_BY_ID = "/api/weather/export/getbyid";
export const ENQUEUE = "/api/weather/export/Enqueue";

export const getAll = async (url) => {
	try {
		const { data } = await axios.get(url);
		return data;
	} catch (error) {}
};

export const getById = async (url) => {
	try {
		const { data } = await axios.get(url);
		return data;
	} catch (error) {}
};

export const enqueue = async (body) => {
	try {
		const { data } = await axios.post(ENQUEUE, body);
		return data;
	} catch (error) {}
};
