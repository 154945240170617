import axios from "axios";
export const DELETE = "/api/CGroup/Delete";

export const deleteCustomerGroup = async (id) => {
	try {
		const { data } = await axios.delete(
			DELETE + `?id=${id}&softDelete=true`
		);
		return data;
	} catch (error) {}
};
