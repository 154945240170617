import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Alert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";

// REDIX INIT
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

//material-UI components
import { Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

// layout components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CommonPage from "components/Pages/CommonPage";
import FieldData from "components/ViewDataField/FieldData";
import Button from "components/CustomButtons/Button";
import Loader from "components/Loader/Loader.jsx";
import FieldInput from "components/FieldForm/FieldInput.jsx";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import MaterialTable from "components/CustomMaterialTable/CustomMaterialTable.jsx";
import Section from "components/Section";
import DocumentTitle from "components/Pages/DocumentTitle";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import {
  GetAccountInfo,
  ShowLoader,
  HideLoader,
  SweetAlert,
  HideAlert,
  ChangePassword,
} from "redux/actions";

//utils
import compose from "utils/compose";

function PhoneMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

function CPFMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

class MyProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { name: "Home" },
        { name: "Usuário" },
        { name: "Meu perfil", last: true },
      ],
      user: {
        id: "",
        fullName: "",
        document: "",
        email: "",
        phoneNumber: "",
        mobilePhoneNumber: "",
        groups: [],
      },
    };
  }

  componentDidMount() {
    this.props.ShowLoader(<Loader />);
    if (this.props.user == null) {
      this.props.history.push("/404");
    } else {
      this.props.GetAccountInfo();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.profileData !== this.props.profileData) {
      this.setState({ user: { ...this.props.profileData.user } });
      this.props.HideLoader();
    }
  }

  handleEdit = () => {
    this.props.history.push("/admin/edit/my_profile");
  };

  handleResetPsswd = () => {
    this.props.SweetAlert(
      <Alert
        style={{ width: "680px", padding: "0 17px" }}
        showConfirm={false}
        showCancel={false}
        title={
          <ResetPasswordForm
            classes={this.props.classes}
            id={this.props.user.id}
            history={this.props.history}
          />
        }
      />
    );
  };

  render() {
    const { t, classes } = this.props;
    const { breadcrumbItems, user } = this.state;

    return (
      <CommonPage>
        <DocumentTitle pageTitle={"Meu perfil"} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Breadcrumbs items={breadcrumbItems} />
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6} lg={6}>
                    <Section title="DADOS DA CONTA" icon="icon-ico_cliente" />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => this.handleEdit()}
                      style={{ marginRight: "10px" }}
                    >
                      <Icon
                        style={{
                          fontSize: "17px",
                          width: "19px",
                          marginRight: "6px",
                        }}
                        className={"icon-editar"}
                      ></Icon>
                      Editar
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => this.handleResetPsswd()}
                    >
                      <Icon
                        style={{ fontSize: "17px", width: "20px" }}
                        className={"icon-senha"}
                      ></Icon>
                      Alterar minha senha
                    </Button>
                  </GridItem>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <PictureUpload disabled={true} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                          <FieldData
                            label="Nome"
                            value={user.fullName}
                          ></FieldData>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FieldData
                            label="CPF"
                            value={user.document}
                            maskCustom={CPFMaskCustom}
                          ></FieldData>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FieldData
                            label="Celular"
                            value={user.mobilePhoneNumber}
                            maskCustom={PhoneMaskCustom}
                          ></FieldData>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FieldData
                            label="Telefone"
                            value={user.phoneNumber}
                            maskCustom={PhoneMaskCustom}
                          ></FieldData>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FieldData
                            label="E-mail"
                            value={user.email}
                          ></FieldData>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>

                  <GridItem xs={12}>
                    <MaterialTable
                      title={"GRUPOS ASSOCIADOS"}
                      columns={[
                        {
                          title: "Grupo",
                          field: "groupName",
                          headerStyle: { textAlign: "left" },
                        },
                        {
                          title: "Descrição",
                          field: "description",
                          headerStyle: { textAlign: "left" },
                        },
                      ]}
                      data={
                        user.groups.length > 0
                          ? user.groups.map((group) => ({
                              groupName: group.name,
                              description: group.description,
                            }))
                          : []
                      }
                      search={false}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </CommonPage>
    );
  }
}

MyProfileView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const SchemaPsswd = Yup.object().shape({
  currentPassword: Yup.string().required("This field is required"),
  newPassword: Yup.string().min(6).required("This field is required"),
  newPasswordConfirm: Yup.string().when("newPassword", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("newPassword")],
      "As senhas não coincidem"
    ),
  }),
});

/**
 *  @author Ayrton Gomes
 *  Function component using formik to render the form to submit the values
 */
const ResetPasswordForm = ({ classes, history, id, ...props }) => {
  const dispatchHook = useDispatch();

  const handleSave = (values) => {
    dispatchHook(ShowLoader(<Loader />));
    dispatchHook(ChangePassword(values)).then((resp) => {
      if (resp) {
        if (resp === true) {
          dispatchHook(HideLoader());
          dispatchHook(HideAlert());
        }
      }
    });
  };

  return (
    <Formik
      onSubmit={(values) => handleSave(values)}
      initialValues={{
        id: id,
        currentPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
      }}
      validationSchema={SchemaPsswd}
    >
      {({ handleSubmit }) => (
        <Form>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "left" }}
            >
              <Section title="REDEFINIR SENHA" />
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "right" }}
              className={classes.noPaddingRight}
            >
              <Button
                size="sm"
                color="primary"
                style={{ marginRight: "10px" }}
                onClick={() => handleSubmit()}
              >
                <Icon
                  style={{ fontSize: "14px", width: "20px" }}
                  className={"icon-salvar"}
                ></Icon>
                Salvar
              </Button>
              <Button
                size="sm"
                color="greenBorderButtonColor"
                style={{ marginRight: "10px" }}
                onClick={() => dispatchHook(HideAlert())}
              >
                <Icon
                  style={{ fontSize: "14px", width: "20px" }}
                  className={"icon-cancelar"}
                ></Icon>
                Cancelar
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <Field name="currentPassword">
              {({ field, form }) => (
                <FieldInput
                  touched={form.touched.currentPassword}
                  error={form.errors.currentPassword}
                  form={form}
                  field={field}
                  label={"Senha antiga"}
                  inputGrid={12}
                  inputType={"password"}
                />
              )}
            </Field>
            <Field name="newPassword">
              {({ field, form }) => (
                <FieldInput
                  touched={form.touched.newPassword}
                  error={form.errors.newPassword}
                  form={form}
                  field={field}
                  label={"Nova senha"}
                  inputGrid={12}
                  inputType={"password"}
                />
              )}
            </Field>
            <Field name="newPasswordConfirm">
              {({ field, form }) => (
                <>
                  <FieldInput
                    touched={form.touched.newPasswordConfirm}
                    error={form.errors.newPasswordConfirm}
                    form={form}
                    field={field}
                    label={"Confirmar nova senha"}
                    inputGrid={12}
                    inputType={"password"}
                  />
                  <span
                    className={classes.error}
                    style={{
                      color: "#f44336",
                      fontSize: "12px",
                      marginLeft: "16px",
                      lineHeight: 0,
                    }}
                  >
                    {form.errors.newPasswordConfirm}
                  </span>
                </>
              )}
            </Field>
          </GridContainer>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    profileData: state.profile.profileData,

    user: state.authentication.user,
    loggedIn: state.authentication.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { SweetAlert, HideAlert, GetAccountInfo, HideLoader, ShowLoader },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sweetAlertStyle),
  withTranslation()
)(MyProfileView);
