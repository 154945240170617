import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import styled from 'styled-components'

export const IconInputBox = styled.div`
	display: flex;
	color: ${germiniDefaultColors[5]};

	div {
		display: flex;
		align-items: center;
	}

	.subtitle {
		flex-direction: column;
		align-items: flex-start;
	}

	.text-container {
		align-items: center;

		min-height: 58px;
	}

	.icon-body {
		min-width: ${({ has_message }) => (has_message ? '130px' : '40px')};
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
			'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
			'Helvetica Neue', sans-serif !important;

		.material-icons {
			color: ${({ icon_color }) => icon_color && icon_color};
			font-size: ${({ icon_size }) => icon_size && icon_size};

			margin-right: 10px;
		}
	}

	.input-body {
		max-width: 80px;
		margin: 0 10px;
	}
`
