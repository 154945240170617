import { FormControl } from '@material-ui/core'
import styled from 'styled-components'
import { Select } from '@material-ui/core'

export const MySelect = styled(Select)`
	&.MuiMenu-paper {
		max-height: 400px !important;
	}
`

export const FormLabel = styled(FormControl)`
	width: ${({ width }) => width};

	.MuiFormLabel-root {
		font-size: 14px;
	}

	.MuiInputBase-input {
		padding: 0 0 12px;
	}
`
