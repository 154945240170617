import { Modal, Paper } from "@material-ui/core";
import styled from "styled-components";
import Button from "components/CustomButtons/Button.jsx";

export const MyButton = styled(Button)`
	cursor: pointer;
	border: none;
	background: #f44336;
	&:hover {
		opacity: 0.7;
	}
	svg {
		margin-right: 3px;
	}
	padding-left: 6px;
	padding-right: 6px;
	border-radius: 6px;
`;

export const MyIcon = styled.div`
	color: red !important;
	font-size: 16px;
	margin-right: 10px;

	&.icon-cancelar::before {
		color: white;
	}
`;
