import { Grid } from "@material-ui/core";
import RenderDescription from "components/NotificationModal/RenderDescription";
import { NotificationSkeleton } from "components/NotificationModal/styles";
import React from "react";

import * as Styled from "./styles";

function SketchView({
	sketch: { date, destiny, title, description },
	loading,
	noTitle,
}) {
	return loading ? (
		<div>
			<NotificationSkeleton
				variant="rect"
				width="840px"
				height="40px"
			></NotificationSkeleton>
			<NotificationSkeleton
				variant="rect"
				width="840px"
				height="40px"
			></NotificationSkeleton>
			<NotificationSkeleton
				variant="rect"
				width="840px"
				height="305px"
			></NotificationSkeleton>
		</div>
	) : (
		<Styled.SketchContainer
			container
			spacing={!noTitle ? 3 : 0}
			no_title={!noTitle ? 1 : 0}
		>
			<Grid item xs={12}>
				{!noTitle && <header>Título</header>}
				<small className="title-small">{title}</small>
			</Grid>
			<Grid item xs={12}>
				{!noTitle && <header>Data de envio</header>}
				<small>{date}</small>
			</Grid>

			<Grid item xs={12} style={{ paddingBottom: 15 }}>
				{!noTitle && <header>Descrição</header>}
				<RenderDescription renderComp={description}></RenderDescription>
			</Grid>
		</Styled.SketchContainer>
	);
}

export default SketchView;
