import React, { useEffect, useState } from 'react'
import CustomDate from 'components/DatePicker'
import { Controller, useForm } from 'react-hook-form'
import {
	KeyboardArrowUp,
	KeyboardArrowDown,
	MoreVert,
} from '@material-ui/icons'
import { IconButton, Grid } from '@material-ui/core'
import * as Styled from './styles'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import CustomSelect from 'components/CustomSelect'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { uniqueId } from 'lodash'

const schema = Yup.object().shape({})

function FilterBox({ classes, filter, setFilter }) {
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			...filter,
		},
	})

	// console.log(filter);

	const [open, setOpen] = useState(false)

	const submitForm = (values) => {
		setFilter({ ...values, filterRefresh: uniqueId('id') })
	}

	const handleClearFilter = () => {
		reset()
		setFilter({})
	}

	return (
		<form style={{ width: '100%' }} onSubmit={handleSubmit(submitForm)}>
			<Styled.SearchBox container className={classes.filterForm}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<h5>Opções de busca</h5>
				</Grid>
				<Grid
					item
					container
					xs={12}
					sm={12}
					md={12}
					lg={12}
					spacing={3}
				>
					<Grid item xs={4}>
						<Controller
							control={control}
							name="Name"
							render={({
								field: { onChange, onBlur, value },
							}) => (
								<CustomInput
									labelText="Apelido da estação"
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										onChange,
										onBlur,
										value,
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Controller
							control={control}
							name="GateStationId"
							render={({
								field: { onChange, onBlur, value },
							}) => (
								<CustomInput
									labelText="Nº de série"
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										onChange,
										onBlur,
										value,
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Controller
							control={control}
							name="OwnerFullName"
							render={({
								field: { onChange, onBlur, value },
							}) => (
								<CustomInput
									labelText="Cliente"
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										onChange,
										onBlur,
										value,
									}}
								/>
							)}
						/>
					</Grid>
				</Grid>
				{open && (
					<Grid
						item
						container
						xs={12}
						sm={12}
						md={12}
						lg={12}
						spacing={3}
					>
						<Grid item xs={4}>
							<Controller
								control={control}
								name="BluetoothDeviceId"
								render={({
									field: { onChange, onBlur, value },
								}) => (
									<CustomInput
										labelText="ID do aparelho Bluetooth"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											onChange,
											onBlur,
											value,
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4} style={{ alignSelf: 'center' }}>
							<Controller
								control={control}
								name="Status"
								render={({
									field,
									onChange,
									onBlur,
									value,
								}) => (
									<CustomSelect
										{...field}
										header="Status"
										width="100%"
										valueList={[
											{
												value: 0,
												label: 'Ativo',
											},
											{
												value: 1,
												label: 'Atenção',
											},
											{
												value: 2,
												label: 'Critico',
											},
											{
												value: 3,
												label: 'Offline - não há dado',
											},
											{
												value: 4,
												label: 'Offline - coleta manual',
											},
											{
												value: 5,
												label: 'Desativado',
											},
										]}
										labelFirst={'Escolha um status'}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4}>
							<Controller
								control={control}
								name="Password"
								render={({
									field: { onChange, onBlur, value },
								}) => (
									<CustomInput
										labelText="Chave de segurança"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											onChange,
											onBlur,
											value,
										}}
									/>
								)}
							/>
						</Grid>
					</Grid>
				)}
				{open && (
					<Grid
						item
						container
						xs={12}
						sm={12}
						md={12}
						lg={12}
						spacing={3}
						style={{ marginTop: '5px' }}
					>
						<Grid item xs={4} style={{ alignSelf: 'center' }}>
							<Controller
								control={control}
								name="TransmissionType"
								render={({
									field,
									onChange,
									onBlur,
									value,
								}) => (
									<CustomSelect
										{...field}
										header="Provedor de sinal"
										width="100%"
										valueList={[
											{
												value: 0,
												label: 'Sem Modem ou Wi-fi',
											},
											{
												value: 1,
												label: 'Modem',
											},
											{
												value: 2,
												label: 'Wi-fi',
											},
										]}
										labelFirst={
											'Escolha um provedor de sinal'
										}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4} style={{ alignSelf: 'center' }}>
							<Controller
								control={control}
								name="Signal"
								render={({
									field,
									onChange,
									onBlur,
									value,
								}) => (
									<CustomSelect
										{...field}
										header="Qualidade do Sinal"
										width="100%"
										valueList={[
											{
												value: 0,
												label: 'Sem sinal',
											},
											{
												value: 5,
												label: 'Terrível',
											},
											{
												value: 4,
												label: 'Ruim',
											},
											{
												value: 3,
												label: 'Regular',
											},
											{
												value: 2,
												label: 'Bom',
											},
											{
												value: 1,
												label: 'Ótimo',
											},
										]}
										labelFirst={
											'Escolha uma qualidade de dados'
										}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4} style={{ alignSelf: 'center' }}>
							<Controller
								control={control}
								name="DataQuality"
								render={({
									field,
									onChange,
									onBlur,
									value,
								}) => (
									<CustomSelect
										{...field}
										header="Qualidade de dados"
										width="100%"
										valueList={[
											{
												value: 3,
												label: 'Excelente',
											},
											{
												value: 2,
												label: 'Boa',
											},
											{
												value: 1,
												label: 'Regular',
											},
											{
												value: 0,
												label: 'Sem dados',
											},
										]}
										labelFirst={
											'Escolha uma qualidade de dados'
										}
									/>
								)}
							/>
						</Grid>
					</Grid>
				)}
				{open && (
					<Grid
						item
						container
						xs={12}
						sm={12}
						md={12}
						lg={12}
						spacing={3}
						style={{ marginTop: '15px', marginBottom: '20px' }}
					>
						<Grid item xs={4} style={{ alignSelf: 'center' }}>
							<Controller
								control={control}
								name="BatteryLevel"
								render={({
									field,
									onChange,
									onBlur,
									value,
								}) => (
									<CustomSelect
										{...field}
										header="Nivel de Bateria"
										width="100%"
										valueList={[
											{
												value: 0,
												label: 'Baixa',
											},
											{
												value: 1,
												label: 'Media',
											},
											{
												value: 2,
												label: 'Alta',
											},
										]}
										labelFirst={
											'Escolha um nivel de bateria'
										}
									/>
								)}
							/>
						</Grid>
					</Grid>
				)}
				<Grid item xs={12} container>
					<Grid item sm={4} xm={0}></Grid>
					<Grid
						item
						xs={12}
						sm={3}
						md={4}
						lg={4}
						xl={4}
						style={{ textAlign: 'center', marginTop: '10px' }}
					>
						{!open && (
							<div>
								<MoreVert style={{ color: '#ccc' }} />
							</div>
						)}
						<IconButton
							className={classes.filterArrowButton}
							onClick={() => setOpen(!open)}
						>
							{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						</IconButton>
					</Grid>

					<Grid
						item
						xs={12}
						sm={4}
						style={{
							textAlign: 'left',
							marginTop: !open ? '35px' : 0,
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<Button
							size="sm"
							color="primary"
							type="submit"
							style={{ marginRight: '10px' }}
						>
							Pesquisar
						</Button>
						<Button
							size="sm"
							color="greenBorderButtonColor"
							onClick={() => handleClearFilter()}
						>
							Limpar
						</Button>
					</Grid>
				</Grid>
			</Styled.SearchBox>
		</form>
	)
}

export default FilterBox
