import React, { useMemo, useState } from 'react'
import CommonPage from 'components/Pages/CommonPage'
import { uniqueId } from 'lodash'

import DocumentTitle from 'components/Pages/DocumentTitle'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'

import { useTranslation } from 'react-i18next'
import { Icon, withStyles, Grid } from '@material-ui/core'

import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'
import FilterBox from './components/FilterBox'
import CustomStep from 'components/CustomStep'
import SentTable from './components/SentTable'
import SketchTable from './components/SketchTable'
import FileTable from './components/FileTable'
import { goTo } from 'utils/utils'
import NotificationModal from 'components/NotificationModal'
import ConfirmModal from 'components/ConfirmModal'
import { format, isValid } from 'date-fns'
import {
	getUsersManager,
	GET_USER_MANAGER_ALL,
} from 'services/api/UserManager/api'

import { useDispatch } from 'react-redux'
import { showNotification as showAlert } from 'redux/actions/notification'
import useSWR from 'swr'
import { archiveMessageManager } from 'services/api/MessageManager/api'
import { SuccessNotificationComponent } from 'redux/react-notifications'

const tabList = [
	{
		name: 'Envios',
		icon: 'icon-enviar',
	},
	{
		name: 'Rascunhos',
		icon: 'icon-rascunho',
	},
	{
		name: 'Arquivos',
		icon: 'icon-arquivar',
	},
]

const breadcrumbItems = [
	{ name: 'Home' },
	{ name: 'Configurações' },
	{ name: 'Mensageria', last: true },
]

function Message({ classes }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [filter, setFilter] = useState({
		title: '',
		sendDateGt: null,
		sendDateLt: null,
		userId: '',
		summary: '',
		filterRefresh: false,
	})

	const urlFilter = useMemo(() => {
		var msg = `${
			filter.title ? ` and contains(Title , '${filter.title}')` : ''
		}${
			filter.summary
				? ` and contains(Description,'${filter.summary}')`
				: ''
		}${
			isValid(filter.sendDateGt)
				? ` and sendDate ge ${format(filter.sendDateGt, 'yyyy-MM-dd')}`
				: ''
		}${
			isValid(filter.sendDateLt)
				? ` and sendDate le ${format(filter.sendDateLt, 'yyyy-MM-dd')}`
				: ''
		}${
			filter.userId
				? ` and Users/All(x: x/userId eq '${filter.userId}')`
				: ''
		}`
		return msg
	}, [
		filter.sendDateGt,
		filter.sendDateLt,
		filter.summary,
		filter.title,
		filter.userId,
	])

	const [showNotification, setShowNotification] = useState({
		loading: false,
		open: false,
		content: () => null,
		title: '',
		headerIcon: false,
	})

	const [confirmModalProps, setConfirmModalProps] = useState({
		header: true,
		open: false,
		type: '',
		text: '',
		subText: '',
		loading: false,
		id: '',
	})

	const containerList = [
		{
			name: 'Envios',
			children: (
				<SentTable
					setShowNotification={setShowNotification}
					setConfirmModalProps={setConfirmModalProps}
					filter={urlFilter}
					mutate={filter.filterRefresh}
				/>
			),
		},
		{
			name: 'Rascunhos',
			children: (
				<SketchTable
					setShowNotification={setShowNotification}
					setConfirmModalProps={setConfirmModalProps}
					filter={urlFilter}
					mutate={filter.filterRefresh}
				/>
			),
		},
		{
			name: 'Arquivos',
			children: (
				<FileTable
					setShowNotification={setShowNotification}
					setConfirmModalProps={setConfirmModalProps}
					filter={urlFilter}
					mutate={filter.filterRefresh}
				/>
			),
		},
	]

	function hideModal() {
		setConfirmModalProps({ open: false, type: '', text: '' })
	}

	const { data: users } = useSWR(`${GET_USER_MANAGER_ALL}`, (url) =>
		getUsersManager(url),
	)

	async function handleConfirm() {
		if (confirmModalProps.type === 'archive') {
			setConfirmModalProps((prevState) => ({
				...prevState,
				loading: true,
			}))
			const data = await archiveMessageManager(confirmModalProps.id)
			if (data) {
				dispatch(
					showAlert(
						SuccessNotificationComponent('Arquivado com sucesso!'),
					),
				)
				setFilter((prevState) => ({
					...prevState,
					filterRefresh: uniqueId('id'),
				}))
				setConfirmModalProps((prevState) => ({
					...prevState,
					loading: false,
					open: false,
				}))
			}
		}
	}

	return (
		<CommonPage>
			<DocumentTitle pageTitle={t('MENU_FIRMWARE')} />
			<NotificationModal
				headerTitle={showNotification.title}
				width="900px"
				height="500px"
				haveEdit={showNotification.haveEdit}
				headerIcon={showNotification.headerIcon}
				closeByClick={() =>
					setShowNotification((prevState) => ({
						...prevState,
						open: false,
						haveEdit: false,
					}))
				}
				onClose={() =>
					setShowNotification((prevState) => ({
						...prevState,
						open: false,
						haveEdit: false,
					}))
				}
				open={showNotification.open}
				buttonTitle="BUTTON_CLOSE"
			>
				{showNotification.content}
			</NotificationModal>
			<ConfirmModal
				header={confirmModalProps.header}
				open={confirmModalProps.open}
				type={confirmModalProps.type}
				text={confirmModalProps.text}
				subText={confirmModalProps.subText}
				iconAlert="icon-mensagens"
				onConfirm={() =>
					confirmModalProps.type === 'cancel'
						? hideModal()
						: handleConfirm()
				}
				onCancel={() => hideModal()}
				loading={confirmModalProps.loading}
			/>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<Breadcrumbs items={breadcrumbItems} />
					<Card>
						<CardBody>
							<Grid container>
								<Grid item xs={12} container>
									<Grid item xs={4}>
										<Section
											title="MENSAGENS"
											icon="icon-mensagens"
										/>
									</Grid>
									<Grid
										item
										xs={8}
										container
										justify="flex-end"
									>
										<Button
											size="sm"
											color="primary"
											// disabled={!hasWritePermission}
											onClick={() =>
												goTo('settings/message/crud')
											}
											style={{ marginRight: 30 }}
										>
											<Icon
												style={{
													fontSize: '18px',
													width: '30px',
												}}
												className={'icon-email_add'}
											/>
											Nova mensagem
										</Button>
									</Grid>
								</Grid>
								<FilterBox
									classes={classes}
									filter={filter}
									setFilter={setFilter}
									users={users?.map((item) => ({
										label: item.value,
										value: item.key,
									}))}
								/>
							</Grid>
							<CustomStep
								tabList={tabList}
								containerList={containerList}
							/>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</CommonPage>
	)
}

export default withStyles(sweetAlertStyle)(Message)
