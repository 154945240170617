import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem";
import Section from "components/Section";
import { add, isBefore, isValid, sub } from "date-fns";
import CustomSelect from "components/CustomSelect";
import CustomDate from "components/DatePicker";
import { Grid } from "@material-ui/core";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomIcon from "components/CustomIcon";
import useSWR from "swr";
import axios from "axios";
import * as Styled from "./styles";
import {
	Icon,
	Slide,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import {
	GET_STATION_MANAGER_BY_ID,
	getStationManagerById,
} from "services/api/StationManager/api";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const schema = Yup.object().shape({
	sendDateGt: Yup.mixed()
		.required("Campo obrigatório")
		.test(
			"date compare",
			"obrigatório DE menor que ATÉ",
			function (sendDateGt) {
				const { sendDateLt, period } = this.parent;
				if (period === 2) {
					if (
						(isValid(sendDateGt) &&
							isValid(sendDateLt) &&
							isBefore(sendDateGt, sendDateLt)) ||
						(!sendDateGt && !sendDateLt)
					) {
						return true;
					}
					return false;
				}
				return true;
			}
		),

	sendDateLt: Yup.mixed()
		.required("Campo obrigatório")
		.test(
			"date compare",
			"obrigatório ATE maior que DE",
			function (sendDateLt) {
				const { sendDateGt, period } = this.parent;
				if (period === 2) {
					if (
						(isValid(sendDateGt) &&
							isValid(sendDateLt) &&
							isBefore(sendDateGt, sendDateLt)) ||
						(!sendDateGt && !sendDateLt)
					) {
						return true;
					}
					return false;
				}
				return true;
			}
		),
});

function ModalExport({ onCancel, showModal, id }) {
	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			period: 2,
			sendDateGt: null,
			sendDateLt: null,
		},
	});
	const userData = JSON.parse(localStorage.user);

	useEffect(() => {
		if (watch("period") === 0) {
			setValue(
				"sendDateGt",
				sub(new Date(), {
					days: 0,
				})
			);
			setValue("sendDateLt", new Date());
		}
		if (watch("period") === 1) {
			setValue(
				"sendDateGt",
				sub(new Date(), {
					days: 7,
				})
			);
			setValue("sendDateLt", new Date());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setValue, watch("period")]);

	const { data: station } = useSWR(`${GET_STATION_MANAGER_BY_ID}`, () =>
		getStationManagerById(id)
	);

	async function submitForm(event) {
		let data = {
			start: event.sendDateGt.toISOString(),
			end: event.sendDateLt.toISOString(),
			stationId: `${id}`,
			requestUserId: userData.id,
		};

		await axios
			.post("/api/weather/export/Enqueue", data)
			.then((obj) => {
				onCancel();
			})
			.catch((err) => {});
	}

	return (
		<GridContainer justify="center">
			<GridItem xs={12} sm={12} md={12}>
				<Dialog
					fullWidth={true}
					maxWidth="md"
					open={showModal.open}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => onCancel}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<form
						onSubmit={handleSubmit(submitForm)}
						style={{ width: "100%" }}
					>
						<DialogTitle
							id="classic-modal-slide-title"
							disableTypography
						>
							<div style={{ float: "left" }}>
								<Section
									title="EXPORTAR DADOS"
									subtitle={"Estação " + station?.name}
									icon="icon-estacao"
								/>
							</div>
							<DialogActions>
								<Button
									size="sm"
									color="primary"
									type="submit"
									onClick={handleSubmit(submitForm)}
								>
									<Icon className="icon-enviar" />
									Confirmar
								</Button>
								<Button
									size="sm"
									color="white"
									style={{
										color: "#034D8A",
										border: "1px solid #034D8A",
									}}
									onClick={onCancel}
								>
									<Icon className="icon-cancelar" />
									Fechar
								</Button>
							</DialogActions>
						</DialogTitle>
						<DialogContent id="classic-modal-slide-description">
							<Styled.SearchBox container xs={12}>
								<Grid
									item
									xs={5}
									style={{
										alignSelf: "center",
										marginTop: "16px",
									}}
								>
									<Controller
										control={control}
										name="period"
										render={({ field }) => (
											<CustomSelect
												{...field}
												header="Período"
												width="100%"
												valueList={[
													{
														value: 0,
														label: "Hoje",
													},
													{
														value: 1,
														label: "Últimos 7 dias",
													},
													{
														value: 2,
														label: "Personalizado",
													},
												]}
												labelFirst={"Escolha um status"}
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									container
									spacing={2}
									style={{ marginLeft: "10px" }}
								>
									<Grid item xs={5}>
										<Controller
											render={({ field }) => (
												<CustomDate
													{...field}
													label="De"
													disabled={
														watch("period") !== 2
													}
													maxDate={
														isValid(
															watch("sendDateLt")
														)
															? sub(
																	watch(
																		"sendDateLt"
																	),
																	{
																		days: 1,
																	}
															  )
															: null
													}
													formatDate="dd/MM/yyyy"
													error={!!errors.sendDateGt}
													helperText={
														errors?.sendDateGt
															?.message
													}
												/>
											)}
											name="sendDateGt"
											control={control}
										/>
									</Grid>
									<Grid item xs={5}>
										<Controller
											render={({ field }) => (
												<CustomDate
													{...field}
													label="Até"
													minDate={
														isValid(
															watch("sendDateGt")
														)
															? add(
																	watch(
																		"sendDateGt"
																	),
																	{
																		days: 1,
																	}
															  )
															: null
													}
													formatDate="dd/MM/yyyy"
													disabled={
														watch("period") !== 2
													}
													error={!!errors.sendDateLt}
													helperText={
														errors?.sendDateLt
															?.message
													}
												/>
											)}
											name="sendDateLt"
											control={control}
										/>
									</Grid>
								</Grid>
							</Styled.SearchBox>
						</DialogContent>
						<GridItem
							style={{
								marginBottom: "30px",
							}}
							xs={12}
						>
							<span>
								<CustomIcon
									className="icon-atencao"
									color="#4993D0"
									size="25px"
								/>
								<br />
								<div
									style={{
										backgroundColor: "#F5F5F5",
										padding: "10px",
									}}
								>
									Ao confirmar será gerado um arquivo para
									download.
									<br />
									Os dados gerados no período informado serão
									enviados da 0:00 hora do dia inicial até a
									data final.
									<br />O processo pode levar alguns minutos.
								</div>
							</span>
						</GridItem>
					</form>
				</Dialog>
			</GridItem>
		</GridContainer>
	);
}

export default ModalExport;
