import Alert from 'react-bootstrap-sweetalert'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Loader from 'components/Loader/Loader.jsx'
import CustomIcon from 'components/CustomIcon'
import CustomArrow from 'components/CustomArrow'
import Button from 'components/CustomButtons/Button.jsx'
import { Icon } from '@material-ui/core'
import CustomTable, {
	initialPagination,
	initialSort,
} from 'components/CustomTable'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import { history } from 'utils'
import ModalMap from 'pages/Stations/components/ModalMap'
import RemoveUserModal from 'pages/Stations/components/RemoveUserModal'
import { useDispatch } from 'react-redux'
import {
	ShowLoader,
	SweetAlert,
	HideAlert,
	DisassociateUser,
	DeleteStation,
	HideLoader,
} from 'redux/actions'

import { GET_STATION_WITH_STATUS } from 'services/api/StationManager/api'
import Tooltip from '@material-ui/core/Tooltip'
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom'
import moment from 'moment'

function ListTable({ classes, filter, mutate }) {
	const dispatch = useDispatch()
	const [pagination, setPagination] = useState(initialPagination)
	const [sort, setSort] = useState(initialSort)
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState({})
	const [reqBody, setReqBody] = useState({})
	const [isFirst, setIsFirst] = useState(true)
	const [showRemoveUserModal, setShowRemoveUserModal] = useState({
		open: false,
		fullName: null,
		id: null,
	})
	const [showModal, setShowModal] = useState({
		loading: false,
		geoLat: null,
		geoLong: null,
		name: null,
		owner: {
			fullName: null,
		},
		open: false,
	})
	const [columnOrder, setColumnOrder] = useState({
		columnText: '',
		columnAction: '',
	})

	const sortReference = {
		0: 'status',
		1: 'name',
		2: 'owner',
		3: 'lastStatusAt',
		4: 'dataQuality',
		5: 'transmissionSignalLevel',
		6: 'battery',
	}
	const orderReferenceText = {
		status: 'Status',
		name: 'Apelido da estação',
		owner: 'Nome do cliente',
		lastStatusAt: 'Atualização climática',
		dataQuality: 'Qualidade dos dados',
		transmissionSignalLevel: 'Qualidade do sinal',
		battery: 'Bateria',
	}
	const sortReferenceDefault = ''

	function useQuery() {
		return new URLSearchParams(useLocation().search)
	}
	const query = useQuery()

	useEffect(() => {
		setColumnOrder({
			columnText: orderReferenceText[reqBody.orderBy],

			columnAction: reqBody.orderType,
		})
	}, [reqBody])

	useEffect(() => {
		if (query.get('body')) {
			if (isFirst) {
				let value = JSON.parse(query.get('body'))
				setIsFirst(false)
				setReqBody(() => value)
				setPagination((prevState) => ({
					...prevState,
					pageSize: value.top,
				}))
			} else {
				if (sort.orderBy != '') {
					setReqBody(() => ({
						...filter,
						skip: pagination.page,
						top: pagination.pageSize,
						orderBy: sortReference[sort.orderBy],
						orderType: sort.orderType,
					}))
				}
			}
		} else {
			setReqBody(() => ({
				...filter,
				skip: pagination.page,
				top: pagination.pageSize,
				orderBy: sortReference[sort.orderBy] || sortReferenceDefault,
				orderType: sort.orderType,
			}))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		filter,
		pagination.currentPage,
		pagination.pageSize,
		sort.orderBy,
		sort.orderType,
	])

	const handleGetStationData = async (body) => {
		dispatch(ShowLoader(<Loader />))
		try {
			const res = await axios.post(`${GET_STATION_WITH_STATUS}`, body)

			// TODO: error handling

			setData(res?.data)
		} catch (error) {
		} finally {
			dispatch(HideLoader(<Loader />))
		}
	}

	useEffect(() => {
		handleGetStationData(reqBody)
	}, [reqBody])

	useEffect(() => {
		if (mutate !== false) {
			setPagination(initialPagination)
		}
	}, [mutate])

	useEffect(() => {
		setLoading(true)

		if (data?.items) {
			setPagination((prevState) => ({
				...prevState,
				recordCount: data.count,
			}))
			setLoading(false)
		}
	}, [data])

	useEffect(() => {
		data?.items?.map((station) => {
			if (!isNaN(station.battery)) {
				station.battery = station.battery
					? Math.round(station?.battery) + '%'
					: 'Sem Dados'
			}
		})
	}, [data, pagination.currentPage, pagination.pageSize])

	useEffect(() => {
		data?.items?.map((station) => {
			// eslint-disable-next-line default-case
			switch (station?.status) {
				case 0:
					station.statusText = 'Ativo'
					station.statusIcon = 'icon-ativo'
					station.statusColor = germiniDefaultColors[0]
					break
				case 1:
					station.statusText = 'Atenção'
					station.statusIcon = 'icon-atencao'
					station.statusColor = germiniDefaultColors[13]
					break
				case 2:
					station.statusText = 'Critico'
					station.statusIcon = 'icon-critico'
					station.statusColor = germiniDefaultColors[10]
					break
				case 3:
					station.statusText = 'Offline - não há dados'
					station.statusIcon = 'icon-offline'
					station.statusColor = germiniDefaultColors[6]
					break
				case 4:
					station.statusText = 'Offline - coleta manual'
					station.statusIcon = 'icon-offline'
					station.statusColor = germiniDefaultColors[14]
					break
				case 5:
					station.statusText = 'Desativado'
					station.statusIcon = 'icon-desativado'
					station.statusColor = germiniDefaultColors[11]
					break
			}
		})
	}, [data, pagination.currentPage, pagination.pageSize])

	useEffect(() => {
		data?.items?.map((station) => {
			// eslint-disable-next-line default-case
			switch (station?.dataQuality) {
				case 3:
					station.dataQualityText = 'Excelente'
					station.dataQualityIcon = 'icon-qualidade_dados'
					station.dataQualityColor = germiniDefaultColors[0]
					break
				case 2:
					station.dataQualityText = 'Boa'
					station.dataQualityIcon = 'icon-qualidade_dados'
					station.dataQualityColor = germiniDefaultColors[1]
					break
				case 1:
					station.dataQualityText = 'Regular'
					station.dataQualityIcon = 'icon-qualidade_dados'
					station.dataQualityColor = germiniDefaultColors[13]
					break
				case 0:
					station.dataQualityText = 'Sem dados'
					station.dataQualityIcon = 'icon-qualidade_dados'
					station.dataQualityColor = germiniDefaultColors[6]
					break
			}

			// eslint-disable-next-line default-case
			switch (station?.transmissionSignalLevel) {
				case 5:
					station.signalColor = germiniDefaultColors[11]
					station.signalQualityText = 'Terrível'
					break
				case 4:
					station.signalColor = germiniDefaultColors[13]
					station.signalQualityText = 'Ruim'
					break
				case 3:
					station.signalColor = germiniDefaultColors[13]
					station.signalQualityText = 'Regular'
					break
				case 2:
					station.signalColor = '#30b455'
					station.signalQualityText = 'Bom'
					break
				case 1:
					station.signalColor = '#30b455'
					station.signalQualityText = 'Ótimo'
					break
				case 0:
					station.signalColor = '#586B74'
					station.signalQualityText = 'Sem sinal'
					break
			}
		})
	}, [data, pagination.currentPage, pagination.pageSize])

	function handleAlertDelete(rowData) {
		dispatch(
			SweetAlert(
				<Alert
					warning
					style={{
						display: 'block',
						marginTop: '-160px',
						width: '630px',
					}}
					title={'Deletar'}
					onConfirm={async () => {
						if (rowData?.ownerId) {
							dispatch(HideAlert())
							// debugger
							// await dispatch(DisassociateUser(rowData.ownerId))
							dispatch(ShowLoader(<Loader />))

							await dispatch(DeleteStation(rowData.id))

							handleGetStationData(reqBody)
						} else {
							dispatch(HideAlert())
							await dispatch(DeleteStation(rowData.id))

							handleGetStationData(reqBody)
						}
					}}
					onCancel={() => dispatch(HideAlert())}
					confirmBtnCssClass={classes.button + ' ' + classes.danger}
					cancelBtnCssClass={
						classes.button + ' ' + classes.greenBorderButtonColor
					}
					confirmBtnText={'Confirmar'}
					cancelBtnText={'Cancelar'}
					showCancel
				>
					<h3
						className={classes.cardTitle}
						style={{ fontWeight: 400 }}
					>
						{rowData.owner && (
							<>
								A estação está vinculada ao cliente{' '}
								<b>{rowData.owner.fullName}</b>,<br></br>
								ao confirmar, essa ação o cliente perderá o
								vínculo e a estação será removida da base de
								dados.
								<br></br>
							</>
						)}
						<b>Deseja realmente excluir essa estação?</b>
					</h3>
				</Alert>,
			),
		)
	}

	function handlewExportStation(id) {
		history.push(`/admin/station/export/${id}`)
	}

	function handlewStationView(id) {
		const string = `{"BluetoothDeviceId":${
			reqBody.BluetoothDeviceId ? '"' + reqBody.BluetoothDeviceId : null
		},"GateStationId":${
			reqBody.GateStationId ? '"' + reqBody.GateStationId + '"' : null
		},"Name":${
			reqBody.Name ? '"' + reqBody.Name + '"' : null
		},"OwnerFullName":${
			reqBody.OwnerFullName ? '"' + reqBody.OwnerFullName + '"' : null
		},"Password":${
			reqBody.Password ? '"' + reqBody.Password + '"' : null
		},"Status":${reqBody.Status ? reqBody.Status : 6},"orderBy":"${
			reqBody.orderBy ? reqBody.orderBy : null
		}","orderType":"${
			reqBody.orderType ? reqBody.orderType : null
		}","skip":${pagination.page},"top":${
			pagination.pageSize
		},"filterRefresh":"${
			reqBody.filterRefresh ? reqBody.filterRefresh : null
		}"}`
		history.push(`/admin/station/view/${id}?body=${string}`)
	}

	function handleRemoveAssociations(rowData) {
		setShowRemoveUserModal({
			open: true,
			id: rowData.id,
			name: rowData?.owner?.fullName ? rowData?.owner?.fullName : null,
		})
	}

	function hideRemoveUserModal() {
		setShowRemoveUserModal({
			open: false,
			id: null,
			name: null,
		})
	}

	function showMap(rowData) {
		setShowModal({
			loading: false,
			name: rowData.name,
			owner: {
				fullName: rowData.owner,
			},
			open: true,
			geoLat: rowData.geoLat,
			geoLong: rowData.geoLong,
		})
	}

	function hideModal() {
		setShowModal({
			loading: false,
			geoLat: null,
			geoLong: null,
			name: null,
			owner: {
				fullName: null,
			},
			open: false,
		})
	}

	const actions = [
		{
			icon: () => (
				<span className="button-span">
					<CustomIcon className="icon-map" color="#fff" size="14px" />
				</span>
			),
			tooltip: 'Ver Mapa',
			onClick: (event, rowData) => {
				showMap(rowData)
			},
		},
		{
			icon: () => (
				<span className="button-span">
					<CustomIcon
						className="icon-bot_busca"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Ver Mais',
			onClick: (event, rowData) => {
				handlewStationView(rowData.id)
			},
		},
		// {
		// 	icon: () => (
		// 		<span className="button-span yellow">
		// 			<CustomIcon
		// 				className="icon-desvincular_prorietario"
		// 				color="#fff"
		// 				size="14px"
		// 				disabled={true}
		// 			/>
		// 		</span>
		// 	),
		// 	tooltip: 'Desvincular',
		// 	onClick: (event, rowData) => {
		// 		handleRemoveAssociations(rowData)
		// 	},
		// },
		(rowData) => ({
			icon:
				rowData?.ownerId == null
					? () => (
							<span className="button-span disabled">
								<CustomIcon
									className="icon-desvincular_prorietario"
									color="#fff"
									size="14px"
									disabled={true}
								/>
							</span>
					  )
					: () => (
							<span className="button-span yellow">
								<CustomIcon
									className="icon-desvincular_prorietario"
									color="#fff"
									size="14px"
									disabled={true}
								/>
							</span>
					  ),

			tooltip: rowData?.ownerId == null ? null : 'Desvincular',
			onClick: (event, rowData) => {
				handleRemoveAssociations(rowData)
			},
		}),
		{
			icon: () => (
				<span className="button-span">
					<CustomIcon
						className="icon-exportar"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Exportar Dados',
			onClick: (event, rowData) => {
				handlewExportStation(rowData.id)
			},
		},
		{
			icon: () => (
				<span className="button-span red">
					<CustomIcon
						className="icon-bot_close"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Excluir',
			onClick: (event, rowData) => {
				handleAlertDelete(rowData)
			},
		},
	]

	const columns = [
		{
			title:
				columnOrder.columnText === 'Status' ? (
					<CustomArrow
						text={columnOrder.columnText}
						order={columnOrder.columnAction}
					/>
				) : (
					'Status'
				),
			field: 'status',
			headerStyle: {
				textAlign: 'center',
			},
			render: function (rowData) {
				return (
					<>
						<Tooltip title={rowData.statusText}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									marginLeft: '-30px',
								}}
							>
								<CustomIcon
									className={rowData.statusIcon}
									color={rowData.statusColor}
									size="18px"
								/>
							</div>
						</Tooltip>
					</>
				)
			},
		},
		{
			title:
				columnOrder.columnText === 'Apelido da estação' ? (
					<CustomArrow
						text={columnOrder.columnText}
						order={columnOrder.columnAction}
					/>
				) : (
					'Apelido da estação'
				),
			field: 'name',
			// cellStyle: { maxWidth: "100px", minWidth: "100px" },
			// headerStyle: { maxWidth: "100px", minWidth: "100px" },
			type: 'string',
		},
		{
			title:
				columnOrder.columnText === 'Nome do cliente' ? (
					<CustomArrow
						text={columnOrder.columnText}
						order={columnOrder.columnAction}
					/>
				) : (
					'Nome do cliente'
				),
			field: 'owner.fullName',
			// cellStyle: { maxWidth: "100px", minWidth: "100px" },
			// headerStyle: { maxWidth: "100px", minWidth: "100px" },
			type: 'string',
		},
		{
			title:
				columnOrder.columnText === 'Atualização climática' ? (
					<CustomArrow
						text={columnOrder.columnText}
						order={columnOrder.columnAction}
					/>
				) : (
					'Atualização climática'
				),

			render: function (rowData) {
				if (rowData?.lastWeatherAt === '1900-01-01T00:00:00+00:00') {
					return '-'
				}

				const date = moment(rowData?.lastWeatherAt).format(
					'DD/MM/YYYY HH:mm:ss',
				)
				return date
			},
		},
		{
			title:
				columnOrder.columnText === 'Qualidade dos dados' ? (
					<CustomArrow
						text={columnOrder.columnText}
						order={columnOrder.columnAction}
					/>
				) : (
					'Qualidade dos dados'
				),
			// cellStyle: { maxWidth: "100px", minWidth: "100px" },
			// headerStyle: { maxWidth: "100px", minWidth: "100px" },
			render: function (rowData) {
				return (
					<>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
							}}
						>
							<CustomIcon
								className={rowData.dataQualityIcon}
								color={rowData.dataQualityColor}
								size="18px"
							/>
							{rowData.dataQualityText}
						</div>
					</>
				)
			},
		},
		{
			title:
				columnOrder.columnText === 'Qualidade do sinal' ? (
					<CustomArrow
						text={columnOrder.columnText}
						order={columnOrder.columnAction}
					/>
				) : (
					'Qualidade do sinal'
				),
			// cellStyle: { maxWidth: "100px", minWidth: "100px" },
			// headerStyle: { maxWidth: "100px", minWidth: "100px" },
			render: function (rowData) {
				return (
					<>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
							}}
						>
							<Icon
								style={{
									fontSize: '18px',
									overflow: 'inherit',
									marginRight: '15px',
									color: rowData.signalColor,
								}}
								className="icon-sinal_wifi"
							/>
							{rowData.signalQualityText}
						</div>
					</>
				)
			},
			field: 'transmissionSignalLevel',
		},
		{
			title:
				columnOrder.columnText === 'Bateria' ? (
					<CustomArrow
						text={columnOrder.columnText}
						order={columnOrder.columnAction}
					/>
				) : (
					'Bateria'
				),
			field: 'battery',
			// cellStyle: { maxWidth: "100px", minWidth: "100px" },
			// headerStyle: { maxWidth: "100px", minWidth: "100px" },
			type: 'string',
		},
	]

	return (
		<>
			<CustomTable
				actions={actions}
				columns={columns}
				pagination={pagination}
				setPagination={setPagination}
				sort={sort}
				setSort={setSort}
				data={data?.items}
				loading={loading}
			/>
			<ModalMap showModal={showModal} onCancel={() => hideModal()} />
			<RemoveUserModal
				onSuccess={() => handleGetStationData(reqBody)}
				showModal={showRemoveUserModal}
				onCancel={() => hideRemoveUserModal()}
			/>
		</>
	)
}
export default ListTable
