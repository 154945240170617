import { Grid, Fade } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import * as Styled from "./styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react";
import { withTranslation } from "react-i18next";

import * as Yup from "yup";
import CustomInput from "components/CustomInput/CustomInput";

const textType = [
  {
    type: "delete",
    text: "Deseja realmete excluir este item?",
  },
  {
    type: "cancel",
    text:
      "As alterações realizadas serão perdidas. Deseja realmente continuar?",
  },
  {
    type: "confirmDelete",
    text: "Para prosseguir com a exclusão é necessário informar a sua senha.",
  },
];

export const validationSchema = Yup.object().shape({
  password: Yup.string().required("Senha obrigatória."),
});

function ConfirmModal({
  open,
  closeByClick,
  maxWidth,
  minWidth,
  className,
  padding,
  shadow,
  text,
  onConfirm,
  onCancel,
  onDelete,
  type,
  parameter,
  error,
  t,
  iconAlert,
  header,
  subText,
}) {
  const { handleSubmit, control } = useForm({ validationSchema });
  const [displayText, setDisplayText] = useState({});

  useEffect(() => {
    function ModalType(type) {
      if (type) {
        if (!text) {
          return textType.forEach((item) => {
            if (item.type === type) {
              setDisplayText(item.text);
            }
          });
        }
        return setDisplayText(text);
      }
      if (text) {
        return setDisplayText(text);
      }
      setDisplayText("Deseja Continuar?");
    }
    ModalType(type);
  }, [type, text, parameter, error]);

  async function removeForm(value) {
    onDelete(value);
  }

  return (
    <Styled.ModalContainer
      open={open}
      className={className}
      onClose={closeByClick}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      shadow={shadow}
      maxwidth={maxWidth}
      minwidth={minWidth}
      disableEnforceFocus
    >
      {type !== "confirmDelete" ? (
        <Fade in={open} unmountOnExit>
          <Styled.PaperContainer padding={padding} maxwidth={maxWidth}>
            <GridContainer
              xs={12}
              sm={12}
              item
              style={{
                margin: header ? 0 : "30px 20px 30px 2px",
                padding: header && "10px 20px",
              }}
            >
              {header && (
                <GridItem xs={12} sm={12} className="header">
                  <div>{header}</div>
                  <div>
                    <Styled.CustomButton
                      size="sm"
                      color="primary"
                      onClick={onConfirm}
                      icon
                      style={{ marginRight: "20px" }}
                    >
                      <i
                        className="icon-salvar"
                        style={{
                          color: germiniDefaultColors[9],
                        }}
                      />
                      {t("BUTTON_SAVE")}
                    </Styled.CustomButton>
                    <Styled.CustomButton
                      size="sm"
                      icon
                      color="greenBorderButtonColor"
                      onClick={onCancel}
                    >
                      <i
                        className="icon-cancelar"
                        style={{
                          color: germiniDefaultColors[9],
                        }}
                      />
                      {t("BUTTON_CANCEL")}
                    </Styled.CustomButton>
                  </div>
                </GridItem>
              )}
              {!header && (
                <GridItem
                  xs={12}
                  sm={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <i
                    className={iconAlert}
                    style={{
                      color: germiniDefaultColors[9],
                      fontSize: "80px",
                    }}
                  />
                </GridItem>
              )}
            </GridContainer>
            <GridItem
              xs={12}
              sm={12}
              style={{ textAlign: "center", margin: header && "20px 0 40px 0" }}
            >
              <div
                style={{
                  fontSize: header ? 16 : "13px",
                  fontWeight: header && "bold",
                }}
              >
                {displayText}
              </div>
              {header && (
                <p style={{ color: germiniDefaultColors[5], marginTop: 10 }}>
                  {subText}
                </p>
              )}
            </GridItem>
            {!header && (
              <GridContainer
                style={{ padding: "20px 0 0 20px", marginBottom: "30px" }}
              >
                <GridItem
                  xs={12}
                  sm={12}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Styled.CustomButton
                    size="sm"
                    color="primary"
                    onClick={onConfirm}
                    style={{ marginRight: "20px" }}
                  >
                    {t("BUTTON_CONFIRM")}
                  </Styled.CustomButton>
                  <Styled.CustomButton
                    size="sm"
                    color="greenBorderButtonColor"
                    onClick={onCancel}
                  >
                    {t("BUTTON_CANCEL")}
                  </Styled.CustomButton>
                </GridItem>
              </GridContainer>
            )}
          </Styled.PaperContainer>
        </Fade>
      ) : (
        <Fade in={open} unmountOnExit>
          <Styled.PaperContainer padding={"0px 60px"} maxwidth={maxWidth}>
            <GridContainer
              xs={12}
              sm={12}
              item
              style={{ margin: "50px 20px 30px 2px" }}
            >
              <GridItem
                xs={12}
                sm={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <i
                  className="icon-alerta_modal"
                  style={{
                    color: germiniDefaultColors[11],
                    fontSize: "80px",
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridItem xs={12} sm={12} style={{ textAlign: "center" }}>
              <div style={{ fontSize: "15px", fontWeight: "bold" }}>
                {displayText}
              </div>
            </GridItem>
            <form style={{ width: "100%" }} onSubmit={handleSubmit(removeForm)}>
              <GridContainer style={{ marginTop: "30px" }}>
                <Grid
                  item
                  xs={10}
                  style={{ position: "relative", left: "60px" }}
                >
                  <Controller
                    as={
                      <CustomInput
                        name="filterBluetoothDeviceId"
                        labelText="ID do aparelho Bluetooth"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        // inputProps={{
                        //   onChange: this.handleChange,
                        //   value: filterBluetoothDeviceId,
                        // }}
                      />
                    }
                    defaultValue=""
                    name="password"
                    control={control}
                  ></Controller>
                </Grid>
              </GridContainer>
              <div
                style={{
                  position: "relative",
                  left: "45px",
                  color: "#f44336",
                  fontSize: "0.80rem",
                  fontWeight: "400",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                }}
              >
                {t(error ? error : "")}
              </div>
              <GridContainer
                style={{
                  padding: "10px 0 0 20px",
                  marginBottom: "50px",
                  marginTop: "35px",
                }}
              >
                <GridItem xs={12} sm={12} style={{ textAlign: "center" }}>
                  <Styled.CustomButton
                    color="primary"
                    style={{ marginRight: 10 }}
                    startIcon={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                        }}
                      >
                        <i
                          className={"icon-ico_cancelar"}
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                    }
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    Cancelar
                  </Styled.CustomButton>

                  <Styled.CustomButton
                    type="submit"
                    // onClick={() => {
                    //     onConfirm()
                    //     // setDisplayError("")
                    // }}
                  >
                    Confirmar exclusão
                  </Styled.CustomButton>
                </GridItem>
              </GridContainer>
            </form>
          </Styled.PaperContainer>
        </Fade>
      )}
    </Styled.ModalContainer>
  );
}

export default withTranslation()(ConfirmModal);
