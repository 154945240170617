import React, { useEffect, useState } from 'react'
import CustomDate from 'components/DatePicker'
import { Controller, useForm } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import * as Styled from './styles'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import * as Yup from 'yup'
import { add, isBefore, isValid, sub } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup'
import { uniqueId } from 'lodash'

const schema = Yup.object().shape({
	dateFrom: Yup.mixed().test(
		'date compare',
		'obrigatório de < até',
		function (dateFrom) {
			const { dateBy } = this.parent
			if (dateBy != null) {
				if (
					isValid(dateFrom) &&
					isValid(dateBy) &&
					isBefore(dateFrom, dateBy)
				) {
					return true
				}
				return false
			} else {
				return true
			}
		},
	),

	dateBy: Yup.mixed().test(
		'date compare',
		'obrigatório até > de',
		function (dateBy) {
			const { dateFrom } = this.parent
			if (dateFrom != null) {
				if (
					isValid(dateFrom) &&
					isValid(dateBy) &&
					isBefore(dateFrom, dateBy)
				) {
					return true
				}
				return false
			} else {
				return true
			}
		},
	),
})

function FilterBox({ classes, filter, setFilter }) {
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			...filter,
		},
	})

	const submitForm = (values) => {
		setFilter({ ...values, filterRefresh: uniqueId('id') })
	}

	const handleClearFilter = () => {
		reset()
		setFilter({})
	}

	return (
		<form style={{ width: '100%' }} onSubmit={handleSubmit(submitForm)}>
			<Styled.SearchBox container className={classes.filterForm}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<h5>Opções de busca</h5>
				</Grid>
				<Grid
					item
					container
					xs={12}
					sm={12}
					md={12}
					lg={12}
					spacing={2}
				>
					<Grid item xs={12} container spacing={3}>
						<Grid item xs={3}>
							<Controller
								render={({ field }) => (
									<CustomDate
										{...field}
										label="De"
										maxDate={
											isValid(watch('dateBy'))
												? sub(watch('dateBy'), {
														days: 1,
												  })
												: false
										}
										formatDate="dd/MM/yyyy"
										error={!!errors.dateFrom}
										helperText={errors?.dateFrom?.message}
									/>
								)}
								name="dateFrom"
								control={control}
							/>
						</Grid>
						<Grid item xs={3}>
							<Controller
								render={({ field }) => (
									<CustomDate
										{...field}
										label="Até"
										minDate={
											isValid(watch('dateFrom'))
												? add(watch('dateFrom'), {
														days: 1,
												  })
												: false
										}
										formatDate="dd/MM/yyyy"
										error={!!errors.dateBy}
										helperText={errors?.dateBy?.message}
									/>
								)}
								name="dateBy"
								control={control}
							/>
						</Grid>
						<Grid item xs={6}>
							<Controller
								control={control}
								name="name"
								render={({
									field: { onChange, onBlur, value },
								}) => (
									<CustomInput
										labelText="Nome do grupo"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											onChange,
											onBlur,
											value,
										}}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} container>
					<Grid item xs={8}></Grid>
					<Grid
						item
						xs={4}
						style={{
							textAlign: 'left',
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<Button
							size="sm"
							color="primary"
							type="submit"
							style={{ marginRight: '10px' }}
						>
							Pesquisar
						</Button>
						<Button
							size="sm"
							color="greenBorderButtonColor"
							onClick={() => handleClearFilter()}
						>
							Limpar
						</Button>
					</Grid>
				</Grid>
			</Styled.SearchBox>
		</form>
	)
}

export default FilterBox
