import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react.jsx";

export const CreateConfigBox = styled(Grid)`
	.MuiGrid-spacing-xs-2 > .MuiGrid-item {
		/* min-height: 89px; */
	}

	.MuiSwitch-root {
		> span {
			top: 9px;
			left: 9px;
		}
	}
	.MuiFormControlLabel-label {
		color: #717171;
		font-size: 12px;
		padding: 0 4px;
	}

	.MuiFormHelperText-root.Mui-error {
		margin-left: 10px;
	}

	legend {
		margin: 0;
		color: #717171;
		font-size: 12px;
	}

	.button-head {
		margin-bottom: 30px;
		button {
			margin-right: 20px;
			min-width: 112px;

			i {
				margin-right: 10px;
			}

			.icon-voltar:before {
				color: ${germiniDefaultColors[1]};
			}

			:focus,
			:hover {
				.icon-voltar:before {
					color: #fff;
				}
			}

			:last-child {
				margin-right: 0;
			}
		}
	}

	.first-checkbox {
		padding-left: 5px;
	}

	.header-form {
		.header-title {
			font-weight: bold;
			color: #3c4858;
			margin-bottom: 10px;
		}

		.header-input {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		.margin {
			margin-top: 30px;
		}
	}

	.body-form {
		margin-top: 20px;
		min-height: 500px;
		.body-title {
			color: #717171;
			margin-bottom: 10px;
		}

		.button-img {
			display: flex;
			justify-content: space-between;
			.input-file {
				display: none;
			}
			button {
				:first-child {
					margin-right: 10px;
				}
			}
		}
		.editor-box {
			/* min-height: 410px; */
			.quill {
				.ql-container.ql-snow {
					height: 320px;
				}
				.ql-container.ql-snow,
				.ql-toolbar.ql-snow {
					border: ${({ errors }) =>
						errors.content ? "1px solid #f44336" : ""};
				}
				.ql-container.ql-snow {
					border-top: ${({ errors }) => errors.content && "none"};
				}
			}
			.error {
				color: #f44336;
			}
		}
	}

	@media screen and (max-width: 1280px) {
		.grid-img {
			justify-content: center;
			.body-title {
				text-align: center;
			}
		}
	}
`;
