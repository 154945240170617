import { Chip, TextField } from '@material-ui/core'
import React from 'react'

import { Controller } from 'react-hook-form'
import { CustomAutocomplete } from './styles'

// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutocompleteSelect = ({
	options = [],
	getOptionLabel,
	control,
	defaultValue,
	name,
	disabled,
	inputLabel,
	inputPlaceholder,
	inputDisable,
}) => {
	return (
		<Controller
			render={({ field: { onChange, onBlur, value }, ...props }) => (
				<CustomAutocomplete
					multiple
					limitTags={2}
					size="small"
					disableCloseOnSelect
					noOptionsText="Sem opção"
					disabled={disabled}
					options={options}
					getOptionLabel={getOptionLabel}
					getOptionSelected={(option, value) =>
						option.value === value.value
					}
					// renderOption={(option, { selected }) => (
					// 	<React.Fragment>
					// 		<Checkbox
					// 			icon={icon}
					// 			checkedIcon={checkedIcon}
					// 			style={{ marginRight: 8 }}
					// 			checked={selected}
					// 		/>
					// 		{option.label}
					// 	</React.Fragment>
					// )}
					// filterSelectedOptions
					renderInput={(params) => (
						<TextField
							{...params}
							variant="standard"
							label={inputLabel}
							disabled={inputDisable}
							placeholder={inputPlaceholder}
						/>
					)}
					onBlur={onBlur}
					value={value}
					onChange={(e, data) => {
						return onChange(data)
					}}
					renderTags={(value, getTagProps) => {
						const numTags = value.length

						return (
							<>
								{value.slice(0, 1).map((option, index) => (
									<Chip
										variant="outlined"
										label={option.label}
										{...getTagProps({ index })}
									/>
								))}

								{numTags > 1 &&
									`e +${numTags - 1} selecionados`}
							</>
						)
					}}
					{...props}
				/>
			)}
			onChange={([, data]) => data}
			defaultValue={defaultValue}
			name={name}
			control={control}
		/>
	)
}

export default AutocompleteSelect
