import * as actionTypes from 'redux/actions/actionsTypes';
import * as API from 'redux/api/account';
import * as Loader from './loader';
import * as Notification from './notification';
import { SuccessNotificationComponent, FailedNotificationComponent } from 'redux/react-notifications';


export const ChangePassword = (userName) => {
    return dispatch => (
        API.ChangePassword(userName).then(response => {
            dispatch(fetchChangePassword(response));
            dispatch(Notification.showNotification(SuccessNotificationComponent('Sua senha foi atualizada com sucesso!')))
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchChangePasswordFailed(error));
            dispatch(Notification.showNotification(FailedNotificationComponent('As senhas não coincidem ou a senha atual está incorreta!')))
            dispatch(Loader.hideLoader());
        })
    );

    function fetchChangePassword(data) {
        return {
            type: actionTypes.FETCH_CHANGE_PASSWORD_DATA,
            userPwd: data
        }
    }

    function fetchChangePasswordFailed(error) {
        return {
            type: actionTypes.FETCH_CHANGE_PASSWORD_FAILED,
            userPwdFailed: error
        };
    }

};

export const UpdateProfile = (obj) => {
    return dispatch => (
        API.UpdateProfile(obj).then(response => {
            dispatch(updateProfileData(response));
            dispatch(Loader.hideLoader());
            dispatch(Notification.showNotification(SuccessNotificationComponent('Perfil atualizado com sucesso!')))
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao salvar dados do perfil!')))
            dispatch(updateProfileDataFailed(error));
        })
    );

    function updateProfileData(data) {
        return {
            type: actionTypes.PUT_CUSER_DATA,
            putData: data
        };
    };

    function updateProfileDataFailed(error) {
        return {
            type: actionTypes.PUT_CUSER_DATA_FAILED,
            putDataFailed: error
        };
    };
};

export const SendTokenToEmail = (email) => {
    return dispatch => (
        API.SendTokenToEmail(email).then(response => {
            dispatch(sendTokenToEmail(response, email));
            dispatch(Loader.hideLoader());
            dispatch(Notification.showNotification(SuccessNotificationComponent('Código token encaminhado ao email ' + email)))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 400) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Usuário não encontrado!')))
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao tentar enviar o token!')))
            }
            dispatch(sendTokenToEmailFailed(error));
        })
    );

    function sendTokenToEmail(data, email) {
        return {
            type: actionTypes.POST_SEND_TOKEN_CUSER,
            sendToken: data,
            email: email
        };
    };

    function sendTokenToEmailFailed(error) {
        return {
            type: actionTypes.POST_SEND_TOKEN_CUSER_FAILED,
            sendTokenFailed: error
        };
    };
};

export const ValidateToken = (email, token) => {
    return dispatch => (
        API.ValidateToken(email, token).then(response => {
            dispatch(validateToken(response));
            dispatch(Loader.hideLoader());
            dispatch(Notification.showNotification(SuccessNotificationComponent('Token validado com sucesso!')));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(Notification.showNotification(FailedNotificationComponent('Token inválido!')))
            dispatch(validateTokenFailed(error));
        })
    );

    function validateToken(data) {
        return {
            type: actionTypes.POST_VALIDATE_TOKEN_CUSER,
            validateToken: data
        };
    };

    function validateTokenFailed(error) {
        return {
            type: actionTypes.POST_VALIDATE_TOKEN_CUSER_FAILED,
            validateTokenFailed: error
        };
    };
};


export const ForgetPassword = (obj) => {
    return dispatch => (
        API.ForgetPassword(obj).then(response => {
            dispatch(fetchForgetPassword(response));
            dispatch(Notification.showNotification(SuccessNotificationComponent('Sua senha foi atualizada com sucesso!')))
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchForgetPasswordFailed(error));
            dispatch(Notification.showNotification(FailedNotificationComponent('As senhas não coincidem!')))
            dispatch(Loader.hideLoader());
        })
    );

    function fetchForgetPassword(data) {
        return {
            type: actionTypes.FETCH_FORGET_PASSWORD_DATA,
            userForgetPwd: data
        }
    }

    function fetchForgetPasswordFailed(error) {
        return {
            type: actionTypes.FETCH_FORGET_PASSWORD_DATA_FAILED,
            userForgetPwdFailed: error
        };
    }

};

export const GetAccountInfo = () => {
    return dispatch => (
        API.GetAccountInfo().then(response => {
            dispatch(fetchProfileInfo(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao pesquisar dados do perfil!')))
            dispatch(fetchProfileInfoFailed(error));
        })
    );

    function fetchProfileInfo(data) {
        return {
            type: actionTypes.FETCH_PROFILE_DATA,
            profileData: data
        };
    };

    function fetchProfileInfoFailed(error) {
        return {
            type: actionTypes.FETCH_PROFILE_DATA_FAILED,
            profileDataFailed: error
        };
    };
};