import React from "react";

import GoogleMap from "google-map-react";

import Marker from "./Marker/Marker.jsx";

const getMapOptions = (maps) => {
	return {
		streetViewControl: false,
		scaleControl: true,
		fullscreenControl: false,
		styles: [
			{
				featureType: "poi.business",
				elementType: "labels",
				stylers: [
					{
						visibility: "off",
					},
				],
			},
		],
		gestureHandling: "greedy",
		disableDoubleClickZoom: true,
		minZoom: 1,
		maxZoom: 22,

		mapTypeControl: true,
		mapTypeId: maps.MapTypeId.SATELLITE,
		mapTypeControlOptions: {
			style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
			position: maps.ControlPosition.BOTTOM_CENTER,
			mapTypeIds: [
				maps.MapTypeId.ROADMAP,
				maps.MapTypeId.SATELLITE,
				maps.MapTypeId.HYBRID,
			],
		},
		zoom: 6,
		zoomControl: true,
		clickableIcons: false,
	};
};

const getMapBounds = (map, maps, places) => {
	const bounds = new maps.LatLngBounds();

	places.forEach((place) => {
		bounds.extend(new maps.LatLng(place.geoLat, place.geoLong));
	});
	return bounds;
};

const bindResizeListener = (map, maps, bounds) => {
	maps.event.addDomListenerOnce(map, "idle", () => {
		maps.event.addDomListener(window, "resize", () => {
			map.fitBounds(bounds);
		});
	});
};

const apiIsLoaded = (map, maps, places) => {
	// Get bounds by our places
	const bounds = getMapBounds(map, maps, places);
	// Fit map to bounds
	map.fitBounds(bounds);
	// Bind the resize listener
	bindResizeListener(map, maps, bounds);
};

const SatelliteMap = ({ ...props }) => {
	return (
		<div style={{ height: "50vh", width: "100%" }}>
			<GoogleMap
				options={getMapOptions}
				bootstrapURLKeys={{
					key: "AIzaSyBPYRls1DTH2kE-7hYxxVMsXaufttm7AbQ",
				}}
				defaultZoom={10}
				center={props.center}
				defaultCenter={props.center}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) =>
					apiIsLoaded(map, maps, props.stations)
				}
			>
				{props.stations.map((marker) => (
					<Marker
						key={marker.id}
						lat={marker.geoLat}
						lng={marker.geoLong}
						name={marker.name}
						color="#0069c0"
						station={marker}
					/>
				))}
			</GoogleMap>
		</div>
	);
};

export default SatelliteMap;
