import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//material-UI components
import { withStyles } from '@material-ui/styles';

// layout components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CommonPage from "components/Pages/CommonPage";
import FieldData from "components/ViewDataField/FieldData";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';
import PictureUpload from 'components/CustomUpload/PictureUpload.jsx';
import MaterialTable from "components/CustomMaterialTable/CustomMaterialTable.jsx";
import Section from 'components/Section';
import DocumentTitle from "components/Pages/DocumentTitle";
import Loader from "components/Loader/Loader.jsx";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import { SweetAlert, HideAlert, GetUserById, ShowLoader, HideLoader } from 'redux/actions';

//utils
import compose from "utils/compose";

class UserView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { name: "Home" },
                { name: "Controle de acesso" },
                { name: "Usuários" },
                { name: "Detalhes", last: true },
            ],
            user: null
        }
    }

    componentDidMount() {
        this.props.ShowLoader(<Loader />);

        const id = this.props.match && this.props.match.params.id;

        this.props.GetUserById(id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userData !== this.props.userData) {
            this.setState({ user: this.props.userData })
            this.props.HideLoader();
        }
    }

    render() {
        const { t, classes } = this.props;
        const { breadcrumbItems, user } = this.state;
        return (
            <CommonPage>
                <DocumentTitle pageTitle={'Detalhes do usuário'} />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Breadcrumbs items={breadcrumbItems} buttonBack functionBack={() => this.props.history.goBack()} />
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Section title="DADOS DO USUÁRIO" icon="icon-ico_cliente" />
                                    </GridItem>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <PictureUpload disabled={true} />
                                        </GridItem>
                                        {user &&
                                            <GridItem xs={12} sm={12} md={9}>
                                                <GridContainer>

                                                    <GridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                                                        <FieldData label="Nome" value={user.fullName}></FieldData>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <FieldData label="CPF" value={user.document}></FieldData>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <FieldData label="Celular" value={user.mobilePhoneNumber}></FieldData>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <FieldData label="Telefone" value={user.phoneNumber}></FieldData>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <FieldData label="E-mail" value={user.email}></FieldData>
                                                    </GridItem>
                                                </GridContainer>
                                            </GridItem>
                                        }
                                    </GridContainer>
                                    <GridItem xs={12}>
                                        <MaterialTable
                                            title={"GRUPOS ASSOCIADOS"}
                                            columns={[
                                                { title: 'Grupo', field: 'groupName', headerStyle: { textAlign: 'left' } },
                                                { title: 'Descrição', field: 'description', headerStyle: { textAlign: 'left' } },
                                            ]}
                                            data={user ? user.groups.map(item => (
                                                {
                                                    groupName: item.name,
                                                    description: item.description,
                                                })
                                            ) : []}
                                            search={false}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </CommonPage>
        );
    }
}

UserView.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {

    return {
        userData: state.user.userById,
        userDataFailed: state.user.userByIdDataFailed,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { SweetAlert, HideAlert, ShowLoader, HideLoader, GetUserById }
    , dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sweetAlertStyle),
    withTranslation(),
)(UserView);