import axios from 'axios';

// GET
export const GetCustomersData = (_filter, _page, _itemsPerPage) => {
    let page = "";
    let count = (_page - 1) * _itemsPerPage;
    if (_filter && _page) {
        page = `&$skip=${count}&$top=${_itemsPerPage}`
    } else if (!_filter && _page) {
        page = `?$skip=${count}&$top=${_itemsPerPage}`
    }
    //
    const url = `/api/UserManager/GetAll${_filter}${page}`;
    return axios.get(url).then(response => response.data);
}

// GET BY ID
export const GetCustomerById = (id) => {
    return axios.get(`/api/UserManager/GetById/${id}`)
        .then(response => response.data);
}

export const PostCustomer = (obj) => {
    return axios.post(`/api/UserManager/Upsert`, obj).then(response => response.data);
}

export const CreateCustomer = (obj) => {
    return axios.post(`/api/UserManager/Create`, obj).then(response => response.data);
}

export const UpdateAttachStation = (body) => {
    return axios.post(`/api/UserManager/AttachStation`, body).then(response => response.data);
}

export const DeleteCustomer = (id) => {
    return axios.delete(`/api/UserManager/Delete?id=${id}&softDelete=false`).then(response => response.data);
}

export const ResetCustomerPassword = (id) => {
    return axios.post(`/api/UserManager/ResetPassword?id=${id}`).then(response => response.data);
}
