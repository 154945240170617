import InputLabel from '@material-ui/core/InputLabel'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

export const MyTextField = styled(TextField)`
	&.MuiFormControl-root {
		width: 100%;
	}
`

export const MyInputLabel = styled(InputLabel)`
	&.MuiFormLabel-root {
		font-size: 14px !important;
	}
`
