import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import { Icon } from "@material-ui/core";

import FieldInput from "components/FieldForm/FieldInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Loader from "components/Loader/Loader.jsx";
import Section from "components/Section";

import { PostStation, ShowLoader, HideAlert } from "redux/actions";

import { isAllowed } from "utils/auth";

const StationSchema = Yup.object().shape({
  name: Yup.string().required(),
  password: Yup.string().required(),
  gateStationId: Yup.string().required(),
});

const StationForm = ({ classes, history, onCancel, ...props }) => {
  const dispatch = useDispatch();

  const handleSave = (values) => {
    dispatch(ShowLoader(<Loader />));
    dispatch(PostStation(values));
    dispatch(HideAlert());
  };

  const userRdx = useSelector((state) => state.authentication.user);

  const [hasWritePermission, setHasWritePermission] = React.useState(false);

  React.useEffect(() => {
    if (userRdx) {
      if (isAllowed(userRdx, ["beWeather.station.write"])) {
        setHasWritePermission(true);
      } else {
        this.props.history.push("/admin/404");
      }
    } else {
      this.props.history.push("/404");
    }
  }, [userRdx]);

  return (
    <Formik
      onSubmit={(values) => {
        handleSave(values);
      }}
      initialValues={{
        id: "",
        name: "",
        password: "",
        gateStationId: "",
        bluetoothDeviceId: "",
      }}
      validationSchema={StationSchema}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "left" }}
            >
              <Section title="NOVA ESTAÇÃO" />
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "right" }}
              className={classes.noPaddingRight}
            >
              <Button
                size="sm"
                color="primary"
                style={{ marginRight: "10px" }}
                disabled={!hasWritePermission}
                onClick={() => handleSubmit()}
              >
                <Icon
                  style={{ fontSize: "14px", width: "20px" }}
                  className={"icon-salvar"}
                ></Icon>
                Salvar
              </Button>
              <Button
                size="sm"
                color="greenBorderButtonColor"
                onClick={() => onCancel()}
              >
                <i
                  style={{ fontSize: "17px", width: "20px" }}
                  className={"icon-cancelar"}
                ></i>
                Cancelar
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <Field name="name">
              {({ field, form }) => (
                <FieldInput
                  touched={form.touched.name}
                  error={form.errors.name}
                  form={form}
                  field={field}
                  label={"Apelido da estação"}
                  inputGrid={12}
                />
              )}
            </Field>
            <Field name="password">
              {({ field, form }) => (
                <FieldInput
                  touched={form.touched.password}
                  error={form.errors.password}
                  form={form}
                  field={field}
                  label={"Chave de Segurança"}
                  inputGrid={12}
                />
              )}
            </Field>
            <Field name="gateStationId">
              {({ field, form }) => (
                <FieldInput
                  touched={form.touched.gateStationId}
                  error={form.errors.gateStationId}
                  form={form}
                  field={field}
                  label={"Número de série"}
                  inputGrid={12}
                />
              )}
            </Field>
            <Field name="bluetoothDeviceId">
              {({ field, form }) => (
                <FieldInput
                  touched={form.touched.bluetoothDeviceId}
                  error={form.errors.bluetoothDeviceId}
                  form={form}
                  field={field}
                  label={"ID do aparelho Bluetooth"}
                  inputGrid={12}
                />
              )}
            </Field>
          </GridContainer>
        </Form>
      )}
    </Formik>
  );
};

export default StationForm;
