// import config from 'config';
import axios from "axios";

import { authHeader } from "../../utils";
import jwt_decode from "jwt-decode";

export const userService = {
  login,
  logout,
  getAll,
};

function login(username, password) {
  const authData = {
    userName: username,
    password: password,
    resource: "console",
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(authData),
  };

  return fetch(
    `${process.env.REACT_APP_B2KWEB_IDENTITY_URL}api/CAccount/Authenticate`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("token", user.token);
      localStorage.setItem("user", JSON.stringify(user.user));
      localStorage.setItem("justOnce", "false");

      axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
      return user.user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("i18next_pt-BR-common");
  localStorage.removeItem("i18next_es-common");
  localStorage.removeItem("i18next_en-common");

  window.onload = function () {
    if (!localStorage.justOnce) {
      localStorage.setItem("justOnce", "true");
      window.location.reload();
    }
  };
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`http://localhost:5000/users`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  //console.log("RESPONSE", response);
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    const user = {
      user: data.user,
      token: data.token,
    };
    return user;
  });
}

export const apiB2k = axios.create({
  baseURL: `${process.env.REACT_APP_B2KWEB_IDENTITY_URL}api/`,
});
