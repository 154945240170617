import React from 'react'
import compose from 'utils/compose'
import { withTranslation } from 'react-i18next'
import { Icon } from '@material-ui/core'

// css
import styles from './styles.module.scss'

const Section = ({ title, subtitle, icon }) => (
	<div
		className={styles.sectionTitle}
		style={{ display: 'flex', alignItems: 'center' }}
	>
		{icon ? (
			<Icon
				style={{
					fontSize: '20px',
					marginRight: '10px',
					overflow: 'inherit',
				}}
				className={icon}
			/>
		) : (
			''
		)}
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div>{title}</div>
			{subtitle ? (
				<div
					style={{
						fontSize: '15px',
						marginTop: '-30px',
						textTransform: 'captalize',
						fontWeight: 'normal',
					}}
				>
					{subtitle}
				</div>
			) : (
				''
			)}
		</div>
	</div>
)

export default compose(withTranslation())(Section)
