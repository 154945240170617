import * as actionTypes from 'redux/actions/actionsTypes';
import * as API from 'redux/api/firmware/firmware';
import * as Loader from '../loader';
import * as Notification from '../notification';
import { SuccessNotificationComponent, FailedNotificationComponent } from 'redux/react-notifications';

export const GetFirmware = () => {
    return dispatch => (
        API.GetFirmwareData().then(response => {
            dispatch(success(response));
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para consultar firmwares!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao consultar firmwares!')))
            }
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function success(data) {
        return {
            type: actionTypes.FETCH_FIRMWARE_DATA,
            lsFirmwares: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.FETCH_FIRMWARE_FAILED,
            firmwaresDataFailed: error
        };
    };
};

export const PostFirmware = (obj) => {
    return dispatch => (
        API.PostFirmware(obj).then(response => {
            dispatch(postFirmwareData(response));
            //dispatch(Loader.hideLoader());
            //dispatch(Notification.showNotification(NotificationComponent('Firmware cadastrado com sucesso!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para cadastrar firmwares!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao cadastrar firmware!')))
            }
            dispatch(postFirmwareDataFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function postFirmwareData(data) {
        return {
            type: actionTypes.POST_FIRMWARE_DATA,
            postData: data
        }
    }

    function postFirmwareDataFailed(error) {
        return {
            type: actionTypes.POST_FIRMWARE_DATA_FAILED,
            postDataFailed: error
        };
    }

};

export const UploadFile = (id, formFile) => {
    return dispatch => (
        API.UploadFile(id, formFile).then(response => {
            dispatch(postFileData(response));
            dispatch(Loader.hideLoader());
            dispatch(Notification.showNotification(SuccessNotificationComponent('Firmware cadastrado com sucesso!')))
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(postFileDataFailed(error));
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para enviar o arquivo de firmware!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro na gravação do arquivo!')))
            }
            dispatch(Loader.hideLoader());
        })
    );

    function postFileData(data) {
        return {
            type: actionTypes.POST_FIRMWARE_FILE,
            postFile: data
        }
    }

    function postFileDataFailed(error) {
        return {
            type: actionTypes.POST_FIRMWARE_FILE_FAILED,
            postFileFailed: error
        };
    }

};

export const DeleteFirmware = (id) => {
    return dispatch => (
        API.DeleteFirmware(id).then(response => {
            dispatch(deleteFirmwareData(response));
            dispatch(Loader.hideLoader());
            dispatch(Notification.showNotification(SuccessNotificationComponent('Firmware removido da base de dados!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para apagar firmwares!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao apagar o firmware!')))
            }
            dispatch(deleteFirmwareDataFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function deleteFirmwareData(data) {
        return {
            type: actionTypes.DELETE_FIRMWARE_DATA,
            deleteData: data
        }
    }

    function deleteFirmwareDataFailed(error) {
        return {
            type: actionTypes.DELETE_FIRMWARE_DATA_FAILED,
            deleteDataFailed: error
        };
    }

};
