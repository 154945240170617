import React from 'react'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import NoContentAlert from 'components/NoContentAlert/NoContentAlert.jsx'
import GridItem from 'components/Grid/GridItem'
import { useDispatch } from 'react-redux'
import { DisassociateUser } from 'redux/actions'
import Section from 'components/Section'
import CustomIcon from 'components/CustomIcon'
import {
	Icon,
	Slide,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})

function RemoveUserModal({ onCancel, showModal, onSuccess }) {
	const dispatch = useDispatch()
	const close = () => {
		onCancel()
	}
	return (
		<GridContainer justify="center">
			<GridItem xs={12} sm={12} md={12}>
				<Dialog
					fullWidth={true}
					maxWidth="md"
					open={showModal.open}
					TransitionComponent={Transition}
					keepMounted
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					{showModal.name !== null ? (
						''
					) : (
						<DialogTitle
							id="classic-modal-slide-title"
							disableTypography
						>
							<DialogActions>
								<Button
									size="sm"
									color="greenBorderButtonColor"
									onClick={() => close()}
								>
									<Icon
										style={{
											fontSize: '18px',
											width: '30px',
										}}
										className={'icon-cancelar'}
									/>
									Fechar
								</Button>
							</DialogActions>
						</DialogTitle>
					)}
					<DialogContent
						id="classic-modal-slide-description"
						style={{ padding: '50px' }}
					>
						{showModal.name !== null ? (
							<div style={{ display: 'flex' }}>
								<div
									style={{
										position: 'relative',
										top: '10px',
									}}
								>
									<CustomIcon
										size={'40px'}
										color={'black'}
										className={'icon-estacao'}
									/>
								</div>
								<div
									style={{
										marginLeft: '15px',
										maxWidth: '500px',
									}}
								>
									<p style={{ fontWeight: 'bold' }}>
										O proprietário {showModal.name} será
										desvinculado da estação. Ao desvincular
										o proprietário, todas as informações
										serão perdidas. Deseja realmente
										desvincular?
									</p>
								</div>
								<div style={{ marginLeft: '30px' }}>
									<Button
										size="sm"
										color="danger"
										style={{ marginRight: '10px' }}
										onClick={() => {
											dispatch(
												DisassociateUser(showModal.id),
											).then(() => {
												onSuccess()
												close()
											})
										}}
									>
										Confirmar
									</Button>
									<Button
										size="sm"
										color="greenBorderButtonColor"
										onClick={onCancel}
									>
										<Icon
											style={{
												fontSize: '18px',
												width: '30px',
											}}
											className={'icon-cancelar'}
										/>
										Cancelar
									</Button>
								</div>
							</div>
						) : (
							<NoContentAlert alertText="Não foi possível localizar a estação" />
						)}
					</DialogContent>
				</Dialog>
			</GridItem>
		</GridContainer>
	)
}

export default RemoveUserModal
