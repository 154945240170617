import React from "react";

// import { Container } from './styles';

function DestinyField({ destiny = [] }) {
	return (
		<div>
			{destiny
				? destiny.map((item) => (
						<small key={item.id}>
							{item?.userId} <br />
						</small>
				  ))
				: "Todos usuários"}
		</div>
	);
}

export default DestinyField;
