import CustomCheckBox from 'components/CustomCheckBox'
import React from 'react'

import CustomStep from 'components/CustomStep'
import StationTable from './StationTable'
import ClientTable from './ClientTable'

const tabList = [
	{
		name: 'Estações',
		icon: 'icon-estacao',
	},
	{
		name: 'Clientes',
		icon: 'icon-User',
	},
]

function ListSavedStationGroup({ id, data }) {
	const containerList = [
		{
			name: 'Estacoes',
			children: <StationTable id={id} />,
		},
		{
			name: 'Clientes',
			children: <ClientTable id={id} />,
		},
	]

	return (
		<>
			<p>Nome do grupo: </p>
			<h4>{data?.name}</h4>
			<CustomCheckBox
				label="Grupo compartilhado"
				disabled
				checked={!!data?.isSharedGroup}
				inputProps={{ value: data?.isSharedGroup }}
			/>

			<CustomStep tabList={tabList} containerList={containerList} />
		</>
	)
}

export default ListSavedStationGroup
