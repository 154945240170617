import { Breadcrumbs, Card, Grid } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import CustomIcon from "components/CustomIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Loader/Loader";
import CommonPage from "components/Pages/CommonPage";
import DocumentTitle from "components/Pages/DocumentTitle";
import Section from "components/Section/Section";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { HideLoader, ShowLoader } from "redux/actions";
import {
	getGroupManagerById,
	GET_GROUP_MANAGER_BY_ID,
} from "services/api/StationGroupManager/api";
import useSWR from "swr";
import { history } from "utils";
import { goTo } from "utils/utils";
import ListSavedStationGroup from "./components/ListSavedStationGroup";

const breadcrumbItems = [
	{ name: "Home" },
	{ name: "Grupo de estações", last: true },
];

const Stations = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { id } = useParams();

	const { data } = useSWR(GET_GROUP_MANAGER_BY_ID, () =>
		getGroupManagerById(id)
	);

	useEffect(() => {
		async function getById() {
			dispatch(ShowLoader(<Loader />));

			if (data) {
				dispatch(HideLoader(<Loader />));
			}
		}

		if (id) getById();
	}, [data, dispatch, id]);

	return (
		<CommonPage>
			<DocumentTitle pageTitle={t("MENU_STATIONS_LINK_GROUP")} />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<Breadcrumbs items={breadcrumbItems} />
					<Card>
						<CardBody>
							<Grid item xs={12} container>
								<Grid item xs={4}>
									<Section title="Agrupamento" icon="icon-estacao" />
								</Grid>
								<Grid
									item
									xs={8}
									container
									justify="flex-end"
									className="button-head"
								>
									<Button
										size="sm"
										color="primary"
										style={{ marginRight: 10 }}
										onClick={() => goTo(`group_stations/crud/${id}`)}
									>
										<CustomIcon
											className="icon-editar"
											color="#fff"
											size="14px"
										/>
										Editar
									</Button>
									<Button
										size="sm"
										color="greenBorderButtonColor"
										onClick={() => history.goBack()}
									>
										<i
											style={{
												fontSize: 14,
												padding: "2px 0",
											}}
											className={"icon-voltar"}
										/>
										Voltar
									</Button>
								</Grid>

								<Grid item xs={12}>
									<ListSavedStationGroup id={id} data={data} />
								</Grid>
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</CommonPage>
	);
};

export default Stations;
