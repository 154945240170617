import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cx from "classnames";
import { Formik, Form, Field, FieldArray } from "formik";
import RSelect from "react-select";
import * as Yup from "yup";
import * as _ from "lodash";

// REDIX INIT
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import {
	Icon,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Divider,
} from "@material-ui/core";

import Check from "@material-ui/icons/Check";

// layout components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import FieldInput from "components/FieldForm/FieldInput.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CommonPage from "components/Pages/CommonPage";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.jsx";
import Table from "components/Table/Table.jsx";
import Section from "components/Section";
import DocumentTitle from "components/Pages/DocumentTitle";
import Loader from "components/Loader/Loader.jsx";

//classes
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

//utils
import compose from "utils/compose";
import { isAllowed } from "utils/auth";

import {
	GetGroups,
	GetUsers,
	CreateGroup,
	UpdateGroup,
	ShowLoader,
	HideLoader,
	GetGroupById,
} from "redux/actions";

/**
 *  @author Ayrton Gomes
 *  RegisterForm class component to register new customer
 */
class RegisterForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumbItems: [
				{ name: "Home" },
				{ name: "Controle de acesso" },
				{ name: "Grupos" },
				{ name: "Cadastro", last: true },
			],
			iniValues: {
				id: "",
				name: "",
				description: "",
				claims: [],
				cUsers: [],
				groupUsers: [],
			},

			groupUsers: [],
			hasWritePermission: false,
			hasCreatePermission: false,
			hasDeletePermission: false,
		};
	}

	componentDidMount() {
		this.props.GetUsers("", "", "");

		let user = this.props.user;
		if (user) {
			if (isAllowed(user, ["beWeather.group.create"])) {
				this.setState({ hasCreatePermission: true });
			}
			if (isAllowed(user, ["beWeather.group.write"])) {
				this.setState({ hasWritePermission: true });
			}
			if (isAllowed(user, ["beWeather.group.delete"])) {
				this.setState({ hasDeletePermission: true });
			}
		} else {
			this.props.history.push("/404");
		}

		const id = this.props.match && this.props.match.params.id;
		let _edit = _.includes(this.props.match.path, "edit");

		if (_edit) {
			this.props.ShowLoader(<Loader />);
			this.props.GetGroupById(id);
		}

		if (_edit && !id) {
			this.props.history.goBack();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.groupData !== this.props.groupData) {
			this.setState({
				iniValues: {
					...this.props.groupData,
					cUsers: this.props.groupData.users.map((x) => x.id),
					groupUsers: this.props.groupData.users,
				},
			});
			this.props.HideLoader();
		}
	}

	render() {
		const { classes } = this.props;
		const {
			breadcrumbItems,
			iniValues,
			hasCreatePermission,
			hasWritePermission,
		} = this.state;
		return (
			<CommonPage>
				<DocumentTitle pageTitle={"Cadastrar cliente"} />
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<Breadcrumbs items={breadcrumbItems} />

						<Card>
							<CardBody>
								<Formikin
									classes={classes}
									history={this.props.history}
									iniValues={iniValues}
									hasCreatePermission={hasCreatePermission}
									hasWritePermission={hasWritePermission}
								/>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</CommonPage>
		);
	}
}

/**
 *  @author Ayrton Gomes
 *  Function component using formik to render the form to submit the values
 */
const Formikin = ({
	classes,
	history,
	iniValues,
	hasWritePermission,
	hasCreatePermission,
	...props
}) => {
	const [usersOptions, setUsersOptions] = React.useState([]);
	const lsUsers = useSelector((state) => state.user.lsUsers);
	const [userFound, setUserFound] = React.useState(null);

	const dispatchHook = useDispatch();

	React.useEffect(() => {
		if (usersOptions.length === 0 && lsUsers) {
			setUsersOptions(
				lsUsers.items.map((x) => ({ label: x.fullName, value: x.id }))
			);
		}
		if (iniValues.id !== "" && usersOptions) {
			let users = iniValues.cUsers;
			let currentOptions = usersOptions;
			users.map((userId) => {
				currentOptions = currentOptions.filter(
					(x) => x.value !== userId
				);
			});
			setUsersOptions(currentOptions);
		}
	}, [lsUsers, iniValues]);

	const addUser = (user, setFieldValue, values) => {
		let users = values.groupUsers;
		let usersId = users.map((x) => x.id);
		let userToAdd = lsUsers.items.find((x) => x.id === user.value);

		let usersOpt = usersOptions;

		usersId.indexOf(user.value) === -1 && users.push(userToAdd);

		setFieldValue(
			"cUsers",
			users.map((x) => x.id)
		);
		setFieldValue("groupUsers", users);

		let usersCrrt = usersOpt.filter((x) => x.value !== user.value);
		setUsersOptions(usersCrrt);
		setUserFound(null);
	};
	const removeUser = (id, setFieldValue, values) => {
		let users = values.groupUsers;

		_.remove(users, (x) => x.id === id);

		let usersOpt = usersOptions;
		let userToAdd = lsUsers.items.find((x) => x.id === id);

		setFieldValue(
			"cUsers",
			users.map((x) => x.id)
		);
		setFieldValue("groupUsers", users);

		usersOpt.push({ label: userToAdd.fullName, value: userToAdd.id });
	};

	const onCheckChange = (e, values, arrayHelpers) => {
		if (e.target.checked) {
			arrayHelpers.push(e.target.value);
		} else {
			const idx = values.claims.indexOf(e.target.value);
			arrayHelpers.remove(idx);
		}
	};

	const selectOnChange = (option) => {
		setUserFound(option);
	};

	const handleSave = (group) => {
		if (group.id === "") {
			delete group.id;
			dispatchHook(CreateGroup(group)).then((resp) => {
				dispatchHook(GetGroups("", 1, "20"));
				if (resp) {
					history.push(
						`/admin/access_control/group/details/${resp.id}`
					);
				}
			});
		} else {
			dispatchHook(UpdateGroup(group)).then((resp) => {
				dispatchHook(GetGroups("", 1, "20"));
				if (resp) {
					history.push(
						`/admin/access_control/groups/details/${resp.id}`
					);
				}
			});
		}
	};

	return (
		<Formik
			onSubmit={(values) => handleSave(values)}
			initialValues={iniValues}
			enableReinitialize
		>
			{({ handleSubmit, setFieldValue, values }) => (
				<Form>
					<GridContainer>
						<GridItem xs={12} sm={6} md={6} lg={6}>
							<Section
								title="CADASTRO DE GRUPO"
								icon="icon-ico_acesso_grupo"
							/>
						</GridItem>
						<GridItem
							xs={12}
							sm={6}
							md={6}
							lg={6}
							style={{ textAlign: "right" }}
							className={classes.noPaddingRight}
						>
							<Button
								size="sm"
								color="primary"
								disabled={
									!hasCreatePermission && !hasWritePermission
								}
								style={{ marginRight: "10px" }}
								onClick={
									hasCreatePermission || hasWritePermission
										? () => handleSubmit()
										: () => console.log("Sem permissão")
								}
							>
								<Icon
									style={{ fontSize: "17px", width: "25px" }}
									className={"icon-salvar"}
								/>
								Salvar
							</Button>
							<Button
								size="sm"
								color="greenBorderButtonColor"
								onClick={() => history.goBack()}
							>
								<Icon
									style={{ fontSize: "17px", width: "20px" }}
									className={"icon-cancelar"}
								/>
								Cancelar
							</Button>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<GridContainer>
								<Field name="name">
									{({ field, form }) => (
										<FieldInput
											touched={form.touched.name}
											error={form.errors.name}
											form={form}
											field={field}
											label={"Nome"}
											inputGrid={12}
										/>
									)}
								</Field>

								<Field name="description">
									{({ field, form }) => (
										<FieldInput
											touched={form.touched.description}
											error={form.errors.description}
											form={form}
											field={field}
											label={"Descrição"}
											inputGrid={12}
										/>
									)}
								</Field>

								<NavPills
									color="primary"
									tabs={[
										{
											tabButton: "PERMISSÕES",
											tabContent: (
												<FieldArray
													name="claims"
													render={(arrayHelpers) => (
														<GridContainer>
															<GridItem xs={12}>
																<Section title="DETALHES DO ACESSO" />
																<h5>
																	<b>
																		Dashboard
																	</b>
																</h5>
																<FormGroup row>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.dashboard.read"
																					) !==
																					-1
																				}
																				value="beWeather.dashboard.read"
																			/>
																		}
																		label="Consultar"
																	/>
																</FormGroup>
															</GridItem>
															<Divider
																variant="middle"
																style={{
																	width: "98%",
																}}
															/>
															<GridItem xs={12}>
																<h5>
																	<b>
																		Clientes
																	</b>
																</h5>
																<FormGroup row>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.customer.read"
																					) !==
																					-1
																				}
																				value="beWeather.customer.read"
																			/>
																		}
																		label="Consultar"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.customer.write"
																					) !==
																					-1
																				}
																				value="beWeather.customer.write"
																			/>
																		}
																		label="Criar/Editar"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.customer.delete"
																					) !==
																					-1
																				}
																				value="beWeather.customer.delete"
																			/>
																		}
																		label="Apagar"
																	/>
																</FormGroup>
															</GridItem>
															<Divider
																variant="middle"
																style={{
																	width: "98%",
																}}
															/>
															<GridItem xs={12}>
																<h5>
																	<b>
																		Estações
																	</b>
																</h5>
																<FormGroup row>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.station.read"
																					) !==
																					-1
																				}
																				value="beWeather.station.read"
																			/>
																		}
																		label="Consultar"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.station.write"
																					) !==
																					-1
																				}
																				value="beWeather.station.write"
																			/>
																		}
																		label="Criar/Editar"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.station.delete"
																					) !==
																					-1
																				}
																				value="beWeather.station.delete"
																			/>
																		}
																		label="Apagar"
																	/>
																</FormGroup>
															</GridItem>
															<Divider
																variant="middle"
																style={{
																	width: "98%",
																}}
															/>
															<GridItem xs={12}>
																<h5>
																	<b>
																		Firmware
																	</b>
																</h5>
																<FormGroup row>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.firmware.read"
																					) !==
																					-1
																				}
																				value="beWeather.firmware.read"
																			/>
																		}
																		label="Consultar"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.firmware.write"
																					) !==
																					-1
																				}
																				value="beWeather.firmware.write"
																			/>
																		}
																		label="Criar"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.firmware.delete"
																					) !==
																					-1
																				}
																				value="beWeather.firmware.delete"
																			/>
																		}
																		label="Apagar"
																	/>
																</FormGroup>
															</GridItem>
															<Divider
																variant="middle"
																style={{
																	width: "98%",
																}}
															/>
															<GridItem xs={12}>
																<h5>
																	<b>
																		Usuários
																	</b>
																</h5>
																<FormGroup row>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.user.read"
																					) !==
																					-1
																				}
																				value="beWeather.user.read"
																			/>
																		}
																		label="Consultar"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.user.write"
																					) !==
																					-1
																				}
																				value="beWeather.user.write"
																			/>
																		}
																		label="Editar"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.user.create"
																					) !==
																					-1
																				}
																				value="beWeather.user.create"
																			/>
																		}
																		label="Criar"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.user.delete"
																					) !==
																					-1
																				}
																				value="beWeather.user.delete"
																			/>
																		}
																		label="Apagar"
																	/>
																</FormGroup>
															</GridItem>
															<Divider
																variant="middle"
																style={{
																	width: "98%",
																}}
															/>
															<GridItem xs={12}>
																<h5>
																	<b>
																		Grupos
																	</b>
																</h5>
																<FormGroup row>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.group.read"
																					) !==
																					-1
																				}
																				value="beWeather.group.read"
																			/>
																		}
																		label="Consultar"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.group.write"
																					) !==
																					-1
																				}
																				value="beWeather.group.write"
																			/>
																		}
																		label="Editar"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.group.create"
																					) !==
																					-1
																				}
																				value="beWeather.group.create"
																			/>
																		}
																		label="Criar"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.group.delete"
																					) !==
																					-1
																				}
																				value="beWeather.group.delete"
																			/>
																		}
																		label="Apagar"
																	/>
																</FormGroup>
															</GridItem>
															<Divider
																variant="middle"
																style={{
																	width: "98%",
																}}
															/>
															<GridItem xs={12}>
																<h5>
																	<b>
																		Integração
																	</b>
																</h5>
																<FormGroup row>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.integration.sap"
																					) !==
																					-1
																				}
																				value="beWeather.integration.sap"
																			/>
																		}
																		label="SAP"
																	/>
																	<FormControlLabel
																		control={
																			<Checkbox
																				tabIndex={
																					-1
																				}
																				onChange={(
																					e
																				) =>
																					onCheckChange(
																						e,
																						values,
																						arrayHelpers
																					)
																				}
																				checkedIcon={
																					<Check
																						className={
																							classes.checkedIcon
																						}
																					/>
																				}
																				icon={
																					<Check
																						className={
																							classes.uncheckedIcon
																						}
																					/>
																				}
																				classes={{
																					checked:
																						classes.checked,
																					root: classes.checkRoot,
																				}}
																				checked={
																					values.claims.indexOf(
																						"beWeather.integration.eprod"
																					) !==
																					-1
																				}
																				value="beWeather.integration.eprod"
																			/>
																		}
																		label="E-produtor"
																	/>
																</FormGroup>
															</GridItem>
														</GridContainer>
													)}
												/>
											),
										},
										{
											tabButton: "ASSOCIAR USUÁRIOS",
											tabContent: (
												<GridContainer>
													<GridItem xs={12}>
														<Section title="USUÁRIOS" />
													</GridItem>
													<GridItem
														xs={12}
														sm={12}
														md={6}
														lg={6}
														xl={6}
													>
														<RSelect
															name={"searchUser"}
															value={userFound}
															cacheOptions
															defaultOptions
															placeholder={
																"Buscar usuário"
															}
															loadingMessage={() =>
																"Procurando..."
															}
															noOptionsMessage={() =>
																"Nenhum resultado encontrado"
															}
															options={
																usersOptions
															}
															onChange={(e) =>
																selectOnChange(
																	e
																)
															}
														/>
													</GridItem>
													<GridItem
														xs={12}
														sm={12}
														md={6}
														lg={6}
														style={{
															textAlign: "left",
														}}
													>
														<Button
															size="sm"
															color="primary"
															onClick={() =>
																addUser(
																	userFound,
																	setFieldValue,
																	values
																)
															}
														>
															Adicionar
														</Button>
													</GridItem>
													<GridItem
														xs={12}
														style={{
															margin: "20px 0",
														}}
													>
														<Table
															hover
															tableHead={[
																"Nome",
																"Ações",
															]}
															tableData={values.groupUsers.map(
																(item) => [
																	item.fullName,
																	<div
																		style={{
																			textAlign:
																				"left",
																		}}
																	>
																		<span
																			className={cx(
																				"icon-bot_close",
																				classes.actionButton
																			)}
																			title="Remover"
																			onClick={() =>
																				removeUser(
																					item.id,
																					setFieldValue,
																					values
																				)
																			}
																		></span>
																	</div>,
																]
															)}
														/>
													</GridItem>
												</GridContainer>
											),
										},
									]}
								/>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</Form>
			)}
		</Formik>
	);
};

RegisterForm.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		groupData: state.group.groupById,
		groupDataFailed: state.group.groupByIdDataFailed,

		user: state.authentication.user,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{ GetGroups, GetUsers, GetGroupById, ShowLoader, HideLoader },
		dispatch
	);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(sweetAlertStyle),
	withTranslation()
)(RegisterForm);
