import React, { useEffect, useState, useCallback } from 'react'
import CustomTable, {
	initialPagination,
	initialSort,
} from 'components/CustomTable'
import Table from 'components/Table/Table.jsx'
import * as moment from 'moment'
import cx from 'classnames'
import Button from 'components/CustomButtons/Button.jsx'
import { Grid } from '@material-ui/core'
import Section from 'components/Section'
import { goTo } from 'utils/utils'
import CustomIcon from 'components/CustomIcon'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import SatelliteMap from 'components/Maps/Maps.jsx'
import NoContentAlert from 'components/NoContentAlert/NoContentAlert.jsx'
import ColoredCircle from 'components/ColoredCircle/ColoredCircle.jsx'
import { useDispatch } from 'react-redux'
import Alert from 'react-bootstrap-sweetalert'
import StationForm from 'pages/Stations/Form/StationForm'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
	Icon,
	Dialog,
	Checkbox,
	Slide,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@material-ui/core'
import { SweetAlert, HideAlert } from 'redux/actions'
import Check from '@material-ui/icons/Check'
import {
	getAllOwnerless,
	getStationManagerById,
} from 'services/api/StationManager/api'
import { AttachStation, getById } from 'services/api/UserManager/api'
import useSWR from 'swr'
import * as S from './styles'
import CloseButton from 'components/CloseButton'
import { showNotification } from 'redux/actions/notification'
import { SuccessNotificationComponent } from 'redux/react-notifications'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})

function LinkedStations({ edit, classes, id }) {
	const dispatch = useDispatch()

	const [pagination, setPagination] = useState(initialPagination)
	const [loading, setLoading] = useState(true)
	const [sort, setSort] = useState(initialSort)
	const [modalLinkStations, setModalLinkStations] = useState({
		open: false,
	})
	const [modalMap, setModalMap] = useState({
		open: false,
	})
	const [checked, setChecked] = useState()
	const [errorMsg, setErrorMsg] = useState('')

	const [stations, setStations] = useState([])
	const arr = []

	const handleReadStations = useCallback(async () => {
		const res = await getById(id)
		setStations(res?.stations)
	}, [id])

	useEffect(() => {
		handleReadStations()
	}, [id])

	const { data: lsAllStationsOwnerless } = useSWR(
		'/api/StationManager/GetAllOwnerless',
		() => getAllOwnerless(),
	)

	function handleShowLocationMap(rowData) {
		setModalMap({ ...rowData, open: true })
	}

	function handleCloseMap() {
		setModalMap({ open: false })
	}

	function handleCloseAttachStation() {
		setModalLinkStations({ open: false })

		handleReadStations()
	}

	const handleSaveAttach = async () => {
		let body = { customerId: id, stationIds: arr }
		const res = await AttachStation(body)

		// window.location.reload()
		if (res?.status !== 200) {
			dispatch(
				SweetAlert(
					<Alert
						style={{ width: '680px', padding: '0 17px' }}
						title={
							res?.title
								? res?.title
								: 'Houve um erro ao vincular a estação'
						}
						warning
						confirmBtnText="Ok"
						onConfirm={() => dispatch(HideAlert())}
					/>,
				),
			)
		} else {
			dispatch(
				showNotification(
					SuccessNotificationComponent(
						'Estação vinculada com sucesso!',
					),
				),
			)
		}

		handleCloseAttachStation()
		// window.location.reload()
	}

	function handleOpenLinkStation() {
		setModalLinkStations({ open: true })
	}

	function handleToggle(id) {
		if (arr.includes(id)) {
			let index = arr.indexOf(id)
			arr.splice(index, 1)
		} else {
			arr.push(id)
		}
	}

	function handleNewStation() {
		dispatch(
			SweetAlert(
				<Alert
					style={{ width: '680px', padding: '0 17px' }}
					showConfirm={false}
					showCancel={false}
					title={
						<StationForm
							classes={classes}
							onCancel={() => dispatch(HideAlert())}
						/>
					}
				/>,
			),
		)
	}

	return (
		<>
			<Grid item xs={4}>
				<Section title="estações vinculadas" icon="icon-estacao" />
			</Grid>
			<Grid
				item
				xs={8}
				container
				justify="flex-end"
				className="button-head"
			>
				<Button
					size="sm"
					color={edit ? 'primary' : 'disabled'}
					disabled={!edit}
					onClick={() => handleNewStation()}
				>
					<i
						style={{
							marginRight: 10,
							fontSize: 14,
							padding: '2px 0',
						}}
						className="icon-ico_add_estacao"
					/>
					<S.MyButtonText>Adicionar estação</S.MyButtonText>
				</Button>
				<Button
					size="sm"
					color={edit ? 'primary' : 'disabled'}
					disabled={!edit}
					onClick={() => handleOpenLinkStation()}
				>
					<i
						style={{
							fontSize: 14,
							padding: '2px 0',
						}}
						className="icon-vincular"
					/>
					<S.MyButtonText>Vincular estação</S.MyButtonText>
				</Button>
			</Grid>
			<Grid item xs={12}>
				{stations?.length > 0 ? (
					<Table
						hover
						tableHead={[
							'Status',
							'Estação',
							'Id',
							'Data de atualização',
							'Ações',
						]}
						tableData={stations.map((item, index) => [
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<ColoredCircle
									color={item.active ? 'green' : 'danger'}
									size={12}
									className={classes.marginCircle}
								/>
							</div>,
							item.name ? item.name : '-',
							item.id,
							moment(item.createdAt).isValid()
								? moment(item.createdAt).format('L')
								: '-',

							<div style={{ textAlign: 'left' }}>
								<span
									className={cx(
										'icon-map',
										classes.actionButton,
									)}
									title="Ver mapa"
									onClick={() => handleShowLocationMap(item)}
								></span>
								<span
									className={cx(
										'icon-bot_busca',
										classes.actionButton,
									)}
									title="Ver mais"
									onClick={() =>
										goTo(
											`station/view/${item.id}?viewMore=true`,
										)
									}
								></span>
							</div>,
						])}
						customCellClasses={[classes.center, classes.right]}
						// 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
						customClassesForCells={[0, 6]}
						customHeadCellClasses={[classes.center, classes.right]}
						// 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
						customHeadClassesForCells={[0, 6]}
					/>
				) : (
					<NoContentAlert alertText="Não há estações vinculadas" />
				)}
			</Grid>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={12}>
					<Dialog
						className={classes.modalSectionTitle}
						fullWidth={true}
						maxWidth="md"
						open={modalMap.open}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => handleCloseMap()}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<DialogTitle
							id="classic-modal-slide-title"
							disableTypography
							className={classes.modalHeader}
						>
							<div style={{ float: 'left' }}>
								<Section title="LOCALIZAÇÃO DA ESTAÇÃO" />
							</div>

							<DialogActions>
								{/* <Button size="sm" color="wine" onClick={() => handleCloseMap()}>
									<S.MyIcon
										style={{
											fontSize: "17px",
											width: "20px",
											color: "white",
										}}
										className={"icon-cancelar"}
									></S.MyIcon>
									Fechar
								</Button> */}
								<CloseButton handleClose={handleCloseMap} />
							</DialogActions>
						</DialogTitle>
						<DialogContent
							id="classic-modal-slide-description"
							className={classes.modalBody}
						>
							{modalMap.geoLat != null &&
							modalMap.geoLat !== 0 &&
							modalMap.geoLong != null &&
							modalMap.geoLong !== 0 ? (
								<SatelliteMap
									stations={[modalMap]}
									center={[modalMap.geoLat, modalMap.geoLong]}
								/>
							) : (
								<NoContentAlert alertText="Não foi possível localizar a estação" />
							)}
						</DialogContent>
					</Dialog>
				</GridItem>
			</GridContainer>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={12}>
					<Dialog
						className={classes.modalSectionTitle}
						fullWidth={true}
						maxWidth="md"
						open={modalLinkStations.open}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => handleCloseAttachStation()}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<DialogTitle
							id="classic-modal-slide-title"
							disableTypography
							className={classes.modalHeader}
						>
							<div style={{ float: 'left' }}>
								<Section title="Vincular Estações" />
							</div>

							<DialogActions>
								<Button
									size="sm"
									color="primary"
									style={{ marginRight: '10px' }}
									onClick={() => handleSaveAttach()}
								>
									<Icon
										style={{
											fontSize: '14px',
											width: '20px',
										}}
										className={'icon-salvar'}
									></Icon>
									Vincular
								</Button>
								<Button
									size="sm"
									color="greenBorderButtonColor"
									onClick={() => handleCloseAttachStation()}
								>
									<Icon
										style={{
											fontSize: '17px',
											width: '20px',
										}}
										className={'icon-cancelar'}
									></Icon>
									Cancelar
								</Button>
							</DialogActions>
						</DialogTitle>
						<DialogContent>
							{lsAllStationsOwnerless &&
								lsAllStationsOwnerless?.length > 0 && (
									<Table
										hover
										tableHead={[
											'',
											'Status',
											'Estação',
											'Bluetooth',
										]}
										tableData={lsAllStationsOwnerless.map(
											(item, index) => [
												<FormControlLabel
													control={
														<Checkbox
															tabIndex={-1}
															onClick={() =>
																handleToggle(
																	item.id,
																	index,
																)
															}
															checkedIcon={
																<Check
																	className={
																		classes.checkedIcon
																	}
																/>
															}
															icon={
																<Check
																	className={
																		classes.uncheckedIcon
																	}
																/>
															}
															classes={{
																checked:
																	classes.checked,
																root: classes.checkRoot,
															}}
														/>
													}
												/>,
												<div
													style={{
														display: 'flex',
														justifyContent:
															'flex-start',
													}}
												>
													<ColoredCircle
														color={
															item.active
																? 'green'
																: 'danger'
														}
														size={12}
														className={
															classes.marginCircle
														}
													/>
												</div>,
												item.name,
												item.bluetoothDeviceId,
											],
										)}
									/>
								)}
							{!lsAllStationsOwnerless?.length > 0 && (
								<NoContentAlert alertText="Nenhuma estação encontrada" />
							)}
						</DialogContent>
					</Dialog>
				</GridItem>
			</GridContainer>
		</>
	)
}

export default LinkedStations
