import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Login from "./components/Login";
import authStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx";
import Footer from "components/Footer/Footer.jsx";
import login from "assets/img/bg_login.jpg";

// Utils
import compose from "utils/compose";

class Auth extends React.Component {

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.wrapper} ref="wrapper">
				<div className={classes.fullPage} style={{ backgroundImage: "url(" + login + ")" }}>
					<Route
						path="/auth/login"
						component={Login}
					/>
					<Footer white />
				</div>
			</div>
		);
	}
}

Auth.propTypes = {
	classes: PropTypes.object.isRequired
};

export default compose(
	withStyles(authStyle)
)(Auth);