import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import CommonPage from 'components/Pages/CommonPage'
import DocumentTitle from 'components/Pages/DocumentTitle'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { HideAlert, HideLoader, ShowLoader, SweetAlert } from 'redux/actions'
import Loader from 'components/Loader/Loader'
import { history } from 'utils'
import { CreateConfigBox } from './styles'
import CustomInput from 'components/CustomInput/CustomInput'
import Section from 'components/Section'
import { goTo } from 'utils/utils'
import { useParams } from 'react-router'
import LinkedStations from '../LinkedStations'
import { getById, Create, Upsert } from 'services/api/UserManager/api'
import { showNotification } from 'redux/actions/notification'
import {
	SuccessNotificationComponent,
	FailedNotificationComponent,
} from 'redux/react-notifications'
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom'
import useSWR from 'swr'
import { useHistory } from 'react-router-dom'
import schema from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import InputMask from 'react-input-mask'

import TextField from '@material-ui/core/TextField'

const breadcrumbItems = [{ name: 'Home' }, { name: 'Clientes', last: true }]

function CrudCustomers({ classes }) {
	const history = useHistory()
	const { id } = useParams()
	const [edit, setEdit] = useState(false)
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		reset,
		formState: { errors, isDirty },
	} = useForm({
		defaultValues: {
			fullName: '',
			email: '',
			phoneNumber: '',
			address: {
				addressLine1: '',
				postalCode: '',
				stateProvince: '',
			},
		},
		resolver: yupResolver(schema),
	})

	function useQuery() {
		return new URLSearchParams(useLocation().search)
	}
	const query = useQuery()
	const body = query.get('body')

	const { data } = useSWR('/api/UserManager/GetById', () => getById(id))

	async function sendForm(formData) {
		try {
			dispatch(ShowLoader(<Loader />))

			let res = ''

			let formObj = ''
			if (id) {
				formObj = {
					id,
					username: formData.email,
					document: '',
					...formData,
				}
				res = await Upsert(formObj)
			} else {
				formObj = {
					id: '',
					username: formData.email,
					document: '',
					...formData,
				}
				res = await Create(formObj)
			}

			if (res?.status !== 200) {
				return dispatch(
					showNotification(
						FailedNotificationComponent(
							'Falha na criação de usuário, email não pode ser repetido!',
						),
					),
				)
			}

			if (res) {
				if (!id) {
					goTo(`customer/crud/${res?.data?.id}`)
				}

				return dispatch(
					showNotification(
						SuccessNotificationComponent(
							'Usuario salvo com sucesso!',
						),
					),
				)
			}
		} catch (error) {
			console.log('deu merda')
		} finally {
			dispatch(HideLoader(<Loader />))
			reset({ ...formData })
		}
	}

	function handleEdit() {
		setEdit(true)
	}

	function handleCancelEdit() {
		setEdit(false)
	}

	useEffect(() => {
		async function fetchData() {
			try {
				dispatch(ShowLoader(<Loader />))

				if (data) {
					return reset({ ...data })
				}
			} catch (error) {
			} finally {
				dispatch(HideLoader(<Loader />))
			}
		}

		if (id) fetchData()
	}, [dispatch, id, reset, data])

	return (
		<CommonPage>
			<DocumentTitle pageTitle={'Clientes'} />
			<GridContainer>
				<GridItem xs={12}>
					<Breadcrumbs items={breadcrumbItems} />
					<Card>
						<CardBody>
							<CreateConfigBox
								item
								xs={12}
								container
								errors={errors}
							>
								<Grid item xs={4}>
									<Section
										title="Cadastrar cliente"
										icon="icon-ico_cliente"
									/>
								</Grid>
								<Grid
									item
									xs={8}
									container
									justify="flex-end"
									className="button-head"
								>
									{id ? (
										<>
											<Button
												size="sm"
												color="primary"
												onClick={
													edit
														? handleSubmit(sendForm)
														: () => handleEdit()
												}
											>
												<i
													style={{
														marginRight: 10,
														fontSize: 14,
														padding: '2px 0',
													}}
													className="icon-salvar"
												/>
												{edit ? 'Salvar' : 'Editar'}
											</Button>
											<Button
												size="sm"
												color="greenBorderButtonColor"
												onClick={
													edit
														? () =>
																handleCancelEdit()
														: () =>
																body
																	? goTo(
																			`customer?body=${body}`,
																			//`/customer`,
																	  )
																	: //history.goBack()
																	  goTo(
																			// `customer?body=${body}`,
																			`customer`,
																	  )
												}
											>
												<i
													style={{
														fontSize: 14,
														padding: '2px 0',
													}}
													className={
														edit
															? 'icon-cancelar'
															: 'icon-voltar'
													}
												/>
												{edit ? 'Cancelar' : 'Voltar'}
											</Button>
										</>
									) : (
										<>
											<Button
												size="sm"
												color="primary"
												onClick={handleSubmit(sendForm)}
											>
												<i
													style={{
														marginRight: 10,
														fontSize: 14,
														padding: '2px 0',
													}}
													className="icon-salvar"
												/>
												Salvar
											</Button>

											<Button
												size="sm"
												color="greenBorderButtonColor"
												onClick={() => goTo('customer')}
											>
												<i
													style={{
														fontSize: 14,
														padding: '2px 0',
													}}
													className="icon-cancelar"
												/>
												Cancelar
											</Button>
										</>
									)}
								</Grid>
								<form
									onSubmit={handleSubmit(sendForm)}
									style={{
										width: '100%',
										marginBottom: '30px',
									}}
									autoComplete="off"
								>
									<Grid
										container
										xs={12}
										className="header-form"
									>
										<Grid
											item
											lg={4}
											md={6}
											sm={6}
											xs={12}
											style={{
												paddingRight: '40px',
												marginBottom: 30,
											}}
										>
											<Controller
												name="fullName"
												control={control}
												render={({
													field: {
														onChange,
														onBlur,
														value,
													},
												}) => (
													<CustomInput
														helpText={
															errors?.fullName
																?.message
														}
														error={
															!!errors?.fullName
														}
														labelText="Cliente*"
														formControlProps={{
															fullWidth: true,
														}}
														inputProps={{
															onChange,
															onBlur,
															value,
														}}
													/>
												)}
											/>
										</Grid>
										<Grid
											item
											lg={4}
											md={6}
											sm={6}
											xs={12}
											style={{
												paddingRight: '40px',
												marginBottom: 30,
											}}
										>
											<Controller
												name="email"
												control={control}
												render={({
													field: {
														onChange,
														onBlur,
														value,
													},
												}) => (
													<CustomInput
														helpText={
															errors?.email
																?.message
														}
														error={!!errors?.email}
														label="E-mail*"
														formControlProps={{
															fullWidth: true,
														}}
														inputProps={{
															onChange,
															onBlur,
															value,
														}}
													/>
												)}
											/>
										</Grid>
										<Grid
											item
											lg={4}
											md={6}
											sm={6}
											xs={12}
											style={{
												paddingRight: '40px',
												marginBottom: 30,
											}}
										>
											<Controller
												name="phoneNumber"
												control={control}
												defaultValue=""
												render={({
													field: {
														onChange,
														onBlur,
														value,
													},
												}) => (
													<InputMask
														mask="(99) 99999-9999"
														maskChar={null}
														error={
															!!errors?.phoneNumber
														}
														label="Telefone"
														formControlProps={{
															fullWidth: true,
														}}
														value={value}
														onChange={onChange}
													>
														{(props) => (
															<CustomInput
																onChange={
																	onChange
																}
																value={value}
																helpText={
																	errors
																		?.phoneNumber
																		?.message
																}
																{...props}
															/>
														)}
													</InputMask>
												)}
											/>
										</Grid>
										<Grid
											item
											lg={4}
											md={6}
											sm={6}
											xs={12}
											style={{
												paddingRight: '40px',
												marginBottom: 30,
											}}
										>
											<Controller
												name="address.postalCode"
												control={control}
												render={({
													field: {
														onChange,
														onBlur,
														value,
													},
												}) => (
													<InputMask
														mask="99999-999"
														maskChar={null}
														labelText="CEP"
														formControlProps={{
															fullWidth: true,
														}}
													>
														{(props) => (
															<CustomInput
																error={
																	!!errors
																		?.address
																		?.postalCode
																}
																{...props}
															/>
														)}
													</InputMask>
												)}
											/>
										</Grid>
										<Grid
											item
											lg={4}
											md={6}
											sm={6}
											xs={12}
											style={{
												paddingRight: '40px',
												marginBottom: 30,
											}}
										>
											<Controller
												name="address.stateProvince"
												control={control}
												render={({
													field: {
														onChange,
														onBlur,
														value,
													},
												}) => (
													<CustomInput
														error={
															!!errors?.address
																?.stateProvince
														}
														labelText="Estado"
														formControlProps={{
															fullWidth: true,
														}}
														inputProps={{
															onChange,
															onBlur,
															value,
														}}
													/>
												)}
											/>
										</Grid>
										<Grid
											item
											lg={4}
											md={6}
											sm={6}
											xs={12}
											style={{
												paddingRight: '40px',
												marginBottom: 30,
											}}
										>
											<Controller
												name="address.addressLine1"
												control={control}
												render={({
													field: {
														onChange,
														onBlur,
														value,
													},
												}) => (
													<CustomInput
														error={
															!!errors?.address
																?.addressLine1
														}
														labelText="Endereço"
														formControlProps={{
															fullWidth: true,
														}}
														inputProps={{
															onChange,
															onBlur,
															value,
														}}
													/>
												)}
											/>
										</Grid>
									</Grid>
								</form>
								{id ? (
									<LinkedStations
										edit={edit}
										// stations={data?.stations}
										classes={classes}
										id={id}
									/>
								) : (
									''
								)}
							</CreateConfigBox>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</CommonPage>
	)
}
export default withStyles(sweetAlertStyle)(CrudCustomers)
