import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid
} from "assets/jss/material-dashboard-pro-react.jsx";

const appStyle = theme => ({
  toolbar: theme.mixins.toolbar,
  wrapper: {
    display: 'flex'
  },
  mainPanel: {
    flexGrow: '1',
    maxHeight: "100%",
    width: "100%",
    minHeight: "600px",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    paddingTop: '10px',
    ...transition,
  },
  content: {
    marginTop: "70px",
    padding: "0 15px",
    minHeight: "calc(100vh - 123px)",
    [theme.breakpoints.down('sm')]: {
      padding: "0px 0px 0 15px !important"
    }
  },
  container: { ...containerFluid },
  map: {
    marginTop: "70px"
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  }
});

export default appStyle;
