import React from 'react';

const PageNotFound = () => (
    <div>
        <h1
            style={{
                fontSize: '25vh',
                textShadow: '0 2px 2px #3C4858',
                fontWeight: 'bold',
                color: '#0069c0',
                textAlign: 'center'
            }}
        >
            404
        </h1>
        <p
            style={{
                textAlign: 'center',
                color: '#3C4858',
                fontWeight: 'bold',
                fontSize: '5vh',
            }}
        >
            Página não encontrada
        </p>
        <p
            style={{
                textAlign: 'center',
                color: '#3C4858',
            }}>
            <small>Essa página não existe ou você não possui permissão para acessá-la.</small>
        </p>
    </div>);

export default PageNotFound;
