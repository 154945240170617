import {
	grayColor,
	primaryColor,
} from 'assets/jss/material-dashboard-pro-react.jsx'

import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import dashboardStyle from './dashboardStyle'

const sweetAlertStyle = {
	...extendedFormsStyle,
	...customSelectStyle,
	...dashboardStyle,
	cardTitle: {
		marginTop: '0',
		marginBottom: '3px',
		color: grayColor[2],
		fontSize: '18px',
		fontWeight: 800,
	},
	center: {
		textAlign: 'center',
	},
	right: {
		textAlign: 'right',
	},
	left: {
		textAlign: 'left',
	},
	actionButton: {
		marginRight: '2px',
		cursor: 'pointer',
		background: primaryColor[0],
		borderRadius: '4px',
		color: 'white',
		padding: '7px',
		fontSize: '14px',
		width: '25px',
		lineHeight: 1.5,
	},
	redButton: {
		background: '#f44336',
	},
	orangeButton: {
		background: '#ffa726',
	},
	greyButton: {
		background: '#b6b6b6',
		cursor: 'auto',
	},
	menuItem: {
		color: primaryColor[0],
		textAlign: 'center',
		'&:hover': {
			color: '#fff !important',
		},
	},
	modalRelatorioBlock: {
		'h2,h3': {
			color: 'black',
		},
		h4: {
			textTransform: 'uppercase',
			color: primaryColor[0],
			fontWeight: '600',
		},
	},
	iconCircle: {
		border: '3px solid ' + primaryColor[0],
		borderRadius: '50%',
		width: '75px',
		height: '75px',
		paddingTop: '10px',
		margin: 'auto',
	},
	imgUpload: {
		display: 'inline-grid',
		'& label': {
			cursor: 'pointer',
			color: 'white',
			borderRadius: '4px',
			background: primaryColor[0],
			padding: '8px 0',
			marginTop: '15px',
			width: '120px',
			textAlign: 'center',
		},
		margin: '10px 0',
		"& input[type='file']": {
			display: 'none',
		},
	},
	filterForm: {
		margin: 'auto',
		background: '#f2f2f2',
		borderRadius: '5px',
		padding: '10px',
		width: '100%',
	},
	noPaddingLeft: {
		paddingLeft: '0 !important',
	},
	noPaddingRight: {
		paddingRight: '0 !important',
	},
	marginCircle: {
		margin: '10%',
	},
	filterArrowButton: {
		padding: '4px',
		color: '#0069c0',
		border: '1px solid #0069c0',
		'&:hover': {
			color: 'white',
			background: '#0069c0',
		},
	},
	checkboxLabel: {
		marginRight: '48px',
		marginBottom: '8px',
		color: '#7E8288',
		fontSize: '14px',
	},
	...buttonStyle,
}

export default sweetAlertStyle
