import CustomTable, { initialPagination } from 'components/CustomTable'
import React, { useState, useEffect, useCallback } from 'react'
import {
	getGroupManagerStations,
	GET_GROUP_MANAGER_STATIONS,
	getGroupManagerById,
	GET_GROUP_MANAGER_BY_ID,
} from 'services/api/StationGroupManager/api'
import useSWR from 'swr'
import { useDispatch } from 'react-redux'
import CustomIcon from 'components/CustomIcon'
import { goTo } from 'utils/utils'
import { HideLoader, ShowLoader } from 'redux/actions'
import Loader from 'components/Loader/Loader'
import CustomStep from 'components/CustomStep'
import ModalMap from 'pages/StationsGroup/components/ModalMap'
import CustomArrow from 'components/CustomArrow'
import { Tooltip } from '@material-ui/core'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

const ClientTable = ({ id }) => {
	const [pagination, setPagination] = useState(initialPagination)
	const dispatch = useDispatch()
	// const { data: users } = useSWR(
	// 	`${GET_GROUP_MANAGER_BY_ID}?id=${id}&skip=${pagination.page}&$top=${pagination.pageSize}`,
	// 	(url) => getGroupManagerById(url),
	// )

	// var teste = `${GET_GROUP_MANAGER_BY_ID}?id=${id}&skip=${pagination.page}&$top=${pagination.pageSize}`

	// console.log(teste)

	const [users, setUsers] = useState([])
	const [columnOrder, setColumnOrder] = useState({
		columnText: '',
		columnAction: '',
	})

	const [reqBody, setReqBody] = useState({
		fullName: '',
		email: '',
		phoneNumber: '',
		active: true,
		skip: pagination.currentPage,
		top: pagination.pageSize,
		orderBy: '',
		orderType: '',
	})

	const [showModal, setShowModal] = useState({
		loading: false,
		geoLat: null,
		geoLong: null,
		name: null,
		owner: {
			fullName: null,
		},
		open: false,
	})

	const orderReferenceText = {
		active: 'Status',
		fullName: 'Cliente',
		email: 'Email',
		phoneNumber: 'Telefone',
	}

	function showMap(rowData) {
		setShowModal({
			loading: false,
			name: rowData.name,
			owner: {
				fullName: rowData.owner,
			},
			open: true,
			geoLat: rowData.geoLat,
			geoLong: rowData.geoLong,
		})
	}

	function hideModal() {
		setShowModal({
			loading: false,
			geoLat: null,
			geoLong: null,
			name: null,
			owner: {
				fullName: null,
			},
			open: false,
		})
	}

	const actions = [
		{
			icon: () => (
				<span className="button-span" style={{ width: '23.1px' }}>
					<CustomIcon
						className="icon-bot_busca"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Visualizar',
			onClick: (event, rowData) => goTo(`customer/crud/${rowData.id}`),
		},
	]

	const columns = [
		{
			title:
				columnOrder.columnText === 'Status' ? (
					<CustomArrow
						text={columnOrder.columnText}
						order={columnOrder.columnAction}
					/>
				) : (
					'Status'
				),
			field: 'active',
			headerStyle: {
				textAlign: 'center',
			},
			render: function (rowData) {
				return (
					<>
						<Tooltip title={rowData.statusText}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									marginLeft: '-30px',
								}}
							>
								<CustomIcon
									className={rowData.statusIcon}
									color={rowData.statusColor}
									size="18px"
								/>
							</div>
						</Tooltip>
					</>
				)
			},
		},
		{
			title: 'Nome do cliente',
			type: 'string',
			field: 'userName',
		},
		{
			title: 'E-mail',
			type: 'string',
			field: 'email',
		},
		{
			title: 'Telefone',
			type: 'string',
			field: 'phoneNumber',
		},
	]

	const handleGetUserList = async (id) => {
		try {
			const res = await getGroupManagerById(id)

			res?.users.map((user) => {
				if (user.active === true) {
					user.statusText = 'Ativo'
					user.statusIcon = 'icon-ativo'
					user.statusColor = germiniDefaultColors[0]
				} else {
					user.statusText = 'Desativado'
					user.statusIcon = 'icon-desativado'
					user.statusColor = germiniDefaultColors[11]
				}
			})

			setUsers(res?.users)
			return res
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		handleGetUserList(id)
	}, [id])

	useEffect(() => {
		setColumnOrder({
			columnText: orderReferenceText[reqBody.orderBy],
			columnAction: reqBody.orderType,
		})
	}, [reqBody])

	// dispatch(ShowLoader(<Loader />))

	// if (users?.items) {
	// 	setPagination((prevState) => ({
	// 		...prevState,
	// 		recordCount: users.count,
	// 	}))

	// 	dispatch(HideLoader())
	// }

	return (
		<div>
			<CustomTable
				actions={actions}
				columns={columns}
				pagination={pagination}
				setPagination={setPagination}
				loading={!users}
				data={users}
				style={{ marginTop: 30 }}
			/>
			<ModalMap showModal={showModal} onCancel={() => hideModal()} />
		</div>
	)
}

export default ClientTable
