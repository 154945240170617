import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import { Icon } from "@material-ui/core";

// layout components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import FieldInput from "components/FieldForm/FieldInput.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CommonPage from "components/Pages/CommonPage";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';
import PictureUpload from 'components/CustomUpload/PictureUpload.jsx';
import Table from "components/Table/Table.jsx";
import NoContentAlert from 'components/NoContentAlert/NoContentAlert';
import Section from 'components/Section';
import DocumentTitle from "components/Pages/DocumentTitle";
import Loader from "components/Loader/Loader.jsx";

//classes
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import { SweetAlert, HideAlert, GetUserById, ShowLoader, HideLoader, CreateUser, UpdateUser, GetUsers, ResetUserPassword } from 'redux/actions';

//utils
import compose from "utils/compose";
import { isAllowed } from "utils/auth";

/**
*  @author Ayrton Gomes
*  RegisterForm class component to register new User
*/
class RegisterForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { name: "Home" },
                { name: "Controle de acesso" },
                { name: "Usuários" },
                { name: "Cadastro", last: true },
            ],
            iniValues: {
                id: '',
                fullName: '',
                document: '',
                email: '',
                mobilePhoneNumber: '',
                phoneNumber: '',
            },
            hasWritePermission: false,
        }
    }
    componentDidMount() {
        const id = this.props.match && this.props.match.params.id;
        let _edit = _.includes(this.props.match.path, "edit");

        let user = this.props.user;
        if (user) {
            if (isAllowed(user, ['beWeather.user.write'])) {
                this.setState({ hasWritePermission: true })
            }
        } else {
            this.props.history.push('/404')
        }

        if (_edit) {
            this.props.ShowLoader(<Loader />);
            this.props.GetUserById(id);
        }

        if (_edit && !id) {
            this.props.history.goBack();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userData !== this.props.userData) {
            this.setState({ iniValues: { ...this.props.userData } })
            this.props.HideLoader();
        }
    }

    handleSubmit = (user) => {
        user['userName'] = user.email;
        if (user.id === '') {
            delete user.id;
            this.props.CreateUser(user).then(resp => {
                this.props.GetUsers();
            });
        } else {
            this.props.UpdateUser(user).then(resp => {
                this.props.GetUsers();
            });
        }
        this.props.history.push(`/admin/access_control/users`)
    }

    handleResetPsswd = id => {
        this.props.ResetUserPassword(id).then(resp => {
            if (resp) this.props.history.push(`/admin/access_control/user/details/${id}`)
        })
    }

    render() {
        const { classes } = this.props;
        const { breadcrumbItems, iniValues, hasWritePermission } = this.state;
        return (
            <CommonPage>
                <DocumentTitle pageTitle={'Cadastro de usuário'} />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Breadcrumbs items={breadcrumbItems} />

                        <Card>
                            <CardBody>
                                <Formik
                                    onSubmit={
                                        (values) => {
                                            this.handleSubmit(values)
                                        }
                                    }
                                    initialValues={iniValues}
                                    enableReinitialize
                                >
                                    {({ handleSubmit, values }) => (
                                        <Form>
                                            <GridContainer>
                                                <GridItem xs={12} sm={6} md={6} lg={6}>
                                                    <Section title="CADASTRO DE USUÁRIO" icon="icon-ico_cliente" />
                                                </GridItem>
                                                <GridItem xs={12} sm={6} md={6} lg={6} style={{ textAlign: 'right' }}>
                                                    <Button
                                                        size="sm"
                                                        color="primary"
                                                        style={{ marginRight: '10px' }}
                                                        disabled={!hasWritePermission}
                                                        onClick={hasWritePermission ? (() => handleSubmit()) : (() => console.log("Sem permissão"))}
                                                    >
                                                        <Icon style={{ fontSize: '14px', width: '20px' }} className={'icon-salvar'}></Icon>Salvar
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        color="greenBorderButtonColor"
                                                        onClick={() => this.props.history.goBack()}
                                                        style={{ marginRight: '10px' }}
                                                    >
                                                        <Icon style={{ fontSize: '17px', width: '20px' }} className={'icon-cancelar'}></Icon>Cancelar
                                                    </Button>
                                                    {values.id !== null && values.id !== '' &&
                                                        < Button
                                                            size="sm"
                                                            color="greenBorderButtonColor"
                                                            disabled={!hasWritePermission}
                                                            style={{ marginRight: '10px' }}
                                                            onClick={hasWritePermission ? (() => this.handleResetPsswd(values.id)) : (() => console.log("Sem permissão"))}
                                                        >
                                                            <Icon style={{ fontSize: '19px', width: '20px' }} className={'icon-reenviar_senha'}></Icon>Reenviar senha
                                                        </Button>
                                                    }
                                                </GridItem>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={3}>
                                                        <PictureUpload disabled={false} />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={9}>
                                                        <GridContainer>
                                                            <Field name='fullName'>
                                                                {({ field, form }) => (
                                                                    <FieldInput touched={form.touched.fullName} error={form.errors.fullName} form={form} field={field} label={'Nome'} inputGrid={8} />
                                                                )}
                                                            </Field>
                                                            <Field name='document'>
                                                                {({ field, form }) => (
                                                                    <FieldInput touched={form.touched.document} error={form.errors.document} form={form} field={field} label={'CPF'} inputGrid={4} />
                                                                )}
                                                            </Field>

                                                            <Field name='mobilePhoneNumber'>
                                                                {({ field, form }) => (
                                                                    <FieldInput touched={form.touched.mobilePhoneNumber} error={form.errors.mobilePhoneNumber} form={form} field={field} label={'Celular'} inputGrid={4} />
                                                                )}
                                                            </Field>
                                                            <Field name='phoneNumber'>
                                                                {({ field, form }) => (
                                                                    <FieldInput touched={form.touched.phoneNumber} error={form.errors.phoneNumber} form={form} field={field} label={'Telefone'} inputGrid={4} />
                                                                )}
                                                            </Field>
                                                            <Field name='email'>
                                                                {({ field, form }) => (
                                                                    <FieldInput touched={form.touched.email} error={form.errors.email} form={form} field={field} label={'E-mail'} inputGrid={4} />
                                                                )}
                                                            </Field>
                                                        </GridContainer>
                                                    </GridItem>
                                                </GridContainer>
                                                <GridItem xs={12} >
                                                    <Section title="GRUPOS ASSOCIADOS" />
                                                </GridItem>
                                                <GridItem xs={12}>
                                                    <Table
                                                        hover
                                                        tableHead={["Grupo", "Descrição", "Ações"]}
                                                        tableData={[[]]}
                                                        customHeadCellClasses={[classes.left, classes.center, classes.right]}
                                                        customHeadClassesForCells={[0, 1, 2]}
                                                    />
                                                    <NoContentAlert alertText={'Não há grupos associados ao usuário'} />
                                                </GridItem>
                                            </GridContainer>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </CommonPage >
        );
    }
}

const mapStateToProps = state => {

    return {
        userData: state.user.userById,
        userDataFailed: state.user.userByIdDataFailed,

        user: state.authentication.user,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { SweetAlert, HideAlert, ShowLoader, HideLoader, GetUserById, CreateUser, UpdateUser, GetUsers, ResetUserPassword }
    , dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sweetAlertStyle),
    withTranslation(),
)(RegisterForm);