import styled from "styled-components";
import { Icon } from "@material-ui/core";

export const MyIcon = styled(Icon)`
	&.icon-cancelar::before {
		color: white;
	}
`;

export const MyButtonText = styled.span`
	color: white;
`;
