import { Icon } from '@material-ui/core'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import CustomIcon from 'components/CustomIcon'
import CustomTable, {
	initialPagination,
	initialSort,
} from 'components/CustomTable'
import React, { useEffect, useState } from 'react'
import {
	getMessageManager,
	GET_MESSAGE_MANAGER_ALL,
} from 'services/api/MessageManager/api'
import SketchView from '../SketchView'
import TableModal from '../TableModal'
import useSWR, { mutate as swrMutate } from 'swr'
import { format } from 'date-fns'

function SentTable({
	setShowNotification,
	setConfirmModalProps,
	filter = '',
	mutate,
}) {
	const [pagination, setPagination] = useState(initialPagination)
	const [loading, setLoading] = useState(true)
	const [sort, setSort] = useState(initialSort)

	const { data } = useSWR(
		`${GET_MESSAGE_MANAGER_ALL}?$skip=${pagination.page}&$top=${pagination.pageSize}&$filter=State eq 'Send'${filter}`,
		(url) => getMessageManager(url),
	)

	useEffect(() => {
		setPagination(initialPagination)
	}, [mutate])

	useEffect(() => {
		swrMutate(
			`${GET_MESSAGE_MANAGER_ALL}?$skip=${pagination.page}&$top=${pagination.pageSize}&$filter=State eq 'Send'${filter}`,
		)
	}, [filter, pagination.page])

	useEffect(() => {
		setPagination((prevState) => ({
			...prevState,
			page: 0,
		}))
		swrMutate(
			`${GET_MESSAGE_MANAGER_ALL}?$skip=${pagination.page}&$top=${pagination.pageSize}&$filter=State eq 'Send'${filter}`,
		)
	}, [pagination.pageSize])

	useEffect(() => {
		setLoading(true)

		if (data?.items) {
			console.log(
				'🚀 ~ file: index.js ~ line 57 ~ useEffect ~ data?.items',
				data?.items,
			)

			setPagination((prevState) => ({
				...prevState,
				recordCount: data.count,
			}))
			setLoading(false)
		}
	}, [data])

	const actions = [
		{
			icon: () => (
				<span className="button-span">
					<CustomIcon
						className="icon-bot_busca"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Procurar',
			onClick: (event, rowData) =>
				setShowNotification((prevState) => ({
					...prevState,
					open: true,
					content: (
						<SketchView
							sketch={{
								date: format(
									new Date(rowData.sendDate),
									'dd/MM/yyyy',
								),
								title: rowData.title,
								description: rowData.description,
							}}
						/>
					),
					title: 'Detalhes da mensagem',
					headerIcon: 'icon-enviar',
					haveEdit: false,
				})),
		},
		{
			icon: () => (
				<span className="button-span yellow">
					<CustomIcon
						className="icon-arquivar"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Arquivar',
			onClick: (event, rowData) =>
				setConfirmModalProps({
					open: true,
					text: 'Deseja realmente arquivar esta mensagem?',
					header: 'Arquivar',
					type: 'archive',
					id: rowData.id,
					subText:
						'Ao confirmar a ação, a mensagem não poderá ser restaurada.',
				}),
		},
	]

	const columns = [
		{
			title: 'Título da mensagem',
			field: 'title',
		},
		{
			title: 'Data de envio',
			field: 'sendDate',

			type: 'datetime',
		},
		{
			title: 'Enviados',
			field: 'totalSent',
		},
		{
			title: 'Lidos',
			field: 'date',
			render: (rowData) => (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}
				>
					<Icon
						style={{
							fontSize: rowData?.totalRead > 0 ? '18px' : '15px',
							width: '30px',
							color: germiniDefaultColors[1],
							cursor: 'pointer',
						}}
						className={
							rowData?.totalRead > 0
								? 'icon-Tab_lido'
								: 'icon-Tab_naoLido'
						}
						onClick={() =>
							setShowNotification((prevState) => ({
								...prevState,
								open: true,
								content: <TableModal id={rowData.id} />,
								title: 'DETALHES DE LEITURA',
								haveEdit: false,
							}))
						}
					/>
					<span>
						{rowData?.totalRead > 0 ? rowData?.totalRead : '-'}
					</span>
				</div>
			),
		},
	]

	return (
		<CustomTable
			actions={actions}
			columns={columns}
			pagination={pagination}
			setPagination={setPagination}
			sort={sort}
			setSort={setSort}
			data={data?.items?.filter((item) => item.state === 0)}
			loading={loading}
		/>
	)
}

export default SentTable
