import axios from "axios";

export const GET_STATION_ALL = "/api/Station/GetAllStation";
export const GET_STATIONS = "api/StationManager/GetAll";
export const GET_STATION_BY_ID = "/api/StationManager/GetById";
export const GET_DATA_INTEGRITY_BY_FILTER =
	"/api/StationManager/GetDataIntegrityByFilter";

export const getStation = async (url) => {
	try {
		const { data } = await axios.get(url);
		return data;
	} catch (error) {}
};

export const getStations = async (filter) => {
	try {
		const { data } = await axios.get(GET_STATIONS + filter);
		return data;
	} catch (error) {}
};

export const getStationById = async (id) => {
	try {
		const { data } = await axios.get(`${GET_STATION_BY_ID}/${id}`);
		return data;
	} catch (error) {}
};

export const getDataIntegrityByFilter = async (url, body) => {
	try {
		const { data } = await axios.post(url, body);
		return data;
	} catch (error) {}
};
