import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import NoContentAlert from "components/NoContentAlert/NoContentAlert.jsx";
import SatelliteMap from "components/Maps/Maps.jsx";
import GridItem from "components/Grid/GridItem";
import Section from "components/Section";

import {
	Icon,
	Slide,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import CloseButton from "components/CloseButton";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

function ModalMap({ onCancel, showModal }) {
	return (
		<GridContainer justify="center">
			<GridItem xs={12} sm={12} md={12}>
				<Dialog
					fullWidth={true}
					maxWidth="md"
					open={showModal.open}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => onCancel}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle id="classic-modal-slide-title" disableTypography>
						<div style={{ float: "left" }}>
							<Section title="LOCALIZAÇÃO DA ESTAÇÃO" />
						</div>

						<DialogActions>
							{/* <Button size="sm" color="wine" onClick={onCancel}>
								<Icon
									style={{
										fontSize: "17px",
										width: "20px",
									}}
									className={"icon-cancelar"}
								></Icon>
								Fechar
							</Button> */}
							<CloseButton handleClose={onCancel} />
						</DialogActions>
					</DialogTitle>
					<DialogContent id="classic-modal-slide-description">
						{showModal.geoLat != null &&
						showModal.geoLat !== 0 &&
						showModal.geoLong != null &&
						showModal.geoLong !== 0 ? (
							<SatelliteMap
								stations={[showModal]}
								center={[showModal.geoLat, showModal.geoLong]}
							/>
						) : (
							<NoContentAlert alertText="Não foi possível localizar a estação" />
						)}
					</DialogContent>
				</Dialog>
			</GridItem>
		</GridContainer>
	);
}

export default ModalMap;
