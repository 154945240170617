import axios from "axios";

// GET
export const GetStationsData = (_filter, _page, _itemsPerPage) => {
	let page = "";

	const count = (_page ? Number(_page, 10) : 0) * Number(_itemsPerPage, 10);

	if (_filter) {
		page = `&$skip=${count}&$top=${_itemsPerPage}`;
	} else {
		page = `?$skip=${count}&$top=${_itemsPerPage}`;
	}
	//
	const url = `/api/StationManager/GetAll${_filter}${page}`;
	return axios.get(url).then((response) => response.data);
};

export const PostStation = (obj) => {
	return axios
		.post(`/api/StationManager/Add`, obj)
		.then((response) => response.data);
};

export const PutStation = (obj) => {
	return axios
		.post(`/api/StationManager/Upsert`, obj)
		.then((response) => response.data);
};

export const DisassociateUser = (id) => {
	return axios
		.put(`/api/StationManager/UnassociateUserById/${id}`)
		.then((response) => response.data);
};
// GET OWNERLESS
export const GetAllStationsOwnerless = () => {
	return axios
		.get(`/api/StationManager/GetAllOwnerless`)
		.then((response) => response.data);
};

// GET BY ID
export const GetStationById = (id) => {
	return axios
		.get(`/api/StationManager/GetById/${id}`)
		.then((response) => response.data);
};

//DELETE
export const DeleteStation = (id) => {
	return axios
		.delete(`/api/StationManager/Delete?id=${id}&softDelete=false`)
		.then((response) => response.data);
};
