import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Alert from "react-bootstrap-sweetalert";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Container } from "@material-ui/core";

// core components
import ErrorPage from "pages/Error/404.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FieldInput from "components/FieldForm/FieldInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button";
import Section from "components/Section";
import GridItem from "components/Grid/GridItem.jsx";
import Loader from "components/Loader/Loader.jsx";

import { dashRoutes } from "./routes.js";

// Utils
import compose from "utils/compose";
import { isAllowed } from "utils/auth";

import { withTranslation } from "react-i18next";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

import { Icon } from "@material-ui/core";

import image from "assets/img/bg_menu.png";
import logoF from "assets/img/beWeather_white_logo.png";
import logoP from "assets/img/beWeather_logo_smaller.png";

import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import { ChangePassword, ShowLoader, HideLoader, logout } from "redux/actions";
import Construction from "pages/Construction/index.js";
import SnackDefault from "components/SnackDefault/index.js";

const AdminContext = React.createContext({
	mobileOpen: false,
	sidebarMini: false,
});

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mobileOpen: false,
			image: image,
			color: "customizeColor",
			bgColor: "black",
			hasImage: true,
			snackStatus: {
				open: false,
			},
			logo: isWidthDown("md", this.props.width) ? logoP : logoF,
			fixedClasses: "dropdown",
			miniActive: isWidthDown("md", this.props.width),
			alertPsswd: null,
			user: null,
		};
		this.resizeFunction = this.resizeFunction.bind(this);
	}

	componentDidMount() {
		window.addEventListener("resize", this.resizeFunction);
		if (this.props.user) {
			this.setState({ user: this.props.user });
		}
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.resizeFunction);
	}
	componentDidUpdate(e) {
		if (e.user !== this.props.user) {
			this.setState({ user: this.props.user });
		}
		if (e.history.location.pathname !== e.location.pathname) {
			// this.refs.mainPanel.scrollTop = 0;
			if (this.state.mobileOpen) {
				this.setState({ mobileOpen: false });
			}
		}
	}
	handleImageClick = (image) => {
		this.setState({ image: image });
	};
	handleColorClick = (color) => {
		this.setState({ color: color });
	};
	handleBgColorClick = (bgColor) => {
		this.setState({ bgColor: bgColor });
	};
	handleFixedClick = () => {
		if (this.state.fixedClasses === "dropdown") {
			this.setState({ fixedClasses: "dropdown show" });
		} else {
			this.setState({ fixedClasses: "dropdown" });
		}
	};
	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};
	getRoute() {
		return this.props.location.pathname !== "/admin/full-screen-maps";
	}
	getActiveRoute = (dashRoutes) => {
		const { t } = this.props;

		let activeRoute = "";
		for (let i = 0; i < dashRoutes.length; i++) {
			if (dashRoutes[i].collapse) {
				let collapseActiveRoute = this.getActiveRoute(
					dashRoutes[i].views
				);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (
					window.location.href.indexOf(
						dashRoutes[i].layout + dashRoutes[i].path
					) !== -1
				) {
					return t(dashRoutes[i].name);
				}
			}
		}
		return activeRoute;
	};

	getRoutes = (dashRoutes) => {
		let user = this.props.user;

		if (typeof user.claims === "undefined" || user === null) {
			this.props.logout();
		}

		return dashRoutes.map((prop, key) => {
			if (prop.collapse) {
				return this.getRoutes(prop.views);
			}
			if (prop.layout === "/admin") {
				if (prop.claims.length === 0 || isAllowed(user, prop.claims)) {
					return (
						<Route
							exact={prop.exact}
							path={prop.layout + prop.path}
							component={prop.component}
							key={key}
						/>
					);
				}
				return (
					<Route path={"/404"} component={Construction} key={key} />
				);
			} else {
				return null;
			}
		});
	};
	sidebarMinimize = (miniActive) => {
		this.setState({ miniActive, logo: miniActive ? logoP : logoF });
	};
	resizeFunction() {
		if (window.innerWidth >= 960) {
			this.setState({ mobileOpen: false });
		}
	}

	render() {
		const { classes, users, alert, loading, notification, ...rest } =
			this.props;

		const { user } = this.state;

		const mainPanel = cx({
			[classes.mainPanel]: true,
			[classes.mainPanelSidebarMini]: this.state.miniActive,
			[classes.mainPanelWithPerfectScrollbar]:
				navigator.platform.indexOf("Win") > -1,
		});
		if (user != null) {
			if (user.hasTemporaryPassword) {
				if (this.state.alertPsswd == null) {
					this.setState({
						alertPsswd: (
							<Alert
								style={{ width: "680px", padding: "0 17px" }}
								showConfirm={false}
								showCancel={false}
								title={
									<ResetPasswordForm
										classes={this.props.classes}
										onCancel={() =>
											this.setState({ alertPsswd: null })
										}
										id={user.id}
									/>
								}
							/>
						),
					});
				}
			} else {
				this.state.alertPsswd != null &&
					this.setState({ alertPsswd: null });
			}
		}

		return (
			<AdminContext.Provider
				value={{
					mobileOpen: this.state.mobileOpen,
					sidebarMini: this.state.miniActive,
					snackStatus: this.state.snackStatus,
					setSnackStatus: this.setState,
				}}
			>
				<div className={classes.wrapper}>
					{alert}
					{loading}
					{notification}
					{this.state.alertPsswd}
					<Sidebar
						sidebarMinimize={this.sidebarMinimize}
						routes={dashRoutes}
						logo={this.state.logo}
						image={this.state.image}
						handleDrawerToggle={this.handleDrawerToggle}
						open={this.state.mobileOpen}
						color={this.state.color}
						bgColor={this.state.bgColor}
						miniActive={
							this.state.miniActive && !this.state.mobileOpen
						}
						mobileOpen={this.state.mobileOpen}
						user={this.state.user}
						{...rest}
					/>
					<SnackDefault
						snackStatus={this.state.snackStatus}
						handleCloseSnack={() =>
							this.setState({
								snackStatus: {
									open: false,
								},
							})
						}
					/>
					<div className={mainPanel}>
						<Container fixed={false} maxWidth={"xl"}>
							{/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
							{user && this.getRoute() ? (
								<Switch>
									{this.getRoutes(
										dashRoutes,
										this.props.user
									)}
									<Route path="*">
										<ErrorPage />
										<Route
											path="/admin/404"
											component={ErrorPage}
										></Route>
									</Route>
								</Switch>
							) : (
								<div className={classes.map}>
									<Switch>
										{this.getRoutes(
											dashRoutes,
											this.props.user
										)}
										<Route path="*">
											<ErrorPage />
										</Route>
										<Route
											path="/admin/404"
											component={ErrorPage}
										></Route>
									</Switch>
								</div>
							)}
							<div
								style={{
									display: "block",
									zIndex: "5",
									float: "right",
									color: "#666",
									margin: "0 20px 10px",
									fontSize: "0.7rem",
									opacity: "0.6",
								}}
							></div>
						</Container>
					</div>
				</div>
			</AdminContext.Provider>
		);
	}
}

const SchemaPsswd = Yup.object().shape({
	currentPassword: Yup.string().required("This field is required"),
	newPassword: Yup.string().min(6).required("This field is required"),
	newPasswordConfirm: Yup.string().when("newPassword", {
		is: (val) => (val && val.length > 0 ? true : false),
		then: Yup.string().oneOf(
			[Yup.ref("newPassword")],
			"As senhas não coincidem"
		),
	}),
});

/**
 *  @author Ayrton Gomes
 *  Function component using formik to render the form to submit the values
 */
const ResetPasswordForm = ({ classes, history, id, ...props }) => {
	const dispatchHook = useDispatch();

	const handleSave = (values) => {
		dispatchHook(ShowLoader(<Loader />));
		dispatchHook(ChangePassword(values)).then((resp) => {
			if (resp) {
				if (resp === true) {
					dispatchHook(HideLoader());
					setTimeout(() => {
						dispatchHook(logout());
					}, 1000);
				}
			}
		});
	};

	return (
		<Formik
			onSubmit={(values) => handleSave(values)}
			initialValues={{
				id: id,
				currentPassword: "",
				newPassword: "",
				newPasswordConfirm: "",
			}}
			validationSchema={SchemaPsswd}
		>
			{({ handleSubmit }) => (
				<Form>
					<GridContainer>
						<GridItem
							xs={12}
							sm={6}
							md={6}
							lg={6}
							style={{ textAlign: "left" }}
						>
							<Section title="REDEFINIR SENHA" />
						</GridItem>
						<GridItem
							xs={12}
							sm={6}
							md={6}
							lg={6}
							style={{ textAlign: "right" }}
							className={classes.noPaddingRight}
						>
							<Button
								size="sm"
								color="primary"
								style={{ marginRight: "10px" }}
								onClick={() => handleSubmit()}
							>
								<Icon
									style={{ fontSize: "14px", width: "20px" }}
									className={"icon-salvar"}
								></Icon>
								Salvar
							</Button>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<Field name="currentPassword">
							{({ field, form }) => (
								<FieldInput
									touched={form.touched.currentPassword}
									error={form.errors.currentPassword}
									form={form}
									field={field}
									label={"Senha antiga"}
									inputGrid={12}
									inputType={"password"}
								/>
							)}
						</Field>
						<Field name="newPassword">
							{({ field, form }) => (
								<FieldInput
									touched={form.touched.newPassword}
									error={form.errors.newPassword}
									form={form}
									field={field}
									label={"Nova senha"}
									inputGrid={12}
									inputType={"password"}
								/>
							)}
						</Field>
						<Field name="newPasswordConfirm">
							{({ field, form }) => (
								<>
									<FieldInput
										touched={
											form.touched.newPasswordConfirm
										}
										error={form.errors.newPasswordConfirm}
										form={form}
										field={field}
										label={"Confirmar nova senha"}
										inputGrid={12}
										inputType={"password"}
									/>
									<span
										className={classes.error}
										style={{
											color: "#f44336",
											fontSize: "12px",
											marginLeft: "16px",
											lineHeight: 0,
										}}
									>
										{form.errors.newPasswordConfirm}
									</span>
								</>
							)}
						</Field>
					</GridContainer>
				</Form>
			)}
		</Formik>
	);
};

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	const { users, authentication, sweetAlert, loader, notification } = state;
	const { user } = authentication;
	return {
		user,
		users,
		alert: sweetAlert.alert,
		loading: loader.loading,
		notification: notification.notification,
	};
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{ ChangePassword, ShowLoader, HideLoader, logout },
		dispatch
	);

export { AdminContext };

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(appStyle),
	withWidth(),
	withTranslation()
)(Dashboard);
