import React, { forwardRef } from 'react'
import { InputLabel, MenuItem, Select } from '@material-ui/core'

import * as Styled from './styles'

const CustomSelect = forwardRef(
	(
		{
			header,
			name,
			labelFirst = 'Escolha um status',
			valueList,
			width = '80%',
			...props
		},
		ref,
	) => {
		return (
			<Styled.FormLabel width={width}>
				<InputLabel htmlFor="group-select">{header}</InputLabel>
				<Styled.MySelect
					inputProps={{
						name,
					}}
					MenuProps={{
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						getContentAnchorEl: null,
					}}
					{...props}
					ref={ref}
				>
					<MenuItem disabled>{labelFirst}</MenuItem>
					{valueList?.map((item, index) => (
						<MenuItem key={String(index)} value={item.value}>
							{item.label}
						</MenuItem>
					))}
				</Styled.MySelect>
			</Styled.FormLabel>
		)
	},
)

export default CustomSelect
