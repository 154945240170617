import * as actionTypes from '../../actions/actionsTypes';
import {
    updateObject
} from '../../utility';

const initialState = {
    lsStations: {},
    stationsDataFailed: false,

    postStationData: null,
    postStationFailed: false,

    stationData: [],
    stationDataFailed: false,

    lsAllStationsOwnerless: {},
    allStationsOwnerlessFailed: false,

    deleteStationData: {},
    deleteStationDataFailed: false
};

const fetchStationsData = (state, action) => {
    return updateObject(state, {
        lsStations: action.lsStations
    });
};

const fetchAllStationsOwnerless = (state, action) => {
    return updateObject(state, {
        lsAllStationsOwnerless: action.lsAllStationsOwnerless
    });
};

// DATA FAIL
const fetchStationsFailed = (state, action) => {
    return updateObject(state, {
        stationsDataFailed: true
    });
};

const fetchAllStationsOwnerlessFailed = (state, action) => {
    return updateObject(state, {
        allStationsOwnerlessFailed: true
    });
};

// POST STATION
const fetchPostStationData = (state, action) => {
    return updateObject(state, {
        postStationData: action.postData
    });
};
const postStationFailed = (state, action) => {
    return updateObject(state, {
        postStationFailed: true
    });
};

// PUT STATION
const fetchPutStationData = (state, action) => {
    return updateObject(state, {
        putStationData: action.putData
    });
};
const putStationFailed = (state, action) => {
    return updateObject(state, {
        putStationFailed: true
    });
};

// BY ID
const fetchStationByIdData = (state, action) => {
    return updateObject(state, {
        stationData: action.stationData
    });
};
const fetchStationByIdFailed = (state, action) => {
    return updateObject(state, {
        stationDataFailed: true
    });
};

//Disassociate
const disassociateUserStation = (state, action) => {
    return updateObject(state, {
        disassociateData: action.disassociateData
    });
};
const disassociateUserStationFailed = (state, action) => {
    return updateObject(state, {
        disassociateDataFailed: true
    });
};


// DELETE 
const deleteStationData = (state, action) => {
    return updateObject(state, {
        deleteStationData: action.deleteData
    });
};
const deleteStationDataFailed = (state, action) => {
    return updateObject(state, {
        deleteStationDataFailed: true
    });
};

//
const stationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_STATION_DATA:
            return fetchStationsData(state, action);
        case actionTypes.FETCH_STATION_FAILED:
            return fetchStationsFailed(state, action);

        case actionTypes.POST_STATION_DATA:
            return fetchPostStationData(state, action);
        case actionTypes.POST_STATION_DATA_FAILED:
            return postStationFailed(state, action);

        case actionTypes.PUT_STATION_DATA:
            return fetchPutStationData(state, action);
        case actionTypes.PUT_STATION_DATA_FAILED:
            return putStationFailed(state, action);

        case actionTypes.FETCH_STATION_BY_ID:
            return fetchStationByIdData(state, action);
        case actionTypes.FETCH_STATION_BY_ID_FAILED:
            return fetchStationByIdFailed(state, action);

        case actionTypes.PUT_DISASSOACIATE_USER_STATION_DATA:
            return disassociateUserStation(state, action);
        case actionTypes.PUT_DISASSOACIATE_USER_STATION_DATA_FAILED:
            return disassociateUserStationFailed(state, action);

        case actionTypes.FETCH_ALL_STATIONS_OWNERLESS:
            return fetchAllStationsOwnerless(state, action);

        case actionTypes.FETCH_ALL_STATIONS_OWNERLESS_FAILED:
            return fetchAllStationsOwnerlessFailed(state, action);

        case actionTypes.DELETE_STATION_DATA:
            return deleteStationData(state, action);
        case actionTypes.DELETE_STATION_DATA_FAILED:
            return deleteStationDataFailed(state, action);

        default:
            return state;
    }
};

export default stationReducer;