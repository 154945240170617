import { Tooltip, withStyles } from "@material-ui/core";
import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react";
import CustomIcon from "components/CustomIcon";
import CustomTable, {
	initialPagination,
	initialSort,
} from "components/CustomTable";
import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import {
	getMessageManager,
	GET_MESSAGE_MANAGER_ALL,
} from "services/api/MessageManager/api";
import useSWR, { mutate as swrMutate } from "swr";
import DestinyField from "../DestinyField";
import SketchView from "../SketchView";

const CssTooltip = withStyles({
	tooltip: {
		backgroundColor: "#171717",
		borderRadius: "6px",
		// fontSize: "0.72rem",
		padding: "2px 8px",
		fontWeight: "initial",
		"& span": {
			color: "#171717",
		},
	},
})(Tooltip);

function FileTable({ setShowNotification, filter = "", mutate }) {
	const [pagination, setPagination] = useState(initialPagination);
	const [loading, setLoading] = useState(true);
	const [sort, setSort] = useState(initialSort);
	const { data } = useSWR(
		`${GET_MESSAGE_MANAGER_ALL}?$skip=${pagination.page}&$top=${pagination.pageSize}&$filter=State eq 'Archived'${filter}`,
		(url) => getMessageManager(url)
	);

	useEffect(() => {
		setPagination(initialPagination);
	}, [mutate]);

	useEffect(() => {
		swrMutate(
			`${GET_MESSAGE_MANAGER_ALL}?$skip=${pagination.page}&$top=${pagination.pageSize}&$filter=State eq 'Archived'${filter}`
		);
	}, [filter, pagination.page]);

	useEffect(() => {
		setPagination((prevState) => ({
			...prevState,
			page: 0,
		}));
		swrMutate(
			`${GET_MESSAGE_MANAGER_ALL}?$skip=${pagination.page}&$top=${pagination.pageSize}&$filter=State eq 'Archived'${filter}`
		);
	}, [pagination.pageSize]);

	useEffect(() => {
		setLoading(true);

		if (data?.items) {
			setPagination((prevState) => ({
				...prevState,
				recordCount: data.count,
			}));
			setLoading(false);
		}
	}, [data]);

	const actions = [
		{
			icon: () => (
				<span className="button-span">
					<CustomIcon className="icon-bot_busca" color="#fff" size="14px" />
				</span>
			),
			tooltip: "Procurar",
			onClick: (event, rowData) =>
				setShowNotification((prevState) => ({
					...prevState,
					open: true,
					content: (
						<SketchView
							sketch={{
								date: format(new Date(rowData.sendDate), "dd/MM/yyyy"),
								destiny: <DestinyField destiny={rowData.users} />,
								title: rowData.title,
								description: rowData.description,
							}}
						/>
					),
					title: "Detalhes da mensagem",
					headerIcon: "icon-enviar",
					haveEdit: false,
				})),
		},
	];

	const columns = [
		{
			title: "Título da mensagem",
			field: "title",
			render: (rowData) => (
				<div
					style={{
						display: "flex",
						justifyContent: "flex-start",
					}}
				>
					<CssTooltip
						id={`tooltip-id`}
						title={rowData?.totalSent > 0 ? "Mensagem enviada" : "Rascunho"}
						placement="top-start"
						arrow
					>
						<i
							className={
								rowData?.totalSent > 0 ? "icon-enviar" : "icon-rascunho"
							}
							style={{
								color: germiniDefaultColors[1],
								fontSize: 18,
							}}
						/>
					</CssTooltip>
					<p style={{ marginLeft: 10 }}>{rowData?.title}</p>
				</div>
			),
		},
		{
			title: "Data de arquivamento",
			field: "archivedDate",
			type: "datetime",
		},
	];

	return (
		<CustomTable
			actions={actions}
			columns={columns}
			pagination={pagination}
			loading={loading}
			setPagination={setPagination}
			sort={sort}
			setSort={setSort}
			data={data?.items}
		/>
	);
}

export default FileTable;
