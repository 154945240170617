import React, { useState, useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import DocumentTitle from 'components/Pages/DocumentTitle'
import FilterBox from './components/FilterBox'
import ListTable from './components/ListTable'
import CommonPage from 'components/Pages/CommonPage'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { Icon, Grid } from '@material-ui/core'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'
import { goTo } from 'utils/utils'
import { format, isValid } from 'date-fns'

import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'

const breadcrumbItems = [{ name: 'Home' }, { name: 'Agrupamento', last: true }]

function StationsGroup({ classes }) {
	const { t } = useTranslation()
	const [filter, setFilter] = useState({
		name: '',
		dateBy: null,
		dateFrom: null,
		filterRefresh: false,
	})

	const urlFilter = useMemo(
		() =>
			`${filter.name ? `contains(name , '${filter.name}')` : ''}${
				isValid(filter.dateFrom)
					? ` ${filter.name ? 'and ' : ''}createdAt gt ${format(
							filter.dateFrom,
							'yyyy-MM-dd',
					  )}`
					: ''
			}${
				isValid(filter.dateBy)
					? ` and createdAt lt ${format(filter.dateBy, 'yyyy-MM-dd')}`
					: ''
			}`,

		[filter.dateBy, filter.dateFrom, filter.name],
	)

	return (
		<CommonPage>
			<DocumentTitle pageTitle={t('MENU_STATIONS_GROUP')} />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<Breadcrumbs items={breadcrumbItems} />
				</GridItem>
				<Card>
					<CardBody>
						<Grid container>
							<Grid item xs={12} container>
								<Grid item xs={6}>
									<Section
										title="AGRUPAMENTO"
										icon="icon-agrupamento_estacoes"
									/>
								</Grid>
								<Grid item xs={6} container justify="flex-end">
									<Button
										size="sm"
										color="primary"
										onClick={() =>
											goTo('group_stations/crud')
										}
										style={{ marginRight: 20 }}
									>
										<Icon
											style={{
												fontSize: '18px',
												width: '30px',
											}}
											className={'icon-add_estacao'}
										/>
										Novo Agrupamento
									</Button>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<FilterBox
									classes={classes}
									filter={filter}
									setFilter={setFilter}
								/>
							</Grid>
							<Grid item xs={12} style={{ mergingTop: '20px' }}>
								<ListTable
									filter={urlFilter}
									mutate={filter.filterRefresh}
									classes={classes}
								/>
							</Grid>
						</Grid>
					</CardBody>
				</Card>
			</GridContainer>
		</CommonPage>
	)
}

export default withStyles(sweetAlertStyle)(StationsGroup)
