import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		color: "#455a64",
		textAlign: "left",
		display: "grid",
		opacity: 0.9,
		margin: "10px 0",
	},
	label: {
		margin: "3px 0",
		fontSize: "14px !important",
	},
	input: {
		fontSize: "15px",
	},
}));

const ViewDataField = ({ label, value, ...props }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<small className={classes.label}>{label}</small>
			<span className={classes.input}>{value}</span>
		</div>
	);
};

export default ViewDataField;
