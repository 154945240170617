import React, { Fragment, Component } from 'react'
//import { withTranslation } from 'react-i18next';
import Alert from 'react-bootstrap-sweetalert'
import * as _ from 'lodash'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import cx from 'classnames'
import { Formik } from 'formik'
import AsyncSelect from 'react-select/async'
import * as Yup from 'yup'

// @material-ui/core components
import { withStyles } from '@material-ui/core/styles'

import {
	Icon,
	Checkbox,
	Slide,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@material-ui/core'

// @material-ui/icons
import Check from '@material-ui/icons/Check'

// layout components
import FormControlLabel from '@material-ui/core/FormControlLabel'
import GridContainer from 'components/Grid/GridContainer.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import Table from 'components/Table/Table.jsx'
import ColoredCircle from 'components/ColoredCircle/ColoredCircle.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx'
import Section from 'components/Section'
import Loader from 'components/Loader/Loader.jsx'
import NoContentAlert from 'components/NoContentAlert/NoContentAlert.jsx'
import SatelliteMap from 'components/Maps/Maps.jsx'
import DocumentTitle from 'components/Pages/DocumentTitle'

//classes
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'
import notificationsStyle from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx'

// Station Form
import StationForm from 'pages/Stations/Form/StationForm'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	CreateCustomer,
	GetCustomerByIdData,
	PostCustomer,
	GetAllStationsOwnerlessData,
	UpdateAttachStation,
	ShowLoader,
	HideLoader,
	SweetAlert,
	HideAlert,
} from 'redux/actions'

// MOMENT JS
import * as moment from 'moment'

//utils
import compose from 'utils/compose'
import { isAllowed } from 'utils/auth'
import CloseButton from 'components/CloseButton'

const validationSchema = Yup.object({
	fullName: Yup.string().nullable().required(' obrigatório.'),
	email: Yup.string().nullable().required(' obrigatório.'),
	phoneNumber: Yup.string().nullable().required(' obrigatório.'),
})

function PhoneMaskCustom(props) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[
				'(',
				/[1-9]/,
				/\d/,
				')',
				' ',
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
				/\d/,
				/\d/,
			]}
			placeholderChar={'\u2000'}
		/>
	)
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})

const Form = (props) => {
	const {
		classes,
		values,
		errors,
		touched,
		handleSubmit,
		handleChange,
		handleCancel,
		handleEdit,
		handleBack,
		handleOpenLinkStation,
		disabledForm,
		setFieldTouched,
		editMode,
		handleNewStation,
		hasDeletePermission,
		hasWritePermission,
	} = props

	const change = (name, e) => {
		e.persist()
		handleChange(e)
		setFieldTouched(name, true, false)
	}

	return (
		<Fragment>
			<GridContainer>
				<GridItem xs={12} sm={6} md={6} lg={6}>
					<Section
						title="CADASTRAR CLIENTE"
						icon="icon-ico_cliente"
					/>
				</GridItem>
				<GridItem
					xs={12}
					sm={6}
					md={6}
					lg={6}
					style={{ textAlign: 'right' }}
				>
					{editMode && (
						<Button
							size="sm"
							color="primary"
							style={{ marginRight: '10px' }}
							onClick={handleSubmit}
						>
							<Icon
								style={{ fontSize: '17px', width: '25px' }}
								className={'icon-salvar'}
							/>
							Salvar
						</Button>
					)}
					{editMode && (
						<Button
							size="sm"
							color="greenBorderButtonColor"
							onClick={handleCancel}
						>
							<Icon
								style={{ fontSize: '17px', width: '20px' }}
								className={'icon-cancelar'}
							/>
							Cancelar
						</Button>
					)}
					{disabledForm && (
						<Fragment>
							<Button
								color="primary"
								size="sm"
								onClick={handleEdit}
							>
								<Icon
									style={{ fontSize: '17px', width: '20px' }}
									className={'icon-editar'}
								/>
								Editar
							</Button>
						</Fragment>
					)}
				</GridItem>
			</GridContainer>
			<GridContainer>
				{/* LINE 1 */}
				<GridItem xs={12} sm={4} md={4}>
					<CustomInput
						error={touched.fullName && Boolean(errors.fullName)}
						id="fullName"
						name="fullName"
						labelText={
							'Cliente*' +
							(touched.fullName && Boolean(errors.fullName)
								? errors.fullName
								: '')
						}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: values.fullName ? values.fullName : '',
							disabled: disabledForm,
							onChange: change.bind(null, 'fullName'),
							type: 'text',
						}}
					/>
				</GridItem>

				<GridItem xs={12} sm={4} md={4}>
					<CustomInput
						error={touched.document && Boolean(errors.document)}
						id="document"
						name="document"
						labelText={
							'CNPJ/CPF' +
							(touched.document && Boolean(errors.document)
								? errors.document
								: '')
						}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: values.document ? values.document : '',
							disabled: disabledForm,
							onChange: change.bind(null, 'document'),
							type: 'text',
						}}
					/>
				</GridItem>

				<GridItem xs={12} sm={4} md={4}>
					<CustomInput
						error={touched.email && Boolean(errors.email)}
						id="email"
						name="email"
						labelText={
							'E-mail*' +
							(touched.email && Boolean(errors.email)
								? errors.email
								: '')
						}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: values.email ? values.email : '',
							disabled: disabledForm,
							onChange: change.bind(null, 'email'),
							type: 'text',
						}}
					/>
				</GridItem>

				{/* L 2 */}
				<GridItem xs={12} sm={4} md={4}>
					<CustomInput
						error={
							touched.phoneNumber && Boolean(errors.phoneNumber)
						}
						id="phoneNumber"
						name="phoneNumber"
						labelText={
							'Telefone*' +
							(touched.phoneNumber && Boolean(errors.phoneNumber)
								? errors.phoneNumber
								: '')
						}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: values.phoneNumber ? values.phoneNumber : '',
							disabled: disabledForm,
							onChange: change.bind(null, 'phoneNumber'),
							type: 'text',
							inputComponent: PhoneMaskCustom,
						}}
					/>
				</GridItem>

				<GridItem xs={12} sm={4} md={4}>
					<CustomInput
						error={touched.postalCode && Boolean(errors.postalCode)}
						id="postalCode"
						name="postalCode"
						labelText={
							'CEP' +
							(touched.postalCode && Boolean(errors.postalCode)
								? errors.postalCode
								: '')
						}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: values.postalCode ? values.postalCode : '',
							disabled: disabledForm,
							onChange: change.bind(null, 'postalCode'),
							type: 'text',
						}}
					/>
				</GridItem>

				<GridItem xs={12} sm={4} md={4}>
					<CustomInput
						error={
							touched.stateProvince &&
							Boolean(errors.stateProvince)
						}
						id="stateProvince"
						name="stateProvince"
						labelText={
							'Estado' +
							(touched.stateProvince &&
							Boolean(errors.stateProvince)
								? errors.stateProvince
								: '')
						}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: values.stateProvince
								? values.stateProvince
								: '',
							disabled: disabledForm,
							onChange: change.bind(null, 'stateProvince'),
							type: 'text',
						}}
					/>
				</GridItem>

				{/* L3 */}
				<GridItem xs={12} sm={4} md={4}>
					<CustomInput
						error={touched.city && Boolean(errors.city)}
						id="city"
						name="city"
						labelText={
							'Cidade' +
							(touched.city && Boolean(errors.city)
								? errors.city
								: '')
						}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: values.city ? values.city : '',
							disabled: disabledForm,
							onChange: change.bind(null, 'city'),
							type: 'text',
						}}
					/>
				</GridItem>

				<GridItem xs={12} sm={4} md={4}>
					<CustomInput
						error={
							touched.addressLine1 && Boolean(errors.addressLine1)
						}
						id="addressLine1"
						name="addressLine1"
						labelText={
							'Endereço' +
							(touched.addressLine1 &&
							Boolean(errors.addressLine1)
								? errors.addressLine1
								: '')
						}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: values.addressLine1
								? values.addressLine1
								: '',
							disabled: disabledForm,
							onChange: change.bind(null, 'addressLine1'),
							type: 'text',
						}}
					/>
				</GridItem>

				<GridItem xs={12} md={6} style={{ margin: '25px 0' }}>
					<Section title="ESTAÇÕES VINCULADAS" icon="icon-estacao" />
				</GridItem>
				<GridItem
					xs={12}
					md={6}
					style={{ margin: '25px 0', textAlign: 'right' }}
				>
					<Fragment>
						<Button
							size="sm"
							color="primary"
							disabled={!values.id || !editMode}
							onClick={
								editMode
									? handleNewStation
									: () =>
											console.log(
												'Entre em modo de edição',
											)
							}
						>
							<Icon
								style={{ fontSize: '19px' }}
								className={'icon-ico_add_estacao'}
							></Icon>
							Adicionar estação
						</Button>
						<Button
							size="sm"
							color="primary"
							disabled={!values.id || !editMode}
							onClick={
								editMode
									? handleOpenLinkStation
									: () =>
											console.log(
												'Entre em modo de edição',
											)
							}
						>
							<Icon
								style={{ fontSize: '19px' }}
								className={'icon-vincular'}
							></Icon>
							Vincular estação
						</Button>
					</Fragment>
				</GridItem>
			</GridContainer>
		</Fragment>
	)
}

class CustomerForm extends Component {
	constructor(props) {
		super(props)
		this.formik = React.createRef()
		this.state = {
			id: '',
			fullName: '',
			document: '',
			email: '',
			phoneNumber: '',
			postalCode: '',
			stateProvince: '',
			city: '',
			addressLine1: '',
			stations: [],

			lsAllStationsOwnerless: [],

			editMode: false,
			disabledForm: false,

			modalLinkStations: false,
			checked: [],

			modalMap: {
				geoLat: null,
				geoLong: null,
				name: null,
				owner: {
					fullName: null,
				},
				open: false,
			},

			count: 0,
			items: {},
			currentItem: {
				id: '',
				fullName: '',
				document: '',
				email: '',
				phoneNumber: '',
				postalCode: '',
				stateProvince: '',
				city: '',
				addressLine1: '',
			},

			breadcrumbItems: [
				{ name: 'Home' },
				{ name: 'Clientes' },
				{ name: 'Cadastrar cliente', last: true },
			],

			hasWritePermission: false,
			hasDeletePermission: false,
		}
	}

	componentDidMount() {
		this.props.GetAllStationsOwnerlessData()

		let user = this.props.user
		if (user) {
			if (isAllowed(user, ['beWeather.customer.write'])) {
				this.setState({ hasWritePermission: true })
			}
			if (isAllowed(user, ['beWeather.customer.delete'])) {
				this.setState({ hasDeletePermission: true })
			}
		} else {
			this.props.history.push('/404')
		}

		const id = this.props.match && this.props.match.params.id
		let _edit = _.includes(this.props.match.path, 'edit')
		let _new = _.includes(this.props.match.path, 'new')

		if (_edit) {
			this.setState({ editMode: _edit, disabledForm: !_edit })
		} else if (_new) {
			this.setState({ editMode: _new, disabledForm: !_new })
		} else {
			this.setState({ editMode: false, disabledForm: true })
		}

		if (_edit && !id) {
			this.props.history.goBack()
		}

		//
		let _obj = null

		if (!_new) {
			if (id && this.props.lsCustomersData.count) {
				_obj = this.props.lsCustomersData.items.find((e) => e.id === id)
				if (!_obj) {
					alert('item não encontrado')
				}
				if (_obj) {
					this.setState({ currentItem: _obj })
				}
			} else {
				//this.props.ShowLoader();
				this.props.GetCustomerByIdData(id)
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.postCustomerData !== this.props.postCustomerData) {
			this.props.HideAlert()
		}
		if (
			prevProps.postCustomerDataFailed !==
			this.props.postCustomerDataFailed
		) {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={'Error'}
					onConfirm={() => {
						this.props.HideAlert()
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					confirmBtnText={'OK'}
				>
					Ocorreu um erro ao efetuar a operação!
				</Alert>,
			)
		}

		if (prevProps.customerData !== this.props.customerData) {
			this.setState({ currentItem: this.props.customerData })
		}
		if (prevProps.customerDataFailed !== this.props.customerDataFailed) {
		}

		if (prevState.currentItem !== this.state.currentItem) {
			this.setState(
				{
					...this.state.currentItem,
					postalCode: this.state.currentItem.address.postalCode,
					stateProvince: this.state.currentItem.address.stateProvince,
					city: this.state.currentItem.address.city,
					addressLine1: this.state.currentItem.address.addressLine1,
					stations: this.state.currentItem.stations,
				},
				() => {},
			)
		}

		// -----
		if (prevProps.postStationData !== this.props.postStationData) {
			let modelObj = {
				customerId: this.state.currentItem.id,
				stationIds: [this.props.postStationData.id],
			}
			this.props.UpdateAttachStation(modelObj)
		}

		if (
			prevProps.lsAllStationsOwnerless !==
			this.props.lsAllStationsOwnerless
		) {
			this.setState({
				lsAllStationsOwnerless: this.props.lsAllStationsOwnerless,
			})
		}
		if (
			prevProps.allStationsOwnerlessFailed !==
			this.props.allStationsOwnerlessFailed
		) {
		}

		if (
			prevProps.updateAttachedStation !== this.props.updateAttachedStation
		) {
			this.props.HideAlert()
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title={null}
					onConfirm={() => {
						this.props.HideAlert()
						this.setState({ checked: [] })
						this.handleCloseAttachStation()
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					confirmBtnText={'OK'}
				>
					Operação realizada com Sucesso!
				</Alert>,
			)
			this.props.GetCustomerByIdData(this.state.currentItem.id)
		}
		if (
			prevProps.updateLinkedStationFailed !==
			this.props.updateLinkedStationFailed
		) {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={'Error'}
					onConfirm={() => {
						this.props.HideAlert()
						this.handleCloseAttachStation()
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					confirmBtnText={'OK'}
				>
					Ocorreu um erro ao efetuar a operação!
				</Alert>,
			)
		}
	}

	handleEdit = () => {
		this.props.history.push(`/admin/customer/edit/${this.state.id}`)
		this.componentDidMount()
	}

	handleSave = (values) => {
		//this.props.ShowLoader(<Loader />);
		this.setState({ editMode: false, disabledForm: true })

		let obj = {
			id: this.state.id ? this.state.id : '',
			fullName: values.fullName,
			document: values.document,
			email: values.email,
			userName: values.email,
			phoneNumber: values.phoneNumber,
			address: {
				postalCode: values.postalCode,
				stateProvince: values.stateProvince,
				city: values.city,
				addressLine1: values.addressLine1,
			},
		}
		if (!obj.id || obj.id == '') {
			this.props.CreateCustomer(obj)
		} else {
			this.props.PostCustomer(obj)
		}
	}

	handleBack = () => {
		this.props.history.goBack()
	}

	CancelForm() {
		this.setState({ editMode: false, disabledForm: true })
		//this.props.HideLoader();
		this.props.HideAlert()
		this.props.history.goBack()
	}

	handleNewStation = () => {
		this.props.SweetAlert(
			<Alert
				style={{ width: '680px', padding: '0 17px' }}
				showConfirm={false}
				showCancel={false}
				title={
					<StationForm
						classes={this.props.classes}
						onCancel={() => this.props.HideAlert()}
					/>
				}
			/>,
		)
	}

	handleCancel = () => {
		if (
			this.formik.current &&
			_.isEmpty(this.formik.current.state.touched)
		) {
			this.CancelForm()
		} else {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => this.CancelForm()}
					onCancel={() => this.props.HideAlert()}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					cancelBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.danger
					}
					confirmBtnText={'Sim'}
					cancelBtnText={'Não'}
					showCancel
				>
					As alterações realizadas serão perdidas. Deseja realmente
					continuar?
				</Alert>,
			)
		}
	}

	handleOpenLinkStation = () => {
		this.setState({ checked: [] })
		this.setState({ modalLinkStations: true })
	}

	handleCloseAttachStation = () => {
		this.setState({ modalLinkStations: false })
	}

	handleSaveAttach = () => {
		if (this.state.checked.length > 0) {
			let obj = {
				customerId: this.state.id,
				stationIds: this.state.checked,
			}
			this.props.UpdateAttachStation(obj).then((resp) => {
				this.handleCloseAttachStation()
			})
		} else {
			this.handleCloseAttachStation()
		}
	}

	handleToggle(id, value) {
		const { checked } = this.state
		const currentIndex = checked.indexOf(id)
		const newChecked = [...checked]

		if (currentIndex === -1) {
			newChecked.push(id)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		this.setState({
			checked: newChecked,
		})
	}

	handleViewStation = (id) => {
		this.props.history.push(`/admin/station/view/${id}?viewMore=true`)
	}

	handleCloseMap = () => {
		this.setState({
			modalMap: {
				...this.state.modalMap,
				open: false,
			},
		})
	}

	handleShowLocationMap = (station) => {
		this.setState({
			modalMap: {
				...station,
				open: true,
			},
		})
	}

	render() {
		const { classes } = this.props
		const {
			hasWritePermission,
			hasDeletePermission,
			modalMap,
			breadcrumbItems,
			items,
			id,
			editMode,
			disabledForm,
			stations,
			fullName,
			document,
			email,
			phoneNumber,
			postalCode,
			stateProvince,
			city,
			addressLine1,
			lsAllStationsOwnerless,
		} = this.state
		const values = {
			id,
			fullName,
			document,
			email,
			phoneNumber,
			postalCode,
			stateProvince,
			city,
			addressLine1,
		}
		return (
			<Fragment>
				<DocumentTitle pageTitle={'Cadastrar cliente'} />
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<Breadcrumbs
							items={breadcrumbItems}
							buttonBack={disabledForm}
							functionBack={() => this.props.history.goBack()}
						/>
						<Card>
							<CardBody>
								<Formik
									ref={this.formik}
									render={(props) => (
										<Form
											{...props}
											classes={classes}
											disabledForm={disabledForm}
											handleCancel={this.handleCancel}
											handleBack={this.handleBack}
											handleEdit={this.handleEdit}
											handleOpenLinkStation={
												this.handleOpenLinkStation
											}
											handleNewStation={
												this.handleNewStation
											}
											editMode={editMode}
											idValid={this.state.id !== 0}
										/>
									)}
									validationSchema={validationSchema}
									onSubmit={this.handleSave.bind(this)}
									initialValues={values}
									enableReinitialize={true}
								/>

								<Table
									hover
									tableHead={[
										'Status',
										'Estação',
										'Id',
										'Data de atualização',
										'Ações',
									]}
									tableData={stations.map((item, index) => [
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
											}}
										>
											<ColoredCircle
												color={
													item.active
														? 'green'
														: 'danger'
												}
												size={12}
												className={classes.marginCircle}
											/>
										</div>,
										item.name ? item.name : '-',
										item.id,
										moment(item.createdAt).isValid()
											? moment(item.createdAt).format('L')
											: '-',
										<div style={{ textAlign: 'left' }}>
											<span
												className={cx(
													'icon-map',
													classes.actionButton,
												)}
												title="Ver mapa"
												onClick={() =>
													this.handleShowLocationMap(
														item,
													)
												}
											></span>
											<span
												className={cx(
													'icon-bot_busca',
													classes.actionButton,
												)}
												title="Ver mais"
												onClick={() =>
													this.handleViewStation(
														item.id,
													)
												}
											></span>
										</div>,
									])}
									customCellClasses={[
										classes.center,
										classes.right,
									]}
									// 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
									customClassesForCells={[0, 6]}
									customHeadCellClasses={[
										classes.center,
										classes.right,
									]}
									// 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
									customHeadClassesForCells={[0, 6]}
								/>
								{!stations.length > 0 && (
									<NoContentAlert alertText="Não há estações vinculadas" />
								)}

								<GridContainer justify="center">
									<GridItem xs={12} sm={12} md={12}>
										<Dialog
											className={
												classes.modalSectionTitle
											}
											fullWidth={true}
											maxWidth="md"
											open={modalMap.open}
											TransitionComponent={Transition}
											keepMounted
											onClose={() =>
												this.handleCloseMap()
											}
											aria-labelledby="classic-modal-slide-title"
											aria-describedby="classic-modal-slide-description"
										>
											<DialogTitle
												id="classic-modal-slide-title"
												disableTypography
												className={classes.modalHeader}
											>
												<div style={{ float: 'left' }}>
													<Section title="LOCALIZAÇÃO DA ESTAÇÃO" />
												</div>

												<DialogActions>
													{/* <Button
														size="sm"
														color="wine"
														onClick={this.handleCloseMap}
													>
														<Icon
															style={{
																fontSize: "17px",
																width: "20px",
															}}
															className={"icon-cancelar"}
														></Icon>
														Fechar
													</Button> */}
													<CloseButton
														handleClose={
															this.handleCloseMap
														}
													/>
												</DialogActions>
											</DialogTitle>
											<DialogContent
												id="classic-modal-slide-description"
												className={classes.modalBody}
											>
												{modalMap.geoLat != null &&
												modalMap.geoLat !== 0 &&
												modalMap.geoLong != null &&
												modalMap.geoLong !== 0 ? (
													<SatelliteMap
														stations={[modalMap]}
														center={[
															modalMap.geoLat,
															modalMap.geoLong,
														]}
													/>
												) : (
													<NoContentAlert alertText="Não foi possível localizar a estação" />
												)}
											</DialogContent>
										</Dialog>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GridContainer justify="center">
					<GridItem xs={12} sm={12} md={12}>
						<Dialog
							className={classes.modalSectionTitle}
							fullWidth={true}
							maxWidth="md"
							open={this.state.modalLinkStations}
							TransitionComponent={Transition}
							keepMounted
							onClose={() => this.handleCloseAttachStation()}
							aria-labelledby="classic-modal-slide-title"
							aria-describedby="classic-modal-slide-description"
						>
							<DialogTitle
								id="classic-modal-slide-title"
								disableTypography
								className={classes.modalHeader}
							>
								<div style={{ float: 'left' }}>
									<Section title="Vincular Estações" />
								</div>

								<DialogActions>
									<Button
										size="sm"
										color="primary"
										style={{ marginRight: '10px' }}
										onClick={this.handleSaveAttach.bind(
											this,
										)}
									>
										<Icon
											style={{
												fontSize: '14px',
												width: '20px',
											}}
											className={'icon-salvar'}
										></Icon>
										Vincular
									</Button>
									<Button
										size="sm"
										color="greenBorderButtonColor"
										onClick={this.handleCloseAttachStation}
									>
										<Icon
											style={{
												fontSize: '17px',
												width: '20px',
											}}
											className={'icon-cancelar'}
										></Icon>
										Cancelar
									</Button>
								</DialogActions>
							</DialogTitle>
							<DialogContent
								id="classic-modal-slide-description"
								className={classes.modalBody}
							>
								{lsAllStationsOwnerless?.length > 0 && (
									<Table
										hover
										tableHead={[
											'',
											'Status',
											'Estação',
											'Bluetooth',
										]}
										tableData={lsAllStationsOwnerless.map(
											(item, index) => [
												<FormControlLabel
													control={
														<Checkbox
															tabIndex={-1}
															onClick={() =>
																this.handleToggle(
																	item.id,
																	index,
																)
															}
															checkedIcon={
																<Check
																	className={
																		classes.checkedIcon
																	}
																/>
															}
															icon={
																<Check
																	className={
																		classes.uncheckedIcon
																	}
																/>
															}
															classes={{
																checked:
																	classes.checked,
																root: classes.checkRoot,
															}}
														/>
													}
												/>,
												<ColoredCircle
													color={
														item.active
															? 'green'
															: 'danger'
													}
													size={12}
													className={
														classes.marginCircle
													}
												/>,
												item.name,
												item.bluetoothDeviceId,
											],
										)}
									/>
								)}
								{!lsAllStationsOwnerless?.length > 0 && (
									<NoContentAlert alertText="Nenhuma estação encontrada" />
								)}
							</DialogContent>
						</Dialog>
					</GridItem>
				</GridContainer>
			</Fragment>
		)
	}
}

CustomerForm.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	return {
		lsCustomersData: state.customer.lsCustomersData,
		customersDataFailed: state.customer.customersDataFailed,

		customerData: state.customer.customerData,
		customerDataFailed: state.customer.customerDataFailed,

		userPwd: state.customer.userPwd,
		userPwdFailed: state.customer.userPwdFailed,

		postCustomerData: state.customer.postCustomerData,
		postCustomerDataFailed: state.customer.postCustomerDataFailed,

		lsAllStationsOwnerless: state.station.lsAllStationsOwnerless,
		allStationsOwnerlessFailed: state.station.allStationsOwnerlessFailed,

		updateAttachedStation: state.customer.updateAttachedStation,
		updateLinkedStationFailed: state.customer.updateLinkedStationFailed,

		postStationData: state.station.postStationData,

		user: state.authentication.user,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			CreateCustomer,
			GetCustomerByIdData,
			PostCustomer,
			GetAllStationsOwnerlessData,
			UpdateAttachStation,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(sweetAlertStyle),
	withStyles(notificationsStyle),
	//withTranslation(),
)(CustomerForm)
