import axios from 'axios';

export const GetDashboardData = () =>
    axios.get(`/api/DashboardManager`)
        .then(response => response.data);

export const GetCharts = () =>
    axios.get("/Dashboard/GetDashboardCharts")
        .then(response => response.data);

export const GetAnnouncementData = () => {
    const API_ROOT = `https://jsonplaceholder.typicode.com/todos/3`;
    return axios.get(API_ROOT, {}).then(response => response.data);
}
