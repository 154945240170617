import React from 'react';
import { useDispatch } from 'react-redux';

import { renderObj } from 'utils/csvToObj';

import Loader from 'components/Loader/Loader.jsx';

import Button from "components/CustomButtons/Button.jsx";

import { ShowLoader } from 'redux/actions/index';

const FileInput = ({ name, callFromParent, setFieldValue, ...props }) => {

    let fR;

    const dispatch = useDispatch();

    const onChange = (file) => {
        dispatch(ShowLoader(<Loader />))
        // fR = new FileReader();
        // fR.onloadend = handleFileRead;
        // fR.readAsText(file);
        sendDataToParent(file)
    }

    const handleFileRead = () => {
        const content = fR.result;
        sendDataToParent(renderObj(content, 'cpf'));

    }

    const sendDataToParent = (data) => {
        callFromParent(data, setFieldValue);
    }

    return (
        <>
            <input
                accept=".bin"
                id={name}
                type="file"
                style={{ display: 'none' }}
                onChange={e => {
                    onChange(e.target.files[0])
                    e.target.value = '';
                }}
            />
            <label htmlFor={name}>
                <Button color="primary" component="span">
                    Carregar
                </Button>
            </label>
        </>
    );
}
export default FileInput;
