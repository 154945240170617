
import React from 'react';
import Snackbar from 'components/Snackbar/Snackbar';
import { CheckCircle, HighlightOff } from '@material-ui/icons';

export const SuccessNotificationComponent = (message) => {
    return (
        <Snackbar
            place="tr"
            color="green"
            message={message}
            open={true}
            icon={CheckCircle}
            close
        />
    )
}

export const FailedNotificationComponent = (message) => {
    return (
        <Snackbar
            place="tr"
            color="danger"
            message={message}
            open={true}
            icon={HighlightOff}
            close
        />
    )
}


