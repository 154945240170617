import * as actionTypes from "redux/actions/actionsTypes";
import * as API from "redux/api/station/station";
import * as Loader from "../loader";
import * as Notification from "../notification";
import {
	SuccessNotificationComponent,
	FailedNotificationComponent,
} from "redux/react-notifications";

export const GetStations = (_filter, _page, _itemsPerPage) => {
	return (dispatch) =>
		API.GetStationsData(_filter, _page, _itemsPerPage)
			.then((response) => {
				dispatch(success(response));
				return response;
			})
			.catch((error) => {
				console.error(error);
				dispatch(failed(error));
				dispatch(Loader.hideLoader());
			});

	function success(data) {
		return {
			type: actionTypes.FETCH_STATION_DATA,
			lsStations: data,
		};
	}

	function failed(error) {
		return {
			type: actionTypes.FETCH_STATION_FAILED,
			stationsDataFailed: error,
		};
	}
};

// GET ALL OWNERLESS
export const GetAllStationsOwnerlessData = () => {
	return (dispatch) =>
		API.GetAllStationsOwnerless()
			.then((response) => {
				dispatch(success(response));
				return response;
			})
			.catch((error) => {
				console.error(error);
				if (
					error.response.status === 403 ||
					error.response.status === 401
				) {
					dispatch(
						Notification.showNotification(
							FailedNotificationComponent(
								"Sem permissão para consultar estações!"
							)
						)
					);
				} else {
					dispatch(
						Notification.showNotification(
							FailedNotificationComponent(
								"Ocorreu um erro ao consultar estações sem proprietários!"
							)
						)
					);
				}
				dispatch(failed(error));
				dispatch(Loader.hideLoader());
			});

	function success(data) {
		return {
			type: actionTypes.FETCH_ALL_STATIONS_OWNERLESS,
			lsAllStationsOwnerless: data,
		};
	}

	function failed(error) {
		return {
			type: actionTypes.FETCH_ALL_STATIONS_OWNERLESS_FAILED,
			allStationsOwnerlessFailed: error,
		};
	}
};

export const PostStation = (obj) => {
	return (dispatch) =>
		API.PostStation(obj)
			.then((response) => {
				dispatch(postStationData(response));
				dispatch(Loader.hideLoader());
				dispatch(
					Notification.showNotification(
						SuccessNotificationComponent(
							"Estação cadastrada com sucesso!"
						)
					)
				);
				return response;
			})
			.catch((error) => {
				console.error(error);
				if (
					error.response.status === 403 ||
					error.response.status === 401
				) {
					dispatch(
						Notification.showNotification(
							FailedNotificationComponent(
								"Sem permissão para criar estações!"
							)
						)
					);
				} else {
					dispatch(
						Notification.showNotification(
							FailedNotificationComponent(
								"Ocorreu um erro ao salvar a estação!"
							)
						)
					);
				}
				dispatch(postStationDataFailed(error));
				dispatch(Loader.hideLoader());
			});

	function postStationData(data) {
		return {
			type: actionTypes.POST_STATION_DATA,
			postData: data,
		};
	}

	function postStationDataFailed(error) {
		return {
			type: actionTypes.POST_STATION_DATA_FAILED,
			postDataFailed: error,
		};
	}
};

export const PutStation = (obj) => {
	return (dispatch) =>
		API.PutStation(obj)
			.then((response) => {
				dispatch(putStationData(response));
				dispatch(Loader.hideLoader());
				dispatch(
					Notification.showNotification(
						SuccessNotificationComponent(
							"Estação atualizada com sucesso!"
						)
					)
				);
				response.id && dispatch(fetchStationById(response));
				return response;
			})
			.catch((error) => {
				console.error(error);
				if (
					error.response.status === 403 ||
					error.response.status === 401
				) {
					dispatch(
						Notification.showNotification(
							FailedNotificationComponent(
								"Sem permissão para atualizar dados de estações!"
							)
						)
					);
				} else {
					dispatch(
						Notification.showNotification(
							FailedNotificationComponent(
								"Ocorreu um erro ao salvar a estação!"
							)
						)
					);
				}
				dispatch(putStationDataFailed(error));
				dispatch(Loader.hideLoader());
			});

	function putStationData(data) {
		return {
			type: actionTypes.PUT_STATION_DATA,
			putData: data,
		};
	}

	function putStationDataFailed(error) {
		return {
			type: actionTypes.PUT_STATION_DATA_FAILED,
			putDataFailed: error,
		};
	}
};

export const DisassociateUser = (id) => {
	return (dispatch) =>
		API.DisassociateUser(id)
			.then((response) => {
				//dispatch(Loader.hideLoader());
				dispatch(
					Notification.showNotification(
						SuccessNotificationComponent(
							"Proprietário desassociado da estação!"
						)
					)
				);
				dispatch(disassociateStationData(response));
				return response;
			})
			.catch((error) => {
				console.error(error);
				if (
					error.response.status === 403 ||
					error.response.status === 401
				) {
					dispatch(
						Notification.showNotification(
							FailedNotificationComponent(
								"Sem permissão para atualizar dados de estações!"
							)
						)
					);
				} else {
					dispatch(
						Notification.showNotification(
							FailedNotificationComponent(
								"Ocorreu um erro ao associar usuários!"
							)
						)
					);
				}
				dispatch(disassociateStationDataFailed(error));
				dispatch(Loader.hideLoader());
			});

	function disassociateStationData(data) {
		return {
			type: actionTypes.PUT_DISASSOACIATE_USER_STATION_DATA,
			disassociateData: data,
		};
	}

	function disassociateStationDataFailed(error) {
		return {
			type: actionTypes.PUT_DISASSOACIATE_USER_STATION_DATA_FAILED,
			disassociateDataFailed: error,
		};
	}
};

export const GetStationById = (id) => {
	return (dispatch) =>
		API.GetStationById(id)
			.then((response) => {
				dispatch(fetchStationById(response));
				return response;
			})
			.catch((error) => {
				console.error(error);
				if (
					error.response.status === 403 ||
					error.response.status === 401
				) {
					dispatch(
						Notification.showNotification(
							FailedNotificationComponent(
								"Sem permissão para consultar estações!"
							)
						)
					);
				} else {
					dispatch(
						Notification.showNotification(
							FailedNotificationComponent(
								'Ocorreu um erro ao encontrar a estação com id "' +
									id +
									'"'
							)
						)
					);
				}
				dispatch(fetchStationByIdFailed(error));
			});
};
export const fetchStationById = (data) => {
	return {
		type: actionTypes.FETCH_STATION_BY_ID,
		stationData: data,
	};
};

export const fetchStationByIdFailed = (error) => {
	return {
		type: actionTypes.FETCH_STATION_BY_ID_FAILED,
		stationDataFailed: error,
	};
};

export const DeleteStation = (id) => {
	return (dispatch) =>
		API.DeleteStation(id)
			.then((response) => {
				dispatch(deleteStationData(response));
				dispatch(Loader.hideLoader());
				dispatch(
					Notification.showNotification(
						SuccessNotificationComponent(
							"Estação removida da base de dados!"
						)
					)
				);
				return response;
			})
			.catch((error) => {
				console.error(error);
				dispatch(deleteStationDataFailed(error));
				if (
					error.response.status === 403 ||
					error.response.status === 401
				) {
					dispatch(
						Notification.showNotification(
							FailedNotificationComponent(
								"Sem permissão para apagar estações!"
							)
						)
					);
				} else {
					dispatch(
						Notification.showNotification(
							FailedNotificationComponent(
								"Ocorreu um erro ao apagar a estação!"
							)
						)
					);
				}
				dispatch(Loader.hideLoader());
			});

	function deleteStationData(data) {
		return {
			type: actionTypes.DELETE_STATION_DATA,
			deleteData: data,
		};
	}

	function deleteStationDataFailed(error) {
		return {
			type: actionTypes.DELETE_STATION_DATA_FAILED,
			deleteDataFailed: error,
		};
	}
};
