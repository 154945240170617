import * as actionTypes from '../../actions/actionsTypes';
import {
    updateObject
} from '../../utility';

const initialState = {
    lsGroups: null,
    groupsDataFailed: false,

    groupById: null,
    groupByIdDataFailed: false,

    postGroup: null,
    postGroupFailed: false,

    putGroup: null,
    putGroupFailed: false,

    deleteGroupData: null,
    deleteGroupDataFailed: false,
};

//GET
const fetchGroupsData = (state, action) => {
    return updateObject(state, {
        lsGroups: action.lsGroups
    });
};

const fetchGroupsFailed = (state, action) => {
    return updateObject(state, {
        groupsDataFailed: true
    });
};


// GET BY ID
const fetchGroupByIdData = (state, action) => {
    return updateObject(state, {
        groupById: action.groupById
    });
};

const fetchGroupByIdFailed = (state, action) => {
    return updateObject(state, {
        groupByIdDataFailed: action.groupById
    });
};


//POST
const postGroupData = (state, action) => {
    return updateObject(state, {
        postGroup: action.postData
    });
};

const postGroupDataFailed = (state, action) => {
    return updateObject(state, {
        postGroupFailed: true
    });
};


//PUT
const putGroupData = (state, action) => {
    return updateObject(state, {
        putGroup: action.putData
    });
};

const putGroupDataFailed = (state, action) => {
    return updateObject(state, {
        putGroupFailed: true
    });
};


// DELETE 
const deleteGroupData = (state, action) => {
    return updateObject(state, {
        deleteGroupData: action.deleteData
    });
};
const deleteGroupDataFailed = (state, action) => {
    return updateObject(state, {
        deleteGroupDataFailed: true
    });
};

//

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_GROUP_DATA:
            return fetchGroupsData(state, action);
        case actionTypes.FETCH_GROUP_FAILED:
            return fetchGroupsFailed(state, action);

        case actionTypes.FETCH_GROUP_BY_ID_DATA:
            return fetchGroupByIdData(state, action);
        case actionTypes.FETCH_GROUP_BY_ID_FAILED:
            return fetchGroupByIdFailed(state, action);

        case actionTypes.POST_GROUP_DATA:
            return postGroupData(state, action);
        case actionTypes.POST_GROUP_DATA_FAILED:
            return postGroupDataFailed(state, action);

        case actionTypes.PUT_GROUP_DATA:
            return putGroupData(state, action);
        case actionTypes.PUT_GROUP_DATA_FAILED:
            return putGroupDataFailed(state, action);

        case actionTypes.DELETE_GROUP_DATA:
            return deleteGroupData(state, action);
        case actionTypes.DELETE_GROUP_DATA_FAILED:
            return deleteGroupDataFailed(state, action);

        default:
            return state;
    }
};

export default groupReducer;