import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CommonPage from "components/Pages/CommonPage";
import Section from "components/Section";
import DocumentTitle from "components/Pages/DocumentTitle";
import CustomIcon from "components/CustomIcon";
import { useDispatch } from "react-redux";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.jsx";
import ExportList from "../ExportList";
import ModalExport from "../ModalExport";

const breadcrumbItems = [
	{ name: "Home" },
	{ name: "Estações" },
	{ name: "Exportar Dados", last: true },
];

function ExportStation(props) {
	const id = props.match.params.id;
	const history = useHistory();
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState({
		open: false,
	});

	function showForm() {
		setShowModal({
			open: true,
		});
	}

	function hideModal() {
		setShowModal({
			open: false,
		});
	}

	return (
		<CommonPage>
			<DocumentTitle pageTitle={"Estações"} />
			<ModalExport
				showModal={showModal}
				onCancel={() => hideModal()}
				id={id}
			/>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<Breadcrumbs items={breadcrumbItems} />
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<Section
										title="HISTÓRICO DE EXPORTAÇÕES"
										icon="icon-estacao"
									/>
								</GridItem>
								<GridItem
									xs={12}
									sm={6}
									md={6}
									lg={6}
									style={{ textAlign: "right" }}
								>
									<Button
										size="sm"
										color="primary"
										onClick={showForm}
									>
										<CustomIcon
											className={"icon-adicionar"}
										/>
										Definir período
									</Button>
									<Button
										size="sm"
										color="white"
										style={{
											color: "#034D8A",
											border: "1px solid #034D8A",
										}}
										onClick={() =>
											history.push("/admin/station")
										}
									>
										<CustomIcon
											className="icon-cancelar"
											color="primary"
										/>
										Cancelar
									</Button>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} style={{ marginTop: "30px" }}>
									<ExportList id={id} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</CommonPage>
	);
}

export default ExportStation;
