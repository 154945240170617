import React, { useEffect, useState, useRef } from 'react'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Alert from 'react-bootstrap-sweetalert'

import CommonPage from 'components/Pages/CommonPage'
import DocumentTitle from 'components/Pages/DocumentTitle'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import dateValidation from './validation'
import { HideAlert, SweetAlert } from 'redux/actions'
import { Grid, withStyles, Tooltip } from '@material-ui/core'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'
import { Controller, useForm } from 'react-hook-form'

import {
	CreateConfigBox,
	MyAutoCompleteSelect,
	MySection,
	MyCustomInput,
	MyLimitText,
} from './styles'
import ConfirmModal from 'components/ConfirmModal'
import CustomCheckBox from 'components/CustomCheckBox'
import CustomInput from 'components/CustomInput/CustomInput'
import { Search } from '@material-ui/icons'
import Section from 'components/Section'
import { goTo, sunEditorOptions } from 'utils/utils'
import NotificationModal from 'components/NotificationModal'

import { yupResolver } from '@hookform/resolvers/yup'
import useSWR, { mutate as swrMutate } from 'swr'
import {
	GetAllUserByFilter,
	GET_ALL_USER_BY_FILTER,
} from 'services/api/UserManager/api'
import SketchView from '../SketchView'
import { format } from 'date-fns'
import {
	GET_ALL_STATION_BY_FILTER,
	getStationsByFilter,
} from 'services/api/StationManager/api'
import {
	getMessageManager,
	GET_MESSAGE_MANAGER_BY_ID,
	upsertMessageManager,
} from 'services/api/MessageManager/api'
import {
	getAllGroupByFilter,
	GET_ALL_GROUP_BY_FILTER,
} from 'services/api/StationGroupManager/api'
import { showNotification as showAlert } from 'redux/actions/notification'
import { SuccessNotificationComponent } from 'redux/react-notifications'
import { useParams } from 'react-router'
import AutocompleteSelect from 'components/AutocompleteSelect'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'

function CrudMessage({ classes }) {
	const { t } = useTranslation()
	const { id } = useParams()
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		reset,
		formState: { errors, isDirty },
	} = useForm({
		defaultValues: {
			title: '',
			description: '',
			usersId: [],
			stationsId: [],
			groupIds: [],
			sendForAllUsers: false,
			usersCheck: false,
			stationsCheck: false,
			groupsCheck: false,
		},
		resolver: yupResolver(dateValidation),
	})

	const breadcrumbItems = [
		{ name: 'Home' },
		{ name: 'Configurações' },
		{ name: 'Mensageria', last: true },
	]

	const [showNotification, setShowNotification] = useState({
		loading: false,
		open: false,
	})

	const [loading, setLoading] = useState(false)

	const [confirmModalProps, setConfirmModalProps] = useState({
		open: false,
		type: '',
		text: '',
	})

	const [titleCharCount, setTitleCharCount] = useState(60)
	const [messageCharCount, setMessageCharCount] = useState(340)

	const dispatch = useDispatch()

	const groups = watch('groupIds')
	const stations = watch('stationsId')
	const users = watch('usersId')

	const title = watch('title')
	const description = watch('description')

	useEffect(() => {
		const len = title.length
		setTitleCharCount(60 - len)
	}, [title])

	useEffect(() => {
		let strippedString = description.replace(/(<([^>]+)>)/gi, '')
		const len = strippedString.length
		setMessageCharCount(340 - len)
	}, [description])

	const { data: dataClient, mutate: mutateClient } = useSWR(
		[GET_ALL_USER_BY_FILTER, groups, stations],
		(url) => {
			let groupStringEffect = ''
			let stationStringEffect = ''

			groups.map((obj) => (groupStringEffect += `&groupIds=${obj.value}`))
			stations.map(
				(obj) => (stationStringEffect += `&stationsId=${obj.value}`),
			)

			if (groups.length <= 0 && stations.length <= 0) {
				return GetAllUserByFilter(url)
			} else {
				if (groups.length > 0 && stations.length <= 0) {
					return GetAllUserByFilter(
						`${url}?${groupStringEffect.replace('&', '')}`,
					)
				}
				if (groups.length <= 0 && stations.length > 0) {
					return GetAllUserByFilter(
						`${url}?${stationStringEffect.replace('&', '')}`,
					)
				}
				if (groups.length > 0 && stations.length > 0) {
					return GetAllUserByFilter(
						`${url}?${groupStringEffect.replace(
							'&',
							'',
						)}${url}?${stationStringEffect}`,
					)
				}
			}
		},
	)

	const { data: dataStation, mutate: mutateStation } = useSWR(
		[GET_ALL_STATION_BY_FILTER, groups, users],
		(url) => {
			let groupStringEffect = ''
			let userStringEffect = ''

			groups.map((obj) => (groupStringEffect += `&groupIds=${obj.value}`))
			users.map((obj) => (userStringEffect += `&usersId=${obj.value}`))

			if (groups.length <= 0 && users.length <= 0) {
				return getStationsByFilter(url)
			} else {
				if (groups.length > 0 && users.length <= 0) {
					return getStationsByFilter(
						`${url}?${groupStringEffect.replace('&', '')}`,
					)
				}
				if (groups.length <= 0 && users.length > 0) {
					return getStationsByFilter(
						`${url}?${userStringEffect.replace('&', '')}`,
					)
				}
				if (groups.length > 0 && users.length > 0) {
					return getStationsByFilter(
						`${url}?${groupStringEffect.replace(
							'&',
							'',
						)}${url}?${userStringEffect}`,
					)
				}
			}
		},
	)

	const { data: dataGroups, mutate: mutateGroup } = useSWR(
		[GET_ALL_GROUP_BY_FILTER, groups, stations],
		(url) => {
			let stationStringEffect = ''
			let userStringEffect = ''

			groups.map(
				(obj) => (stationStringEffect += `&stationsId=${obj.value}`),
			)
			users.map((obj) => (userStringEffect += `&usersId=${obj.value}`))

			if (stations.length <= 0 && users.length <= 0) {
				return getAllGroupByFilter(url)
			} else {
				if (stations.length > 0 && users.length <= 0) {
					return getAllGroupByFilter(
						`${url}?${stationStringEffect.replace('&', '')}`,
					)
				}
				if (stations.length <= 0 && users.length > 0) {
					return getAllGroupByFilter(
						`${url}?${userStringEffect.replace('&', '')}`,
					)
				}
				if (stations.length > 0 && users.length > 0) {
					return getAllGroupByFilter(
						`${url}?${userStringEffect.replace(
							'&',
							'',
						)}${url}?${stationStringEffect}`,
					)
				}
			}
		},
	)

	useEffect(() => {
		mutateGroup()
		mutateStation()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [users])

	useEffect(() => {
		mutateClient()
		mutateStation()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groups])

	useEffect(() => {
		mutateClient()
		mutateGroup()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stations])

	const { data: messageData } = useSWR(
		id && `${GET_MESSAGE_MANAGER_BY_ID}/${id}`,
		async (url) => getMessageManager(url),
	)

	useEffect(() => {
		if (id && messageData) {
			reset({
				title: messageData.title,
				description: messageData.description,
				usersId: messageData?.users?.map((value) => ({
					value: value.user?.id,
					label: value.user?.fullName,
				})),
				stationsId: [],
				groupIds: [],
				sendForAllUsers: false,
				usersCheck: true,
				stationsCheck: false,
				groupsCheck: false,
			})
		}
	}, [id, messageData, reset])

	async function sendForm(formData) {
		let param = {
			allUsers: formData.sendForAllUsers,
			userIds: formData.usersId?.map((item) => item.value),
			stationIds: formData.stationsId?.map((item) => item.value),
			groupIds: formData.groupIds?.map((item) => item.value),
			title: formData.title,
			description: formData.description,
		}

		if (formData.sendForAllUsers === true) {
			param.userIds = dataClient?.map((value) => value.key)
		}

		if (id) {
			param.id = id
		}

		try {
			setLoading(true)
			if (confirmModalProps.type === 'draft') {
				const data = await upsertMessageManager({
					body: {
						...param,
						send: false,
						state: 'draft',
					},
				})
				hideModal()

				if (data)
					dispatch(
						showAlert(
							SuccessNotificationComponent(
								'Rascunho salvo com sucesso!',
							),
						),
					)
				return goTo(`settings/message`)
			}
			const data = await upsertMessageManager({
				body: {
					...param,
					send: true,
				},
			})
			if (data)
				dispatch(
					showAlert(
						SuccessNotificationComponent(
							'Mensagem enviada com sucesso!',
						),
					),
				)
			return goTo(`settings/message`)
		} catch (error) {
			console.log('handleImage -> error', error)
		} finally {
			setLoading(false)
		}
	}

	function confirmPublish() {
		setConfirmModalProps({
			open: true,
			text: 'Confirmar o envio da mensagem',
			type: 'send',
		})
	}

	function saveDraft() {
		setConfirmModalProps({
			open: true,
			text: 'Deseja realmente salvar esta mensagem como rascunho?',
			type: 'draft',
		})
	}

	function alertVisualization() {
		setShowNotification((prevState) => ({
			...prevState,
			open: true,
			haveEdit: false,
		}))
	}

	function hideModal() {
		setConfirmModalProps({ open: false, type: '', text: '' })
	}

	function cancelConfirmed() {
		hideModal()
	}

	function handleCancel() {
		if (isDirty)
			return dispatch(
				SweetAlert(
					<Alert
						warning
						style={{ display: 'block' }}
						title={''}
						onConfirm={() => {
							dispatch(HideAlert())
							goTo('settings/message')
						}}
						onCancel={() => dispatch(HideAlert())}
						confirmBtnCssClass={
							classes.button + ' ' + classes.greenButtonColor
						}
						cancelBtnCssClass={
							classes.button + ' ' + classes.danger
						}
						confirmBtnText={t('BUTTON_YES')}
						cancelBtnText={t('BUTTON_NO')}
						showCancel
					>
						{t('Sair sem salvar?')}
					</Alert>,
				),
			)
		return goTo('settings/message')
	}

	return (
		<CommonPage>
			<DocumentTitle pageTitle={t('MENU_FIRMWARE')} />
			<NotificationModal
				headerTitle="MESSAGE_VIEW"
				width="400px"
				headerIcon={false}
				closeByClick={() =>
					setShowNotification((prevState) => ({
						...prevState,
						open: false,
						haveEdit: false,
					}))
				}
				onClose={() =>
					setShowNotification((prevState) => ({
						...prevState,
						open: false,
						haveEdit: false,
					}))
				}
				open={showNotification.open}
				buttonTitle="BUTTON_CLOSE"
			>
				<SketchView
					noTitle
					sketch={{
						date: format(new Date(), 'dd/MM/yyyy'),
						title: watch('title'),
						description: watch('description'),
					}}
				/>
			</NotificationModal>
			<ConfirmModal
				open={confirmModalProps.open}
				type={confirmModalProps.type}
				text={confirmModalProps.text}
				iconAlert="icon-mensagens"
				loading={loading}
				onConfirm={async () => {
					return confirmModalProps.type === 'cancel'
						? cancelConfirmed()
						: sendForm(watch())
				}}
				onCancel={() => hideModal()}
			/>
			<GridContainer>
				<GridItem xs={12}>
					<Breadcrumbs items={breadcrumbItems} />
					<Card>
						<CardBody>
							<CreateConfigBox
								item
								xs={12}
								container
								errors={errors}
							>
								<Grid item xs={4}>
									<MySection
										title="Parametrização da mensagem"
										icon="icon-mensagens"
									/>
								</Grid>
								<Grid
									item
									xs={8}
									container
									// justify="flex-end"
									// className="button-head"
									style={{
										display: 'flex',
										justifyContent: 'end',
									}}
								>
									<div style={{ marginLeft: 10 }}>
										<Button
											size="sm"
											color="primary"
											disabled={!isDirty}
											onClick={handleSubmit(
												confirmPublish,
											)}
										>
											<i
												style={{
													marginRight: 10,
													fontSize: 14,
													padding: '2px 0',
												}}
												className="icon-enviar"
											/>
											{t('BUTTON_SENT')}
										</Button>
									</div>
									<div style={{ marginLeft: 10 }}>
										<Button
											size="sm"
											color="greenBorderButtonColor"
											disabled={!isDirty}
											onClick={() => alertVisualization()}
										>
											<Search className={classes.icons} />
											{t('BUTTON_VIEW_MESSAGE')}
										</Button>
									</div>
									<div style={{ marginLeft: 10 }}>
										<Button
											size="sm"
											color="greenBorderButtonColor"
											disabled={!isDirty}
											onClick={handleSubmit(saveDraft)}
										>
											<i
												style={{
													marginRight: 10,
													fontSize: 14,
													padding: '2px 0',
												}}
												className="icon-rascunho"
											/>

											{t('SAVE_DRAFT')}
										</Button>
									</div>
									<div
										style={{
											marginLeft: 10,
										}}
									>
										<Button
											size="sm"
											color="greenBorderButtonColor"
											onClick={() => handleCancel()}
										>
											<i
												style={{
													fontSize: 14,
													padding: '2px 0',
												}}
												className={
													isDirty
														? 'icon-cancelar'
														: 'icon-voltar'
												}
											/>
											{isDirty ? 'Cancelar' : 'Voltar'}
										</Button>
									</div>
								</Grid>
								<form
									onSubmit={handleSubmit(sendForm)}
									style={{ width: '100%' }}
									autoComplete="off"
								>
									<Grid
										container
										xs={12}
										className="header-form"
									>
										<Grid
											container
											xs={12}
											style={{ marginTop: 40 }}
										>
											<Grid item xs={12}>
												<div
													className="header-title"
													style={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													Enviar para:{' '}
													<Tooltip
														style={{
															marginLeft: 10,
															cursor: 'pointer',
															color: errors?.destinyVerification
																? 'red'
																: 'black',
														}}
														disableFocusListener
														arrow
														placement="top-start"
														// open={
														// 	errors?.destinyVerification
														// 		? true
														// 		: false
														// }
														// title={
														// 	errors
														// 		?.destinyVerification
														// 		?.message
														// }
														title="Selecione ao menos um cliente ou grupo para enviar"
													>
														<i className="icon-informacao" />
													</Tooltip>
												</div>
											</Grid>
											<Grid item xs={12}>
												<Controller
													render={({
														field: {
															onChange,
															onBlur,
															value,
														},
													}) => (
														<CustomCheckBox
															inputProps={{
																onChange: (
																	event,
																) => {
																	if (
																		event
																			.target
																			.checked
																	) {
																		setValue(
																			'usersCheck',
																			false,
																		)
																		setValue(
																			'stationsCheck',
																			false,
																		)
																		setValue(
																			'usersId',
																			[],
																		)
																		setValue(
																			'stationsId',
																			[],
																		)
																	}
																	return onChange(
																		event,
																	)
																},

																onBlur,
																value,
															}}
															checked={value}
															label="Todos"
															error={
																!!errors.sendForAllUsers
															}
															helperText={
																errors
																	?.sendForAllUsers
																	?.message
															}
														/>
													)}
													name="sendForAllUsers"
													control={control}
												/>
											</Grid>
											<Grid item xs={12} container>
												<Grid
													item
													xs={4}
													container
													alignItems="flex-end"
													// style={{ marginRight: 30 }}
												>
													<Grid
														item
														xs={12}
														style={{
															display: 'flex',
															justifyContent:
																'end',
															marginRight: 30,
														}}
													>
														<Controller
															render={({
																field: {
																	onChange,
																	onBlur,
																	value,
																},
															}) => (
																<CustomCheckBox
																	align="flex-end"
																	padding="0 9px"
																	label=""
																	error={
																		!!errors.usersCheck
																	}
																	helperText={
																		errors
																			?.usersCheck
																			?.message
																	}
																	inputProps={{
																		onChange:
																			(
																				event,
																			) => {
																				if (
																					event
																						.target
																						.checked
																				) {
																					setValue(
																						'sendForAllUsers',
																						false,
																					)
																				} else {
																					setValue(
																						'usersId',
																						[],
																					)
																				}
																				return onChange(
																					event,
																				)
																			},

																		onBlur,
																		value,
																	}}
																	checked={
																		value
																	}
																/>
															)}
															name="usersCheck"
															control={control}
														/>
														<div
															style={{
																width: '100%',
															}}
														>
															<MyAutoCompleteSelect
																control={
																	control
																}
																loading={
																	!dataClient
																}
																name="usersId"
																disabled={
																	!watch(
																		'usersCheck',
																	)
																}
																options={
																	dataClient?.map(
																		(
																			item,
																		) => ({
																			value: item.key,
																			label: item.value,
																		}),
																	) || []
																}
																getOptionLabel={(
																	option,
																) =>
																	`${option.label}`
																}
																inputLabel="*Clientes"
																inputDisable={
																	!watch(
																		'usersCheck',
																	)
																}
																inputPlaceholder="Buscar nome"
															/>
														</div>
													</Grid>
												</Grid>
												<Grid
													item
													xs={4}
													container
													alignItems="flex-end"
													// style={{ marginRight: 30 }}
												>
													<Grid
														item
														xs={12}
														style={{
															display: 'flex',
															justifyContent:
																'end',
															marginRight: 30,
														}}
													>
														<Controller
															render={({
																field: {
																	onChange,
																	onBlur,
																	value,
																},
															}) => (
																<CustomCheckBox
																	align="flex-end"
																	padding="0 9px"
																	label=""
																	error={
																		!!errors.groupsCheck
																	}
																	helperText={
																		errors
																			?.groupsCheck
																			?.message
																	}
																	inputProps={{
																		onChange:
																			(
																				event,
																			) => {
																				if (
																					event
																						.target
																						.checked
																				) {
																					setValue(
																						'sendForAllUsers',
																						false,
																					)
																				} else {
																					setValue(
																						'groupIds',
																						[],
																					)
																				}
																				return onChange(
																					event,
																				)
																			},

																		onBlur,
																		value,
																	}}
																	checked={
																		value
																	}
																/>
															)}
															name="groupsCheck"
															control={control}
														/>
														<div
															style={{
																width: '100%',
															}}
														>
															<MyAutoCompleteSelect
																control={
																	control
																}
																loading={
																	!dataGroups
																}
																name="groupIds"
																disabled={
																	!watch(
																		'groupsCheck',
																	)
																}
																options={
																	dataGroups?.map(
																		(
																			item,
																		) => ({
																			value: item.key,
																			label: item.value,
																		}),
																	) || []
																}
																getOptionLabel={(
																	option,
																) =>
																	`${option.label}`
																}
																inputLabel="*Grupo de estações"
																inputDisable={
																	!watch(
																		'groupsCheck',
																	)
																}
																inputPlaceholder="Buscar grupo"
															/>
														</div>
													</Grid>
												</Grid>
												<Grid
													item
													xs={4}
													container
													alignItems="flex-end"
													// style={{ marginRight: 30 }}
												>
													<Grid
														item
														xs={12}
														style={{
															display: 'flex',
															justifyContent:
																'end',
														}}
													>
														<Controller
															render={({
																field: {
																	onChange,
																	onBlur,
																	value,
																},
															}) => (
																<CustomCheckBox
																	inputProps={{
																		onChange:
																			(
																				event,
																			) => {
																				if (
																					event
																						.target
																						.checked
																				) {
																					setValue(
																						'sendForAllUsers',
																						false,
																					)
																				} else {
																					setValue(
																						'stationsId',
																						[],
																					)
																				}
																				return onChange(
																					event,
																				)
																			},

																		onBlur,
																		value,
																	}}
																	checked={
																		value
																	}
																	align="flex-end"
																	padding="0 9px"
																	label=""
																	error={
																		!!errors.stationsCheck
																	}
																	helperText={
																		errors
																			?.stationsCheck
																			?.message
																	}
																/>
															)}
															name="stationsCheck"
															control={control}
														/>
														<div
															style={{
																width: '100%',
															}}
														>
															<MyAutoCompleteSelect
																control={
																	control
																}
																loading={
																	!dataStation
																}
																name="stationsId"
																disabled={
																	!watch(
																		'stationsCheck',
																	)
																}
																options={
																	dataStation?.map(
																		(
																			item,
																		) => ({
																			value: item.key,
																			label: item.value,
																		}),
																	) || []
																}
																getOptionLabel={(
																	option,
																) =>
																	`${option.label}`
																}
																inputLabel="*Estações"
																inputDisable={
																	!watch(
																		'stationsCheck',
																	)
																}
																inputPlaceholder="Buscar estação"
															/>
														</div>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<div className="header-title margin">
													Mensagem:
												</div>
											</Grid>
											<Grid
												item
												xs={8}
												container
												spacing={4}
											>
												<Grid item xs={12}>
													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															alignItems: 'end',
														}}
													>
														<Controller
															name="title"
															control={control}
															render={({
																field: {
																	onChange,
																	onBlur,
																	value,
																},
															}) => (
																<MyCustomInput
																	error={
																		!!errors?.title
																	}
																	helpText={
																		errors
																			?.title
																			?.message
																	}
																	labelText="*Título"
																	formControlProps={{
																		fullWidth: true,
																	}}
																	inputProps={{
																		onChange,
																		onBlur,
																		value,
																		maxLength: 60,
																	}}
																/>
															)}
														/>
														<MyLimitText>
															{titleCharCount}{' '}
															caracteres
														</MyLimitText>
													</div>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										item
										xs={12}
										container
										className="body-form"
									>
										<Grid item xs={12} container>
											<Grid
												item
												xs={12}
												className="editor-box"
											>
												<div className="body-title">
													*Mensagem
												</div>
												<div>
													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															alignItems: 'end',
														}}
													>
														<Controller
															name="description"
															control={control}
															render={({
																field: {
																	value,
																	onChange,
																	onBlur,
																},
															}) => (
																<SunEditor
																	height="200px"
																	setContents={
																		value
																	}
																	setOptions={{
																		...sunEditorOptions,
																		maxCharCount: 340,
																		charCounter: true,
																	}}
																	autoFocus={
																		false
																	}
																	onChange={
																		onChange
																	}
																	onBlur={
																		onBlur
																	}
																	placeholder="Digita sua mensagem aqui"
																	lang="pt_br"
																/>
															)}
														/>

														<MyLimitText>
															{messageCharCount}{' '}
															caracteres
														</MyLimitText>
													</div>
												</div>
												{errors.description && (
													<p className="error">
														Este campo é
														obrigatório.
													</p>
												)}
											</Grid>
										</Grid>
									</Grid>
								</form>
							</CreateConfigBox>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</CommonPage>
	)
}

export default withStyles(extendedFormsStyle)(
	withStyles(sweetAlertStyle)(CrudMessage),
)
