import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import "assets/icons/style.css";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import { Divider } from "@material-ui/core";

import { isAllowed } from "utils/auth";

//const user = JSON.parse(localStorage.getItem('user'));
var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
	}
	render() {
		const { className, headerLinks, links } = this.props;
		return (
			<div
				style={{ height: "100%" }}
				className={className}
				ref="sidebarWrapper"
			>
				{headerLinks}
				{links}
			</div>
		);
	}
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openAvatar: false,
			...this.getCollapseStates(props.routes),
			user: null,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.user !== this.props.user) {
			this.setState({ user: this.props.user });
		}
	}

	// this creates the intial state of this component based on the collapse routes
	// that it gets through this.props.routes
	getCollapseStates = (routes) => {
		let initialState = {};
		routes.map((prop, key) => {
			if (prop.collapse) {
				initialState = {
					[prop.state]: this.getCollapseInitialState(prop.views),
					...this.getCollapseStates(prop.views),
					...initialState,
				};
			}
			return null;
		});
		return initialState;
	};
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
	getCollapseInitialState(routes) {
		for (let i = 0; i < routes.length; i++) {
			if (
				routes[i].collapse &&
				this.getCollapseInitialState(routes[i].views)
			) {
				return true;
			} else if (window.location.href.indexOf(routes[i].path) !== -1) {
				return true;
			}
		}
		return false;
	}
	// verifies if routeName is the one active (in browser input)
	activeRoute = (routeName) => {
		return this.props.location.pathname.indexOf(routeName) > -1
			? "active"
			: "";
	};
	openCollapse(collapse) {
		var st = {};
		st[collapse] = !this.state[collapse];
		this.setState(st);
	}
	// this function creates the links and collapses that appear in the sidebar (left menu)
	createLinks = (routes, user) => {
		const { t, classes, color, rtlActive } = this.props;
		//
		return routes
			.filter((n) => n.sidebar)
			.map((prop, key) => {
				if (prop.redirect) {
					return null;
				}
				if (prop.claims.length > 0) {
					if (!isAllowed(user, prop.claims)) {
						return null;
					}
				}
				if (prop.collapse) {
					var st = {};
					st[prop["state"]] = !this.state[prop.state];
					const navLinkClasses =
						classes.itemLink +
						" " +
						cx({
							[" " + classes.collapseActive]:
								this.getCollapseInitialState(prop.views),
						});
					const itemText =
						classes.itemText +
						" " +
						cx({
							[classes.itemTextMini]: this.props.miniActive,
							[classes.itemTextMiniRTL]:
								rtlActive && this.props.miniActive,
							[classes.itemTextRTL]: rtlActive,
						});
					const collapseItemText =
						classes.collapseItemText +
						" " +
						cx({
							[classes.collapseItemTextMini]:
								this.props.miniActive,
							[classes.collapseItemTextMiniRTL]:
								rtlActive && this.props.miniActive,
							[classes.collapseItemTextRTL]: rtlActive,
						});
					const itemIcon =
						classes.itemIcon +
						" " +
						cx({
							[classes.itemIconRTL]: rtlActive,
						});
					const caret =
						classes.caret +
						" " +
						cx({
							[classes.caretRTL]: rtlActive,
						});
					const collapseItemMini =
						classes.collapseItemMini +
						" " +
						cx({
							[classes.collapseItemMiniRTL]: rtlActive,
						});
					return (
						<ListItem
							key={key}
							className={cx(
								{ [classes.item]: prop.icon !== undefined },
								{
									[classes.collapseItem]:
										prop.icon === undefined,
								}
							)}
						>
							<NavLink
								to={"#"}
								className={navLinkClasses}
								onClick={(e) => {
									e.preventDefault();
									this.setState(st);
								}}
							>
								{prop.icon !== undefined ? (
									typeof prop.icon === "string" ? (
										<Icon
											className={cx(
												{ [itemIcon]: itemIcon },
												{ [prop.icon]: prop.icon }
											)}
										></Icon>
									) : (
										<prop.icon className={itemIcon} />
									)
								) : (
									<span className={collapseItemMini}>
										{prop.mini}
									</span>
								)}
								<ListItemText
									primary={
										rtlActive
											? t(prop.rtlName)
											: t(prop.name)
									}
									secondary={
										<b
											className={
												caret +
												" " +
												(this.state[prop.state]
													? classes.caretActive
													: "")
											}
										/>
									}
									disableTypography={true}
									className={cx(
										{ [itemText]: prop.icon !== undefined },
										{
											[collapseItemText]:
												prop.icon === undefined,
										}
									)}
								/>
							</NavLink>
							<Collapse in={this.state[prop.state]} unmountOnExit>
								<List
									className={
										classes.list +
										" " +
										classes.collapseList
									}
								>
									{this.createLinks(prop.views, user)}
								</List>
							</Collapse>
						</ListItem>
					);
				}
				const innerNavLinkClasses =
					classes.collapseItemLink +
					" " +
					cx({
						[" " + classes[color]]: this.activeRoute(prop.path),
					});
				const collapseItemMini =
					classes.collapseItemMini +
					" " +
					cx({
						[classes.collapseItemMiniRTL]: rtlActive,
					});
				const navLinkClasses =
					classes.itemLink +
					" " +
					cx({
						[" " + classes[color]]: this.activeRoute(prop.path),
					});
				const itemText =
					classes.itemText +
					" " +
					cx({
						[classes.itemTextMini]: this.props.miniActive,
						[classes.itemTextMiniRTL]:
							rtlActive && this.props.miniActive,
						[classes.itemTextRTL]: rtlActive,
					});
				const collapseItemText =
					classes.collapseItemText +
					" " +
					cx({
						[classes.collapseItemTextMini]: this.props.miniActive,
						[classes.collapseItemTextMiniRTL]:
							rtlActive && this.props.miniActive,
						[classes.collapseItemTextRTL]: rtlActive,
					});
				const itemIcon =
					classes.itemIcon +
					" " +
					cx({
						[classes.itemIconRTL]: rtlActive,
					});
				return (
					<ListItem
						key={key}
						className={cx(
							{ [classes.item]: prop.icon !== undefined },
							{ [classes.collapseItem]: prop.icon === undefined }
						)}
					>
						<NavLink
							to={prop.layout + prop.path}
							className={cx(
								{ [navLinkClasses]: prop.icon !== undefined },
								{
									[innerNavLinkClasses]:
										prop.icon === undefined,
								}
							)}
						>
							{prop.icon !== undefined ? (
								typeof prop.icon === "string" ? (
									prop.iconSmall !== undefined &&
									prop.iconSmall ? (
										<Icon
											className={cx(
												{
													[classes.iconSmall]:
														classes.iconSmall,
												},
												{ [prop.icon]: prop.icon }
											)}
										/>
									) : (
										<Icon
											className={cx(
												{ [itemIcon]: itemIcon },
												{ [prop.icon]: prop.icon }
											)}
										></Icon>
									)
								) : (
									<prop.icon className={itemIcon} />
								)
							) : (
								<span className={collapseItemMini}>
									{prop.mini}
								</span>
							)}
							<ListItemText
								primary={t(prop.name)}
								disableTypography={true}
								className={cx(
									{ [itemText]: prop.icon !== undefined },
									{
										[collapseItemText]:
											prop.icon === undefined,
									}
								)}
							/>
						</NavLink>
					</ListItem>
				);
			});
	};
	render() {
		const {
			classes,
			logo,
			image,
			routes,
			bgColor,
			rtlActive,
			miniActive,
			sidebarMinimize,
			user,
		} = this.props;

		var links = (
			<List className={classes.list}>
				{this.createLinks(routes, user)}
			</List>
		);

		const logoClasses =
			classes.logo +
			" " +
			cx({
				[classes.whiteAfter]: bgColor === "white",
			});
		var brand = (
			<div className={logoClasses}>
				<NavLink to="/admin/dashboard">
					<img src={logo} alt="logo" className={classes.img} />
				</NavLink>
			</div>
		);
		const drawerPaper =
			classes.drawerPaper +
			" " +
			cx({
				[classes.drawerPaperMini]: this.props.miniActive,
				[classes.drawerPaperRTL]: rtlActive,
			});

		const drawer = cx({
			[classes.drawer]: !this.props.miniActive,
			[classes.drawerMini]: this.props.miniActive,
		});

		const sidebarWrapper = cx({
			[classes.sidebarWrapper]: true,
			[classes.drawerPaperMini]: this.props.miniActive,
			[classes.sidebarWrapperWithPerfectScrollbar]:
				navigator.platform.indexOf("Win") > -1,
		});
		return (
			<React.Fragment>
				<Hidden mdUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={"right"}
						open={this.props.open}
						className={drawer}
						classes={{
							paper:
								drawerPaper +
								" " +
								classes[bgColor + "Background"],
						}}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{/* {brand} */}
						<SidebarWrapper
							className={sidebarWrapper}
							headerLinks={
								<AdminNavbarLinks rtlActive={rtlActive} />
							}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{
									backgroundImage: "url(" + image + ")",
								}}
							></div>
						) : null}
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<div>
						<Drawer
							className={drawer}
							// onMouseEnter={() => sidebarMinimize(false)}
							// onMouseLeave={() => sidebarMinimize(true)}
							anchor={"left"}
							variant="permanent"
							open
							classes={{
								paper:
									drawerPaper +
									" " +
									classes[bgColor + "Background"],
							}}
						>
							{brand}
							<Divider
								variant="middle"
								className={classes.sidebarDivider}
								style={{
									margin: "16px",
									background: "#ccc",
									opacity: "0.4",
									zIndex: "2",
								}}
							/>
							<SidebarWrapper
								className={sidebarWrapper}
								links={links}
							/>
							{image !== undefined ? (
								<div
									className={classes.background}
									style={{
										backgroundImage: "url(" + image + ")",
									}}
								/>
							) : null}

							{miniActive ? (
								<div
									aria-label="expand"
									onClick={() => sidebarMinimize(false)}
									style={{
										position: "absolute",
										top: "21px",
										left: "100%",
										cursor: "pointer",
									}}
								>
									<NavigateNextIcon
										style={{
											backgroundColor: "#ccc",
											borderRadius: "0px 4px 4px 0px",
											color: "black",
										}}
									/>
								</div>
							) : (
								<div
									aria-label="collapse"
									onClick={() => sidebarMinimize(true)}
									style={{
										position: "absolute",
										top: "21px",
										left: "100%",
										cursor: "pointer",
									}}
								>
									<NavigateBeforeIcon
										style={{
											backgroundColor: "#ccc",
											borderRadius: "0px 4px 4px 0px",
											color: "black",
										}}
									/>
								</div>
							)}
						</Drawer>
					</div>
				</Hidden>
			</React.Fragment>
		);
	}
}

Sidebar.defaultProps = {
	bgColor: "blue",
};

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(["white", "black", "blue"]),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf([
		"customizeColor",
		"white",
		"red",
		"orange",
		"blue",
		"purple",
		"rose",
	]),
	logo: PropTypes.string,
	image: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	user: PropTypes.object,
};

export default withStyles(sidebarStyle)(Sidebar);
