
import axios from 'axios';

// CHANGE PASSWORD
export const ChangePassword = (obj) => {
    const url = `/api/CAccount/ChangePassword`;
    return axios.post(url, obj).then(response => response.data);
}

// UPDATE
export const UpdateProfile = (obj) => {
    return axios.put(`/api/CAccount/Update`, obj).then(response => response.data);
}

// ACCOUNT INFO
export const GetAccountInfo = () => {
    return axios.get(`/api/CAccount/GetAccountInfo`).then(response => response.data);
}

// GENERATE TOKEN
export const SendTokenToEmail = (email) => {
    return axios.post(`/api/CAccount/GenerateToken?userName=${email}`).then(response => response);
}

// VALIDATE TOKEN
export const ValidateToken = (email, token) => {
    return axios.post(`/api/CAccount/Validation?userName=${email}&token=${token}`).then(response => response);
}

// FORGET PASSWORD
export const ForgetPassword = (obj) => {
    return axios.post(`/api/CAccount/ForgetPassword`, obj).then(response => response);
}
