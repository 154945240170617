import { Icon, TextField } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput";
import React from "react";
import { Controller } from "react-hook-form";

import * as Styled from "./styles";

function IconInputs({
	inputData: {
		icon,
		iconMessage,
		iconColor,
		iconSubtitle,
		firstMessage,
		secondMessage,
		firstInput,
		secondInput,
		iconSize,
	},
	control,
	errors,
}) {
	return (
		<Styled.IconInputBox
			icon_color={iconColor}
			icon_size={iconSize}
			has_message={iconMessage}
		>
			<div className="text-container icon-body">
				<Icon className={icon} />
				{iconMessage && (
					<div className="subtitle">
						<span>{iconMessage}</span>
						{iconSubtitle && <small>{iconSubtitle}</small>}
					</div>
				)}
			</div>
			<div className="text-container">
				<div>
					<span>{firstMessage}</span>
				</div>
				<div className="input-body">
					{firstInput?.name && (
						<Controller
							name={firstInput?.name}
							control={control}
							render={({
								field: { onChange, onBlur, value },
							}) => (
								<TextField
									id={firstInput?.label}
									// label={firstInput?.label}
									error={!!errors?.[firstInput?.name]}
									helperText={
										errors?.[firstInput?.name]?.message
									}
									inputProps={{
										onChange,
										onBlur,
										value,
									}}
									InputProps={{
										endAdornment: (
											<small>{firstInput?.label}</small>
										),
									}}
								/>
							)}
						/>
					)}
				</div>
				<div>{secondMessage && <span>{secondMessage}</span>}</div>
				<div className="input-body">
					{secondInput?.name && (
						<Controller
							name={secondInput?.name}
							control={control}
							render={({
								field: { onChange, onBlur, value },
							}) => (
								<TextField
									error={!!errors?.[secondInput?.name]}
									helhelperTextpText={
										errors?.[secondInput?.name]?.message
									}
									inputProps={{
										onChange,
										onBlur,
										value,
									}}
									InputProps={{
										endAdornment: (
											<small>
												{secondInput?.secondLabel}
											</small>
										),
									}}
								/>
							)}
						/>
					)}
				</div>
			</div>
		</Styled.IconInputBox>
	);
}

export default IconInputs;
