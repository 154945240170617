import React from "react";
import "./Marker.scss";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useHistory } from "react-router-dom";

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: "rgb(31, 120, 180, 0.85)",
		color: "#f1f1f1",
		boxShadow: "0px 0px 2px #808080",
		maxWidth: 460,
		fontSize: theme.typography.pxToRem(12),
		marginRight: "10px",
		width: "160px",
		fontWeight: 400,
	},
}))(Tooltip);

const useStyles = makeStyles({
	content: {
		display: "block",
	},
	textContent: {
		display: "flex",
		margin: "3px 0",
	},
});

const Marker = ({ ...props }) => {
	const [open, setOpen] = React.useState(false);

	let history = useHistory();

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(!open);
	};

	const classes = useStyles();

	const { color, name, id } = props;
	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<div style={{ padding: "10px 0 0 10px", lineHeight: "auto" }}>
				<HtmlTooltip
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					placement={"right"}
					title={
						<React.Fragment>
							<div className={classes.content}>
								<span className={classes.textContent}>
									{props.station.name}{" "}
								</span>
								{props.station.owner && (
									<span className={classes.textContent}>
										Cliente: {props.station.owner.fullName}{" "}
									</span>
								)}
								<span className={classes.textContent}>
									Nº Série: {props.station.gateStationId}{" "}
								</span>
							</div>
						</React.Fragment>
					}
				>
					<div onClick={handleTooltipOpen}>
						<div
							className="pin bounce"
							style={{
								backgroundColor: color,
								cursor: "pointer",
							}}
							title={name}
						/>
						<div className="pulse" />
					</div>
				</HtmlTooltip>
			</div>
		</ClickAwayListener>
	);
};

export default Marker;
