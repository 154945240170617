import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import React from "react";

function CustomArrow({ text, order }) {
	return (
		<div
			style={{
				display: "flex",
				position: "relative",
				top: "5px",
			}}
		>
			<p>{text}</p>
			{order === "asc" ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
		</div>
	);
}

export default CustomArrow;
