import * as Yup from "yup";

export const regexPhone =
	/([ \t\r\n()-\s\u00A0\u1680\u2000-\u200A\u202F\u205F\u3000\u2028\u2029])/g;

const validatePhone = (message) =>
	Yup.string().test("is-null", message, (value) =>
		value?.replace(regexPhone, "").length < 10 ? false : true
	);

export const contactValidation = Yup.object().shape({
	contact: Yup.object().shape({
		email: Yup.string()
			.email("Email inválido")
			.required("Email obrigatório"),
		whatsapp: validatePhone("Formato de Whatsapp invalido").required(
			"Whatsapp obrigatório"
		),
	}),
});
