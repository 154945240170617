import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import CustomTable, {
	initialPagination,
	initialSort,
} from "components/CustomTable";
import {
	getDataIntegrityByFilter,
	GET_DATA_INTEGRITY_BY_FILTER,
} from "services/api/Station/api";
import useSWR, { mutate as swrMutate } from "swr";

function ModalDataIntegrity({ period, id }) {
	const [pagination, setPagination] = useState(initialPagination);
	const [loading, setLoading] = useState(false);
	const [sort, setSort] = useState(initialSort);
	const [body, setBody] = useState({
		id: id,
		period: period,
		skip: pagination.page,
		top: pagination.pageSize,
	});

	const { data: integrity } = useSWR(
		[GET_DATA_INTEGRITY_BY_FILTER, body],
		(url, body) => getDataIntegrityByFilter(url, body)
	);

	useEffect(() => {
		swrMutate([GET_DATA_INTEGRITY_BY_FILTER, body], (url, body) =>
			getDataIntegrityByFilter(url, body)
		);
	}, [body]);

	useEffect(() => {
		setBody((prevState) => ({
			...prevState,
			skip: pagination.page,
			top: pagination.pageSize,
		}));
	}, [pagination.page, pagination.pageSize]);

	useEffect(() => {
		setLoading(true);

		if (integrity?.items) {
			setPagination((prevState) => ({
				...prevState,
				recordCount: integrity.count,
			}));
			setLoading(false);
		}
	}, [integrity]);

	useEffect(() => {
		integrity?.items?.map((value) => {
			// eslint-disable-next-line default-case
			switch (value.dataQuality) {
				case 3:
					value.dataQualityText = "Excelente";
					break;
				case 2:
					value.dataQualityText = "Boa";
					break;
				case 1:
					value.dataQualityText = "Regular";
					break;
				case 0:
					value.dataQualityText = "Sem dados";
					break;
			}
		});
	}, [integrity]);

	const columns = [
		{
			title: "Data",
			field: "date",
			// cellStyle: { maxWidth: "100px", minWidth: "100px" },
			// headerStyle: { maxWidth: "100px", minWidth: "100px" },
			type: "date",
		},
		{
			title: "Status",
			field: "dataQualityText",
			// cellStyle: { maxWidth: "100px", minWidth: "100px" },
			// headerStyle: { maxWidth: "100px", minWidth: "100px" },
			type: "string",
		},
	];

	return (
		<Grid container style={{ marginTop: 20 }}>
			<Grid item xs={12} style={{ overflowY: "auto", maxHeight: "400px" }}>
				<CustomTable
					columns={columns}
					actions={[]}
					pagination={pagination}
					setPagination={setPagination}
					data={integrity?.items}
					loading={loading}
					sort={sort}
					setSort={setSort}
				/>
			</Grid>
		</Grid>
	);
}
export default ModalDataIntegrity;
