import * as actionTypes from '../../actions/actionsTypes';
import {
    updateObject
} from '../../utility';

const initialState = {
    lsFirmwares: {},
    firmwaresDataFailed: false,

    postFirmwareData: null,
    postFirmwareFailed: false,

    postFirmwareFile: {},
    postFirmwareFileFailed: false,

    deleteFirmwareData: null,
    deleteFirmwareDataFailed: false,
};

const fetchFirmwaresData = (state, action) => {
    return updateObject(state, {
        lsFirmwares: action.lsFirmwares
    });
};
const fetchFirmwaresFailed = (state, action) => {
    return updateObject(state, {
        postFirmwareFailed: true
    });
};


// POST FIRMWARE
const fetchPostFirmwareData = (state, action) => {
    return updateObject(state, {
        postFirmwareData: action.postData
    });
};
const postFirmwareFailed = (state, action) => {
    return updateObject(state, {
        postFirmwareFailed: true
    });
};

// UPLOAD FILE
const postFirmwareFile = (state, action) => {
    return updateObject(state, {
        postFirmwareFile: action.postData
    });
};
const postFirmwareFileFailed = (state, action) => {
    return updateObject(state, {
        postFirmwareFileFailed: true
    });
};

// DELETE FIRMWARE
const deleteFirmwareData = (state, action) => {
    return updateObject(state, {
        deleteFirmwareData: action.deleteData
    });
};
const deleteFirmwareDataFailed = (state, action) => {
    return updateObject(state, {
        deleteFirmwareDataFailed: true
    });
};

//
const firmwareReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_FIRMWARE_DATA:
            return fetchFirmwaresData(state, action);
        case actionTypes.FETCH_FIRMWARE_FAILED:
            return fetchFirmwaresFailed(state, action);

        case actionTypes.POST_FIRMWARE_DATA:
            return fetchPostFirmwareData(state, action);
        case actionTypes.POST_FIRMWARE_DATA_FAILED:
            return postFirmwareFailed(state, action);

        case actionTypes.POST_FIRMWARE_FILE:
            return postFirmwareFile(state, action);
        case actionTypes.POST_FIRMWARE_FILE_FAILED:
            return postFirmwareFileFailed(state, action);

        case actionTypes.DELETE_FIRMWARE_DATA:
            return deleteFirmwareData(state, action);
        case actionTypes.DELETE_FIRMWARE_DATA_FAILED:
            return deleteFirmwareDataFailed(state, action);

        default:
            return state;
    }
};

export default firmwareReducer;