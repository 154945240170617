import React from 'react';
import PropTypes from "prop-types";
import cx from "classnames";

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    alert: {
        margin: 'auto',
        textAlign: 'center',
        padding: '20px 0.8px',
        color: '#ccc'
    },
    text: {
        fontWeight: 'bold'
    },
    icon: {
        fontSize: '2.8rem'
    }
}))

const NoContentAlert = ({ alertText }) => {

    const classes = useStyles();

    return (
        <div className={classes.alert}>
            <span className={cx("icon-information", classes.icon)}></span>
            <h4 className={classes.text}>{alertText}</h4>
        </div>
    )
}

NoContentAlert.propTypes = {
    alertText: PropTypes.string.isRequired
};

export default NoContentAlert;
