import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import DocumentTitle from 'components/Pages/DocumentTitle'
import CommonPage from 'components/Pages/CommonPage'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { Icon, Grid } from '@material-ui/core'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'
import { goTo } from 'utils/utils'
import ListStation from './components/ListTable'
import FilterBox from './components/FilterBox'

import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'

const breadcrumbItems = [{ name: 'Home' }, { name: 'Estações', last: true }]

function Stations(props) {
	const { classes } = props
	const { t } = useTranslation()
	const [filter, setFilter] = useState({
		Name: '',
		GateStationId: '',
		OwnerFullName: '',
		Password: '',
		BluetoothDeviceId: '',
		TransmissionType: '',
		DataQuality: '',
		SignalQuality: '',
		BatteryLevel: '',
		Signal: '',
		Status: 6,
		filterRefresh: false,
	})

	return (
		<>
			<CommonPage>
				<DocumentTitle pageTitle={t('MENU_STATIONS')} />
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<Breadcrumbs items={breadcrumbItems} />
					</GridItem>
					<Card>
						<CardBody>
							<Grid container>
								<Grid item xs={12} container>
									<Grid item xs={6}>
										<Section
											title="DADOS DAS ESTAÇÕES"
											icon="icon-estacao"
										/>
									</Grid>
									<Grid
										item
										xs={6}
										container
										justify="flex-end"
									>
										<Button
											size="sm"
											color="primary"
											onClick={() => goTo('station/crud')}
											style={{ marginRight: 20 }}
										>
											<Icon
												style={{
													fontSize: '18px',
													width: '30px',
												}}
												className={'icon-add_estacao'}
											/>
											Nova estação
										</Button>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<FilterBox
										classes={classes}
										filter={filter}
										setFilter={setFilter}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									style={{ mergingTop: '20px' }}
								>
									<ListStation
										classes={classes}
										filter={filter}
										mutate={filter.filterRefresh}
									/>
								</Grid>
							</Grid>
						</CardBody>
					</Card>
				</GridContainer>
			</CommonPage>
		</>
	)
}

export default withStyles(sweetAlertStyle)(Stations)
