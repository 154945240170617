import React, { useEffect } from 'react'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/CustomButtons/Button.jsx'

import CommonPage from 'components/Pages/CommonPage'
import DocumentTitle from 'components/Pages/DocumentTitle'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { useTranslation } from 'react-i18next'

import { Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import CustomInput from 'components/CustomInput/CustomInput'
import Section from 'components/Section'
import { validation } from './validation'
import { goTo } from 'utils/utils'
import { HideLoader, ShowLoader } from 'redux/actions'
import Loader from 'components/Loader/Loader'

import { yupResolver } from '@hookform/resolvers/yup'
import {
	getStationManagerById,
	upsertStationManager,
} from 'services/api/StationManager/api'
import { useParams } from 'react-router-dom'
import { history } from 'utils'
import { useDispatch } from 'react-redux'
import { showNotification } from 'redux/actions/notification'
import { SuccessNotificationComponent } from 'redux/react-notifications'
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom'

const breadcrumbItems = [{ name: 'Home' }, { name: 'Estações', last: true }]

function CrudStation({ classes }) {
	const { t } = useTranslation()
	const { id } = useParams()
	const dispatch = useDispatch()
	function useQuery() {
		return new URLSearchParams(useLocation().search)
	}
	const query = useQuery()
	const queryString = query.get('body')
	const viewMore = query.get('viewMore')

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty },
		reset,
	} = useForm({
		defaultValues: {
			name: '',
			password: '',
			gateStationId: '',
			bluetoothDeviceId: '',
		},
		resolver: yupResolver(validation),
	})

	useEffect(() => {
		async function getById() {
			try {
				dispatch(ShowLoader(<Loader />))

				const data = await getStationManagerById(id)

				if (data) {
					return reset({ ...data })
				}

				history.goBack()
			} catch (error) {
			} finally {
				dispatch(HideLoader(<Loader />))
			}
		}

		if (id) getById()
	}, [dispatch, id, reset])

	async function sendForm(value) {
		try {
			dispatch(ShowLoader(<Loader />))

			let data

			if (id) {
				data = await upsertStationManager({ id, ...value })
			} else {
				data = await upsertStationManager({ ...value })
			}

			if (data) {
				if (!id) {
					goTo(`station/crud/${data?.id}`)
				}

				return dispatch(
					showNotification(
						SuccessNotificationComponent(
							'Estação salva com sucesso!',
						),
					),
				)
			}
		} catch (error) {
		} finally {
			dispatch(HideLoader(<Loader />))
			reset({ ...value })
		}
	}

	function handleCancel() {
		if (id) {
			if (viewMore && viewMore == 'true') {
				history.goBack()
			} else {
				debugger
				goTo(`station/view/${id}?body=${queryString}`)
			}
		} else {
			goTo('station')
		}
	}

	return (
		<CommonPage>
			<DocumentTitle pageTitle={t('MENU_STATION')} />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<Breadcrumbs items={breadcrumbItems} />
					<Card>
						<form
							onSubmit={handleSubmit(sendForm)}
							style={{ width: '100%' }}
							autoComplete="off"
						>
							<CardBody>
								<Grid item xs={12} container>
									<Grid item xs={4}>
										<Section
											title={
												id
													? 'EDITAR ESTAÇÃO'
													: 'CADASTRO DA ESTAÇÃO'
											}
											icon="icon-estacao"
										/>
									</Grid>
									<Grid
										item
										xs={8}
										container
										justify="flex-end"
										className="button-head"
									>
										<Button
											size="sm"
											color="primary"
											type="submit"
											disabled={!isDirty}
											onClick={handleSubmit(sendForm)}
											style={{
												marginRight: 10,
											}}
										>
											<i
												style={{
													fontSize: 14,
													padding: '2px 0',
												}}
												className={'icon-salvar'}
											/>
											Salvar
										</Button>
										<Button
											size="sm"
											color="greenBorderButtonColor"
											onClick={() => handleCancel()}
										>
											<i
												style={{
													fontSize: 14,
													padding: '2px 0',
												}}
												className={
													isDirty
														? 'icon-cancelar'
														: 'icon-voltar'
												}
											/>
											{isDirty ? 'Cancelar' : 'Voltar'}
										</Button>
									</Grid>
								</Grid>
								<Grid container xs={12} className="header-form">
									<Grid
										item
										xs={4}
										style={{
											paddingRight: '30px',
											marginBottom: 30,
										}}
									>
										<Controller
											name="name"
											control={control}
											render={({
												field: {
													onChange,
													onBlur,
													value,
												},
											}) => (
												<CustomInput
													error={!!errors?.name}
													helpText={
														errors?.name?.message
													}
													labelText="Apelido da estação*"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														onChange,
														onBlur,
														value,
													}}
												/>
											)}
										/>
									</Grid>
									<Grid
										item
										xs={4}
										style={{
											paddingRight: '30px',
											marginBottom: 30,
										}}
									>
										<Controller
											name="gateStationId"
											control={control}
											render={({
												field: {
													onChange,
													onBlur,
													value,
												},
											}) => (
												<CustomInput
													error={
														!!errors?.gateStationId
													}
													helpText={
														errors?.gateStationId
															?.message
													}
													labelText="Nº de série*"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														onChange,
														onBlur,
														value,
													}}
												/>
											)}
										/>
									</Grid>
									<Grid
										item
										xs={4}
										style={{
											paddingRight: '30px',
											marginBottom: 30,
										}}
									>
										<Controller
											name="bluetoothDeviceId"
											control={control}
											render={({
												field: {
													onChange,
													onBlur,
													value,
												},
											}) => (
												<CustomInput
													error={
														!!errors?.bluetoothDeviceId
													}
													helpText={
														errors
															?.bluetoothDeviceId
															?.message
													}
													labelText="ID do aparelho Bluetooth*"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														onChange,
														onBlur,
														value,
													}}
												/>
											)}
										/>
									</Grid>
									<Grid
										item
										xs={4}
										style={{
											paddingRight: '30px',
											marginBottom: 30,
										}}
									>
										<Controller
											name="password"
											control={control}
											render={({
												field: {
													onChange,
													onBlur,
													value,
												},
											}) => (
												<CustomInput
													error={!!errors?.password}
													helpText={
														errors?.password
															?.message
													}
													labelText="Chave de segurança*"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														onChange,
														onBlur,
														value,
													}}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</CardBody>
						</form>
					</Card>
				</GridItem>
			</GridContainer>
		</CommonPage>
	)
}

export default CrudStation
