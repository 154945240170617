import axios from "axios";

export const GET_MESSAGE_MANAGER_ALL = "/api/MessageManager/getall";
export const GET_MESSAGE_USER_ALL = "/api/MessageManager/getallmessageuser";
export const GET_MESSAGE_MANAGER_BY_ID = "/api/MessageManager/getbyid";
export const UPSERT_MESSAGE_MANAGER = "/api/MessageManager/upsert";
export const ARCHIVE_MESSAGE_MANAGER = "/api/MessageManager/archive/";
export const GET_MESSAGE_ALL = "/api/Message/getall";

export const getMessageManager = async (url, setLoading) => {
	try {
		const { data } = await axios.get(url);
		return data;
	} catch (error) {}
};

export const upsertMessageManager = async ({ url, body }) => {
	try {
		const { data } = await axios.post(url ?? UPSERT_MESSAGE_MANAGER, body);
		return data;
	} catch (error) {}
};

export const archiveMessageManager = async (id) => {
	try {
		const { data } = await axios.post(`${ARCHIVE_MESSAGE_MANAGER}${id}`);
		return data;
	} catch (error) {}
};
