import * as actionTypes from 'redux/actions/actionsTypes';
import {
    updateObject
} from '../../utility';

const initialState = {
    userPwd: false,
    userPwdFailed: false,

    lsCustomersData: {},
    customersDataFailed: false,

    customerData: [],
    customerDataFailed: false,

    postCustomerData: {},
    postCustomerFailed: false,

    updateAttachedStation: {},
    updateLinkedStationFailed: false,

    deleteCustomerData: null,
    deleteCustomerDataFailed: false,

    resetPasswordData: null,
    resetPasswordDataFailed: false,
};

// GET ALL
const fetchCustomersData = (state, action) => {
    return updateObject(state, {
        lsCustomersData: action.lsCustomersData
    });
};
const fetchCustomersFailed = (state, action) => {
    return updateObject(state, {
        customersDataFailed: true
    });
};

// BY ID
const fetchCustomerByIdData = (state, action) => {
    return updateObject(state, {
        customerData: action.customerData
    });
};
const fetchCustomerByIdFailed = (state, action) => {
    return updateObject(state, {
        customerDataFailed: true
    });
};


// POST CUSTOMER
const fetchPostCustomerData = (state, action) => {
    return updateObject(state, {
        postCustomerData: action.postCustomerData
    });
};
const postCustomerFailed = (state, action) => {
    return updateObject(state, {
        postCustomerFailed: true
    });
};

// POST CUSTOMER
const fetchCreateCustomerData = (state, action) => {
    return updateObject(state, {
        postCustomerData: action.createCustomerData
    });
};
const createCustomerFailed = (state, action) => {
    return updateObject(state, {
        postCustomerFailed: true
    });
};

// CHANGE PWD
const fetchChangePassword = (state, action) => {
    return updateObject(state, {
        userPwd: action.userPwd
    });
};
const fetchChangePasswordFailed = (state, action) => {
    return updateObject(state, {
        userPwdFailed: true
    });
};

// ATTACH STATION
const fetchAttachStation = (state, action) => {
    return updateObject(state, {
        updateAttachedStation: action.updateAttachedStation
    });
}

const fetchAttachStationFailed = (state, action) => {
    return updateObject(state, {
        updateLinkedStationFailed: true
    });
}


// DELETE 
const deleteCustomerData = (state, action) => {
    return updateObject(state, {
        deleteCustomerData: action.deleteData
    });
};
const deleteCustomerDataFailed = (state, action) => {
    return updateObject(state, {
        deleteCustomerDataFailed: true
    });
};

// RESET PASSWORD 
const resetPasswordData = (state, action) => {
    return updateObject(state, {
        resetPasswordData: action.deleteData
    });
};
const resetPasswordDataFailed = (state, action) => {
    return updateObject(state, {
        resetPasswordDataFailed: true
    });
};

//
const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CUSTOMERS_DATA:
            return fetchCustomersData(state, action);
        case actionTypes.FETCH_CUSTOMERS_FAILED:
            return fetchCustomersFailed(state, action);


        case actionTypes.FETCH_CUSTOMER_BY_ID:
            return fetchCustomerByIdData(state, action);
        case actionTypes.FETCH_CUSTOMER_BY_ID_FAILED:
            return fetchCustomerByIdFailed(state, action);


        case actionTypes.POST_CUSTOMER_DATA:
            return fetchPostCustomerData(state, action);
        case actionTypes.POST_CUSTOMER_DATA_FAILED:
            return postCustomerFailed(state, action);


        case actionTypes.CREATE_CUSTOMER_DATA:
            return fetchCreateCustomerData(state, action);
        case actionTypes.CREATE_CUSTOMER_DATA_FAILED:
            return createCustomerFailed(state, action);


        case actionTypes.FETCH_CHANGE_PASSWORD_DATA:
            return fetchChangePassword(state, action);
        case actionTypes.FETCH_CHANGE_PASSWORD_FAILED:
            return fetchChangePasswordFailed(state, action);

        case actionTypes.FETCH_ATTACH_STATION:
            return fetchAttachStation(state, action);
        case actionTypes.FETCH_ATTACH_STATION_FAILED:
            return fetchAttachStationFailed(state, action);


        case actionTypes.DELETE_CUSTOMER_DATA:
            return deleteCustomerData(state, action);
        case actionTypes.DELETE_CUSTOMER_DATA_FAILED:
            return deleteCustomerDataFailed(state, action);


        case actionTypes.POST_CUSTOMER_NEW_PASSWORD:
            return resetPasswordData(state, action);
        case actionTypes.POST_CUSTOMER_NEW_PASSWORD_FAILED:
            return resetPasswordDataFailed(state, action);

        default:
            return state;
    }
};

export default customerReducer;