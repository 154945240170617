import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react";

export const inputsList = [
	{
		inputData: {
			icon: "icon-qualidade_dados",
			iconSize: "22px",
			iconColor: germiniDefaultColors[0],
			firstMessage:
				"Excelente qualidade - Quantidade de pacotes recebidos entre: ",
			secondMessage: " a ",
			firstInput: {
				name: "stationDataQuality.greatMin",
				label: "",
			},
			secondInput: {
				name: "stationDataQuality.greatMax",
				label: "",
			},
		},
	},
	{
		inputData: {
			icon: "icon-qualidade_dados",
			iconSize: "22px",
			iconColor: germiniDefaultColors[1],
			firstMessage:
				"Qualidade boa - Quantidade de pacotes recebidos entre: ",
			secondMessage: " a ",
			firstInput: {
				name: "stationDataQuality.wellMin",
				label: "",
			},
			secondInput: {
				name: "stationDataQuality.wellMax",
				label: "",
			},
		},
	},
	{
		inputData: {
			icon: "icon-qualidade_dados",
			iconSize: "22px",
			iconColor: germiniDefaultColors[13],
			iconSubtitle: "Não há dados",
			firstMessage:
				"Qualidade regular - Quantidade de pacotes recebidos entre: ",
			secondMessage: " a ",
			firstInput: {
				name: "stationDataQuality.regularMin",
				label: "",
			},
			secondInput: {
				name: "stationDataQuality.regularMax",
				label: "",
			},
		},
	},
	{
		inputData: {
			icon: "icon-qualidade_dados",
			iconSize: "22px",
			iconColor: germiniDefaultColors[6],
			iconSubtitle: "Coleta manual",
			firstMessage: "Sem dados - Quantidade de pacotes recebidos entre: ",
			secondMessage: " a ",
			firstInput: {
				name: "stationDataQuality.noDataMin",
				label: "",
			},
			secondInput: {
				name: "stationDataQuality.noDataMax",
				label: "",
			},
		},
	},
];
