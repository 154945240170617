import React from "react";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import { history } from "utils";

export const numberFormatText = (_data, _prefix) => {
	let tSeparator = ".";
	let dSeparator = ",";
	if (localStorage.getItem("i18nextLng") != null) {
		if (
			localStorage.getItem("i18nextLng") === "pt-BR" ||
			localStorage.getItem("i18nextLng") === "es"
		) {
			tSeparator = ".";
			dSeparator = ",";
		} else {
			tSeparator = ",";
			dSeparator = ".";
		}
	}
	return (
		<NumberFormat
			value={_data}
			displayType={"text"}
			thousandSeparator={tSeparator}
			decimalSeparator={dSeparator}
			prefix={_prefix}
		/>
	);
};

export const formatPhone = (phone) =>
	phone
		.replace(/\D/g, "")
		.replace(/(\d{2})(\d)(\d{4})(\d{4})$/, "($1) $2 $3-$4");

export const goTo = (path) => history.push(`/admin/${path}`);

export function PhoneMaskCustom(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[
				"(",
				/[1-9]/,
				/\d/,
				")",
				" ",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
			]}
			placeholderChar={"\u2000"}
			showMask
		/>
	);
}

export const sunEditorOptions = {
	imageWidth: "100%",
	imageHeight: "100%",
	videoFileInput: false,
	tabDisable: false,
	imageSizeOnlyPercentage: true,
	buttonList: [
		[
			"undo",
			"redo",
			"font",
			"fontSize",
			"formatBlock",
			"paragraphStyle",
			"blockquote",
			"bold",
			"underline",
			"italic",
			"strike",
			"subscript",
			"superscript",
			"fontColor",
			"hiliteColor",
			"textStyle",
			"removeFormat",
			"outdent",
			"indent",
			"align",
			"horizontalRule",
			"list",
			"lineHeight",
			"table",
			"link",
			"image",
			"video",
			"audio",
			"fullScreen",
			"showBlocks",
			"codeView",
		],
	],
};

export function DocumentMaskCustom(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[
				/[1-9]/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
			]}
			placeholderChar={"\u2000"}
		/>
	);
}
