import * as Yup from "yup";

export const validation = Yup.object().shape({
	name: Yup.string().required("Apelido da estação obrigatório"),
	password: Yup.string().required("Chave de segurança obrigatória"),
	gateStationId: Yup.string().required("Nº de série obrigatório"),
	bluetoothDeviceId: Yup.string().required(
		"ID do aparelho bluetooth obrigatório"
	),
});
