import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
	KeyboardArrowUp,
	KeyboardArrowDown,
	MoreVert,
} from '@material-ui/icons'
import { IconButton, Grid } from '@material-ui/core'
import * as Styled from './styles'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import CustomSelect from 'components/CustomSelect'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { uniqueId } from 'lodash'
import { DocumentMaskCustom } from 'utils/utils'

const schema = Yup.object().shape({})

function FilterBox({ classes, filter, setFilter }) {
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
		watch,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			...filter,
		},
	})

	const [open, setOpen] = useState(false)

	const submitForm = (values) => {
		setFilter({ ...values, filterRefresh: uniqueId('id') })
	}

	const handleClearFilter = () => {
		reset()
		setFilter({})
	}

	return (
		<form style={{ width: '100%' }} onSubmit={handleSubmit(submitForm)}>
			<Styled.SearchBox container className={classes.filterForm}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<h5>Opções de busca</h5>
				</Grid>
				<Grid
					item
					container
					xs={12}
					sm={12}
					md={12}
					lg={12}
					spacing={3}
				>
					<Grid item xs={4}>
						<Controller
							control={control}
							name="fullName"
							render={({
								field: { onChange, onBlur, value },
							}) => (
								<CustomInput
									labelText="Cliente"
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										onChange,
										onBlur,
										value,
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4} style={{ alignSelf: 'center' }}>
						<Controller
							control={control}
							name="active"
							render={({ field }) => (
								<CustomSelect
									{...field}
									header="Status"
									width="100%"
									valueList={[
										{
											value: 'true',
											label: 'Ativo',
										},
										{
											value: 'false',
											label: 'Inativo',
										},
									]}
									labelFirst={'Escolha um status'}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Controller
							control={control}
							name="email"
							render={({
								field: { onChange, onBlur, value },
							}) => (
								<CustomInput
									labelText="E-mail"
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										onChange,
										onBlur,
										value,
									}}
								/>
							)}
						/>
					</Grid>
					{open && (
						<Grid item xs={4}>
							<Controller
								control={control}
								name="phoneNumber"
								render={({
									field: { onChange, onBlur, value },
								}) => (
									<CustomInput
										labelText="Telefone"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											onChange,
											onBlur,
											value,
										}}
									/>
								)}
							/>
						</Grid>
					)}
					<Grid item xs={12} container>
						<Grid item xs={10} sm={9} md={4} lg={4} xl={4}></Grid>
						<Grid
							item
							xs={2}
							sm={3}
							md={4}
							lg={4}
							xl={4}
							style={{ textAlign: 'center', marginTop: '10px' }}
						>
							{!open && (
								<div>
									<MoreVert style={{ color: '#ccc' }} />
								</div>
							)}
							<IconButton
								className={classes.filterArrowButton}
								onClick={() => setOpen(!open)}
							>
								{open ? (
									<KeyboardArrowUp />
								) : (
									<KeyboardArrowDown />
								)}
							</IconButton>
						</Grid>
						<Grid
							item
							xs={10}
							sm={9}
							md={4}
							lg={4}
							xl={4}
							style={{
								textAlign: 'right',
								marginTop: !open ? '35px' : 0,
							}}
						>
							<Button
								size="sm"
								color="primary"
								type="submit"
								style={{ marginRight: '10px' }}
							>
								Pesquisar
							</Button>
							<Button
								size="sm"
								color="greenBorderButtonColor"
								onClick={() => handleClearFilter()}
							>
								Limpar
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Styled.SearchBox>
		</form>
	)
}

export default FilterBox
