import React from 'react';

import MaterialTable, { MTableCell, MTableToolbar, MTableHeader } from 'material-table';

import { Paper } from "@material-ui/core";

import { withStyles } from '@material-ui/core';

import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";

const styles = theme => ({
    tableTitle: {
        padding: 0,
        "& h6": {
            color: '#3C4858',
            fontSize: '18px !important',
            fontWeight: 800 + ' !important',
        },
        "& span": {
            color: primaryColor[0]
        }
    },
    headerStyle: {
        color: primaryColor[0],
        fontWeight: "700",
        fontSize: '1em',
        background: "#f2f2f2",
        "&:first-child": {
            borderRadius: '10px 0 0 0',
        },
        "&:last-child": {
            borderRadius: '0 10px 0 0',
        },
    }

});

const CustomMaterialTable = ({ data, search, columns, title, classes, ...props }) => {

    return (
        <MaterialTable
            components={{
                Container: props => <Paper {...props} elevation={0} />,
                Toolbar: props => <MTableToolbar {...props} classes={{ root: classes.tableTitle }} />,
                Cell: props => <MTableCell {...props} classes={{ root: classes.tableCell }} />,
                Header: props => <MTableHeader {...props} classes={{ header: classes.headerStyle }} />,
            }}
            localization={{
                toolbar: {
                    searchPlaceholder: "Buscar"
                },
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'linhas'
                },
                body: {
                    emptyDataSourceMessage: 'Não foram encontrados resultados'
                }
            }}
            title={title ? title : false}
            columns={columns}
            data={data}
            options={{
                search: search ? search : false
            }}
            {...props}
        />
    )
};

export default withStyles(styles)(CustomMaterialTable);
