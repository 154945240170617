import styled from 'styled-components'

export const TableContainer = styled.div`
	.MuiTableCell-root {
		> div {
			.MuiIconButton-root {
				background: transparent !important;
				box-shadow: none !important;
			}
			button {
				.MuiTouchRipple-root {
					display: none !important;
				}
				cursor: pointer;
				/* width: 40px; */
				box-shadow: none !important;
			}
		}
	}

	div {
		> div.MuiToolbar-root {
			display: ${({ toolbar }) => !toolbar && 'none'};
		}
	}

	.MuiTableHead-root {
		tr {
			th {
				background-color: #e9e9e9;
				color: #2e88d4;
				font-size: 16px;
				font-weight: bold;

				padding: 0px 16px;
				height: 40px;

				:first-child {
					border-top-left-radius: 6px;
				}

				:last-child {
					border-top-right-radius: 6px;
					span {
						svg {
							display: none;
						}
					}
				}
			}
		}
	}

	.MuiTableCell-body {
		> div {
			justify-content: center;
			align-items: center;
		}
	}

	.MuiTableBody-root {
		tr {
			color: #717171;

			td {
				font-size: 14px !important;
				height: 45px !important;
				padding: 0px 15px !important;

				> div p {
					margin: 0px;
					font-size: 13px;
					font-style: italic;
				}
			}
		}
	}

	.button-span {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
		border-radius: 5px;
		background-color: #0069c0;
	}

	.yellow {
		background-color: #e69a00;
	}

	.disabled {
		background-color: #d3d3d3;
	}

	.red {
		background-color: red;
	}

	.MuiIconButton-root {
		padding: 5px;
	}
`
