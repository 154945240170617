import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    lsDashData: [],
    lsChartsData: [],
    lsAnnouncementData: [],
    cardsDataFailed: false,
    chartDataFailed: false,
    announcementDataFailed: false
};

const fetchDashData = (state, action) => {
    return updateObject(state, {
        lsDashData: action.lsDashData
    });
};

const fetchChartsData = (state, action) => {
    return updateObject(state, {
        lsChartsData: action.lsChartsData,
    });
};

const fetchAnnouncementData = (state, action) => {
    return updateObject(state, {
        lsAnnouncementData: action.lsAnnouncementData
    });
};
//
// DATA FAIL
const fetchDashFail = (state, action) => {
    return updateObject(state, {
        dashDataFailed: true
    });
};
const fetchChartsFail = (state, action) => {
    return updateObject(state, {
        chartDataFailed: true
    });
};
const fetchAnnouncementFail = (state, action) => {
    return updateObject(state, {
        announcementDataFailed: true
    });
};

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DASHBOARD_DATA: return fetchDashData(state, action);
        case actionTypes.FETCH_CHARTS_DATA: return fetchChartsData(state, action);
        case actionTypes.FETCH_ANNOUNCEMENT_DATA: return fetchAnnouncementData(state, action);

        case actionTypes.FETCH_DASHBOARD_FAILED: return fetchDashFail(state, action);
        case actionTypes.FETCH_CHARTS_FAILED: return fetchChartsFail(state, action);
        case actionTypes.FETCH_ANNOUNCEMENT_FAILED: return fetchAnnouncementFail(state, action);

        default: return state;
    }
};

export default dashboard;