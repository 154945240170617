import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import logoVxp from "assets/img/logo_Vexpro.png";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";

function Footer({ ...props }) {
	const { classes, fluid, white } = props;
	var container = cx({
		[classes.container]: !fluid,
		[classes.containerFluid]: fluid,
		[classes.whiteColor]: white
	});
	return (
		<footer className={classes.footer}>
			<div className={container}>
				<div className={classes.right}>
					<a href="http://vexpro.com.br/" target="blank">
						<img src={logoVxp} alt="logoVxp" className={classes.img} />
					</a>
				</div>
			</div>
		</footer>
	);
}

Footer.propTypes = {
	classes: PropTypes.object.isRequired,
	fluid: PropTypes.bool,
	white: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
