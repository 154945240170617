import * as actionTypes from './actionsTypes';
import * as API from '../api/dashboard';

import * as Notification from './notification';
import { SuccessNotificationComponent, FailedNotificationComponent } from 'redux/react-notifications';
//----------------------------------------------------
export const GetDashboardData = () => {
    return dispatch => (
        API.GetDashboardData().then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para consultar dados da dashboard!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao consultar dados da dashboard!')))
            }
            dispatch(failed(error));
        })
    );

    function success(data) {
        return {
            type: actionTypes.FETCH_DASHBOARD_DATA,
            lsDashData: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.FETCH_DASHBOARD_FAILED,
            dashDataFailed: error
        };
    };

};
