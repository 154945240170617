import React, { useEffect, useState } from 'react'
import CustomDate from 'components/DatePicker'
import { Controller, useForm } from 'react-hook-form'
import {
	KeyboardArrowUp,
	KeyboardArrowDown,
	MoreVert,
} from '@material-ui/icons'
import { IconButton, Grid, TextField } from '@material-ui/core'
import * as Styled from './styles'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import CustomSelect from 'components/CustomSelect'
import * as Yup from 'yup'
import { add, isBefore, isValid, sub } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup'
import { uniqueId } from 'lodash'
import { Autocomplete } from '@material-ui/lab'

const schema = Yup.object().shape({
	sendDateGt: Yup.mixed().test(
		'date compare',
		'obrigatório DE menor que ATÉ',
		function (sendDateGt) {
			const { sendDateLt, period } = this.parent
			if (period === 2) {
				if (
					(isValid(sendDateGt) &&
						isValid(sendDateLt) &&
						isBefore(sendDateGt, sendDateLt)) ||
					(!sendDateGt && !sendDateLt)
				) {
					return true
				}
				return false
			}
			return true
		},
	),

	sendDateLt: Yup.mixed().test(
		'date compare',
		'obrigatório ATE maior que DE',
		function (sendDateLt) {
			const { sendDateGt, period } = this.parent
			if (period === 2) {
				if (
					(isValid(sendDateGt) &&
						isValid(sendDateLt) &&
						isBefore(sendDateGt, sendDateLt)) ||
					(!sendDateGt && !sendDateLt)
				) {
					return true
				}
				return false
			}
			return true
		},
	),
})

function FilterBox({ classes, filter, setFilter, users }) {
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
		watch,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			...filter,
			period: 2,
		},
	})

	const [open, setOpen] = useState(false)

	const submitForm = (values) => {
		setFilter({ ...values, filterRefresh: uniqueId('id') })
	}

	const handleClearFilter = () => {
		reset()
		setFilter({})
	}

	useEffect(() => {
		if (watch('period') === 0) {
			setValue(
				'sendDateGt',
				sub(new Date(), {
					days: 7,
				}),
			)
			setValue('sendDateLt', new Date())
		}
		if (watch('period') === 1) {
			setValue(
				'sendDateGt',
				sub(new Date(), {
					days: 30,
				}),
			)
			setValue('sendDateLt', new Date())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setValue, watch('period')])

	return (
		<form style={{ width: '100%' }} onSubmit={handleSubmit(submitForm)}>
			<Styled.SearchBox container className={classes.filterForm}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<h5>Opções de busca</h5>
				</Grid>
				<Grid
					item
					container
					xs={12}
					sm={12}
					md={12}
					lg={12}
					spacing={2}
				>
					<Grid item container xs={6} spacing={4}>
						<Grid item xs={4} style={{ alignSelf: 'center' }}>
							<Controller
								control={control}
								name="period"
								render={({ field }) => (
									<CustomSelect
										{...field}
										header="Período"
										width="100%"
										valueList={[
											{
												value: 0,
												label: 'Últimos 7 dias',
											},
											{
												value: 1,
												label: 'Últimos 30 dias',
											},
											{
												value: 2,
												label: 'Personalizado',
											},
										]}
										labelFirst={'Escolha um status'}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={8} container spacing={2}>
							<Grid item xs={6}>
								<Controller
									render={({ field }) => (
										<CustomDate
											{...field}
											label="De"
											disabled={watch('period') !== 2}
											// maxDate={
											// 	isValid(watch('sendDateLt'))
											// 		? sub(watch('sendDateLt'), {
											// 				days: 1,
											// 		  })
											// 		: null
											// }
											formatDate="dd/MM/yyyy"
											error={!!errors.sendDateGt}
											helperText={
												errors?.sendDateGt?.message
											}
										/>
									)}
									name="sendDateGt"
									control={control}
								/>
							</Grid>
							<Grid item xs={6}>
								<Controller
									render={({ field }) => (
										<CustomDate
											{...field}
											label="Até"
											// minDate={
											// 	isValid(watch('sendDateGt'))
											// 		? add(watch('sendDateGt'), {
											// 				days: 1,
											// 		  })
											// 		: null
											// }
											formatDate="dd/MM/yyyy"
											disabled={watch('period') !== 2}
											error={!!errors.sendDateLt}
											helperText={
												errors?.sendDateLt?.message
											}
										/>
									)}
									name="sendDateLt"
									control={control}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={3} style={{ alignSelf: 'center' }}>
						<Controller
							// onChange={([, data]) => data}
							name="userId"
							control={control}
							defaultValue={null}
							render={({
								field: { onChange, value, props, field },
							}) => (
								<Autocomplete
									{...field}
									{...props}
									// value={value}
									// disablePortal
									onChange={(event, item) => {
										onChange(item.value)
									}}
									options={users}
									getOptionLabel={(option) => option.label}
									getOptionSelected={(option, value) =>
										option.value === value.value
									}
									// renderOption={(option) => (
									// 	<span>{option.label}</span>
									// )}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="standard"
											label="Escolha o cliente"
										/>
									)}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={3}>
						<Controller
							control={control}
							name="title"
							render={({
								field: { onChange, onBlur, value },
							}) => (
								<CustomInput
									labelText="Título"
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										onChange,
										onBlur,
										value,
									}}
								/>
							)}
						/>
					</Grid>
					{open && (
						<Grid item container xs={12} spacing={3}>
							<Grid item xs={6}>
								<Controller
									control={control}
									name="summary"
									render={({
										field: { onChange, onBlur, value },
									}) => (
										<CustomInput
											labelText="Texto da mensagem"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange,
												onBlur,
												value,
											}}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={4} />
						</Grid>
					)}
					<Grid item xs={12} container>
						<Grid item sm={4} xs={0}></Grid>
						<Grid
							item
							sm={4}
							xs={12}
							style={{ textAlign: 'center', marginTop: '10px' }}
						>
							{!open && (
								<div>
									<MoreVert style={{ color: '#ccc' }} />
								</div>
							)}
							<IconButton
								className={classes.filterArrowButton}
								onClick={() => setOpen(!open)}
							>
								{open ? (
									<KeyboardArrowUp />
								) : (
									<KeyboardArrowDown />
								)}
							</IconButton>
						</Grid>

						<Grid
							item
							sm={4}
							xs={12}
							style={{
								textAlign: 'left',
								marginTop: !open ? '35px' : 0,
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								size="sm"
								color="primary"
								type="submit"
								style={{ marginRight: '10px' }}
							>
								Pesquisar
							</Button>
							<Button
								size="sm"
								color="greenBorderButtonColor"
								onClick={() => handleClearFilter()}
							>
								Limpar
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Styled.SearchBox>
		</form>
	)
}

export default FilterBox
