import axios from 'axios'

export const GET_STATION_MANAGER_ALL = '/api/StationManager/GetAllStation'
export const GET_STATION_MANAGER_BY_ID = '/api/StationManager/GetById'
export const GET_STATION_WITH_STATUS = '/api/StationManager/GetAllWithStatus'
export const UPSERT_STATION_MANAGER = '/api/StationManager/upsert'
export const GET_ALL_OWNERLESS = '/api/StationManager/GetAllOwnerless'
export const GET_ALL_STATION_BY_FILTER =
	'/api/StationManager/GetAllStationByFilter'

export const getStationManager = async (url, setLoading) => {
	try {
		const { data } = await axios.get(url)
		return data
	} catch (error) {}
}

export const getStationByIdWithStatus = async (url, body) => {
	try {
		const { data } = await axios.post(url, body)
		return data
	} catch (error) {}
}

export const getStationManagerById = async (id) => {
	try {
		const { data } = await axios.get(`${GET_STATION_MANAGER_BY_ID}/${id}`)
		return data
	} catch (error) {}
}

export const upsertStationManager = async (body) => {
	try {
		const { data } = await axios.post(`${UPSERT_STATION_MANAGER}`, body)
		return data
	} catch (error) {}
}

export const getAllOwnerless = async () => {
	try {
		const { data } = await axios.get(`${GET_ALL_OWNERLESS}`)
		return data
	} catch (error) {}
}

export const getStationsByFilter = async (url) => {
	try {
		const { data } = await axios.get(url)
		return data
	} catch (error) {}
}
