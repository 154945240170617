import CustomIcon from 'components/CustomIcon'
import CustomTable, {
	initialPagination,
	initialSort,
} from 'components/CustomTable'
import Alert from 'react-bootstrap-sweetalert'
import React, { useState } from 'react'
import { useEffect } from 'react'
import {
	deleteGroup,
	getStationGroupManager,
	GET_STATION_GROUP_MANAGER_ALL,
} from 'services/api/StationGroupManager/api'
import useSWR, { mutate as swrMutate } from 'swr'
import { goTo } from 'utils/utils'
import { useDispatch } from 'react-redux'

import { SweetAlert, HideAlert } from 'redux/actions'

function ListTable({ filter, classes, mutate }) {
	const [pagination, setPagination] = useState(initialPagination)
	const [loading, setLoading] = useState(true)
	const [sort, setSort] = useState(initialSort)
	const dispatch = useDispatch()

	const { data } = useSWR(
		`${GET_STATION_GROUP_MANAGER_ALL}${
			filter ? `?$filter=${filter}&` : '?'
		}$skip=${pagination.page}&$top=${pagination.pageSize}`,
		(url) => getStationGroupManager(url),
	)

	useEffect(() => {
		setPagination(initialPagination)
	}, [mutate])

	useEffect(() => {
		swrMutate(
			`${GET_STATION_GROUP_MANAGER_ALL}${
				filter ? `?$filter=${filter}&` : '?'
			}$skip=${pagination.page}&$top=${pagination.pageSize}`,
		)
	}, [filter, pagination.page, pagination.pageSize])

	useEffect(() => {
		setLoading(true)

		if (data?.items) {
			setPagination((prevState) => ({
				...prevState,
				recordCount: data.count,
			}))
			setLoading(false)
		}
	}, [data])

	function handleAlertDelete(rowData) {
		dispatch(
			SweetAlert(
				<Alert
					warning
					style={{
						display: 'block',
						marginTop: '-160px',
						width: '630px',
					}}
					title={'Atenção'}
					onConfirm={() => {
						dispatch(HideAlert())
						if (rowData.id) {
							deleteGroup(rowData.id).then(() => {
								window.location.reload()
							})
						}
						dispatch(HideAlert())
					}}
					onCancel={() => dispatch(HideAlert())}
					confirmBtnCssClass={classes.button + ' ' + classes.danger}
					cancelBtnCssClass={
						classes.button + ' ' + classes.greenBorderButtonColor
					}
					confirmBtnText={'Confirmar'}
					cancelBtnText={'Cancelar'}
					showCancel
				>
					<h3
						className={classes.cardTitle}
						style={{ fontWeight: 400 }}
					>
						<b>Deseja realmente excluir esse grupo?</b>
					</h3>
				</Alert>,
			),
		)
	}

	const actions = [
		{
			icon: () => (
				<span className="button-span">
					<CustomIcon
						className="icon-editar"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Editar',
			onClick: (_, rowData) => goTo(`group_stations/crud/${rowData.id}`),
		},
		{
			icon: () => (
				<span className="button-span" style={{ width: '23.1px' }}>
					<CustomIcon
						className="icon-estacao"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Visualizar agrupamento',
			onClick: (_, rowData) => goTo(`group_stations/link/${rowData.id}`),
		},
		{
			icon: () => (
				<span className="button-span" style={{ width: '23.1px' }}>
					<CustomIcon
						className="icon-ico_excluir"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Excluir grupo',
			onClick: (_, rowData) => {
				handleAlertDelete(rowData)
			},
		},
	]

	const columns = [
		{
			title: 'Data criação',
			type: 'datetime',
			field: 'createdAt',
		},
		{
			title: 'Agrupamento',
			type: 'string',
			field: 'name',
		},
	]

	return (
		<CustomTable
			actions={actions}
			columns={columns}
			pagination={pagination}
			setPagination={setPagination}
			data={data?.items}
			sort={sort}
			setSort={setSort}
			loading={loading}
		/>
	)
}

export default ListTable
