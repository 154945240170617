import React, { useEffect } from "react";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CommonPage from "components/Pages/CommonPage";
import DocumentTitle from "components/Pages/DocumentTitle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { useTranslation } from "react-i18next";
import { validation } from "./validation";
import Alert from "react-bootstrap-sweetalert";

import { Grid, withStyles } from "@material-ui/core";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import { Controller, useForm } from "react-hook-form";
import { CreateConfigBox } from "./styles";
import CustomCheckBox from "components/CustomCheckBox";
import CustomInput from "components/CustomInput/CustomInput";
import Section from "components/Section";
import { goTo } from "utils/utils";
import { HideAlert, HideLoader, ShowLoader, SweetAlert } from "redux/actions";
import Loader from "components/Loader/Loader";

import { yupResolver } from "@hookform/resolvers/yup";
import {
	getGroupManagerById,
	postGroupManager,
} from "services/api/StationGroupManager/api";
import { useParams } from "react-router-dom";
import { history } from "utils";
import { useDispatch } from "react-redux";
import { showNotification } from "redux/actions/notification";
import { SuccessNotificationComponent } from "redux/react-notifications";
import CrudLink from "../CrudLink";

const breadcrumbItems = [{ name: "Home" }, { name: "Agrupamento", last: true }];

function CrudStationsGroup({ classes }) {
	const { t } = useTranslation();
	const { id } = useParams();
	const dispatch = useDispatch();

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty },
		reset,
	} = useForm({
		defaultValues: {
			name: "",
			description: "",
			isSharedGroup: false,
		},
		resolver: yupResolver(validation),
	});

	useEffect(() => {
		async function getById() {
			try {
				dispatch(ShowLoader(<Loader />));

				const data = await getGroupManagerById(id);

				if (data) {
					return reset({ ...data });
				}

				history.goBack();
			} catch (error) {
			} finally {
				dispatch(HideLoader(<Loader />));
			}
		}

		if (id) getById();
	}, [dispatch, id, reset]);

	async function sendForm(value) {
		try {
			dispatch(ShowLoader(<Loader />));

			let data;

			if (id) {
				data = await postGroupManager({ id, ...value });
			} else {
				data = await postGroupManager({ ...value });
			}

			if (data) {
				if (!id) {
					goTo(`group_stations/crud/${data?.id}`);
				}

				return dispatch(
					showNotification(
						SuccessNotificationComponent("Grupo salvo com sucesso!")
					)
				);
			}
		} catch (error) {
		} finally {
			dispatch(HideLoader(<Loader />));
			reset({ ...value });
		}
	}

	function handleCancel() {
		return isDirty
			? dispatch(
					SweetAlert(
						<Alert
							warning
							style={{ display: "block" }}
							title={""}
							onConfirm={() => {
								dispatch(HideAlert());
								goTo("group_stations");
							}}
							onCancel={() => dispatch(HideAlert())}
							confirmBtnCssClass={
								classes.button + " " + classes.greenButtonColor
							}
							cancelBtnCssClass={
								classes.button + " " + classes.danger
							}
							confirmBtnText={t("BUTTON_YES")}
							cancelBtnText={t("BUTTON_NO")}
							showCancel
						>
							{t("Sair sem salvar?")}
						</Alert>
					)
			  )
			: goTo("group_stations");
	}

	return (
		<CommonPage>
			<DocumentTitle pageTitle={t("MENU_STATIONS_GROUP")} />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<Breadcrumbs items={breadcrumbItems} />
					<Card>
						<form
							onSubmit={handleSubmit(sendForm)}
							style={{ width: "100%" }}
							autoComplete="off"
						>
							<CardBody>
								<CreateConfigBox item xs={12} container>
									<Grid item xs={4}>
										<Section
											title={
												id
													? "EDITAR AGRUPAMENTO"
													: "NOVO AGRUPAMENTO"
											}
											icon="icon-estacao"
										/>
									</Grid>
									<Grid
										item
										xs={8}
										container
										justify="flex-end"
										className="button-head"
									>
										<Button
											size="sm"
											color="primary"
											type="submit"
											disabled={!isDirty}
											style={{
												marginRight: 10,
											}}
										>
											<i
												style={{
													fontSize: 14,
													padding: "2px 0",
												}}
												className={"icon-salvar"}
											/>
											Salvar
										</Button>
										<Button
											size="sm"
											color="greenBorderButtonColor"
											onClick={() => handleCancel()}
										>
											<i
												style={{
													fontSize: 14,
													padding: "2px 0",
												}}
												className={
													isDirty
														? "icon-cancelar"
														: "icon-voltar"
												}
											/>
											{isDirty ? "Cancelar" : "Voltar"}
										</Button>
									</Grid>
									<Grid
										container
										xs={12}
										className="header-form"
									>
										<Grid
											container
											xs={12}
											style={{ marginLeft: "30px" }}
										>
											<Grid
												item
												xs={4}
												style={{ marginRight: "20px" }}
											>
												<Controller
													name="name"
													control={control}
													render={({
														field: {
															onChange,
															onBlur,
															value,
														},
													}) => (
														<CustomInput
															error={
																!!errors?.name
															}
															helpText={
																errors?.name
																	?.message
															}
															labelText="Nome do grupo*"
															formControlProps={{
																fullWidth: true,
															}}
															inputProps={{
																onChange,
																onBlur,
																value,
															}}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={6}>
												<Controller
													name="description"
													control={control}
													render={({
														field: {
															onChange,
															onBlur,
															value,
														},
													}) => (
														<CustomInput
															error={
																!!errors?.description
															}
															helpText={
																errors
																	?.description
																	?.message
															}
															labelText="Descrição*"
															formControlProps={{
																fullWidth: true,
															}}
															inputProps={{
																onChange,
																onBlur,
																value,
															}}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={6}>
												<Controller
													render={({
														field: {
															onChange,
															onBlur,
															value,
														},
													}) => (
														<CustomCheckBox
															label="Grupo Compartilhado*"
															error={
																!!errors.isSharedGroup
															}
															helperText={
																errors
																	?.isSharedGroup
																	?.message
															}
															checked={value}
															inputProps={{
																onChange,
																onBlur,
																value,
															}}
														/>
													)}
													name="isSharedGroup"
													control={control}
												/>
											</Grid>
										</Grid>
									</Grid>
									<CrudLink />
								</CreateConfigBox>
							</CardBody>
						</form>
					</Card>
				</GridItem>
			</GridContainer>
		</CommonPage>
	);
}

export default withStyles(extendedFormsStyle)(
	withStyles(sweetAlertStyle)(CrudStationsGroup)
);
