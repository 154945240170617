import {
  container,
  cardTitle,
  whiteColor,
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const loginPageStyle = theme => ({
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  container: {
    ...container,
    zIndex: "4",
    "@media (max-width: 1200px)": {
      "& > div": {
        marginLeft: "-7px"
      }
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor
  },
  textCenter: {
    textAlign: "center"
  },
  justifyContentCenter: {
    margin: theme.spacing(1),
    position: 'relative',
    justifyContent: "center !important"
  },
  buttonProgress: {
    color: whiteColor,
    position: 'absolute',
    top: '50%',
    left: '80%',
    marginTop: -12,
    marginLeft: -12,
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    marginBottom: "0"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  img: {
    width: "100%"
  }
});

export default loginPageStyle;
