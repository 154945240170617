import * as actionTypes from 'redux/actions/actionsTypes';
import * as API from 'redux/api/customer/customer';
import * as Loader from '../loader';
import * as Notification from '../notification';
import { SuccessNotificationComponent, FailedNotificationComponent } from 'redux/react-notifications';
let user = JSON.parse(localStorage.getItem('user'));

//----------------------------------------------------

export const GetCustomers = (_filter, _page, _itemsPerPage) => {
    return dispatch => {
        API.GetCustomersData(_filter, _page, _itemsPerPage).then(response => {
            dispatch(fetchCustomers(response));
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para consultar clientes!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao consultar clientes!')))
            }
            dispatch(fetchCustomersFailed(error));
            dispatch(Loader.hideLoader());
        })
    }

    function fetchCustomers(data) {
        return {
            type: actionTypes.FETCH_CUSTOMERS_DATA,
            lsCustomersData: data
        };
    };

    function fetchCustomersFailed(error) {
        return {
            type: actionTypes.FETCH_CUSTOMERS_FAILED,
            customersDataFailed: error
        };
    };
};

export const GetCustomerByIdData = (id) => {
    return dispatch => (
        API.GetCustomerById(id).then(response => {
            dispatch(fetchCustomerById(response));
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para consultar clientes!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao consultar o cliente!')))
            }
            dispatch(fetchCustomerByIdFailed(error));
        })
    );

    function fetchCustomerById(data) {
        return {
            type: actionTypes.FETCH_CUSTOMER_BY_ID,
            customerData: data
        };
    };

    function fetchCustomerByIdFailed(error) {
        return {
            type: actionTypes.FETCH_CUSTOMER_BY_ID_FAILED,
            customerDataFailed: error
        };
    };
};

export const PostCustomer = (obj) => {
    return dispatch => (
        API.PostCustomer(obj).then(response => {
            dispatch(postCustomerData(response));
            dispatch(Loader.hideLoader());
            dispatch(Notification.showNotification(SuccessNotificationComponent('Dados do cliente registrado com sucesso!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para criar/editar clientes!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao criar/editar o cliente!')))
            }
            dispatch(postCustomerDataFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function postCustomerData(data) {
        return {
            type: actionTypes.POST_CUSTOMER_DATA,
            postCustomerData: data
        }
    }

    function postCustomerDataFailed(error) {
        return {
            type: actionTypes.POST_CUSTOMER_DATA_FAILED,
            postCustomerDataFailed: error
        };
    }
};

export const CreateCustomer = (obj) => {
    return dispatch => (
        API.CreateCustomer(obj).then(response => {
            dispatch(createCustomerData(response));
            dispatch(Loader.hideLoader());
            dispatch(Notification.showNotification(SuccessNotificationComponent('Cliente cadastrado com sucesso!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para criar clientes!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao criar o cliente!')))
            }
            dispatch(createCustomerDataFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function createCustomerData(data) {
        return {
            type: actionTypes.CREATE_CUSTOMER_DATA,
            createCustomerData: data
        }
    }

    function createCustomerDataFailed(error) {
        return {
            type: actionTypes.CREATE_CUSTOMER_DATA_FAILED,
            createCustomerDataFailed: error
        };
    }
};

// POST LINKED STATION TO CUSTOMER
export const UpdateAttachStation = (body) => {
    return dispatch => (
        // 
        API.UpdateAttachStation(body).then(response => {
            dispatch(success(response));
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para alterar dados de clientes!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao atualizar dados do cliente!')))
            }
            dispatch(failed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function success(data) {
        return {
            type: actionTypes.FETCH_ATTACH_STATION,
            updateAttachedStation: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.FETCH_ATTACH_STATION_FAILED,
            updateLinkedStationFailed: error
        };
    };
};


export const DeleteCustomer = (id) => {
    return dispatch => (
        API.DeleteCustomer(id).then(response => {
            dispatch(deleteCustomerData(response));
            dispatch(Loader.hideLoader());
            dispatch(Notification.showNotification(SuccessNotificationComponent('Cliente removido da base de dados!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para apagar clientes!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao apagar o cliente!')))
            }
            dispatch(deleteCustomerDataFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function deleteCustomerData(data) {
        return {
            type: actionTypes.DELETE_CUSTOMER_DATA,
            deleteData: data
        }
    }

    function deleteCustomerDataFailed(error) {
        return {
            type: actionTypes.DELETE_CUSTOMER_DATA_FAILED,
            deleteDataFailed: error
        };
    }

};

export const ResetCustomerPassword = (id) => {
    return dispatch => (
        API.ResetCustomerPassword(id).then(response => {
            dispatch(resetPasswordData(response));
            dispatch(Loader.hideLoader());
            dispatch(Notification.showNotification(SuccessNotificationComponent('Senha nova enviada ao e-mail do cliente!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para alterar clientes!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao alterar a senha do cliente!')))
            }
            dispatch(resetPasswordDataFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function resetPasswordData(data) {
        return {
            type: actionTypes.POST_CUSTOMER_NEW_PASSWORD,
            resetPsswd: data
        }
    }

    function resetPasswordDataFailed(error) {
        return {
            type: actionTypes.POST_CUSTOMER_NEW_PASSWORD_FAILED,
            resetPsswdFailed: error
        };
    }

};