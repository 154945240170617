import React from 'react'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

// react plugin for tab title
import DocumentTitle from 'components/Pages/DocumentTitle'
// react plugin for creating stacked bar chart
import Chart from 'react-apexcharts'

// react plugin for skeleton
import Skeleton from 'react-loading-skeleton'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import SatelliteMap from 'components/Maps/Maps.jsx'
import NoContentAlert from 'components/NoContentAlert/NoContentAlert.jsx'

// Utils
import compose from 'utils/compose'
import { averageGeolocation } from 'utils/geoLoc'

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'
import style from './dashboard.module.scss'

// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { GetDashboardData, ShowLoader, HideLoader } from '../../redux/actions'

// MOMENT JS
import * as moment from 'moment'

/**
 *  @author Ayrton Gomes
 *  Dashboard component with charts and cards
 */
class Dash extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dashGraphUsersData: null,
			dashVersionGraph: null,

			totalClients: 0,
			activeClients: 0,

			totalStations: 0,
			activeStations: 0,

			totalFirmwareUpdated: 0,
			totalFirmwarePending: 0,

			stations: [],
			center: [],
		}
		moment.locale(localStorage.getItem('i18nextLng').toLowerCase())
	}

	componentDidMount() {
		this.props.GetDashboardData()
		this.timer = setInterval(() => this.props.GetDashboardData(), 20000)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.lsDashData !== this.props.lsDashData) {
			let center = averageGeolocation(
				this.props.lsDashData.stations.stationsLocationInfo,
			)
			this.setState({ center: [center.lat, center.lng] })
			this.getCardsValue(this.props.lsDashData)
			this.setUserChartData(this.props.lsDashData.customers, 12)
			this.setStationsChartData(this.props.lsDashData.stations, 6)
			this.setState({
				stations: this.props.lsDashData.stations.stationsLocationInfo,
			})
		}
		if (prevProps.cardsDataFailed !== this.props.cardsDataFailed) {
		}
		if (prevProps.chartDataFailed !== this.props.chartDataFailed) {
		}
	}

	componentWillUnmount() {
		clearInterval(this.timer)
	}

	getCardsValue = (data) => {
		this.setState({
			totalClients: data.customers.general.registeredCount,
			activeClients: data.customers.general.activatedCount,
			totalStations: data.stations.general.registeredCount,
			activeStations: data.stations.general.activatedCount,
			totalFirmwareUpdated: data.firmwares.general.activatedCount,
			totalFirmwarePending: data.firmwares.general.inactivatedCount,
		})
	}
	/**
	 * 	@author Ayrton Gomes
	 *  Function to set config of react-apexcharts users graph
	 *  @param data is the dataByMonthUsers
	 *  @param qtyMonth is the months quantity that graph must show
	 */
	setUserChartData = (data, qtyMonth) => {
		let labels = data.customersPerMonth
			.slice(0, qtyMonth)
			.map((x) => moment(x.condition).format('MMM'))
			.reverse()
		let activated = data.customersPerMonth
			.slice(0, qtyMonth)
			.map((x) => x.activatedCount)
			.reverse()
		let inactivated = data.customersPerMonth
			.slice(0, qtyMonth)
			.map((x) => x.inactivatedCount)
			.reverse()

		const options = {
			chart: {
				zoom: {
					enabled: false,
				},
				toolbar: {
					show: false,
				},
			},
			colors: ['#0069c0', '#8D918B'],
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: [1.8, 1.8],
				curve: 'straight',
			},
			markers: {
				size: 4,
				colors: ['#fff'],
				strokeColor: ['#0069c0', '#8D918B'],
				strokeWidth: 1.8,
				hover: {
					sizeOffset: 2,
				},
			},
			xaxis: {
				categories: labels,
			},
			grid: {
				xaxis: {
					lines: {
						show: true,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
				borderColor: '#ccc',
			},
			legend: {
				itemMargin: {
					horizontal: 20,
				},
			},
		}
		const series = [
			{
				name: 'Ativos',
				type: 'line',
				data: activated,
			},
			{
				name: 'Inativos',
				type: 'line',
				data: inactivated,
			},
		]

		this.setState({ dashGraphUsersData: { options, series } })
	}
	/**
	 * 	@author Ayrton Gomes
	 *  Function to set config of react-apexcharts users graph
	 *  @param data is an array of access per moth redux data returned from api call
	 *  @param qtyMonth is the months quantity that graph must show
	 */
	setStationsChartData = (data) => {
		let inactivatedCount = data.general.inactivatedCount
		let activatedCount = data.general.activatedCount

		const options = {
			dataLabels: {
				enabled: false,
			},
			labels: ['Estações ativas', 'Estações inativas'],
			colors: ['#1F78B4', '#A6CEE3'],
			plotOptions: {
				pie: {
					donut: {
						size: '80%',
						background: 'transparent',
						labels: {
							// These are the inner labels appearing inside donut
							show: true,
							name: {
								show: true,
								fontSize: '16px',
								offsetY: -10,
							},
							value: {
								show: true,
								fontSize: '20px',
								offsetY: 10,
								formatter: function (val) {
									return val
								},
							},
							total: {
								show: true,
								label: 'Estações ativas',
								color: '#1F78B4',
								formatter: function (w) {
									let total = _.sum(w.globals.seriesTotals)
									let percentage = Math.floor(
										(w.globals.seriesTotals[0] / total) *
											100,
									)
									return percentage + ' %'
								},
							},
						},
					},
				},
			},
			legend: {
				position: 'bottom',
				offsetY: 0,
				itemMargin: {
					horizontal: 20,
				},
			},
		}

		const series = [activatedCount, inactivatedCount]

		this.setState({ dashStationsChart: { options, series } })
	}

	render() {
		const { t, classes } = this.props
		const {
			totalClients,
			activeClients,
			totalStations,
			activeStations,
			totalFirmwareUpdated,
			totalFirmwarePending,
			dashStationsChart,
			dashGraphUsersData,
		} = this.state

		let graphLoaderSkeleton = (
			<div>
				<Skeleton height={300} count={1} />
				<p>
					<Skeleton height={38} width={150} count={2} />
				</p>
			</div>
		)

		return (
			<div>
				<DocumentTitle pageTitle={t('MENU_DASHBOARD')} />
				<GridContainer style={{ paddingTop: '20px' }}>
					{/* card 1 */}
					{/*<GridItem xs={12} sm={6} md={4} lg={4}>*/}
					<GridItem xs={12} sm={6} md={6} lg={4}>
						<Card>
							<CardHeader color="warning" stats icon>
								<CardIcon color="warning">
									<Icon className={'icon-ico_cliente'}></Icon>
								</CardIcon>
								<p className={style.cardHeadTitle}>
									{'CLIENTES'}
								</p>
							</CardHeader>
							<CardBody>
								<p className={classes.cardCategory}>
									TOTAL DE CLIENTES
								</p>
								<h3 className={classes.cardTitle}>
									{totalClients}
								</h3>
								<hr />
								<p className={classes.cardCategory}>
									CLIENTES ATIVOS
								</p>
								<h3 className={classes.cardTitle}>
									{activeClients}
								</h3>
							</CardBody>
						</Card>
					</GridItem>
					{/* card 2 */}
					{/*<GridItem xs={12} sm={6} md={4} lg={4}>*/}
					<GridItem xs={12} sm={6} md={6} lg={4}>
						<Card>
							<CardHeader color="green" stats icon>
								<CardIcon color="green">
									<Icon className={'icon-estacao'}></Icon>
								</CardIcon>
								<p className={style.cardHeadTitle}>
									{'ESTAÇÕES'}
								</p>
							</CardHeader>
							<CardBody>
								<p className={classes.cardCategory}>
									TOTAL DE ESTAÇÕES
								</p>
								<h3 className={classes.cardTitle}>
									{totalStations}
								</h3>
								<hr />
								<p className={classes.cardCategory}>
									ESTAÇÕES EM ATIVIDADE
								</p>
								<h3 className={classes.cardTitle}>
									{activeStations}
								</h3>
							</CardBody>
						</Card>
					</GridItem>
					{/* card 3 */}
					{/* Descomentar no futuro */}
					<GridItem xs={12} sm={6} md={4} lg={4}>
						<Card>
							<CardHeader color="primary" stats icon>
								<CardIcon color="primary">
									<Icon
										className={'icon-ico_firmware'}
									></Icon>
								</CardIcon>
								<p className={style.cardHeadTitle}>
									{'FIRMWARE'}
								</p>
							</CardHeader>
							<CardBody>
								<p className={classes.cardCategory}>
									TOTAL DE ATUALIZAÇÕES
								</p>
								<h3 className={classes.cardTitle}>
									{totalFirmwareUpdated}
								</h3>
								<hr />
								<p className={classes.cardCategory}>
									TOTAL PENDENTE
								</p>
								<h3 className={classes.cardTitle}>
									{totalFirmwarePending}
								</h3>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				{/* GRAPHS */}
				{/* <GridContainer> */}
				{/* GRAPH 3 */}
				{/* <GridItem xs={12} sm={12} md={6}>
						<Card>
							<CardHeader color="rose" stats icon>
								<CardIcon color="rose">
									<Icon
										className={"icon-ico_cadastro"}
									></Icon>
								</CardIcon>
								<div className={style.cardHeadTitle}>
									EVOLUÇÃO CADASTRO DE CLIENTES
								</div>
							</CardHeader>
							<CardBody>
								{dashGraphUsersData ? (
									dashGraphUsersData.series.length > 0 ? (
										<div style={{ height: "380px" }}>
											<Chart
												options={
													dashGraphUsersData.options
												}
												series={
													dashGraphUsersData.series
												}
												type="line"
												width={"98%"}
												height={405}
											/>
										</div>
									) : (
										<NoContentAlert alertText="Sem informações para exibir nesse momento!" />
									)
								) : (
									graphLoaderSkeleton
								)}
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} sm={12} md={6}>
						<Card>
							<CardHeader color="green" stats icon>
								<CardIcon color="green">
									<Icon className={"icon-estacao"}></Icon>
								</CardIcon>
								<div className={style.cardHeadTitle}>
									ESTAÇÕES ATIVAS
								</div>
							</CardHeader>
							<CardBody>
								{dashStationsChart ? (
									dashStationsChart.series.length > 0 ? (
										<div style={{ height: "380px" }}>
											<Chart
												options={
													dashStationsChart.options
												}
												series={
													dashStationsChart.series
												}
												type="donut"
												height={380}
											/>
										</div>
									) : (
										<NoContentAlert alertText="Sem informações para exibir nesse momento!" />
									)
								) : (
									graphLoaderSkeleton
								)}
							</CardBody>
						</Card>
					</GridItem> */}
				{/* </GridContainer> */}
				{/* MAP */}
				<GridContainer>
					{/* MAP 3 */}
					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardHeader color="info" stats icon>
								<CardIcon color="info">
									<Icon className="icon-map"></Icon>
								</CardIcon>
								<div className={style.cardHeadTitle}>
									{'LOCALIZAÇÃO DAS ESTAÇÕES'}
								</div>
							</CardHeader>
							<CardBody>
								<SatelliteMap
									center={this.state.center}
									stations={this.state.stations}
								/>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

Dash.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	return {
		lsDashData: state.dashboard.lsDashData,
		dashDataFailed: state.dashboard.cardsDataFailed,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ GetDashboardData, ShowLoader, HideLoader }, dispatch)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(dashboardStyle),
	withTranslation(),
)(Dash)
