import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react.jsx";
import { DatePicker, TimePicker } from "./styles";
import { WatchLater } from "@material-ui/icons";

import { createMuiTheme, TextField, ThemeProvider } from "@material-ui/core";

const TextFieldComponent = (props) => {
  // return <TextField {...props} disabled={!props.textDisable} />;
  return <TextField {...props} />;
};

function CustomDate({
  formatDate,
  name,
  onChange,
  styled,
  value,
  error,
  helperText,
  touched,
  label,
  clearable,
  placeholder,
  ...props
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <DatePicker
        {...props}
        placeholder={placeholder ?? "dd/mm/aaaa"}
        clearable={clearable}
        fullWidth
        inputVariant="standard"
        ampm={false}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        label={label}
        showTodayButton
        autoOk
        autoFill={false}
        name={name}
        format={formatDate}
        invalidDateMessage="Data inválida"
        maxDateMessage="Data futura inválida"
        minDateMessage="Data passada inválida"
        todayLabel="Hoje"
        clearLabel="Limpar"
        cancelLabel="Cancelar"
        keyboardIcon={
          <i
            className="icon-calendario"
            style={{
              color: "#2e88d4",
              fontSize: "18px",
            }}
          />
        }
        TextFieldComponent={TextFieldComponent}
      />
    </MuiPickersUtilsProvider>
  );
}

export function CustomTime({
  formatDate,
  name,
  onChange,
  styled,
  value,
  error,
  helperText,
  touched,
  label,
  clearable,
  ...props
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <TimePicker
        clearable={clearable}
        fullWidth
        variant="inline"
        disableToolbar
        inputVariant="standard"
        ampm={false}
        mask="__:__"
        error={error}
        helperText={helperText}
        label={label}
        showTodayButton
        autoOk
        format={formatDate}
        invalidDateMessage="Data inválida"
        maxDateMessage="Data futura inválida"
        minDateMessage="Data passada inválida"
        todayLabel="Hoje"
        clearLabel="Limpar"
        cancelLabel="Cancelar"
        {...props}
        keyboardIcon={
          <WatchLater
            style={{
              color: germiniDefaultColors[0],
            }}
          ></WatchLater>
        }
        InputProps={{
          disableUnderline: true,
        }}
        TextFieldComponent={TextFieldComponent}
      />
    </MuiPickersUtilsProvider>
  );
}

export default CustomDate;
