import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import SendTokenForm from "./components/SendTokenForm";
import ValidateTokenForm from "./components/ValidateTokenForm";
import NewPasswordForm from "./components/NewPasswordForm";
import authStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx";
import Footer from "components/Footer/Footer.jsx";
import login from "assets/img/bg_login.jpg";

// Utils
import compose from "utils/compose";

class ForgetPassword extends React.Component {

    render() {
        const { classes, notification, loading } = this.props;
        return (
            <div className={classes.wrapper} ref="wrapper">
                {notification}
                {loading}
                <div className={classes.fullPage} style={{ backgroundImage: "url(" + login + ")" }}>
                    <Route
                        path="/forgetpassword/sendtoken"
                        component={SendTokenForm}
                    />
                    <Route
                        path="/forgetpassword/validatetoken"
                        component={ValidateTokenForm}
                    />
                    <Route
                        path="/forgetpassword/newpassword"
                        component={NewPasswordForm}
                    />


                    <Footer white />
                </div>
            </div>
        );
    }
}

ForgetPassword.propTypes = {
    classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const { notification, loader } = state;
    return {
        loading: loader.loading,
        notification: notification.notification,
    };
}

export default compose(
    connect(mapStateToProps, null),
    withStyles(authStyle)
)(ForgetPassword);