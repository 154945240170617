import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";

import { connect } from 'react-redux';

//@material-ui components
import { withStyles } from "@material-ui/core/styles";

// layout components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CommonPage from "components/Pages/CommonPage";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';
import Section from 'components/Section';
import DocumentTitle from "components/Pages/DocumentTitle";

import eProdutorLogo from 'assets/img/logo_eProdutor.svg';

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

//utils
import compose from "utils/compose";
import { isAllowed } from "utils/auth";

class Integration extends Component {

	constructor(props) {
		super(props);
		this.state = {
			breadcrumbItems: [
				{ name: "Home" },
				{ name: "Configurações" },
				{ name: "Integração", last: true },
			],
			hasSAPPermission: false,
			hasEProdPermission: false
		}
	}

	componentDidMount() {
		let user = this.props.user;
		if (user) {
			if (isAllowed(user, ['beWeather.integration.sap'])) {
				this.setState({ hasSAPPermission: true })
			}
			if (isAllowed(user, ['beWeather.integration.eprod'])) {
				this.setState({ hasEProdPermission: true })
			}
		} else {
			this.props.history.push('/404')
		}
	}

	render() {
		const { t, classes } = this.props;
		const { breadcrumbItems, hasEProdPermission, hasSAPPermission } = this.state;
		return (
			<CommonPage>
				<DocumentTitle pageTitle={t("MENU_INTEGRATION")} />
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<Breadcrumbs items={breadcrumbItems} />

						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12} sm={12} md={12} lg={12}>
										<Section title="PARCEIROS DE INTEGRAÇÃO" icon="icon-ico_integracao" />
									</GridItem>
									<GridItem xs={12} sm={12} md={6} lg={6} xl={4} style={{ display: 'flex' }}>
										<GridContainer>
											{hasSAPPermission &&
												<GridItem xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'center' }}>
													<div style={{ border: '1px solid #CCC', padding: '20px 0px', borderRadius: '8px', width: '220px', margin: 'auto', verticalAlign: 'middle' }}>
													</div>
													<h3 className={classes.cardTitle} style={{ marginTop: '10px' }}>Cloud Platform IoT</h3>
												</GridItem>

											}
											{hasEProdPermission &&
												<GridItem xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'center' }}>
													<div style={{ border: '1px solid #CCC', padding: '20px 0px', borderRadius: '8px', width: '220px', margin: 'auto' }}>
														<img src={eProdutorLogo} alt="Logo eProdutor" width='200px' height='80px'></img>
													</div>
													<h3 className={classes.cardTitle} style={{ marginTop: '10px' }}>ERP</h3>
												</GridItem>
											}
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</CommonPage>
		);
	}
}

Integration.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
	return {
		user: state.authentication.user
	};
}

export default compose(
	connect(mapStateToProps, null),
	withStyles(sweetAlertStyle),
	withTranslation(),
)(Integration);
