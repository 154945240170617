import { authentication } from "./authenticate";

import dashboard from "./dashboard";
import config from "./systemConfig";
import user from './user/user';
import profile from './account';
import customer from './customer/customer';
import station from './station/station';
import firmware from './firmware/firmware';
import group from './group/group';

// GERAL
import images from "./images";
import sweetAlert from "./sweetAlert";
import loader from "./loader";
import notification from "./notification";

import { users } from "./user";
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  users,
  authentication,
  notification,
  sweetAlert,
  loader,
  dashboard,
  user,
  group,
  profile,
  firmware,
  station,
  customer,
  config,
  images,
});

export default createRootReducer;