import Dash from "pages/Dashboard";
import Customers from "pages/Customers";
import CrudCustomers from "pages/Customers/components/CrudCustomers";
import Stations from "pages/Stations";
import StationsGroup from "pages/StationsGroup";
import CrudStationsGroup from "pages/StationsGroup/components/CrudStationsGroup";
import LinkStation from "pages/StationsGroup/components/Stations";
import CustomerRegisterForm from "pages/Customers/CustomerRegister";
import StationDetails from "pages/Stations/components/StationView";
import StationExport from "pages/Stations/components/ExportStation";
import CrudStation from "pages/Stations/components/CrudStation";
import Users from "pages/ControlAccess/Users/Users";
import Groups from "pages/ControlAccess/Groups/Groups";

import Firmware from "pages/Settings/Firmware";
import Integration from "pages/Settings/Integration";

import MyProfileView from "pages/Profile/MyProfileView";
import EditProfile from "pages/Profile/EditProfile/EditProfile";
import UserForm from "pages/ControlAccess/Users/Forms/RegisterForm";
import GroupForm from "pages/ControlAccess/Groups/Forms/RegisterForm";
import UserGroupForm from "pages/ControlAccess/Users/Forms/GroupAssociation/UserGroupForm";
import UserDetails from "pages/ControlAccess/Users/Details/UserView";
import GroupDetail from "pages/ControlAccess/Groups/Details/GroupDetail";
import Message from "pages/Settings/Message";
import CrudMessage from "pages/Settings/Message/components/CrudMessage";
import Contact from "pages/Settings/Contact";

import Reference from "pages/Reference";

export const dashRoutes = [
	{
		path: "/dashboard",
		name: "MENU_DASHBOARD",
		sidebar: true,
		icon: "icon-ico_dashboard",
		layout: "/admin",
		claims: ["beWeather.dashboard.read"],
		component: Dash,
	},
	{
		path: "/customer",
		name: "MENU_CLIENTS",
		sidebar: true,
		exact: true,
		icon: "icon-ico_cliente",
		layout: "/admin",
		claims: ["beWeather.customer.read", "beWeather.customer.write"],
		component: Customers,
	},
	{
		path: "/customer/crud",
		name: "MENU_CLIENTS",
		sidebar: false,
		exact: true,
		icon: "icon-ico_cliente",
		layout: "/admin",
		claims: ["beWeather.customer.read", "beWeather.customer.write"],
		component: CrudCustomers,
	},
	{
		path: "/customer/crud/:id",
		name: "MENU_CLIENTS",
		sidebar: false,
		exact: true,
		icon: "icon-ico_cliente",
		layout: "/admin",
		claims: ["beWeather.customer.read", "beWeather.customer.write"],
		component: CrudCustomers,
	},
	{
		path: "/station/view/:id",
		claims: ["beWeather.station.read", "beWeather.station.write"],
		sidebar: false,
		exact: true,
		layout: "/admin",
		component: StationDetails,
	},
	{
		path: "/station/export/:id",
		claims: ["beWeather.firmware.read", "beWeather.firmware.write"],
		sidebar: false,
		exact: true,
		layout: "/admin",
		component: StationExport,
	},
	{
		path: "/station",
		name: "MENU_STATIONS",
		sidebar: true,
		exact: true,
		icon: "icon-estacao",
		layout: "/admin",
		claims: ["beWeather.station.read", "beWeather.station.write"],
		component: Stations,
	},
	{
		path: "/station/crud",
		name: "MENU_STATIONS",
		sidebar: false,
		exact: true,
		icon: "icon-estacao",
		layout: "/admin",
		claims: ["beWeather.station.read", "beWeather.station.write"],
		component: CrudStation,
	},
	{
		path: "/station/crud/:id",
		name: "MENU_STATIONS",
		sidebar: false,
		exact: true,
		icon: "icon-estacao",
		layout: "/admin",
		claims: ["beWeather.station.read", "beWeather.station.write"],
		component: CrudStation,
	},
	{
		path: "/group_stations",
		name: "MENU_STATIONS_GROUP",
		sidebar: true,
		exact: true,
		icon: "icon-agrupamento_estacoes",
		layout: "/admin",
		claims: ["beWeather.station.read", "beWeather.station.write"],
		component: StationsGroup,
	},
	{
		path: "/group_stations/crud",
		name: "MENU_STATIONS_GROUP",
		sidebar: false,
		exact: true,
		icon: "icon-agrupamento_estacoes",
		layout: "/admin",
		claims: ["beWeather.station.read", "beWeather.station.write"],
		component: CrudStationsGroup,
	},
	{
		path: "/group_stations/crud/:id",
		name: "MENU_STATIONS_GROUP",
		sidebar: false,
		exact: true,
		icon: "icon-agrupamento_estacoes",
		layout: "/admin",
		claims: ["beWeather.station.read", "beWeather.station.write"],
		component: CrudStationsGroup,
	},
	{
		path: "/group_stations/link/:id",
		name: "MENU_STATIONS_GROUP",
		sidebar: false,
		exact: true,
		icon: "icon-agrupamento_estacoes",
		layout: "/admin",
		claims: ["beWeather.station.read", "beWeather.station.write"],
		component: LinkStation,
	},
	{
		path: "/my_profile",
		sidebar: false,
		layout: "/admin",
		claims: [],
		component: MyProfileView,
	},
	{
		path: "/edit/my_profile",
		sidebar: false,
		layout: "/admin",
		claims: [],
		component: EditProfile,
	},

	{
		collapse: true,
		name: "MENU_ACCESS_CONTROL",
		sidebar: false,
		icon: "icon-ico_acesso",
		claims: [
			"beWeather.user.read",
			"beWeather.user.write",
			"beWeather.user.create",
			"beWeather.user.delete",
		],
		state: "pageCollapseMngt",
		views: [
			{
				path: "/access_control/users",
				name: "MENU_USERS",
				sidebar: false,
				icon: "icon-ico_acesso_usuario",
				layout: "/admin",
				claims: [
					"beWeather.user.read",
					"beWeather.user.write",
					"beWeather.user.create",
					"beWeather.user.delete",
				],
				component: Users,
			},
			{
				path: "/access_control/user/new",
				sidebar: false,
				claims: ["beWeather.user.create"],
				layout: "/admin",
				component: UserForm,
			},
			{
				path: "/access_control/user/edit/:id",
				sidebar: false,
				claims: ["beWeather.user.write"],
				layout: "/admin",
				component: UserForm,
			},
			{
				path: "/access_control/user/associateGroup/:id",
				sidebar: false,
				claims: ["beWeather.user.write", "beWeather.user.create"],
				layout: "/admin",
				component: UserGroupForm,
			},
			{
				path: "/access_control/user/details/:id",
				sidebar: false,
				claims: [
					"beWeather.user.read",
					"beWeather.user.write",
					"beWeather.user.create",
					"beWeather.user.delete",
				],
				layout: "/admin",
				component: UserDetails,
			},
			{
				path: "/access_control/groups",
				name: "MENU_GROUPS",
				sidebar: false,
				claims: [
					"beWeather.group.read",
					"beWeather.group.write",
					"beWeather.group.create",
					"beWeather.group.delete",
				],
				icon: "icon-ico_acesso_grupo",
				iconSmall: true,
				layout: "/admin",
				component: Groups,
			},
			{
				path: "/access_control/group/new",
				claims: ["beWeather.group.create"],
				sidebar: false,
				layout: "/admin",
				component: GroupForm,
			},
			{
				path: "/access_control/group/edit/:id",
				claims: ["beWeather.group.write"],
				sidebar: false,
				layout: "/admin",
				component: GroupForm,
			},
			{
				path: "/access_control/group/details/:id",
				claims: [
					"beWeather.group.read",
					"beWeather.group.write",
					"beWeather.group.create",
				],
				sidebar: false,
				layout: "/admin",
				component: GroupDetail,
			},
		],
	},
	{
		collapse: true,
		name: "MENU_SETTINGS",
		sidebar: true,
		icon: "icon-ico_configuracoes",
		claims: [
			"beWeather.firmware.read",
			"beWeather.firmware.write",
			"beWeather.firmware.delete",
			"beWeather.integration.sap",
			"beWeather.integration.sap",
		],
		state: "pageCollapseCons",
		views: [
			{
				path: "/settings/message",
				name: "MENU_MESSAGE",
				claims: [
					"beWeather.firmware.read",
					"beWeather.firmware.write",
					"beWeather.firmware.delete",
				],
				exact: true,
				sidebar: true,
				icon: "icon-mensagens",
				iconSmall: true,
				layout: "/admin",
				component: Message,
			},
			{
				path: "/settings/message/crud",
				name: "MENU_MESSAGE",
				claims: [
					"beWeather.firmware.read",
					"beWeather.firmware.write",
					"beWeather.firmware.delete",
				],
				sidebar: false,
				exact: true,
				icon: "icon-mensagens",
				iconSmall: true,
				layout: "/admin",
				component: CrudMessage,
			},
			{
				path: "/settings/message/crud/:id",
				claims: [
					"beWeather.group.read",
					"beWeather.group.write",
					"beWeather.group.create",
				],
				sidebar: false,
				layout: "/admin",
				component: CrudMessage,
			},
			{
				path: "/settings/firmware",
				name: "MENU_FIRMWARE",
				claims: [
					"beWeather.firmware.read",
					"beWeather.firmware.write",
					"beWeather.firmware.delete",
				],
				sidebar: true,
				icon: "icon-ico_firmware",
				iconSmall: true,
				layout: "/admin",
				component: Firmware,
			},
			{
				path: "/settings/integration",
				name: "MENU_INTEGRATION",
				claims: [
					"beWeather.integration.sap",
					"beWeather.integration.sap",
				],
				sidebar: true,
				icon: "icon-ico_integracao",
				iconSmall: true,
				layout: "/admin",
				component: Integration,
			},
			{
				path: "/settings/contacts",
				name: "MENU_CONTACT",
				claims: [
					"beWeather.integration.sap",
					"beWeather.integration.sap",
				],
				sidebar: true,
				icon: "icon-ico_whatsapp",
				iconSmall: true,
				layout: "/admin",
				component: Contact,
			},
			{
				path: "/settings/reference",
				name: "MENU_REFERENCE",
				claims: [
					"beWeather.firmware.read",
					"beWeather.firmware.write",
					"beWeather.firmware.delete",
				],
				sidebar: true,
				icon: "icon-ico_referencias",
				iconSmall: true,
				layout: "/admin",
				component: Reference,
			},
		],
	},
];
