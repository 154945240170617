import React, { useState } from "react";

import Button from "components/CustomButtons/Button";
import CustomCheckBox from "components/CustomCheckBox";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import * as Styled from "./styles";
import { useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import {
	getGroupSelectAndRemove,
	GET_GROUP_SELECT_AND_REMOVE,
	postLinkStation,
	postUnlinkStation,
} from "services/api/StationGroupManager/api";
import { Tooltip, withStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { HideLoader, ShowLoader } from "redux/actions";
import Loader from "components/Loader/Loader";
import { showNotification } from "redux/actions/notification";
import {
	FailedNotificationComponent,
	SuccessNotificationComponent,
} from "redux/react-notifications";

const CssTooltip = withStyles({
	tooltip: {
		backgroundColor: "#171717",
		borderRadius: "6px",
		padding: "2px 8px",
		fontWeight: "initial",
		"& span": {
			color: "#171717",
		},
	},
})(Tooltip);

const PainelSelect = ({ left, list, addRemove, clonedList, setList, id }) => {
	const [selected, setSelected] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	function handleChange(id) {
		setSelected((prevState) =>
			prevState.find((item) => item.id === id)
				? prevState.filter((item) => item.id !== id)
				: [...prevState, list.find((item) => item.id === id)]
		);
	}

	function selectAll(event) {
		return event.target.checked
			? setSelected(list.map((item) => item))
			: setSelected([]);
	}

	function searchName(event) {
		setSearchValue(event.target.value);
	}

	useEffect(() => {
		function nameFilter() {
			if (left) {
				return setList((prevState) => ({
					...prevState,
					available: searchValue
						? clonedList.filter((item) =>
								item.name
									.toLowerCase()
									.includes(searchValue.toLowerCase())
						  )
						: clonedList,
				}));
			}

			return setList((prevState) => ({
				...prevState,
				selected: searchValue
					? clonedList.filter((item) =>
							item.name
								.toLowerCase()
								.includes(searchValue.toLowerCase())
					  )
					: clonedList,
			}));
		}

		nameFilter();
	}, [clonedList, left, searchValue, setList]);

	return (
		<Styled.SectionPainel padding={left ? "0 20px 0 0" : "0 0 0 20px"}>
			<header>
				<div>
					<div>
						<label htmlFor="search">
							<b>Procurar cliente/estação:</b>
						</label>
						<input
							value={searchValue}
							type="text"
							id="search"
							placeholder="Todos"
							onChange={searchName}
						/>
					</div>
					<Button
						size="sm"
						color="primary"
						disabled={!id}
						style={{ width: 130 }}
						onClick={() => addRemove(selected)}
					>
						{left ? (
							<>
								Adicionar
								<ArrowRightAltIcon style={{ marginLeft: 10 }} />
							</>
						) : (
							<>
								<KeyboardBackspaceIcon
									style={{ marginRight: 10 }}
								/>
								Remover
							</>
						)}
					</Button>
				</div>
			</header>
			<Styled.BodySelect>
				<header>
					<CustomCheckBox
						label="Selecionar todos"
						onChange={selectAll}
					/>
				</header>
				<section>
					{list.map((item) => (
						<CustomCheckBox
							key={item.id}
							label={
								<div>
									<CssTooltip
										id="tooltip-id"
										title={
											item.isUser ? "Cliente" : "Estação"
										}
										placement="top-start"
										arrow
									>
										<Styled.IsUserBall
											isUser={item.isUser}
										/>
									</CssTooltip>
									{item.name}
								</div>
							}
							checked={
								!!selected.find(
									(select) => select.id === item.id
								)
							}
							value={
								!!selected.find(
									(select) => select.id === item.id
								)
							}
							onChange={() => handleChange(item.id)}
						/>
					))}
				</section>
			</Styled.BodySelect>
		</Styled.SectionPainel>
	);
};

function LinkGroup() {
	const { id } = useParams();
	const [list, setList] = useState({
		available: [],
		selected: [],
	});

	const [clonedList, setClonedList] = useState({
		available: [],
		selected: [],
	});

	const dispatch = useDispatch();

	const { data } = useSWR(id && GET_GROUP_SELECT_AND_REMOVE, () =>
		getGroupSelectAndRemove(id)
	);

	useEffect(() => {
		if (id) {
			dispatch(ShowLoader(<Loader />));

			if (data) {
				setList({
					available: data?.available?.map((item) => ({
						...item,
						selected: false,
					})),
					selected: data?.selected?.map((item) => ({
						...item,
						selected: false,
					})),
				});

				setClonedList({
					available: data?.available?.map((item) => ({
						...item,
						selected: false,
					})),
					selected: data?.selected?.map((item) => ({
						...item,
						selected: false,
					})),
				});
				dispatch(HideLoader(<Loader />));
			}
		}
	}, [data, dispatch, id]);

	async function addSelected(selected) {
		dispatch(ShowLoader(<Loader />));

		const select = {
			id,
			stationsIds: selected
				.filter((item) => !item.isUser)
				.map((item) => item.id),
			usersIds: selected
				.filter((item) => item.isUser)
				.map((item) => item.id),
		};

		await postLinkStation(select).then(() => {
			dispatch(
				showNotification(
					SuccessNotificationComponent(
						"Estação ou Cliente adicionados com sucesso!"
					)
				)
			);
		});
		mutate(GET_GROUP_SELECT_AND_REMOVE);

		dispatch(HideLoader(<Loader />));
	}

	async function removeSelected(selected) {
		dispatch(ShowLoader(<Loader />));

		const select = {
			id,
			stationsIds: selected
				.filter((item) => !item.isUser)
				.map((item) => item.id),
			usersIds: selected
				.filter((item) => item.isUser)
				.map((item) => item.id),
		};

		await postUnlinkStation(select).then(() => {
			dispatch(
				showNotification(
					SuccessNotificationComponent(
						"Estação ou Cliente removidos com sucesso!"
					)
				)
			);
		});
		mutate(GET_GROUP_SELECT_AND_REMOVE);

		dispatch(HideLoader(<Loader />));
	}

	return (
		<Styled.LinkContainer>
			<PainelSelect
				left
				list={list?.available || []}
				setList={setList}
				addRemove={addSelected}
				id={id}
				clonedList={clonedList?.available}
			/>
			<Styled.DividerLineHeight />
			<PainelSelect
				list={list?.selected || []}
				setList={setList}
				clonedList={clonedList?.selected}
				addRemove={removeSelected}
				id={id}
			/>
		</Styled.LinkContainer>
	);
}

export default LinkGroup;
