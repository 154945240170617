import axios from "axios";

export const GET_CONFIGURATION_MANAGER_ALL = "/api/ConfigurationManager/getall";
export const POST_CONFIGURATION_MANAGER =
	"/api/ConfigurationManager/upsertContact";
export const POST_CONFIGURATION_STATUS =
	"/api/ConfigurationManager/upsertStationStatus";
export const POST_CONFIGURATION_QUALITY =
	"/api/ConfigurationManager/upsertStationDataQuality";

export const getConfigurationManager = async (filter) => {
	try {
		const { data } = await axios.get(
			`${GET_CONFIGURATION_MANAGER_ALL}${filter ?? ""}`
		);
		return data;
	} catch (error) {}
};

export const postConfigurationManager = async (body) => {
	try {
		const { data } = await axios.post(POST_CONFIGURATION_MANAGER, body);
		return data;
	} catch (error) {}
};

export const postConfigurationManagerStatus = async (body) => {
	try {
		const { data } = await axios.post(POST_CONFIGURATION_STATUS, body);
		return data;
	} catch (error) {}
};

export const postConfigurationManagerQuality = async (body) => {
	try {
		const { data } = await axios.post(POST_CONFIGURATION_QUALITY, body);
		return data;
	} catch (error) {}
};
