import { Modal, Paper } from '@material-ui/core'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

export const MyButton = styled(Button)`
	&.MuiButtonBase-root.Mui-disabled {
		background: #d3d3d3 !important;
		border: none;
		color: white;
	}

	.MuiButton-root.Mui-disabled {
		box-shadow: none;
	}
`
