import React, { useState, useEffect } from 'react'
import CommonPage from 'components/Pages/CommonPage'
import { useParams } from 'react-router-dom'
import DocumentTitle from 'components/Pages/DocumentTitle'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx'
import FieldData from 'components/ViewDataField/FieldData.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import { history } from 'utils'
import Section from 'components/Section'
import Button from 'components/CustomButtons/Button.jsx'
import { Grid } from '@material-ui/core'
import { Icon } from '@material-ui/core'
// import {getStationByIdWithStatus,GET_STATION_WITH_STATUS,} from "services/api/Station/api";
import { getStationById, GET_STATION_BY_ID } from 'services/api/Station/api'
import useSWR from 'swr'
import moment from 'moment'
import BatteryLevel from 'components/Gauges/BatteryLevel'
import NotificationModal from 'components/NotificationModal'
import ModalDataIntegrity from '../ModalDataIntegrity'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import { goTo } from 'utils/utils'
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

const breadcrumbItems = [
	{ name: 'Home' },
	{ name: 'Estações' },
	{ name: 'Visualizar Estação', last: true },
]

const StationView = () => {
	const history = useHistory()
	const { id } = useParams()
	const [showModal, setShowModal] = useState({
		loading: false,
		open: false,
		title: '',
		subTitle: '',
		headerIcon: false,
		period: null,
	})
	const [statusInfo, setStatusInfo] = useState({
		statusIcon: 'icon-desativado',
		statusText: 'Desativado',
		statusColor: germiniDefaultColors[11],
	})
	const [signalLevelInfo, setSignalLevelInfo] = useState({
		signalLevel: '0%',
		signalColor: '#586B74',
	})
	const [transmissionType, setTransmissionType] = useState({
		transmissionText: 'Sem Modem ou Wi-fi',
	})
	const sevenDays = 'Últimos 7 dias'
	const thirtyDays = 'Últimos 30 dias'

	const { data: station } = useSWR(GET_STATION_BY_ID, () =>
		getStationById(id),
	)

	function useQuery() {
		return new URLSearchParams(useLocation().search)
	}
	const query = useQuery()
	const queryString = query.get('body')
	const viewMore = query.get('viewMore')

	useEffect(() => {
		// eslint-disable-next-line default-case
		switch (station?.transmissionSignalLevel) {
			case 0:
				setSignalLevelInfo({
					signalLevel: '0%',
					signalColor: '#586B74',
				})
				break
			case 1:
				setSignalLevelInfo({
					signalLevel: '100%',
					signalColor: '#30b455',
				})
				break
			case 2:
				setSignalLevelInfo({
					signalLevel: '80%',
					signalColor: '#30b455',
				})
				break
			case 3:
				setSignalLevelInfo({
					signalLevel: '60%',
					signalColor: '#30b455',
				})
				break
			case 4:
				setSignalLevelInfo({
					signalLevel: '40%',
					signalColor: germiniDefaultColors[13],
				})
				break
			case 5:
				setSignalLevelInfo({
					signalLevel: '20%',
					signalColor: germiniDefaultColors[13],
				})
				break
		}
	}, [station])

	useEffect(() => {
		// eslint-disable-next-line default-case
		switch (station?.transmissionType) {
			case 0:
				setTransmissionType({
					transmissionText: 'Sem Modem ou Wi-fi',
				})
				break
			case 1:
				setTransmissionType({
					transmissionText: 'Modem',
				})
				break
			case 2:
				setTransmissionType({
					transmissionText: 'Wi-fi',
				})
				break
		}
	}, [station])

	useEffect(() => {
		// eslint-disable-next-line default-case
		switch (station?.dataQuality) {
			case 3:
				station.dataQualityText = 'Exelente'
				station.dataQualityIcon = 'icon-qualidade_dados'
				station.dataQualityColor = germiniDefaultColors[0]
				break
			case 2:
				station.dataQualityText = 'Boa'
				station.dataQualityIcon = 'icon-qualidade_dados'
				station.dataQualityColor = germiniDefaultColors[1]
				break
			case 1:
				station.dataQualityText = 'Regular'
				station.dataQualityIcon = 'icon-qualidade_dados'
				station.dataQualityColor = germiniDefaultColors[13]
				break
			case 0:
				station.dataQualityText = 'Sem dados'
				station.dataQualityIcon = 'icon-qualidade_dados'
				station.dataQualityColor = germiniDefaultColors[6]
				break
		}
	}, [station])

	useEffect(() => {
		// eslint-disable-next-line default-case
		switch (station?.status) {
			case 0:
				setStatusInfo({
					statusIcon: 'icon-ativo',
					statusText: ' Ativo',
					statusColor: '#30b455',
					defaultPalette: '#30B455',
					battery: true,
					signal: true,
				})
				break
			case 1:
				setStatusInfo({
					statusIcon: 'icon-atencao',
					statusText: ' Atenção',
					statusColor: germiniDefaultColors[13],
					defaultPalette: '#30B455',
					battery: true,
					signal: true,
				})
				break
			case 2:
				setStatusInfo({
					statusIcon: 'icon-critico',
					statusText: ' Critico',
					statusColor: germiniDefaultColors[10],
					defaultPalette: '#30B455',
					battery: true,
					signal: true,
				})
				break
			case 3:
				setStatusInfo({
					statusIcon: 'icon-offline',
					statusText: ' Offline - não há dados',
					statusColor: germiniDefaultColors[6],
					defaultPalette: '#586B74',
					battery: false,
					signal: false,
				})
				break
			case 4:
				setStatusInfo({
					statusIcon: 'icon-offline',
					statusText: ' Offline - coleta manual',
					statusColor: germiniDefaultColors[14],
					defaultPalette: '#586B74',
					battery: false,
					signal: false,
				})
				break
			case 5:
				setStatusInfo({
					statusIcon: 'icon-desativado',
					statusText: ' Desativado',
					statusColor: germiniDefaultColors[11],
					defaultPalette: '#586B74',
					battery: false,
					signal: false,
				})
				break
		}
	}, [station])

	function showHistory(period, subtitle) {
		setShowModal({
			loading: false,
			open: true,
			title: 'INTEGRIDADE DE DADOS',
			headerIcon: false,
			period: period,
			subtitle: subtitle,
		})
	}

	return (
		<CommonPage>
			<DocumentTitle pageTitle={'Detalhes da estação'} />
			<NotificationModal
				headerTitle={showModal.title}
				headerSubtitle={showModal.subtitle}
				width="400px"
				height="530px"
				headerIcon={showModal.headerIcon}
				closeByClick={() =>
					setShowModal((prevState) => ({ ...prevState, open: false }))
				}
				onClose={() =>
					setShowModal((prevState) => ({ ...prevState, open: false }))
				}
				open={showModal.open}
				buttonTitle="BUTTON_CLOSE"
			>
				<ModalDataIntegrity period={showModal.period} id={id} />
			</NotificationModal>
			<GridContainer>
				<Breadcrumbs
					items={breadcrumbItems}
					buttonEdit
					functionEdit={() => {
						if (viewMore && viewMore == 'true') {
							return goTo(`station/crud/${id}?viewMore=true`)
						}

						return goTo(`station/crud/${id}?body=${queryString}`)
					}}
					buttonBack
					functionBack={() => {
						if (viewMore && viewMore == 'true') {
							return history.goBack()
						}
						if (queryString && queryString.length) {
							queryString
								? goTo(`station?body=${queryString}`)
								: goTo(`station`)
						}
						return goTo(`station`)
						// return history.goBack()
					}}
				/>
				<GridItem xs={12}>
					<Card>
						<CardBody>
							<Grid item xs={12} container>
								<Grid item xs={12}>
									<Section
										title="Identificação"
										icon="icon-estacao"
									/>
								</Grid>
								<Grid item xs={3}>
									<FieldData
										label="Apelido da estação"
										value={
											station?.name
												? station?.name
												: 'Não possui'
										}
									></FieldData>
								</Grid>
								<Grid item xs={3}>
									<FieldData
										label="Nome do cliente"
										value={
											station?.owner?.fullName
												? station?.owner?.fullName
												: 'Não possui'
										}
									></FieldData>
								</Grid>
								<Grid item xs={6}></Grid>
								<Grid item xl={3} lg={3} md={3}>
									<FieldData
										label="Nº de série"
										value={
											station?.gateStationId
												? station?.gateStationId
												: 'Não possui'
										}
									></FieldData>
								</Grid>
								<Grid item xl={3} lg={5} md={5} sm={12} xs={12}>
									<FieldData
										label="ID"
										value={
											station?.bluetoothDeviceId
												? station?.bluetoothDeviceId
												: 'Não possui'
										}
									></FieldData>
								</Grid>
								<Grid item xl={3} lg={2} md={2} sm={6} xs={6}>
									<FieldData
										label="Chave de segurança"
										value={
											station?.password
												? station?.password
												: 'Não possui'
										}
									></FieldData>
								</Grid>
								<Grid item xl={3} lg={2} md={2} sm={6} xs={6}>
									<FieldData
										label="Versão de firmware"
										value={
											station?.firmwareVersion
												? station?.firmwareVersion
												: 'No version'
										}
									></FieldData>
								</Grid>
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={6}>
					<Card>
						<CardBody>
							<Grid item xs={12} container>
								<Grid item xs={12}>
									<Section
										title="Medidores"
										icon="icon-estacao"
									/>
								</Grid>
								<Grid item xs={4}>
									<FieldData
										label="Status"
										value={
											<>
												<Icon
													style={{
														fontSize: '18px',
														overflow: 'inherit',
														color: statusInfo?.statusColor,
													}}
													className={
														statusInfo?.statusIcon
													}
												/>
												{statusInfo?.statusText}
											</>
										}
									></FieldData>
								</Grid>
								<Grid item xs={4}>
									<FieldData
										label="Qualidade de dados"
										value={
											<>
												<Icon
													style={{
														fontSize:
															'1rem !important',
														overflow: 'inherit',
														marginRight: '5px',
														color: station?.dataQualityColor,
													}}
													className={
														station?.dataQualityIcon
													}
												/>
												{station?.dataQualityText}
											</>
										}
									></FieldData>
								</Grid>
								<Grid item xs={4}>
									<BatteryLevel
										isActive={statusInfo.battery}
										level={
											station?.battery
												? Math.round(station?.battery)
												: 0
										}
									/>
								</Grid>
								<Grid item xs={4}>
									<FieldData
										label="Provedor de sinal"
										value={
											<div style={{ display: 'flex' }}>
												<Icon
													style={{
														fontSize: '18px',
														overflow: 'inherit',
														marginRight: '8px',
														color: statusInfo.signal
															? signalLevelInfo.signalColor
															: '#586B74',
													}}
													className="icon-configuracao"
												/>
												{
													transmissionType.transmissionText
												}
											</div>
										}
									></FieldData>
								</Grid>
								<Grid item xs={4}>
									<FieldData
										label="Qualidade do sinal"
										value={
											<div style={{ display: 'flex' }}>
												<Icon
													style={{
														fontSize: '18px',
														overflow: 'inherit',
														marginRight: '15px',
														color: statusInfo.signal
															? signalLevelInfo.signalColor
															: '#586B74',
													}}
													className="icon-sinal_wifi"
												/>
												{signalLevelInfo.signalLevel}
											</div>
										}
									></FieldData>
								</Grid>
								<Grid item xs={4}></Grid>
								<Grid item xs={12}>
									<hr></hr>
								</Grid>
								<Grid item xs={4} style={{ marginTop: '10px' }}>
									<span
										style={{
											color: '#586B74',
										}}
									>
										A representação grafica dos dados:
									</span>
								</Grid>
								<Grid item xs={3} style={{ marginTop: '10px' }}>
									<div style={{ display: 'flex' }}>
										<div
											style={{
												width: '20px',
												height: '20px',
												backgroundColor: '#30b455',
												borderRadius: '5px',
											}}
										></div>
										<span
											style={{
												marginLeft: '5px',
												color: '#586B74',
											}}
										>
											Dados Recentes
										</span>
									</div>
								</Grid>
								<Grid item xs={5} style={{ marginTop: '10px' }}>
									<div style={{ display: 'flex' }}>
										<div
											style={{
												width: '20px',
												height: '20px',
												backgroundColor: '#586B74',
												borderRadius: '5px',
											}}
										></div>
										<span
											style={{
												marginLeft: '5px',
												color: '#586B74',
											}}
										>
											Dados não atualizados
										</span>
									</div>
								</Grid>
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={6}>
					<Card>
						<CardBody>
							<Grid item xs={12} container>
								<Grid item xs={12}>
									<Section
										title="Registros"
										icon="icon-estacao"
									/>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									marginTop: '16px',
									minHeight: '30px',
								}}
							>
								<span
									style={{
										color: '#0069C0',
										fontWeight: 'bold',
										margin: 'auto 0',
									}}
								>
									Fim do suporte
								</span>
								<span style={{ margin: 'auto 0' }}>
									Sem previsão
								</span>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									marginTop: '16px',
									minHeight: '30px',
								}}
							>
								<span
									style={{
										color: '#0069C0',
										fontWeight: 'bold',
										margin: 'auto 0',
									}}
								>
									Ativa desde
								</span>
								<span style={{ margin: 'auto 0' }}>
									{station?.activatedAt
										? moment(station?.activatedAt).format(
												'DD-MM-YYYY',
										  )
										: 'Nunca ativa'}
								</span>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									marginTop: '16px',
									minHeight: '30px',
								}}
							>
								<span
									style={{
										color: '#0069C0',
										fontWeight: 'bold',
										margin: 'auto 0',
									}}
								>
									Última atualização de status
								</span>
								<span style={{ margin: 'auto 0' }}>
									{station?.lastStatusAt
										? moment(station?.lastStatusAt).format(
												'DD-MM-YYYY - HH:mm',
										  )
										: 'Nunca Atualizada'}
								</span>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									marginTop: '16px',
									minHeight: '30px',
								}}
							>
								<span
									style={{
										color: '#0069C0',
										fontWeight: 'bold',
										margin: 'auto 0',
									}}
								>
									Última atualização climatica
								</span>
								<span>
									{station?.lastWeatherAt
										? moment(station?.lastWeatherAt).format(
												'DD/MM/YYYY HH:mm',
										  )
										: 'Nunca Atualizada'}
								</span>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									marginTop: '16px',
									minHeight: '30px',
								}}
							>
								<span
									style={{
										color: '#0069C0',
										fontWeight: 'bold',
										margin: 'auto 0',
									}}
								>
									Integridade de dados - 7 dias
								</span>
								<Button
									size="sm"
									color="primary"
									onClick={() => showHistory(7, sevenDays)}
									style={{ width: '10px', height: '20px' }}
								>
									<Icon
										style={{
											fontSize: '13px',
											marginLeft: '5px',
										}}
										className={'icon-bot_busca'}
									/>
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									marginTop: '16px',
									minHeight: '30px',
								}}
							>
								<span
									style={{
										color: '#0069C0',
										fontWeight: 'bold',
										margin: 'auto 0',
									}}
								>
									Integridade de dados - 30 dias
								</span>
								<Button
									size="sm"
									color="primary"
									onClick={() => showHistory(30, thirtyDays)}
									style={{ width: '10px', height: '20px' }}
								>
									<Icon
										style={{
											fontSize: '13px',
											marginLeft: '5px',
										}}
										className={'icon-bot_busca'}
									/>
								</Button>
							</Grid>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</CommonPage>
	)
}

export default StationView
