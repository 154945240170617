import React from "react";
import { Tab, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import * as Styled from "./styles";

function CustomStep({ tabList, containerList, handleClick = () => null }) {
	return (
		<Styled.TabContainer>
			<TabList style={{ maxWidth: "100%" }}>
				{tabList.map((item) => (
					<Tab key={item.name} onClick={handleClick}>
						<i className={item.icon} />
						<b>{item.name}</b>
					</Tab>
				))}
			</TabList>

			{containerList.map((item, index) => (
				<TabPanel key={String(index)}>{item.children}</TabPanel>
			))}
		</Styled.TabContainer>
	);
}

export default CustomStep;
