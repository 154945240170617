import axios from 'axios';

// GET
export const GetUsersData = (_filter, _page, _itemsPerPage) => {
    let page = "";
    let count = (_page - 1) * _itemsPerPage;
    if (_filter && _page) {
        page = `&$skip=${count}&$top=${_itemsPerPage}`
    } else if (!_filter && _page) {
        page = `?$skip=${count}&$top=${_itemsPerPage}`
    }

    const url = `/api/CUser/GetAll${_filter}${page}`;
    return axios.get(url).then(response => response.data);
}

export const GetUserById = (id) => {
    return axios.get(`/api/CUser/GetById?id=${id}`).then(response => response.data);
}

export const CreateUser = (obj) => {
    return axios.post(`/api/CUser/Create`, obj).then(response => response.data);
}

export const UpdateUser = (obj) => {
    return axios.put(`/api/CUser/Update`, obj).then(response => response.data);
}

export const AssignGroupsToUser = (obj) => {
    return axios.post(`/api/CUser/AssignGroups`, obj).then(response => response.data);
}

export const DeleteUser = (id) => {
    return axios.delete(`/api/CUser/Delete?id=${id}&softDelete=false`).then(response => response.data);
}

export const ResetUserPassword = (id) => {
    return axios.post(`/api/CUser/ResetPassword?id=${id}`).then(response => response.data);
}
