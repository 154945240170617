import React, { useState, useEffect } from "react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CommonPage from "components/Pages/CommonPage";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.jsx";
import DocumentTitle from "components/Pages/DocumentTitle";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import * as Styled from "./styles";
import { Grid, Icon } from "@material-ui/core";
import Section from "components/Section";
import CustomStep from "components/CustomStep";
import { StatusReference } from "./components/StatusReference";
import { IntegrityReference } from "./components/IntegrityReference";
import Button from "components/CustomButtons/Button.jsx";

import { showNotification } from "redux/actions/notification";
import {
	FailedNotificationComponent,
	SuccessNotificationComponent,
} from "redux/react-notifications";

import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "./validation";

import {
	postConfigurationManagerStatus,
	postConfigurationManagerQuality,
	getConfigurationManager,
} from "services/api/ConfigurationManager/api";

const breadcrumbItems = [
	{ name: "Home" },
	{ name: "Configurações" },
	{ name: "Referências", last: true },
];

const tabList = [
	{
		name: "Referência de status",
	},
	{
		name: "Referência de integridade de dados",
	},
];

function Reference() {
	const { t } = useTranslation();
	const [id, setId] = useState("");
	const dispatch = useDispatch();
	const {
		control,
		handleSubmit,
		reset,
		formState: { isDirty },
	} = useForm({
		defaultValues: {
			stationStatus: {
				warning: "",
				critical: "",
				offline: "",
				offlineManual: "",
				disabled: "",
			},
			stationDataQuality: {
				greatMin: "",
				wellMin: "",
				regularMin: "",
				noDataMin: "",
				greatMax: "",
				wellMax: "",
				regularMax: "",
				noDataMax: "",
			},
		},

		resolver: yupResolver(validation),
	});

	useEffect(() => {
		async function getStatus() {
			try {
				// dispatch(ShowLoader(<Loader />));
				const data = await getConfigurationManager();

				const item = data?.items?.[0];

				setId(item?.id);
			} catch (error) {
			} finally {
				// dispatch(HideLoader());
			}
		}
		getStatus();
	}, [dispatch, reset]);

	const containerList = [
		{
			name: "Referência de status",
			children: <StatusReference control={control} reset={reset} />,
		},
		{
			name: "Referência de integridade de dados",
			children: <IntegrityReference control={control} reset={reset} />,
		},
	];

	async function sendForm(values) {
		if (isDirty) {
			try {
				// dispatch(ShowLoader(<Loader />));
				let obj = { ...values };
				if (id) obj = { id, ...obj };
				if (values.stationStatus) {
					await postConfigurationManagerStatus({ ...obj })
						.then(() => {
							dispatch(
								showNotification(
									SuccessNotificationComponent(
										"Referência de status salvo com sucesso!"
									)
								)
							);
						})
						.catch(() => {
							dispatch(
								showNotification(
									FailedNotificationComponent("Falha no envio de dados!")
								)
							);
						});
				}

				if (values.stationDataQuality) {
					await postConfigurationManagerQuality({
						...obj,
					})
						.then(() => {
							dispatch(
								showNotification(
									SuccessNotificationComponent(
										"Referência de status salvo com sucesso!"
									)
								)
							);
						})
						.catch(() => {
							dispatch(
								showNotification(
									FailedNotificationComponent("Falha no envio de dados!")
								)
							);
						});
				}
			} catch (error) {
			} finally {
				// dispatch(HideLoader());
			}
		}
	}

	return (
		<CommonPage>
			<DocumentTitle pageTitle={t("MENU_REFERENCE")} />
			<Styled.ReferenceBox>
				<Styled.NavBar>
					<Breadcrumbs items={breadcrumbItems} />
					<Button
						size="sm"
						color="primary"
						onClick={handleSubmit(sendForm)}
						className="save-button"
					>
						<Icon
							style={{
								fontSize: "18px",
								width: "30px",
							}}
							className={"icon-salvar"}
						/>
						Salvar
					</Button>
				</Styled.NavBar>
				<Card>
					<CardBody>
						<Grid container>
							<Grid container item xs={12}>
								<Section title="REFERêNCIAS" icon="icon-ico_referencias" />
							</Grid>
							<Grid item xs={12} style={{ marginTop: 30 }}>
								<form>
									<CustomStep
										tabList={tabList}
										containerList={containerList}
										handleClick={handleSubmit(sendForm)}
									/>
								</form>
							</Grid>
						</Grid>
					</CardBody>
				</Card>
			</Styled.ReferenceBox>
		</CommonPage>
	);
}

export default Reference;
