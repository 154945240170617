import styled from 'styled-components'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'

export const TimePicker = styled(KeyboardTimePicker)`
	.MuiInputBase-input {
		border: 1px solid #e7e9f1;
		border-radius: 4px 4px 0px 0px;
		border-bottom: 1px solid #ccced8;
		padding: 6px 0 7px 10px;
		color: #717171;
		font-size: 14px;
	}

	> label {
		margin-left: 10px;
		color: #717171;
	}

	.MuiInputBase-root.Mui-disabled {
		i,
		svg {
			color: #c8c8c8;
		}
	}
`

export const DatePicker = styled(KeyboardDatePicker)`
	&:hover {
		button {
			background-color: transparent;
		}

		input {
			border-bottom: 2px solid #0069c0;
		}
	}

	input {
		border-bottom: 1px solid rgba(0, 0, 0, 0.42);
		color: #717171 !important;

		:focus {
			border-bottom: 2px solid #0069c0;
		}
	}

	> label {
		color: #717171;
		font-size: 12px;
		top: 6px;
	}

	.MuiInputBase-root.Mui-disabled {
		i,
		svg {
			color: #c8c8c8;
		}
	}

	.MuiInput-underline:before {
		border-bottom-style: initial;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom-style: initial;
	}

	.MuiInput-underline.Mui-disabled:before {
		border-bottom-style: initial;
	}
`
