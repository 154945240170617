import React from "react";
import { ModalContainer, PaperContainer } from "./styles";
import * as S from "./styles";

const CloseButton = ({ title = "Fechar", handleClose }) => {
	return (
		<div>
			<S.MyButton onClick={handleClose}>
				<S.MyIcon className="icon-cancelar" />
				{title}
			</S.MyButton>
		</div>
	);
};

export default CloseButton;
