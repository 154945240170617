import CustomIcon from 'components/CustomIcon'
import CustomTable, {
	initialPagination,
	initialSort,
} from 'components/CustomTable'
import Alert from 'react-bootstrap-sweetalert'
import CustomArrow from 'components/CustomArrow'
import React, { useState, useEffect } from 'react'
import { getAll, GET_ALL, Upsert, getById } from 'services/api/UserManager/api'
import useSWR, { mutate as swrMutate } from 'swr'
import { history } from 'utils'
import { useDispatch } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import {
	SweetAlert,
	HideAlert,
	DeleteCustomer,
	ResetCustomerPassword,
} from 'redux/actions'
import { showNotification } from 'redux/actions/notification'
import {
	FailedNotificationComponent,
	SuccessNotificationComponent,
} from 'redux/react-notifications'
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import { ContactSupportOutlined } from '@material-ui/icons'

function ListCustomers({
	filter,
	setFilter,
	classes,
	mutate,
	hasDeletePermission,
	hasWritePermission,
}) {
	const [pagination, setPagination] = useState(initialPagination)
	const [loading, setLoading] = useState(true)
	const [sort, setSort] = useState({})
	const [persistence, setPersistence] = useState()
	const [first, setFirst] = useState(true)
	const [data, setData] = useState(initialSort)
	const [reload, setReload] = useState(0)
	const [columnOrder, setColumnOrder] = useState({
		columnText: '',
		columnAction: '',
	})
	const [isFirst, setIsFirst] = useState(true)
	const [reqBody, setReqBody] = useState({
		fullName: '',
		email: '',
		phoneNumber: '',
		active: true,
		skip: pagination.currentPage,
		top: pagination.pageSize,
		orderBy: '',
		orderType: '',
	})
	const sortReference = {
		0: 'active',
		1: 'fullName',
		2: 'email',
		3: 'phoneNumber',
	}
	const orderReferenceText = {
		active: 'Status',
		fullName: 'Cliente',
		email: 'Email',
		phoneNumber: 'Telefone',
	}
	const sortReferenceDefault = ''
	const dispatch = useDispatch()

	useEffect(() => {
		setColumnOrder({
			columnText: orderReferenceText[reqBody.orderBy],
			columnAction: reqBody.orderType,
		})
	}, [reqBody])

	function useQuery() {
		return new URLSearchParams(useLocation().search)
	}
	const query = useQuery()
	// const body = query.get("body");

	useEffect(() => {
		const fetchApi = async (url, body) => {
			try {
				const data = await axios.post(url, body)
				return data
			} catch (error) {}
		}

		fetchApi(`${GET_ALL}`, reqBody).then((value) => {
			return setData(value?.data)
		})
	}, [reqBody, reload])

	useEffect(() => {
		setReqBody(() => ({
			fullName: filter?.fullName,
			email: filter?.email,
			phoneNumber: filter?.phoneNumber,
			active: filter?.active,
			skip: pagination.page,
			top: pagination.pageSize,
			orderBy: sortReference[sort.orderBy] || sortReferenceDefault,
			orderType: sort.orderType,
		}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		filter,
		pagination.page,
		pagination.pageSize,
		sort.orderBy,
		sort.orderType,
	])

	useEffect(() => {
		setPagination(initialPagination)
	}, [mutate])

	useEffect(() => {
		if (query.get('body')) {
			if (isFirst) {
				let value = JSON.parse(query.get('body'))
				setIsFirst(false)
				setReqBody(() => value)
				setPagination((prevState) => ({
					...prevState,
					pageSize: value.top,
				}))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		filter,
		pagination.currentPage,
		pagination.pageSize,
		sort.orderBy,
		sort.orderType,
	])

	useEffect(() => {
		setLoading(true)

		if (data?.items) {
			setPagination((prevState) => ({
				...prevState,
				recordCount: data.count,
			}))
			setLoading(false)
		}
	}, [data])

	async function inactivateCustomer(rowData) {
		await Upsert({
			...rowData,
			active: rowData.active ? false : true,
		}).then(() => {
			dispatch(
				showNotification(
					SuccessNotificationComponent(
						`Cliente ${
							rowData.active ? 'inativado' : 'ativado'
						} com sucesso!`,
					),
				),
			)
		})
		setReload(reload + 1)
	}

	function handleDelete(rowData) {
		hasDeletePermission
			? dispatch(
					SweetAlert(
						<Alert
							warning
							style={{
								display: 'block',
								marginTop: '-160px',
								width: '630px',
							}}
							onConfirm={() => {
								inactivateCustomer(rowData)
								//dispatch(DeleteCustomer());
								dispatch(HideAlert())
							}}
							onCancel={() => dispatch(HideAlert())}
							confirmBtnCssClass={
								classes.button +
								' ' +
								classes.greenBorderButtonColor
							}
							cancelBtnCssClass={
								classes.button + ' ' + classes.danger
							}
							confirmBtnText={'Confirmar'}
							cancelBtnText={'Cancelar'}
							showCancel
						>
							<h3
								className={classes.cardTitle}
								style={{ fontWeight: 400 }}
							>
								<b>
									Deseja realmente{' '}
									{rowData.active ? 'inativar' : 'ativar'}{' '}
									esse cliente?
								</b>
							</h3>
						</Alert>,
					),
			  )
			: dispatch(
					showNotification(
						FailedNotificationComponent('Sem permissão'),
					),
			  )
	}

	function handleNewPassword(rowData) {
		hasWritePermission
			? dispatch(
					SweetAlert(
						<Alert
							warning
							style={{
								display: 'block',
								marginTop: '-160px',
								width: '630px',
							}}
							onConfirm={() => {
								dispatch(ResetCustomerPassword(rowData.id))
								dispatch(HideAlert())
							}}
							onCancel={() => dispatch(HideAlert())}
							confirmBtnCssClass={
								classes.button +
								' ' +
								classes.greenBorderButtonColor
							}
							cancelBtnCssClass={
								classes.button + ' ' + classes.danger
							}
							confirmBtnText={'Confirmar'}
							cancelBtnText={'Cancelar'}
							showCancel
						>
							<h3
								className={classes.cardTitle}
								style={{ fontWeight: 400 }}
							>
								<b>
									Deseja realmente enviar uma senha nova para
									esse cliente?
								</b>
							</h3>
						</Alert>,
					),
			  )
			: dispatch(
					showNotification(
						FailedNotificationComponent('Sem permissão'),
					),
			  )
	}

	useEffect(() => {
		data?.items?.map((user) => {
			if (user.active === true) {
				user.statusText = 'Ativo'
				user.statusIcon = 'icon-ativo'
				user.statusColor = germiniDefaultColors[0]
			} else {
				user.statusText = 'Desativado'
				user.statusIcon = 'icon-desativado'
				user.statusColor = germiniDefaultColors[11]
			}
		})
	})

	function handleViewCustomer(id) {
		const string = `{"fullName":${
			reqBody.fullName ? '"' + reqBody.fullName : null
		},"email":${
			reqBody.email ? '"' + reqBody.email + '"' : null
		},"phoneNumber":${
			reqBody.phoneNumber ? '"' + reqBody.phoneNumber + '"' : null
		},"Status":${reqBody.Status ? reqBody.Status : 6},"orderBy":"${
			reqBody.orderBy ? reqBody.orderBy : null
		}","orderType":"${
			reqBody.orderType ? reqBody.orderType : null
		}","skip":${pagination.page},"top":${
			pagination.pageSize
		},"filterRefresh":"${
			reqBody.filterRefresh ? reqBody.filterRefresh : null
		}"}`
		// history.push(`/admin/station/view/${id}?body=${string}`);
		history.push(`/admin/customer/crud/${id}?body=${string}`)
	}

	// const handleViewCustomer = (id) => {
	// 	let string = `{"skip":"${pagination.page}",
	// 	"top":"${pagination.pageSize}",
	// 	"active":"${filter.active}",
	// 	"fullName":"${filter.fullName}",
	// 	"email":"${filter.email}",
	// 	"phoneNumber":"${filter.phoneNumber}"}`;
	// 	history.push(`/admin/customer/crud/${id}?body=${string}`);
	// };

	const actions = [
		{
			icon: () => (
				<span className="button-span">
					<CustomIcon
						className="icon-bot_busca"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Ver mais',
			// onClick: (event, rowData) => {
			// 	handlewStationView(rowData.id);
			// },
			onClick: (_, rowData) => handleViewCustomer(rowData.id),
		},
		{
			icon: () => (
				<span className="button-span">
					<CustomIcon
						className="icon-reenviar_senha"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Resetar senha',
			onClick: (_, rowData) => handleNewPassword(rowData),
		},
		{
			icon: () => (
				<span className="button-span">
					<CustomIcon
						className="icon-bot_close"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Inativar/Ativar',
			onClick: (_, rowData) => handleDelete(rowData),
		},
	]
	const columns = [
		{
			title:
				columnOrder.columnText === 'Status' ? (
					<CustomArrow
						text={columnOrder.columnText}
						order={columnOrder.columnAction}
					/>
				) : (
					'Status'
				),
			field: 'active',
			headerStyle: {
				textAlign: 'center',
			},
			render: function (rowData) {
				return (
					<>
						<Tooltip title={rowData.statusText}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									marginLeft: '-30px',
								}}
							>
								<CustomIcon
									className={rowData.statusIcon}
									color={rowData.statusColor}
									size="18px"
								/>
							</div>
						</Tooltip>
					</>
				)
			},
		},
		{
			title:
				columnOrder.columnText === 'Cliente' ? (
					<CustomArrow
						text={columnOrder.columnText}
						order={columnOrder.columnAction}
					/>
				) : (
					'Cliente'
				),
			type: 'string',
			field: 'fullName',
		},
		{
			title:
				columnOrder.columnText === 'Email' ? (
					<CustomArrow
						text={columnOrder.columnText}
						order={columnOrder.columnAction}
					/>
				) : (
					'Email'
				),
			type: 'string',
			field: 'email',
		},
		{
			title:
				columnOrder.columnText === 'Telefone' ? (
					<CustomArrow
						text={columnOrder.columnText}
						order={columnOrder.columnAction}
					/>
				) : (
					'Telefone'
				),
			type: 'string',
			field: 'phoneNumber',
		},
	]

	return (
		<>
			<CustomTable
				actions={actions}
				columns={columns}
				pagination={pagination}
				setPagination={setPagination}
				data={data?.items}
				sort={sort}
				setSort={setSort}
				loading={!data}
			/>
		</>
	)
}

export default ListCustomers
