import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import cx from "classnames";
import * as _ from 'lodash';
import Alert from 'react-bootstrap-sweetalert';

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//material-UI components
import { Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";

// layout components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CommonPage from "components/Pages/CommonPage";
import FieldData from "components/ViewDataField/FieldData";
import Button from "components/CustomButtons/Button";
import Table from "components/Table/Table.jsx";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';
import PictureUpload from 'components/CustomUpload/PictureUpload.jsx';
import NoContentAlert from 'components/NoContentAlert/NoContentAlert';
import Section from 'components/Section';
import DocumentTitle from "components/Pages/DocumentTitle";
import Loader from "components/Loader/Loader.jsx";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import { SweetAlert, HideAlert, GetUserById, ShowLoader, HideLoader, GetGroups, ShowNotification, AssignGroupsToUser } from 'redux/actions';

//utils
import compose from "utils/compose";
import { isAllowed } from "utils/auth";

import { FailedNotificationComponent } from 'redux/react-notifications';

class UserGroupForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { name: "Home" },
                { name: "Controle de acesso" },
                { name: "Usuário" },
                { name: "Associar grupo", last: true },
            ],
            groups: [],
            groupSelected: '',
            model: {
                userId: null,
                cGroups: [],
            },
            user: null,
            userGroups: [],
            hasWritePermission: false,
        }
    }

    componentDidMount() {
        this.props.GetGroups("", "", "");
        this.props.ShowLoader(<Loader />);

        let user = this.props.user;
        if (user) {
            if (isAllowed(user, ['beWeather.user.write'])) {
                this.setState({ hasWritePermission: true })
            }
        } else {
            this.props.history.push('/404')
        }

        const id = this.props.match && this.props.match.params.id;

        this.props.GetUserById(id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userData !== this.props.userData) {
            this.setState({
                model: {
                    userId: this.props.userData.id,
                    cGroups: this.props.userData.groups.map(x => x.id)
                }
            })
            this.setState({ user: this.props.userData });
            this.setState({ userGroups: this.props.userData.groups })
            this.props.HideLoader();
        }
        if (prevProps.lsGroups !== this.props.lsGroups) {
            this.setState({ groups: this.props.lsGroups.items })
        }
    }

    handleEdit = (id) => {
        this.props.history.push(`/admin/access_control/user/edit/${id}`);
    }

    addGroup = (groupId) => {
        if (groupId !== '') {
            let groups = this.state.groups;
            let groupsId = this.state.userGroups.map(x => x.id);
            let groupsToSave = this.state.userGroups;
            let groupToAdd = groups.find(x => x.id === groupId);

            groupsId.indexOf(groupId) === -1 ? groupsToSave.push(groupToAdd) : this.props.ShowNotification(FailedNotificationComponent('Grupo já adicionado!'));
            this.setState({ userGroups: groupsToSave })
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    removeGroup = id => {
        let groups = this.state.userGroups;
        _.remove(groups, x => x.id === id);

        this.setState({ userGroups: groups })
    }

    handleSave = model => {
        this.props.ShowLoader(<Loader />)
        let modelo = {
            userId: model.userId,
            cGroups: this.state.userGroups.map(x => x.id)
        }
        this.props.AssignGroupsToUser(modelo).then(resp => {
            if (resp) {
                this.props.history.push(`/admin/access_control/user/details/${resp.id}`)
            }
        })
    }

    render() {
        const { classes } = this.props;
        const { breadcrumbItems, groups, user, model, hasWritePermission, userGroups } = this.state;
        return (
            <CommonPage>
                <DocumentTitle pageTitle={'Associar grupo à usuário'} />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Breadcrumbs items={breadcrumbItems} />
                        <Card>
                            <CardBody>
                                {user &&
                                    <GridContainer>
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <Section title="DADOS DA CONTA" icon="icon-ico_cliente" />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6} lg={6} style={{ textAlign: 'right' }}>
                                            <Button
                                                size="sm"
                                                color="primary"
                                                style={{ marginRight: '10px' }}
                                                disabled={!hasWritePermission}
                                                onClick={hasWritePermission ? (() => this.handleSave(model)) : (() => console.log("Sem permissão"))}
                                            >
                                                <Icon style={{ fontSize: '17px', width: '25px' }} className={'icon-salvar'} />Salvar
                                        </Button>
                                            <Button
                                                size="sm"
                                                color="primary"
                                                disabled={!hasWritePermission}
                                                onClick={hasWritePermission ? (() => this.handleEdit(user.id)) : (() => console.log("Sem permissão"))}
                                                style={{ marginRight: '10px' }}
                                            >
                                                <Icon style={{ fontSize: '17px', width: '19px', marginRight: '6px' }} className={'icon-editar'}></Icon>
                                                Editar
                                         </Button>
                                            <Button
                                                size="sm"
                                                color="greenBorderButtonColor"
                                                onClick={() => this.props.history.goBack()}
                                            >
                                                <Icon style={{ fontSize: '17px', width: '20px' }} className={'icon-cancelar'} />Cancelar
                                        </Button>
                                        </GridItem>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <PictureUpload disabled={true} />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={9}>

                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                                                        <FieldData label="Nome" value={user.fullName}></FieldData>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <FieldData label="CPF" value={user.document}></FieldData>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <FieldData label="Celular" value={user.mobilePhoneNumber}></FieldData>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <FieldData label="Telefone" value={user.phoneNumber}></FieldData>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <FieldData label="E-mail" value={user.email}></FieldData>
                                                    </GridItem>
                                                </GridContainer>
                                            </GridItem>
                                        </GridContainer>
                                        <GridItem xs={12} >
                                            <Section title="GRUPOS ASSOCIADOS" />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={5} lg={5}>
                                            <FormControl
                                                fullWidth
                                                className={classes.selectFormControl}
                                            >
                                                <InputLabel
                                                    htmlFor="group-select"
                                                    className={classes.selectLabel}
                                                >
                                                    Nome do grupo
                                            </InputLabel>
                                                <Select
                                                    MenuProps={{
                                                        className: classes.selectMenu
                                                    }}
                                                    classes={{
                                                        select: classes.select
                                                    }}
                                                    value={this.state.groupSelected}
                                                    onChange={this.handleChange}
                                                    inputProps={{
                                                        name: "groupSelected",
                                                        id: "group-select"
                                                    }}
                                                >
                                                    <MenuItem
                                                        disabled
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                    >
                                                        Escolha um grupo
                                                    </MenuItem>
                                                    {groups.map((item, index) => (

                                                        <MenuItem
                                                            key={index}
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value={item.id}
                                                        >
                                                            {item.name}
                                                        </MenuItem>

                                                    ))}

                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={5} lg={4} style={{ float: 'left', marginTop: '20px' }}>
                                            <Button
                                                size="sm"
                                                color="primary"
                                                onClick={() => this.addGroup(this.state.groupSelected)}
                                            >
                                                <Icon style={{ fontSize: '17px', width: '20px' }} className={'icon-adicionar'} />Adicionar
                                         </Button>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Table
                                                hover
                                                tableHead={["Grupo", "Descrição", "Ações"]}
                                                tableData={userGroups.length !== 0 ? userGroups.map(item => ([
                                                    item.name,
                                                    item.description,
                                                    <div style={{ textAlign: 'left' }}>
                                                        <span className={cx('icon-bot_close', this.props.classes.actionButton)} title="Remover" onClick={() => this.removeGroup(item.id)}></span>
                                                    </div>
                                                ])) : []}
                                            />
                                            {model && model.cGroups.length === 0 &&
                                                <NoContentAlert alertText={'Não há grupos associados ao usuário'} />
                                            }
                                        </GridItem>
                                    </GridContainer>
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </CommonPage>
        );
    }
}

UserGroupForm.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {

    return {
        userData: state.user.userById,
        userDataFailed: state.user.userByIdDataFailed,

        lsGroups: state.group.lsGroups,
        groupsDataFailed: state.group.groupsDataFailed,

        user: state.authentication.user,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { SweetAlert, HideAlert, ShowLoader, HideLoader, GetUserById, GetGroups, ShowNotification, AssignGroupsToUser }
    , dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sweetAlertStyle),
    withTranslation(),
)(UserGroupForm);