import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";

// REDIX INIT
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import { Icon, FormGroup, FormControlLabel, Divider } from "@material-ui/core";

// layout components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import FieldData from "components/ViewDataField/FieldData.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CommonPage from "components/Pages/CommonPage";
import DocumentTitle from "components/Pages/DocumentTitle";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';
import Table from "components/Table/Table.jsx";
import Section from 'components/Section';
import Loader from "components/Loader/Loader.jsx";

//classes
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import { GetGroupById, ShowLoader, HideLoader } from 'redux/actions';

//utils
import compose from "utils/compose";

/**
*  @author Ayrton Gomes
*  GroupDetail class component to register new costumer
*/
class GroupDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { name: "Home" },
                { name: "Controle de acesso" },
                { name: "Grupos" },
                { name: "Detalhes", last: true },
            ],
            group: null
        }
    }


    componentDidMount() {
        this.props.ShowLoader(<Loader />);

        const id = this.props.match && this.props.match.params.id;

        this.props.GetGroupById(id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.groupData !== this.props.groupData) {
            this.setState({ group: this.props.groupData })
            this.props.HideLoader();
        }
    }


    render() {
        const { classes } = this.props;
        const { breadcrumbItems, group } = this.state;
        return (
            <CommonPage>
                <DocumentTitle pageTitle={"Detalhes do grupo"} />

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Breadcrumbs items={breadcrumbItems} buttonBack functionBack={() => this.props.history.goBack()} />
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                        <Section title="DADOS DO GRUPO" icon="icon-ico_acesso_grupo" />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    {group &&
                                        <>
                                            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FieldData label="Nome" value={group.name}></FieldData>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FieldData label="Descrição" value={group.description}></FieldData>
                                            </GridItem>
                                            <NavPills
                                                color="primary"
                                                tabs={[
                                                    {
                                                        tabButton: "PERMISSÕES",
                                                        tabContent: (
                                                            <GridContainer>
                                                                <GridItem xs={12} style={{ marginBottom: '10px' }}>
                                                                    <Section title="DETALHES DO ACESSO" />
                                                                    <h5><b>Dashboard</b></h5>
                                                                    {group.claims.indexOf('beWeather.dashboard.read') !== -1 && <span className={classes.checkboxLabel}>Consultar</span>}
                                                                </GridItem>
                                                                <Divider variant="middle" style={{ width: '98%' }} />
                                                                <GridItem xs={12} style={{ marginBottom: '10px' }}>
                                                                    <h5><b>Clientes</b></h5>
                                                                    {group.claims.indexOf('beWeather.costumer.read') !== -1 && <span className={classes.checkboxLabel}>Consultar</span>}
                                                                    {group.claims.indexOf('beWeather.costumer.create') !== -1 && <span className={classes.checkboxLabel}>Criar</span>}
                                                                    {group.claims.indexOf('beWeather.costumer.write') !== -1 && <span className={classes.checkboxLabel}>Editar</span>}
                                                                    {group.claims.indexOf('beWeather.costumer.delete') !== -1 && <span className={classes.checkboxLabel}>Apagar</span>}
                                                                </GridItem>
                                                                <Divider variant="middle" style={{ width: '98%' }} />
                                                                <GridItem xs={12} style={{ marginBottom: '10px' }}>
                                                                    <h5><b>Estações</b></h5>
                                                                    {group.claims.indexOf('beWeather.station.read') !== -1 && <span className={classes.checkboxLabel}>Consultar</span>}
                                                                    {group.claims.indexOf('beWeather.station.create') !== -1 && <span className={classes.checkboxLabel}>Criar</span>}
                                                                    {group.claims.indexOf('beWeather.station.write') !== -1 && <span className={classes.checkboxLabel}>Editar</span>}
                                                                    {group.claims.indexOf('beWeather.station.delete') !== -1 && <span className={classes.checkboxLabel}>Apagar</span>}
                                                                </GridItem>
                                                                <Divider variant="middle" style={{ width: '98%' }} />
                                                                <GridItem xs={12} style={{ marginBottom: '10px' }}>
                                                                    <h5><b>Firmware</b></h5>
                                                                    {group.claims.indexOf('beWeather.firmware.read') !== -1 && <span className={classes.checkboxLabel}>Consultar</span>}
                                                                    {group.claims.indexOf('beWeather.firmware.create') !== -1 && <span className={classes.checkboxLabel}>Criar</span>}
                                                                    {group.claims.indexOf('beWeather.firmware.delete') !== -1 && <span className={classes.checkboxLabel}>Apagar</span>}
                                                                </GridItem>
                                                                <Divider variant="middle" style={{ width: '98%' }} />
                                                                <GridItem xs={12} style={{ marginBottom: '10px' }}>
                                                                    <h5><b>Usuários</b></h5>
                                                                    {group.claims.indexOf('beWeather.user.read') !== -1 && <span className={classes.checkboxLabel}>Consultar</span>}
                                                                    {group.claims.indexOf('beWeather.user.create') !== -1 && <span className={classes.checkboxLabel}>Criar</span>}
                                                                    {group.claims.indexOf('beWeather.user.write') !== -1 && <span className={classes.checkboxLabel}>Editar</span>}
                                                                    {group.claims.indexOf('beWeather.user.delete') !== -1 && <span className={classes.checkboxLabel}>Apagar</span>}
                                                                </GridItem>
                                                                <Divider variant="middle" style={{ width: '98%' }} />
                                                                <GridItem xs={12} style={{ marginBottom: '10px' }}>
                                                                    <h5><b>Grupos</b></h5>
                                                                    {group.claims.indexOf('beWeather.group.read') !== -1 && <span className={classes.checkboxLabel}>Consultar</span>}
                                                                    {group.claims.indexOf('beWeather.group.create') !== -1 && <span className={classes.checkboxLabel}>Criar</span>}
                                                                    {group.claims.indexOf('beWeather.group.write') !== -1 && <span className={classes.checkboxLabel}>Editar</span>}
                                                                    {group.claims.indexOf('beWeather.group.delete') !== -1 && <span className={classes.checkboxLabel}>Apagar</span>}
                                                                </GridItem>
                                                            </GridContainer>

                                                        )
                                                    },
                                                    {
                                                        tabButton: "USUÁRIOS ASSOCIADOS",
                                                        tabContent: (
                                                            <GridContainer>
                                                                <GridItem xs={12} style={{ margin: '20px 0' }}>
                                                                    <Section title="USUÁRIOS" />
                                                                </GridItem>
                                                                <GridItem xs={12}>
                                                                    <Table
                                                                        hover
                                                                        tableHead={["Nome", "E-mail"]}
                                                                        tableData={group.users.map((x) => ([
                                                                            x.fullName,
                                                                            x.email
                                                                        ]))}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>
                                                        )
                                                    }
                                                ]}
                                            />
                                        </>
                                    }
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </CommonPage>
        );
    }
}

GroupDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {

    return {
        groupData: state.group.groupById,
        groupDataFailed: state.group.groupByIdDataFailed,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { ShowLoader, HideLoader, GetGroupById }
    , dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sweetAlertStyle),
    withTranslation(),
)(GroupDetail);