// LOGIN USER
export const LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const LOGOUT = "USERS_LOGOUT";
export const GETALL_REQUEST = "USERS_GETALL_REQUEST";
export const GETALL_SUCCESS = "USERS_GETALL_SUCCESS";
export const GETALL_FAILURE = "USERS_GETALL_FAILURE";

// DASHBOARD
export const FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA";
export const FETCH_CHARTS_DATA = "FETCH_CHARTS_DATA";
export const FETCH_ANNOUNCEMENT_DATA = "FETCH_ANNOUNCEMENT_DATA";
export const FETCH_DASHBOARD_FAILED = "FETCH_DASHBOARD_FAILED";
export const FETCH_CHARTS_FAILED = "FETCH_CHARTS_FAILED";
export const FETCH_ANNOUNCEMENT_FAILED = "FETCH_ANNOUNCEMENT_FAILED";

// IMAGES
export const POST_IMAGE_DATA = "POST_IMAGE_DATA";
export const SET_FILE_DATA = "SET_FILE_DATA";
export const FETCH_THUMBNAILS = "FETCH_THUMBNAILS";
export const FETCH_IMAGE_FAILED = "FETCH_IMAGE_FAILED";

// SWEET-ALERT
export const SWEET_ALERT = "SWEET_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

// LOADER
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

// NOTIFICATION
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

//
export const FETCH_UNAUTH_USER = "FETCH_UNAUTH_USER";

export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_USER_BY_ID_DATA = "FETCH_USER_BY_ID_DATA";
export const FETCH_USER_BY_ID_FAILED = "FETCH_USER_BY_ID_FAILED";
export const POST_USER_DATA = "POST_USER_DATA";
export const POST_USER_DATA_FAILED = "POST_USER_DATA_FAILED";
export const PUT_USER_DATA = "PUT_USER_DATA";
export const PUT_USER_DATA_FAILED = "PUT_USER_DATA_FAILED";
export const DELETE_USER_DATA = "DELETE_USER_DATA";
export const DELETE_USER_DATA_FAILED = "DELETE_USER_DATA_FAILED";
export const POST_USER_ASSIGN_GROUPS_DATA = "POST_USER_ASSIGN_GROUPS_DATA";
export const POST_USER_ASSIGN_GROUPS_DATA_FAILED = "POST_USER_ASSIGN_GROUPS_DATA_FAILED";
export const POST_RESET_USER_PASSWORD = "POST_RESET_USER_PASSWORD";
export const POST_RESET_USER_PASSWORD_FAILED = "POST_RESET_USER_PASSWORD_FAILED";

export const FETCH_GROUP_DATA = "FETCH_GROUP_DATA";
export const FETCH_GROUP_FAILED = "FETCH_GROUP_FAILED";
export const FETCH_GROUP_BY_ID_DATA = "FETCH_GROUP_BY_ID_DATA";
export const FETCH_GROUP_BY_ID_FAILED = "FETCH_GROUP_BY_ID_FAILED";
export const POST_GROUP_DATA = "POST_GROUP_DATA";
export const POST_GROUP_DATA_FAILED = "POST_GROUP_DATA_FAILED";
export const PUT_GROUP_DATA = "PUT_GROUP_DATA";
export const PUT_GROUP_DATA_FAILED = "PUT_GROUP_DATA_FAILED";
export const DELETE_GROUP_DATA = "DELETE_GROUP_DATA";
export const DELETE_GROUP_DATA_FAILED = "DELETE_GROUP_DATA_FAILED";

// CUSTOMERS
export const FETCH_CUSTOMERS_DATA = "FETCH_CUSTOMERS_DATA";
export const FETCH_CUSTOMERS_FAILED = "FETCH_CUSTOMERS_FAILED";
export const FETCH_CUSTOMER_BY_ID = "FETCH_CUSTOMER_BY_ID";
export const FETCH_CUSTOMER_BY_ID_FAILED = "FETCH_CUSTOMER_BY_ID_FAILED";
export const POST_CUSTOMER_DATA = "POST_CUSTOMER_DATA";
export const POST_CUSTOMER_DATA_FAILED = "POST_CUSTOMER_DATA_FAILED";
export const CREATE_CUSTOMER_DATA = "CREATE_CUSTOMER_DATA";
export const CREATE_CUSTOMER_DATA_FAILED = "CREATE_CUSTOMER_DATA_FAILED";
export const FETCH_ATTACH_STATION = "FETCH_ATTACH_STATION";
export const FETCH_ATTACH_STATION_FAILED = "FETCH_ATTACH_STATION_FAILED";
export const DELETE_CUSTOMER_DATA = "DELETE_CUSTOMER_DATA";
export const DELETE_CUSTOMER_DATA_FAILED = "DELETE_CUSTOMER_DATA_FAILED";
export const POST_CUSTOMER_NEW_PASSWORD = "POST_CUSTOMER_NEW_PASSWORD";
export const POST_CUSTOMER_NEW_PASSWORD_FAILED = "POST_CUSTOMER_NEW_PASSWORD_FAILED";

// STATIONS
export const FETCH_STATION_DATA = "FETCH_STATION_DATA";
export const FETCH_STATION_FAILED = "FETCH_STATION_FAILED";
export const FETCH_ALL_STATIONS_OWNERLESS = "FETCH_ALL_STATIONS_OWNERLESS";
export const FETCH_ALL_STATIONS_OWNERLESS_FAILED = "FETCH_ALL_STATIONS_OWNERLESS_FAILED";
export const POST_STATION_DATA = "POST_STATION_DATA";
export const POST_STATION_DATA_FAILED = "POST_STATION_DATA_FAILED";
export const PUT_STATION_DATA = "PUT_STATION_DATA";
export const PUT_STATION_DATA_FAILED = "PUT_STATION_DATA_FAILED";
export const FETCH_STATION_BY_ID = "FETCH_STATION_BY_ID";
export const FETCH_STATION_BY_ID_FAILED = "FETCH_STATION_BY_ID_FAILED";
export const PUT_DISASSOACIATE_USER_STATION_DATA_FAILED = "PUT_DISASSOACIATE_USER_STATION_DATA_FAILED";
export const PUT_DISASSOACIATE_USER_STATION_DATA = "PUT_DISASSOACIATE_USER_STATION_DATA";
export const DELETE_STATION_DATA = "DELETE_STATION_DATA";
export const DELETE_STATION_DATA_FAILED = "DELETE_STATION_DATA_FAILED";

// ACCOUNT
export const FETCH_CHANGE_PASSWORD_DATA = "FETCH_CHANGE_PASSWORD_DATA";
export const FETCH_CHANGE_PASSWORD_FAILED = "FETCH_CHANGE_PASSWORD_FAILED";
export const PUT_CUSER_DATA = "PUT_USER_DATA";
export const PUT_CUSER_DATA_FAILED = "PUT_USER_DATA_FAILED";
export const FETCH_PROFILE_DATA = "FETCH_PROFILE_DATA";
export const FETCH_PROFILE_DATA_FAILED = "FETCH_PROFILE_DATA_FAILED";
export const POST_SEND_TOKEN_CUSER = "POST_SEND_TOKEN_CUSER";
export const POST_SEND_TOKEN_CUSER_FAILED = "POST_SEND_TOKEN_CUSER_FAILED";
export const POST_VALIDATE_TOKEN_CUSER = "POST_VALIDATE_TOKEN_CUSER";
export const POST_VALIDATE_TOKEN_CUSER_FAILED = "POST_VALIDATE_TOKEN_CUSER_FAILED";
export const FETCH_FORGET_PASSWORD_DATA = "FETCH_FORGET_PASSWORD_DATA";
export const FETCH_FORGET_PASSWORD_DATA_FAILED = "FETCH_FORGET_PASSWORD_DATA_FAILED";


// SYSTEM CONFIG
export const FETCH_CUSTOMIZATION_DATA = "FETCH_CUSTOMIZATION_DATA";
export const FETCH_CUSTOMIZATION_FAILED = "FETCH_CUSTOMIZATION_FAILED";
export const POST_CUSTOMIZATION_DATA = "POST_CUSTOMIZATION_DATA";
export const POST_CUSTOMIZATION_FAILED = "POST_CUSTOMIZATION_FAILED";

// FIRMWARE
export const FETCH_FIRMWARE_DATA = "FETCH_FIRMWARE_DATA";
export const FETCH_FIRMWARE_FAILED = "FETCH_FIRMWARE_FAILED";
export const POST_FIRMWARE_DATA = "POST_FIRMWARE_DATA";
export const POST_FIRMWARE_DATA_FAILED = "POST_FIRMWARE_DATA_FAILED";
export const POST_FIRMWARE_FILE = "POST_FIRMWARE_FILE";
export const POST_FIRMWARE_FILE_FAILED = "POST_FIRMWARE_FILE_FAILED";
export const DELETE_FIRMWARE_DATA = "DELETE_FIRMWARE_DATA";
export const DELETE_FIRMWARE_DATA_FAILED = "DELETE_FIRMWARE_DATA_FAILED";