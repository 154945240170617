const customPaginationStyle = {
    toolbar: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		background: '#fafafa',
		minHeight: '40px'
    },
    totalCount: {
        color: 'black',
        right: '50px',
        position: 'absolute'
    }
}

export default customPaginationStyle;