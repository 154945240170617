import * as actionTypes from './actionsTypes';

export const showNotification = (data) => {
    return {
        type: actionTypes.SHOW_NOTIFICATION,
        notification: data
    };
};

export const hideNotification = () => {
    return {
        type: actionTypes.HIDE_NOTIFICATION,
        notification: null
    };
};

//----------------------------------------------------

export const ShowNotification = (data) => {
    return dispatch => (
        dispatch(showNotification(data))
    );
};
export const HideNotification = () => {
    return dispatch => (
        dispatch(hideNotification())
    );
}
