import React from "react";

import * as Styled from "./styles";

function ModalContact({ content: { email, phone, whatsapp, description } }) {
  return (
    <Styled.ContactModalBox container>
      <div>
        <section>
          <p>Email</p>
          <small>{email}</small>
        </section>
        <section>
          <p>Telefone</p>
          <small>{phone}</small>
        </section>
        <section>
          <p>Whatsapp</p>
          <small>{whatsapp}</small>
        </section>
      </div>
      <footer>
        <p>{description}</p>
      </footer>
    </Styled.ContactModalBox>
  );
}

export default ModalContact;
