import * as actionTypes from '../../actions/actionsTypes';
import {
    updateObject
} from '../../utility';

const initialState = {
    userPwd: false,
    userPwdFailed: false,

    lsUsers: null,
    usersDataFailed: false,

    userById: null,
    userByIdDataFailed: false,

    postUser: null,
    postUserFailed: false,

    putUser: null,
    putUserFailed: false,

    deleteUserData: null,
    deleteUserDataFailed: false,

    assignGroupsUserData: null,
    assignGroupsUserDataFailed: false,
};

const fetchUserData = (state, action) => {
    return updateObject(state, {
        lsUsers: action.lsUsers
    });
};

const fetchUserByIdData = (state, action) => {
    return updateObject(state, {
        userById: action.userById
    });
};

const postUserData = (state, action) => {
    return updateObject(state, {
        postUser: action.postData
    });
};

const putUserData = (state, action) => {
    return updateObject(state, {
        putUser: action.putData
    });
};

const fetchChangePassword = (state, action) => {
    return updateObject(state, {
        userPwd: action.userPwd
    });
};

// DATA FAIL
const fetchUserByIdFailed = (state, action) => {
    return updateObject(state, {
        userByIdDataFailed: true
    });
};

const fetchUserFailed = (state, action) => {
    return updateObject(state, {
        usersDataFailed: true
    });
};

const fetchChangePasswordFailed = (state, action) => {
    return updateObject(state, {
        userPwdFailed: true
    });
};

const postUserDataFailed = (state, action) => {
    return updateObject(state, {
        postUserFailed: true
    });
};

const putUserDataFailed = (state, action) => {
    return updateObject(state, {
        putUserFailed: true
    });
};


// DELETE 
const deleteUserData = (state, action) => {
    return updateObject(state, {
        deleteUserData: action.deleteData
    });
};
const deleteUserDataFailed = (state, action) => {
    return updateObject(state, {
        deleteUserDataFailed: true
    });
};


// ASSIGN GROUPS 
const assignGroupsUserData = (state, action) => {
    return updateObject(state, {
        assignGroupsUserData: action.assignGroupsData
    });
};
const assignGroupsUserDataFailed = (state, action) => {
    return updateObject(state, {
        assignGroupsUserDataFailed: true
    });
};


// RESET PASSWORD 
const resetPassword = (state, action) => {
    return updateObject(state, {
        resetPsswd: action.resetPsswd
    });
};

const resetPasswordFailed = (state, action) => {
    return updateObject(state, {
        resetPsswd: action.resetPasswordFailed
    });
};

//
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USER_DATA:
            return fetchUserData(state, action);
        case actionTypes.FETCH_USER_FAILED:
            return fetchUserFailed(state, action);

        case actionTypes.FETCH_USER_BY_ID_DATA:
            return fetchUserByIdData(state, action);
        case actionTypes.FETCH_USER_BY_ID_FAILED:
            return fetchUserByIdFailed(state, action);

        case actionTypes.POST_USER_DATA:
            return postUserData(state, action);
        case actionTypes.POST_USER_DATA_FAILED:
            return postUserDataFailed(state, action);

        case actionTypes.PUT_USER_DATA:
            return putUserData(state, action);
        case actionTypes.PUT_USER_DATA_FAILED:
            return putUserDataFailed(state, action);

        case actionTypes.FETCH_CHANGE_PASSWORD_DATA:
            return fetchChangePassword(state, action);
        case actionTypes.FETCH_CHANGE_PASSWORD_FAILED:
            return fetchChangePasswordFailed(state, action);


        case actionTypes.DELETE_USER_DATA:
            return deleteUserData(state, action);
        case actionTypes.DELETE_USER_DATA_FAILED:
            return deleteUserDataFailed(state, action);


        case actionTypes.POST_USER_ASSIGN_GROUPS_DATA:
            return assignGroupsUserData(state, action);
        case actionTypes.POST_USER_ASSIGN_GROUPS_DATA_FAILED:
            return assignGroupsUserDataFailed(state, action);

        case actionTypes.POST_RESET_USER_PASSWORD:
            return resetPassword(state, action);
        case actionTypes.POST_RESET_USER_PASSWORD_FAILED:
            return resetPasswordFailed(state, action);

        default:
            return state;
    }
};

export default userReducer;