import React from "react";
import PropTypes from "prop-types";

const RenderDescription = ({ renderComp }) => {
	return (
		<div className="render-html">
			<div
				style={{ width: "100%" }}
				contentEditable={false}
				dangerouslySetInnerHTML={{
					__html: renderComp
						? renderComp
						: "O conteúdo da mensagem não está mais disponível",
				}}
			/>
		</div>
	);
};

RenderDescription.propTypes = {
	renderComp: PropTypes.string,
};

RenderDescription.defaultProps = {
	renderComp: "",
};

export default RenderDescription;
