import { Autocomplete } from '@material-ui/lab'
import styled from 'styled-components'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react.jsx'

export const CustomAutocomplete = styled(Autocomplete)`
	.MuiFormLabel-root {
		font-size: 12px;
		padding-top: 5px;
	}

	.MuiInput-underline.Mui-disabled:before {
		border-bottom-style: solid;
	}

	.MuiInput-underline:before {
		border-bottom: 3px solid ${germiniDefaultColors[1]};
	}
`
