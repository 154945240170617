import styled, { css } from 'styled-components'

export const Icon = styled.i`
	${({ color, size, disabled }) => css`
		font-size: ${size};

		::before {
			color: ${color};
		}
	`}
`
