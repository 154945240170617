import React from 'react'
import { TablePagination } from '@material-ui/core'
import MaterialTable from 'material-table'

import * as Styled from './styles'

export const initialPagination = {
	currentPage: 0,
	page: 0,
	pageCount: 1,
	pageSize: 10,
	recordCount: 1,
	filter: {
		startDate: null,
		endDate: null,
	},
}

export const initialSort = {
	orderBy: '',
	orderType: '',
}

function CustomTable({
	pagination,
	loading,
	setPagination,
	sort,
	setSort,
	data,
	columns,
	actions,
	toolbar = false,
	style,
	tableRef,
	changePage = () => null,
}) {
	return (
		<Styled.TableContainer toolbar={toolbar} style={style}>
			<MaterialTable
				tableRef={tableRef}
				key={data?.length}
				components={{
					Container: (props) => <div>{props.children}</div>,
					Pagination: (props) => {
						return (
							<TablePagination
								{...props}
								rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
								count={pagination.recordCount}
								page={pagination.currentPage}
								rowsPerPage={pagination.pageSize}
								onChangePage={(e, page) =>
									setPagination((prevState) => {
										changePage({
											...prevState,
											page,
										})

										return {
											...prevState,
											currentPage: page,
											page:
												Number(page) *
												pagination.pageSize,
										}
									})
								}
							/>
						)
					},
				}}
				title=""
				options={{
					emptyRowsWhenPaging: false,
					search: toolbar ?? false,
					toolBar: false,
					pageSize: pagination.pageSize,
					actionsColumnIndex: -1,
					draggable: false,
				}}
				columns={columns}
				actions={actions}
				data={data}
				onChangeRowsPerPage={(e) =>
					e !== pagination.pageSize
						? setPagination((prevState) => {
								changePage({
									...prevState,
									pageSize: e,
									page: 0,
								})
								return {
									...prevState,
									pageSize: e,
									currentPage: 0,
								}
						  })
						: ''
				}
				onOrderChange={(orderBy, orderType) =>
					setSort((prevState) => {
						if (prevState.orderBy === orderBy) {
							if (prevState.orderType === 'desc') {
								return {
									orderBy: '',
									orderType: '',
								}
							} else if (prevState.orderType === '') {
								return {
									orderBy: orderBy,
									orderType: 'asc',
								}
							} else {
								return {
									orderBy: orderBy,
									orderType: 'desc',
								}
							}
						} else {
							return {
								orderBy: orderBy,
								orderType: orderType,
							}
						}
					})
				}
				localization={{
					header: {
						actions: 'Ações',
					},
					pagination: {
						labelRowsSelect: 'linhas',
					},
					body: {
						emptyDataSourceMessage: 'Nenhum registro encontrado.',
					},
					toolbar: {
						searchTooltip: 'Buscar',
						searchAriaLabel: 'Buscar',
						searchPlaceholder: 'Buscar',
					},
				}}
				isLoading={loading}
			/>
		</Styled.TableContainer>
	)
}

export default CustomTable
