import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DocumentTitle from "components/Pages/DocumentTitle";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import logoImg from "assets/img/logo_login.png";

import { SendTokenToEmail, ShowLoader } from 'redux/actions';

// Utils
import compose from "utils/compose";

class SendTokenForm extends React.Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered

        //this.props.logout();

        this.state = {
            cardAnimaton: "cardHidden",
            // login form
            email: "",
            submitted: false
        };
    }

    change(event, stateName, type) {
        switch (type) {
            case "email":
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + "State"]: "success", [stateName]: event.target.value });
                } else {
                    this.setState({ [stateName + "State"]: "error", [stateName]: "" });
                }
                break;
            default:
                break;
        }
    }

    submit = (e) => {
        e.preventDefault();
        this.setState({ submitted: true });
        const { email } = this.state;
        if (email) {
            this.props.SendTokenToEmail(email).then(resp => {
                if (resp.status === 200) {
                    this.props.history.push('/forgetpassword/validatetoken')
                }
            });
        }
    }
    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    verifyNumber(value) {
        var numberRex = new RegExp("^[0-9]+$");
        if (numberRex.test(value)) {
            return true;
        }
        return false;
    }

    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }


    render() {
        const { t, classes, history } = this.props;
        return (
            <div className={classes.container}>
                <DocumentTitle pageTitle={t("FIELD_PARTICIPANTS_BLOCK_LOGIN")} />

                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <form name="form" onSubmit={this.submit}>
                            <Card login className={classes[this.state.cardAnimaton]} style={{ backgroundColor: "#eee", paddingBottom: '20px', marginBottom: 0 }}>
                                <div className={classes.textCenter}
                                    style={{ marginTop: '-40px' }}
                                >
                                    <img src={logoImg} alt="logo" className={classes.img} />
                                </div>
                                <CardBody>
                                    <Typography style={{ fontSize: '14px', color: "#454545" }}>
                                        Informe o e-mail cadastrado para redefinir a senha de acesso.
                                        <br />
                                        Em seguida, você irá receber em seu e-mail um código token que permitirá redefinir a sua senha.
                                    </Typography>
                                    <CustomInput
                                        blueUnderline
                                        success={this.state.email === "success"}
                                        error={this.state.email === "error"}
                                        labelText={t("LOGIN_EMAIL")}
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            onChange: event =>
                                                this.change(event, "email", "email"),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Email className={classes.inputAdornmentIcon} />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </CardBody>
                                <CardFooter className={classes.justifyContentCenter} style={{ margin: "auto", width: '90%' }}>
                                    <Button color="primary" block type="submit">
                                        Enviar
                                    </Button>
                                    <Button color="greenBorderButtonColor" block onClick={() => history.push('/auth/login')}>
                                        Cancelar
                                    </Button>
                                </CardFooter>
                            </Card>
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

SendTokenForm.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {

    const { profile } = state;

    return {
        sendTokenToEmail: profile.sendTokenToEmail,
        sendTokenToEmailFailed: profile.sendTokenToEmailFailed,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { SendTokenToEmail, ShowLoader }, dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(loginPageStyle),
    withTranslation()
)(SendTokenForm);