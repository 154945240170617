import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .header-grow {
      header {
        z-index: 0 !important;
      }
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  .icon-voltar:before,.icon-cancelar:before {
    color: ${germiniDefaultColors[1]};
  }

  .button-icon {
    :hover, :focus {
      .icon-voltar:before,.icon-cancelar:before {
        color: white;
      }
    }
  }
`;

export default GlobalStyle;
