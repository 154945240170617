import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DocumentTitle from "components/Pages/DocumentTitle";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import logoImg from "assets/img/logo_login.png";

import { login, logout } from '../../../redux/actions';

// Utils
import compose from "utils/compose";

class LoginPage extends React.Component {
	constructor(props) {
		super(props);
		// we use this to make the card to appear after the page has been rendered

		this.props.logout();

		this.state = {
			cardAnimaton: "cardHidden",
			// login form
			login: "",
			loginState: "",
			password: "",
			passwordState: "",
			submitted: false
		};
	}

	change(event, stateName, type) {
		switch (type) {
			case "login":
				if (this.verifyLength(event.target.value, 1)) {
					this.setState({ [stateName + "State"]: "success", [stateName]: event.target.value });
				} else {
					this.setState({ [stateName + "State"]: "error", [stateName]: "" });
				}
				break;
			case "password":
				if (this.verifyLength(event.target.value, 1)) {
					this.setState({ [stateName + "State"]: "success", [stateName]: event.target.value });
				} else {
					this.setState({ [stateName + "State"]: "error", [stateName]: "" });
				}
				break;
			default:
				break;
		}
	}

	submit = (e) => {
		e.preventDefault();
		this.setState({ submitted: true });
		const { login, password } = this.state;
		if (login && password) {
			this.props.login(login, password);
		}
	}
	// function that verifies if a string has a given length or not
	verifyLength(value, length) {
		if (value.length >= length) {
			return true;
		}
		return false;
	}

	verifyNumber(value) {
		var numberRex = new RegExp("^[0-9]+$");
		if (numberRex.test(value)) {
			return true;
		}
		return false;
	}

	componentDidMount() {
		// we add a hidden class to the card and after 700 ms we delete it and the transition appears
		this.timeOutFunction = setTimeout(
			function () {
				this.setState({ cardAnimaton: "" });
			}.bind(this),
			700
		);
	}
	componentWillUnmount() {
		clearTimeout(this.timeOutFunction);
		this.timeOutFunction = null;
	}

	render() {
		const { t, classes, loggingIn, logginFailed } = this.props;
		return (
			<div className={classes.container}>
				<DocumentTitle pageTitle={t("FIELD_PARTICIPANTS_BLOCK_LOGIN")} />

				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={4}>
						<form name="form" onSubmit={this.submit}>
							<Card login className={classes[this.state.cardAnimaton]} style={{ backgroundColor: "#eee", paddingBottom: '20px', marginBottom: 0 }}>
								<div className={classes.textCenter}
									style={{ marginTop: '-40px' }}
								>
									<img src={logoImg} alt="logo" className={classes.img} />
								</div>
								<CardBody>
									<CustomInput
										blueUnderline
										success={this.state.loginState === "success"}
										error={this.state.loginState === "error"}
										labelText={t("LOGIN_EMAIL")}
										id="login"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											onChange: event =>
												this.change(event, "login", "login"),
											endAdornment: (
												<InputAdornment position="end">
													<Face className={classes.inputAdornmentIcon} />
												</InputAdornment>
											)
										}}
									/>
									<CustomInput
										blueUnderline
										success={this.state.passwordState === "success"}
										error={this.state.passwordState === "error"}
										labelText={t("LOGIN_PASSWORD")}
										id="password"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											onChange: event =>
												this.change(event, "password", "password"),
											endAdornment: (
												<InputAdornment position="end">
													<Icon className={classes.inputAdornmentIcon}>
														lock_outline
                          							</Icon>
												</InputAdornment>
											),
											type: "password"
										}}
									/>
								</CardBody>
								{logginFailed &&
									<div style={{ textAlign: "center" }}>
										<Typography color="error" style={{ fontWeight: 'bold' }}>
											{t("LOGIN_FAILED")}
										</Typography>
									</div>
								}
								<CardFooter className={classes.justifyContentCenter} style={{ margin: "auto", width: '60%' }}>

									<Button color="success" type="submit" size="lg" block disabled={loggingIn} >
										{t("LOGIN_SEND")}
									</Button>

									{loggingIn &&
										<CircularProgress className={classes.buttonProgress} />
									}
								</CardFooter>
							</Card>
							<NavLink to="/forgetpassword/sendtoken">
								<Button className={classes[this.state.cardAnimaton]} color="success" type="button" simple size="sm" block disabled={loggingIn} style={{ padding: 0, justifyContent: 'end', color: '#ccc' }}>
									{t("LOGIN_FORGET_PASSWORD")}
								</Button>
							</NavLink>
						</form>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

LoginPage.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	const { loggingIn, loggedIn, logginFailed } = state.authentication;

	return {
		loggingIn,
		loggedIn,
		logginFailed
	};
}

const mapDispatchToProps = dispatch => bindActionCreators(
	{ login, logout }, dispatch);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(loginPageStyle),
	withTranslation()
)(LoginPage);