import { Checkbox } from "@material-ui/core";
import React from "react";
import * as Styled from "./styles";

export default function CustomCheckBox({ label, align, padding, ...props }) {
	return (
		<Styled.LabelForm
			{...props}
			align={align}
			padding={padding}
			control={
				<Checkbox
					color="primary"
					inputProps={{
						"aria-label": "secondary checkbox",
					}}
					{...props}
				/>
			}
			label={label}
		/>
	);
}
