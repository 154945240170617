import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";


import { withTranslation } from 'react-i18next';


import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";


class HeaderLinks extends React.Component {
	state = {
		open: false,
		openLang: false
	};
	handleClick = () => {
		this.setState({ open: !this.state.open });
	};
	handleClickLang = () => {
		this.setState({ openLang: !this.state.openLang });
	}
	handleClose = () => {
		this.setState({ open: false });
	};
	handleCloseLang = () => {
		this.setState({ openLang: false });
	};
	handleChangeLanguage = (lng) => {
		this.props.i18n.changeLanguage(lng);
		this.setState({ openLang: false });
	};
	render() {
		const { classes, rtlActive } = this.props;
		const wrapper = classNames({
			[classes.wrapperRTL]: rtlActive
		});
		return (
			<div className={wrapper}>
				{/* <CustomInput
					rtlActive={rtlActive}
					formControlProps={{
						className: classes.top + " " + classes.search
					}}
					inputProps={{
						placeholder: rtlActive ? "بحث" : "Buscar",
						inputProps: {
							"aria-label": rtlActive ? "بحث" : "Buscar",
							className: classes.searchInput
						}
					}}
				/>
				<Button
					color="white"
					aria-label="edit"
					justIcon
					round
					className={searchButton}
				>
					<Search
						className={classes.headerLinksSvg + " " + classes.searchIcon}
					/>
				</Button> */}

				{/* LANGUAGES */}
				{/* <div className={managerClasses}>
					<Button
						color="transparent"
						justIcon
						aria-label="Languages"
						aria-owns={openLang ? "menu-list-lang" : null}
						aria-haspopup="true"
						onClick={this.handleClickLang}
						className={classes.buttonLink}
						buttonRef={node => {
							this.anchorElLang = node;
						}}
					>
						<Language
							className={
								classes.headerLinksSvg +
								" " +
								(classes.links)
							}
						/>
						<Hidden mdUp implementation="css">
							<span onClick={this.handleClickLang} className={classes.linkText}>
								{"Notification"}
							</span>
						</Hidden>
					</Button>
					<Popper
						open={openLang}
						anchorEl={this.anchorElLang}
						transition
						disablePortal
						placement="bottom"
						className={classNames({
							[classes.popperClose]: !openLang,
							[classes.pooperResponsive]: true,
							[classes.pooperNav]: true
						})}
					>
						{({ TransitionProps }) => (
							<Grow
								{...TransitionProps}
								id="menu-list-lang"
								style={{ transformOrigin: "0 0 0" }}
							>
								<Paper className={classes.dropdown}>
									<ClickAwayListener onClickAway={this.handleCloseLang}>
										<MenuList role="menu">
											<MenuItem
												onClick={this.handleChangeLanguage.bind(this, 'pt-BR')}
												className={dropdownItem}>
												<img src={iconBR} alt="BR" /> Brasil
											</MenuItem>
											<MenuItem
												onClick={this.handleChangeLanguage.bind(this, 'es')}
												className={dropdownItem}>
												<img src={iconES} alt="ES" /> Espanhol
											</MenuItem>
											<MenuItem
												onClick={this.handleChangeLanguage.bind(this, 'us')}
												className={dropdownItem}>
												<img src={iconUS} alt="US" /> USA
											</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</div> */}
				{/* END LANGUAGES */}
			</div>
		);
	}
}

HeaderLinks.propTypes = {
	classes: PropTypes.object.isRequired,
	rtlActive: PropTypes.bool
};

export default withStyles(adminNavbarLinksStyle)(withTranslation()(HeaderLinks));