import axios from "axios";

export const GET_STATION_GROUP_MANAGER_ALL = "/api/GroupManager/getall";
export const POST_GROUP_MANAGER = "/api/GroupManager/upsert";
export const POST_LINK_STATIONS = "/api/GroupManager/LinkStationsAndUsers";
export const POST_UNLINK_STATIONS = "/api/GroupManager/UnlinkStationsAndUsers";
export const GET_GROUP_MANAGER_BY_ID = "/api/GroupManager/getbyid";
export const GET_GROUP_MANAGER_STATIONS = (groupId) =>
	`/api/GroupManager/${groupId}/Stations`;
export const GET_GROUP_SELECT_AND_REMOVE =
	"/api/GroupManager/SelectedAndAvailableStationAndUsers?groupId=";
export const DELETE_GROUP = "/api/GroupManager/delete?id=";
export const GET_ALL_GROUPS = "/api/GroupManager/GetAllGroups";
export const GET_ALL_GROUP_BY_FILTER = "/api/GroupManager/GetAllGroupByFilter";

export const getStationGroupManager = async (url, setLoading) => {
	try {
		const { data } = await axios.get(url);
		return data;
	} catch (error) {}
};

export const getGroupManagerStations = async (url) => {
	try {
		const { data } = await axios.get(url);
		return data;
	} catch (error) {}
};

export const getGroupSelectAndRemove = async (id) => {
	try {
		const { data } = await axios.get(`${GET_GROUP_SELECT_AND_REMOVE}${id}`);
		return data;
	} catch (error) {}
};

export const getGroupManagerById = async (id) => {
	try {
		const { data } = await axios.get(`${GET_GROUP_MANAGER_BY_ID}?id=${id}`);
		return data;
	} catch (error) {}
};

export const postGroupManager = async (body) => {
	try {
		const { data } = await axios.post(POST_GROUP_MANAGER, body);
		return data;
	} catch (error) {}
};

export const postLinkStation = async (body) => {
	try {
		const { data } = await axios.post(POST_LINK_STATIONS, body);
		return data;
	} catch (error) {}
};

export const postUnlinkStation = async (body) => {
	try {
		const { data } = await axios.post(POST_UNLINK_STATIONS, body);
		return data;
	} catch (error) {}
};

export const getStationGroupManagerItens = async (url, setLoading) => {
	try {
		const { data } = await axios.get(url);
		return data.items;
	} catch (error) {}
};

export const deleteGroup = async (id) => {
	try {
		const { data } = await axios.delete(DELETE_GROUP + id);
		return data;
	} catch (error) {}
};

export const getAllgroups = async (url) => {
	try {
		const { data } = await axios.get(url);
		return data;
	} catch (error) {}
};

export const getAllGroupByFilter = async (url) => {
	try {
		const { data } = await axios.get(url);
		return data;
	} catch (error) {}
};
