import React from "react";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "components/CustomButtons/Button.jsx";

import styles from "./BreadcrumbsNav.module.scss";

class BreadcrumbsNav extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//titleNavPill: "Regras Gerais",
			items: props.items,
		};
	}
	render() {
		const { functionBack, buttonBack, buttonEdit, functionEdit } = this.props;
		return (
			<div style={{ display: "flex", paddingBottom: "10px" }}>
				<Breadcrumbs
					arial-label="Breadcrumb"
					separator="›"
					className={styles.breadcrumb}
					style={{ marginTop: "11px", marginLeft: "25px" }}
				>
					{this.state.items.map((i) =>
						!i.last ? (
							<div key={i}>{i.name}</div>
						) : (
							<Typography color="primary" key={i}>
								{i.name}
							</Typography>
						)
					)}
				</Breadcrumbs>
				{buttonEdit && (
					<Button
						style={{
							float: "right",
							position: "absolute",
							right: "150px",
							marginTop: "0.3em",
						}}
						color="primary"
						size="sm"
						onClick={functionEdit}
					>
						<Icon
							style={{
								fontSize: "12px",
								width: "17px",
							}}
							className={"icon-editar"}
						></Icon>
						Editar
					</Button>
				)}
				{buttonBack && (
					<Button
						style={{
							float: "right",
							position: "absolute",
							right: "45px",
							marginTop: "0.3em",
						}}
						color="greenBorderButtonColor"
						size="sm"
						onClick={functionBack}
					>
						<Icon
							style={{ fontSize: "12px", width: "17px" }}
							className={"icon-voltar"}
						></Icon>
						Voltar
					</Button>
				)}
			</div>
		);
	}
}

export default BreadcrumbsNav;
