import { Grid } from "@material-ui/core";
import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react";
import styled from "styled-components";

export const SearchBox = styled(Grid)`
  margin: 20px 0;
  padding: 10px 20px;

  h5 {
    color: ${germiniDefaultColors[5]};
    font-size: 14px;
  }

  .MuiFormLabel-root {
    font-size: 14px;
  }

  .MuiSelect-root {
    margin-top: 1px;
  }
`;
