import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/styles";

// core components
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const FieldInput = ({
	field,
	label,
	touched,
	error,
	form,
	labelGrid,
	inputGrid,
	disabled,
	maskCustom,
	...props
}) => {
	const { inputType } = props;
	return (
		<>
			{labelGrid && labelGrid > 0 && (
				<GridItem
					xs={12}
					sm={labelGrid}
					md={labelGrid}
					lg={labelGrid}
					xl={labelGrid}
				>
					<FormLabel
						className={props.classes.labelHorizontal}
						error={touched && Boolean(error)}
					>
						{label}:
					</FormLabel>
				</GridItem>
			)}

			<GridItem
				xs={12}
				sm={12}
				md={inputGrid}
				lg={inputGrid}
				xl={inputGrid}
			>
				<FormControl fullWidth>
					<CustomInput
						error={touched && Boolean(error)}
						labelText={label}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							...field,
							disabled: disabled,
							inputComponent: maskCustom,
						}}
						type={inputType}
					/>
				</FormControl>
			</GridItem>
		</>
	);
};

FieldInput.propTypes = {
	field: PropTypes.object.isRequired,
	inputGrid: PropTypes.number.isRequired,
	label: PropTypes.string.isRequired,
};

export default withStyles(styles)(FieldInput);
