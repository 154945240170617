import * as Yup from 'yup'

let dateValidation = Yup.object().shape({
	title: Yup.string().required('Título obrigatório'),
	description: Yup.string().required('Descrição obrigatória'),
	sendForAllUsers: Yup.boolean(),
	usersId: Yup.array(),
	groupIds: Yup.array(),
	stationsId: Yup.array(),
})

dateValidation = dateValidation.test('checkBoxTest', (obj) => {
	if (
		obj.sendForAllUsers ||
		obj.usersId.length > 0 ||
		obj.groupIds.length > 0 ||
		obj.stationsId.length > 0
	) {
		return true
	}

	return new Yup.ValidationError(
		'Selecione ao menos um cliente ou grupo para enviar',
		null,
		'destinyVerification',
	)
})

export default dateValidation
