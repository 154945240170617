import CustomTable, { initialPagination } from 'components/CustomTable'
import React, { useState, useEffect } from 'react'
import {
	getGroupManagerStations,
	GET_GROUP_MANAGER_STATIONS,
} from 'services/api/StationGroupManager/api'
import useSWR from 'swr'
import { useDispatch } from 'react-redux'
import CustomIcon from 'components/CustomIcon'
import { goTo } from 'utils/utils'
import { HideLoader, ShowLoader } from 'redux/actions'
import Loader from 'components/Loader/Loader'
import CustomStep from 'components/CustomStep'
import ModalMap from 'pages/StationsGroup/components/ModalMap'

const StationTable = ({ id }) => {
	const [pagination, setPagination] = useState(initialPagination)
	const dispatch = useDispatch()
	const { data: group } = useSWR(
		`${GET_GROUP_MANAGER_STATIONS(id)}?$skip=${pagination.page}&$top=${
			pagination.pageSize
		}`,
		(url) => getGroupManagerStations(url),
	)

	const [showModal, setShowModal] = useState({
		loading: false,
		geoLat: null,
		geoLong: null,
		name: null,
		owner: {
			fullName: null,
		},
		open: false,
	})

	function showMap(rowData) {
		setShowModal({
			loading: false,
			name: rowData.name,
			owner: {
				fullName: rowData.owner,
			},
			open: true,
			geoLat: rowData.geoLat,
			geoLong: rowData.geoLong,
		})
	}

	function hideModal() {
		setShowModal({
			loading: false,
			geoLat: null,
			geoLong: null,
			name: null,
			owner: {
				fullName: null,
			},
			open: false,
		})
	}

	const actions = [
		{
			icon: () => (
				<span className="button-span">
					<CustomIcon className="icon-map" color="#fff" size="14px" />
				</span>
			),
			tooltip: 'Visualizar Mapa',
			onClick: (event, rowData) => {
				showMap(rowData)
			},
		},
		{
			icon: () => (
				<span className="button-span" style={{ width: '23.1px' }}>
					<CustomIcon
						className="icon-bot_busca"
						color="#fff"
						size="14px"
					/>
				</span>
			),
			tooltip: 'Visualizar',
			onClick: (event, rowData) => goTo(`station/view/${rowData.id}`),
		},
	]

	const columns = [
		// {
		// 	title: "Status",
		// 	type: "string",
		// 	field: "updatedAt",
		// },
		{
			title: 'Apelido da Estação',
			type: 'string',
			field: 'name',
		},
		{
			title: 'N° de série',
			type: 'string',
			field: 'gateStationId',
		},
		{
			title: 'Nome do Cliente / CPF',
			type: 'string',
			render: (rowData) => {
				return (
					<>
						<span>{rowData?.owner?.fullName}</span> <br />
						<small>{rowData?.owner?.document}</small>
					</>
				)
			},
		},
	]

	useEffect(() => {
		dispatch(ShowLoader(<Loader />))
		if (group?.items) {
			setPagination((prevState) => ({
				...prevState,
				recordCount: group.count,
			}))

			dispatch(HideLoader())
		}
	}, [dispatch, group])

	return (
		<div>
			<CustomTable
				actions={actions}
				columns={columns}
				pagination={pagination}
				setPagination={setPagination}
				loading={!group?.items}
				data={group?.items}
				style={{ marginTop: 30 }}
			/>
			<ModalMap showModal={showModal} onCancel={() => hideModal()} />
		</div>
	)
}

export default StationTable
