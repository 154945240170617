import * as actionTypes from 'redux/actions/actionsTypes';
import * as API from 'redux/api/group/group';
import * as Loader from '../loader';
import * as Notification from '../notification';
import { SuccessNotificationComponent, FailedNotificationComponent } from 'redux/react-notifications';

export const fetchGroups = (data) => {
    return {
        type: actionTypes.FETCH_GROUP_DATA,
        lsGroups: data
    };
}

export const fetchGroupById = (data) => {
    return {
        type: actionTypes.FETCH_GROUP_BY_ID_DATA,
        groupById: data
    };
}

// FAIL FETCH -------------------------------------

export const fetchGroupsFailed = (error) => {
    return {
        type: actionTypes.FETCH_GROUP_FAILED,
        groupsDataFailed: error
    };
}
    ;
export const fetchGroupByIdFailed = (error) => {
    return {
        type: actionTypes.FETCH_GROUP_BY_ID_FAILED,
        groupByIdDataFailed: error
    };
};

//----------------------------------------------------

export const GetGroups = (_filter, _page, _itemsPerPage) => {
    return dispatch => (
        API.GetGroupsData(_filter, _page, _itemsPerPage).then(response => {
            dispatch(fetchGroups(response));
            //console.log(_filter)
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para consultar grupos!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao consultar grupos!')))
            }
            dispatch(fetchGroupsFailed(error));
            dispatch(Loader.hideLoader());
        })
    );
};

export const GetGroupById = (id) => {
    return dispatch => (
        API.GetGroupById(id).then(response => {
            dispatch(fetchGroupById(response));
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para consultar grupos!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao consultar o grupo!')))
            }
            dispatch(fetchGroupByIdFailed(error));
            dispatch(Loader.hideLoader());
        })
    );
};

export const CreateGroup = (obj) => {
    return dispatch => (
        API.CreateGroup(obj).then(response => {
            dispatch(createGroupData(response));
            dispatch(Notification.showNotification(SuccessNotificationComponent('Grupo cadastrado com sucesso!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para criar grupos!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao salvar o grupo!')))
            }
            dispatch(createGroupDataFailed(error));
        })
    );

    function createGroupData(data) {
        return {
            type: actionTypes.POST_GROUP_DATA,
            postData: data
        };
    };

    function createGroupDataFailed(error) {
        return {
            type: actionTypes.POST_GROUP_DATA_FAILED,
            postDataFailed: error
        };
    };
};

export const UpdateGroup = (obj) => {
    return dispatch => (
        API.UpdateGroup(obj).then(response => {
            dispatch(updateGroupData(response));
            dispatch(Notification.showNotification(SuccessNotificationComponent('Grupo atualizado com sucesso!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para atualizar dados de grupos!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao salvar o grupo!')))
            }
            dispatch(updateGroupDataFailed(error));
        })
    );

    function updateGroupData(data) {
        return {
            type: actionTypes.PUT_GROUP_DATA,
            putData: data
        };
    };

    function updateGroupDataFailed(error) {
        return {
            type: actionTypes.PUT_GROUP_DATA_FAILED,
            putDataFailed: error
        };
    };
};

export const DeleteGroup = (id) => {
    return dispatch => (
        API.DeleteGroup(id).then(response => {
            dispatch(deleteGroupData(response));
            dispatch(Loader.hideLoader());
            dispatch(Notification.showNotification(SuccessNotificationComponent('Grupo removido da base de dados!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Sem permissão para apagar grupos!')));
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao apagar o grupo!')))
            }
            dispatch(deleteGroupDataFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function deleteGroupData(data) {
        return {
            type: actionTypes.DELETE_GROUP_DATA,
            deleteData: data
        }
    }

    function deleteGroupDataFailed(error) {
        return {
            type: actionTypes.DELETE_GROUP_DATA_FAILED,
            deleteDataFailed: error
        };
    }

};