import { Fade } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { ModalContainer, PaperContainer } from "./styles";
import { germiniDefaultColors } from "assets/jss/material-dashboard-pro-react";
import { withTranslation } from "react-i18next";
import PageModal from "./PageModal";
import { useState } from "react";
import { useEffect } from "react";
import { Checkbox } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CloseButton from "components/CloseButton";

function NotificationModal({
	children,
	open,
	closeByClick,
	width,
	height,
	className,
	showCheckBox,
	padding,
	shadow,
	t,
	headerIcon = "icon-ico_regra_plataforma",
	headerTitle = "TITLE_REGULATION_REGULATION_MODEL",
	buttonTitle,
	days,
	onClose,
	totalPage = 0,
	sendPage,
	presentPage = 0,
	readAllImportantAlerts,
}) {
	// api/UserPreference?viewImportantAlerts=true
	const [showModal, setShowModal] = useState(false);

	function callUserPreference() {
		setShowModal(!showModal);
		// userPreference(showModal);
	}

	useEffect(() => {}, [totalPage, presentPage]);

	return (
		<Fragment>
			{showCheckBox ? (
				<Fragment>
					<ModalContainer
						open={open}
						className={className}
						onClose={onClose}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
						shadow={shadow}
						width={width}
						height={height}
						disableEnforceFocus
					>
						<Fade in={open} unmountOnExit>
							<PaperContainer padding={padding} width={width} height={height}>
								<header>
									<div className="section-part">
										{headerIcon && (
											<i
												className={headerIcon}
												style={{
													color: germiniDefaultColors[0],
													fontSize: "16px",
													marginRight: "10px",
												}}
											/>
										)}
										<div className="section-header">{t(headerTitle)}</div>
									</div>
									<div className="line">
										<hr></hr>
									</div>
									{presentPage === totalPage && (
										// <div className="close-part">
										// 	<button
										// 		type="button"
										// 		className="close-button"
										// 		onClick={closeByClick}
										// 	>
										// 		<i
										// 			className="icon-ico_cancelar"
										// 			style={{
										// 				color: "#fff",
										// 				fontSize: "16px",
										// 				marginRight: "10px",
										// 			}}
										// 		/>
										// 		{t(buttonTitle)}
										// 	</button>
										// </div>
										<CloseButton handleClose={closeByClick} />
									)}
								</header>
								<main>{children}</main>
								<GridContainer>
									<GridItem
										xs={6}
										style={{
											marginLeft: "15px",
											marginBottom: "20px",
											display: "flex",
											alignItems: "center",
											justifyContent: "flex-start",
										}}
									>
										<Checkbox
											style={{ color: "blue" }}
											onClick={() => callUserPreference()}
										/>
										<label
											style={{
												color: "black",
												fontSize: "12px",
											}}
										>
											Não mostrar mais estas notificações
										</label>
									</GridItem>
									<GridItem
										xs={5}
										style={{
											position: "relative",
											top: "-10px",
											right: "-70px",
										}}
									>
										{totalPage > 1 && (
											<PageModal
												total={totalPage}
												sendPage={sendPage}
											></PageModal>
										)}
									</GridItem>
								</GridContainer>
							</PaperContainer>
						</Fade>
					</ModalContainer>
				</Fragment>
			) : (
				<Fragment>
					<ModalContainer
						open={open}
						className={className}
						onClose={onClose}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
						shadow={shadow}
						width={width}
						height={height}
						disableEnforceFocus
					>
						<Fade in={open} unmountOnExit>
							<PaperContainer padding={padding} width={width} height={height}>
								<header>
									<div className="section-part">
										{headerIcon && (
											<i
												className={headerIcon}
												style={{
													color: germiniDefaultColors[5],
													fontSize: "16px",
													marginRight: "10px",
												}}
											/>
										)}
										<div className="section-header">{t(headerTitle)}</div>
									</div>
									<div className="line">
										<hr></hr>
									</div>
									{Number(totalPage, 10) === Number(presentPage, 10) && (
										// <div className="close-part">
										// 	<button
										// 		type="button"
										// 		className="close-button"
										// 		onClick={closeByClick}
										// 	>
										// 		<i
										// 			className="icon-cancelar"
										// 			style={{
										// 				color: "#fff",
										// 				fontSize: "16px",
										// 				marginRight: "10px",
										// 			}}
										// 		/>
										// 		{t(buttonTitle)}
										// 	</button>
										// </div>
										<CloseButton handleClose={closeByClick} />
									)}
								</header>
								<main>{children}</main>
								<GridContainer>
									<GridItem
										xs={6}
										style={{ alignItens: "left", marginLeft: "20px" }}
									></GridItem>
									<GridItem
										xs={5}
										style={{
											position: "relative",
											top: "-20px",
											right: "-70px",
										}}
									>
										{totalPage > 1 && (
											<PageModal
												total={totalPage}
												sendPage={sendPage}
											></PageModal>
										)}
									</GridItem>
								</GridContainer>
							</PaperContainer>
						</Fade>
					</ModalContainer>
				</Fragment>
			)}
		</Fragment>
	);
}
NotificationModal.propTypes = {
	children: PropTypes.element,
	open: PropTypes.bool,
	closeByClick: PropTypes.func,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className: PropTypes.string,
	padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shadow: PropTypes.string,
	headTitle: PropTypes.string,
	readAllImportantAlerts: PropTypes.func,
	showCheckBox: PropTypes.bool,
};

NotificationModal.defaultProps = {
	className: "",
	padding: "",
	shadow: "",
	headTitle: "",
	children: null,
	open: false,
	closeByClick: null,
	// width: "900px",
	// height: "500px",
	readAllImportantAlerts: null,
	showCheckBox: false,
};

export default withTranslation()(NotificationModal);
