import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import IconInputs from "../IconInputs";
import { inputsList } from "./utils";

import { getConfigurationManager } from "services/api/ConfigurationManager/api";

export const StatusReference = ({ control, reset }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		async function getStatus() {
			try {
				// dispatch(ShowLoader(<Loader />));
				const data = await getConfigurationManager();

				const item = data?.items?.[0];

				reset({ stationStatus: { ...item?.stationStatus } });
			} catch (error) {
			} finally {
				// dispatch(HideLoader());
			}
		}
		getStatus();
	}, [dispatch, reset]);

	return (
		<Grid container>
			<Grid item xs={12} style={{ marginTop: 10 }}>
				<p>Configurações das referências</p>
			</Grid>
			<Grid item xs={12}>
				{inputsList.map((item) => (
					<IconInputs
						key={item.inputData.iconMessage}
						inputData={item.inputData}
						control={control}
					/>
				))}
			</Grid>
		</Grid>
	);
};
