import CustomIcon from "components/CustomIcon";
import CustomTable, {
	initialPagination,
	initialSort,
} from "components/CustomTable";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import {
	getMessageManager,
	GET_MESSAGE_MANAGER_ALL,
} from "services/api/MessageManager/api";
import useSWR, { mutate as swrMutate } from "swr";
import { goTo } from "utils/utils";
import SketchView from "../SketchView";

function SketchTable({
	setShowNotification,
	setConfirmModalProps,
	filter = "",
	mutate,
}) {
	const [pagination, setPagination] = useState(initialPagination);
	const [loading, setLoading] = useState(true);
	const [sort, setSort] = useState(initialSort);
	filter = filter.replaceAll("sendDate", "createdAt");

	const { data } = useSWR(
		`${GET_MESSAGE_MANAGER_ALL}?$skip=${pagination.page}&$top=${pagination.pageSize}&$filter=State eq 'Draft'${filter}`,
		(url) => getMessageManager(url)
	);

	useEffect(() => {
		setPagination(initialPagination);
	}, [mutate]);

	useEffect(() => {
		swrMutate(
			`${GET_MESSAGE_MANAGER_ALL}?$skip=${pagination.page}&$top=${pagination.pageSize}&$filter=State eq 'Draft'${filter}`
		);
	}, [filter, pagination.page]);

	useEffect(() => {
		setPagination((prevState) => ({
			...prevState,
			page: 0,
		}));
		swrMutate(
			`${GET_MESSAGE_MANAGER_ALL}?$skip=${pagination.page}&$top=${pagination.pageSize}&$filter=State eq 'Draft'${filter}`
		);
	}, [pagination.pageSize]);

	useEffect(() => {
		setLoading(true);

		if (data?.items) {
			setPagination((prevState) => ({
				...prevState,
				recordCount: data.count,
			}));
			setLoading(false);
		}
	}, [data]);

	const actions = [
		{
			icon: () => (
				<span className="button-span">
					<CustomIcon className="icon-editar" color="#fff" size="14px" />
				</span>
			),
			tooltip: "Editar",
			onClick: (event, rowData) => goTo(`settings/message/crud/${rowData.id}`),
		},
		{
			icon: () => (
				<span className="button-span">
					<CustomIcon className="icon-bot_busca" color="#fff" size="14px" />
				</span>
			),
			tooltip: "Procurar",
			onClick: (event, rowData) =>
				setShowNotification((prevState) => ({
					...prevState,
					open: true,
					content: (
						<SketchView
							sketch={{
								date: format(new Date(rowData.sendDate), "dd/MM/yyyy"),
								title: rowData.title,
								description: rowData.description,
							}}
						/>
					),
					title: "Detalhes da mensagem",
					headerIcon: "icon-enviar",
					haveEdit: () => goTo(`settings/message/crud/${rowData.id}`),
				})),
		},
		{
			icon: () => (
				<span className="button-span yellow">
					<CustomIcon className="icon-arquivar" color="#fff" size="14px" />
				</span>
			),
			tooltip: "Arquivar",
			onClick: (event, rowData) =>
				setConfirmModalProps({
					open: true,
					text: "Deseja realmente arquivar esta mensagem?",
					header: "Arquivar",
					type: "archive",
					id: rowData.id,
					subText: "Ao confirmar a ação, a mensagem não poderá ser restaurada.",
				}),
		},
	];

	const columns = [
		{
			title: "Título da mensagem",
			field: "title",
		},
		{
			title: "Data de rascunho",
			field: "createdAt",
			type: "datetime",
		},
	];

	return (
		<CustomTable
			actions={actions}
			columns={columns}
			pagination={pagination}
			setPagination={setPagination}
			sort={sort}
			setSort={setSort}
			data={data?.items}
			loading={loading}
		/>
	);
}

export default SketchTable;
