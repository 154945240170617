import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { Formik, Form, Field } from 'formik';
import cx from 'classnames';
import Alert from 'react-bootstrap-sweetalert';
import * as _ from 'lodash';

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// @material-ui/core components
import { withStyles, makeStyles } from "@material-ui/core/styles";

// layout components
import Table from "components/Table/Table.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FieldInput from "components/FieldForm/FieldInput.jsx";
import CommonPage from "components/Pages/CommonPage";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';
import Section from 'components/Section';
import DocumentTitle from "components/Pages/DocumentTitle";
import Loader from "components/Loader/Loader.jsx";
import NoContentAlert from 'components/NoContentAlert/NoContentAlert.jsx';
import CustomPagination from 'components/CustomPagination/CustomPagination.jsx';
import CustomInput from "components/CustomInput/CustomInput.jsx";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

//utils
import compose from "utils/compose";
import { isAllowed } from "utils/auth";
import { connectRouterState } from "utils/connectRouterState";

import { IconButton, Icon, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown, MoreVert } from '@material-ui/icons';

import { GetUsers, ShowLoader, HideLoader, SweetAlert, HideAlert, DeleteUser } from 'redux/actions';

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { name: "Home" },
                { name: "Controle de acesso" },
                { name: "Usuários", last: true },
            ],
            open: false,

            users: null,
            hasWritePermission: false,
            hasCreatePermission: false,
            hasDeletePermission: false,

            filterName: "",
            filterDocument: "",
            filterStatus: "",
            filterEmail: "",

            pageSize: 20,
            page: 1,
            recordCount: 0,
        }
    }

    componentDidMount() {
        this.props.ShowLoader(<Loader />)
        this.setState({ ...this.props.urlState });

        this.props.GetUsers(this.stringifyFilter(this.props.urlState), 1, '20');

        let user = this.props.user;
        if (user) {
            if (isAllowed(user, ['beWeather.user.create'])) {
                this.setState({ hasCreatePermission: true })
            }
            if (isAllowed(user, ['beWeather.user.write'])) {
                this.setState({ hasWritePermission: true })
            }
            if (isAllowed(user, ['beWeather.user.delete'])) {
                this.setState({ hasDeletePermission: true })
            }
        } else {
            this.props.history.push('/404')
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let prevUrlState = _.get(prevProps, 'urlState');
        let urlState = _.get(this.props, 'urlState');
        if (!_.isEqual(prevUrlState, urlState)) {
            this.props.GetUsers(this.stringifyFilter(urlState), 1, 20);
        }

        if (prevProps.lsUsers !== this.props.lsUsers) {
            this.setState({ users: this.props.lsUsers.items })
            this.setState({ recordCount: this.props.lsUsers.count })
            this.props.HideLoader();
        }
    }


    handleFilter = () => {
        const { filterName, filterDocument, filterStatus, filterEmail } = this.state;
        //this.props.ShowLoader(<Loader />);
        this.props.setURLState({ filterName, filterDocument, filterStatus, filterEmail })
    }

    handleClearFilter = () => {
        this.setState({
            filterName: "",
            filterDocument: "",
            filterStatus: "",
            filterEmail: ""
        }, () => {
            this.handleFilter();
        });
    }

    handleChange = (event) => {
        const id = event.target.id || event.target.name;
        const value = event.target.value;
        this.setState({ [id]: value });
    }

    handleNew = () => {
        this.props.history.push('/admin/access_control/user/new')
    }

    handleUserView = (id) => {
        this.props.history.push(`/admin/access_control/user/details/${id}`)
    }

    handleAssociateGroup = (id) => {
        this.props.history.push(`/admin/access_control/user/associateGroup/${id}`)
    }

    handleDelete = (user) => {
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title={null}
                onConfirm={() => {
                    this.props.HideAlert();
                    this.props.ShowLoader(<Loader />);
                    this.props.DeleteUser(user.id).then(resp => {
                        this.props.GetUsers();
                    });
                }}
                onCancel={() => this.props.HideAlert()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.greenBorderButtonColor
                }
                confirmBtnText={"Confirmar"}
                cancelBtnText={"Cancelar"}
                showCancel
            >
                Deseja realmente excluir o usuário <b>"{user.fullName}"</b>?
            </Alert>
        )
    }

    handleToggle = () => {
        this.setState({ open: !this.state.open });
    }

    handlePaginate = (page) => {
        this.props.ShowLoader(<Loader />);
        this.props.setURLState({ page });
    }

    render() {
        const { classes } = this.props;
        const { breadcrumbItems, users, open, hasCreatePermission, hasDeletePermission, hasWritePermission, filterName, filterDocument, filterStatus, filterEmail, pageSize, recordCount, page } = this.state;
        return (
            <CommonPage bottomAppBar={
                <CustomPagination
                    pageSize={pageSize}
                    recordCount={recordCount}
                    offset={pageSize * (page - 1)}
                    clickEvent={(e, offset) => this.handlePaginate(Math.round(offset / pageSize) + 1)}
                />}>
                <DocumentTitle pageTitle={'Usuários'} />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Breadcrumbs items={breadcrumbItems} />
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                        <Section title="USUÁRIOS CADASTRADOS" icon="icon-ico_cliente" />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6} style={{ textAlign: 'right' }} className={classes.noPaddingRight}>
                                        <Button
                                            size="sm"
                                            color="primary"
                                            disabled={!hasCreatePermission}
                                            onClick={hasCreatePermission ? (() => this.handleNew()) : (() => console.log("Sem permissão"))}>
                                            <Icon
                                                style={{ fontSize: '19px', width: '30px' }}
                                                className={'icon-ico_usuario_novo'} />
                                            Novo
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer className={classes.filterForm}>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <h5><b>Opções de busca:</b></h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        {/* <FilterForm callFromParent={this.callbackFromChild} itemsValues={this.state.values} {...this.props} /> */}
                                        <GridContainer>

                                            <GridItem xs={12} sm={4} md={4}>
                                                <CustomInput
                                                    id="filterName"
                                                    name="filterName"
                                                    labelText="Nome"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        onChange: this.handleChange,
                                                        value: filterName
                                                    }}
                                                />
                                            </GridItem>

                                            <GridItem xs={12} sm={4} md={4}>
                                                <CustomInput
                                                    id="filterDocument"
                                                    name="filterDocument"
                                                    labelText="CPF/CNPJ"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        onChange: this.handleChange,
                                                        value: filterDocument
                                                    }}
                                                />
                                            </GridItem>

                                            <GridItem xs={12} sm={4} md={4}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="group-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        Status
                                                            </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        value={filterStatus}
                                                        onChange={this.handleChange}
                                                        inputProps={{
                                                            name: "filterStatus",
                                                            id: "filterStatus"
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            Escolha um status
                                                                </MenuItem>
                                                        <MenuItem
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value="true"
                                                        >
                                                            Ativo
                                                                </MenuItem>
                                                        <MenuItem
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value="false"
                                                        >
                                                            Inativo
                                                                </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </GridItem>


                                            {open &&

                                                <GridItem xs={12} sm={4} md={4}>
                                                    <CustomInput
                                                        id="filterEmail"
                                                        name="filterEmail"
                                                        labelText="E-mail"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            onChange: this.handleChange,
                                                            value: filterEmail
                                                        }}
                                                    />
                                                </GridItem>
                                            }

                                            <GridContainer>
                                                <GridItem xs={10} sm={9} md={4} lg={4} xl={4} style={{ textAlign: 'left', marginTop: !open ? '35px' : 0 }}>
                                                    <Button
                                                        size="sm"
                                                        color="primary"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => this.handleFilter()}
                                                    >
                                                        Pesquisar
                                                        </Button>
                                                    <Button
                                                        size="sm"
                                                        color="greenBorderButtonColor"
                                                        onClick={() => this.handleClearFilter()}
                                                    >
                                                        Limpar
                                                        </Button>
                                                </GridItem>
                                                <GridItem xs={2} sm={3} md={4} lg={4} xl={4} style={{ textAlign: 'center', marginTop: '10px' }}>
                                                    {!open &&
                                                        <div>
                                                            <MoreVert style={{ color: '#ccc' }} />
                                                        </div>
                                                    }
                                                    <IconButton className={classes.filterArrowButton} onClick={this.handleToggle}>
                                                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                    </IconButton>
                                                </GridItem>
                                            </GridContainer>

                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>

                                <GridContainer style={{ margin: '30px 0' }}>
                                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.noPaddingLeft}>
                                        <Section title="RESULTADOS DA PESQUISA" />
                                    </GridItem>
                                    <GridItem xs={12} className={classes.noPaddingLeft}>
                                        {users &&
                                            <>
                                                <Table
                                                    hover
                                                    tableHead={["Nome", "CPF", "E-mail", "Status", "Ações"]}
                                                    tableData={
                                                        users.map((item) => ([
                                                            item.fullName,
                                                            item.document,
                                                            item.email,
                                                            item.active ? 'Ativo' : 'Inativo',
                                                            <div style={{ textAlign: 'left' }}>
                                                                <span
                                                                    className={cx('icon-bot_edit',
                                                                        classes.actionButton,
                                                                        {
                                                                            [classes.greyButton]: !hasWritePermission ? true : false
                                                                        })}
                                                                    title="Editar"
                                                                    onClick={hasWritePermission ? (() => this.handleAssociateGroup(item.id)) : (() => console.log("Sem permissão"))}>
                                                                </span>
                                                                <span className={cx('icon-bot_busca', classes.actionButton)} title="Ver mais" onClick={() => this.handleUserView(item.id)}></span>
                                                                <span
                                                                    className={cx('icon-bot_close',
                                                                        classes.actionButton,
                                                                        {
                                                                            [classes.redButton]: hasDeletePermission ? true : false,
                                                                            [classes.greyButton]: !hasDeletePermission ? true : false
                                                                        })}
                                                                    title="Excluir"
                                                                    onClick={hasDeletePermission ? (() => this.handleDelete(item)) : (() => console.log("Sem permissão"))}>
                                                                </span>
                                                            </div>
                                                        ]))}
                                                />

                                                {!users.length > 0 && <NoContentAlert alertText="Não há usuários cadastrados" />}
                                            </>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </CommonPage>
        );
    }

    stringifyFilter = (filterData) => {
        const { filterName, filterDocument, filterStatus, filterEmail } = filterData;
        if (_.isEmpty(filterName) && _.isEmpty(filterDocument) && _.isEmpty(filterStatus) && _.isEmpty(filterEmail)) {
            return '';
        }
        let filter = "?$filter=";
        let fName = "";
        let fDocument = "";
        let fStatus = "";
        let fEmail = "";

        // Name
        fName = filterName ? "contains(FullName, '" + _.trim(filterName) + "') and " : "";

        // Document
        fDocument += filterDocument ? "contains(Document, '" + _.trim(filterDocument) + "') and " : "";

        // Status
        fStatus += filterStatus ? "Active eq " + _.trim(filterStatus) + " and " : "";

        // Email
        fEmail += filterEmail ? "contains(Email, '" + _.trim(filterEmail) + "') and " : "";
        //
        filter += fName + fDocument + fStatus + fEmail;
        filter = filter.substring(0, filter.lastIndexOf(" and "));
        return filter;
    }
}

Users.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {

    return {
        lsUsers: state.user.lsUsers,
        usersDataFailed: state.user.usersDataFailed,

        user: state.authentication.user,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { GetUsers, ShowLoader, HideLoader, SweetAlert, HideAlert, DeleteUser }
    , dispatch);


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    connectRouterState({ filterName: "", filterDocument: "", filterStatus: "", filterEmail: "", currentPage: 1 }),
    withStyles(sweetAlertStyle),
    withTranslation(),
)(Users);