import * as actionTypes from 'redux/actions/actionsTypes';
import * as API from 'redux/api/user/user';
import * as Loader from '../loader';
import * as Notification from '../notification';
import { SuccessNotificationComponent, FailedNotificationComponent } from 'redux/react-notifications';

export const fetchUser = (data) => {
    return {
        type: actionTypes.FETCH_USER_DATA,
        lsUsers: data
    };
}

export const fetchUserById = (data) => {
    return {
        type: actionTypes.FETCH_USER_BY_ID_DATA,
        userById: data
    };
}
// FAIL FETCH -------------------------------------

export const fetchUserFailed = (error) => {
    return {
        type: actionTypes.FETCH_USER_FAILED,
        usersDataFailed: error
    };
}
    ;
export const fetchUserByIdFailed = (error) => {
    return {
        type: actionTypes.FETCH_USER_BY_ID_FAILED,
        usersByIdDataFailed: error
    };
};

//----------------------------------------------------

export const GetUsers = (_filter, _page, _itemsPerPage) => {
    return dispatch => (
        API.GetUsersData(_filter, _page, _itemsPerPage).then(response => {
            dispatch(fetchUser(response));
            //console.log(_filter)
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Você não possui permissão para pesquisar usuários!')))
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao pesquisar usuários!')))
            }
            dispatch(fetchUserFailed(error));
            dispatch(Loader.hideLoader());
        })
    );
};

export const GetUserById = (id) => {
    return dispatch => (
        API.GetUserById(id).then(response => {
            dispatch(fetchUserById(response));
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Você não possui permissão para pesquisar usuários!')))
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao pesquisar o usuário!')))
            }
            dispatch(fetchUserByIdFailed(error));
            dispatch(Loader.hideLoader());
        })
    );
};

export const CreateUser = (obj) => {
    return dispatch => (
        API.CreateUser(obj).then(response => {
            dispatch(createUserData(response));
            dispatch(Notification.showNotification(SuccessNotificationComponent('Usuário cadastrado com sucesso!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Você não possui permissão para criar usuários!')))
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao salvar o usuário!')))
            }
            dispatch(createUserDataFailed(error));
        })
    );

    function createUserData(data) {
        return {
            type: actionTypes.POST_USER_DATA,
            postData: data
        };
    };

    function createUserDataFailed(error) {
        return {
            type: actionTypes.POST_USER_DATA_FAILED,
            postDataFailed: error
        };
    };
};

export const UpdateUser = (obj) => {
    return dispatch => (
        API.UpdateUser(obj).then(response => {
            dispatch(updateUserData(response));
            dispatch(Notification.showNotification(SuccessNotificationComponent('Usuário atualizado com sucesso!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Você não possui permissão para alterar dados de usuários!')))
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao salvar o usuário!')))
            }
            dispatch(updateUserDataFailed(error));
        })
    );

    function updateUserData(data) {
        return {
            type: actionTypes.PUT_USER_DATA,
            putData: data
        };
    };

    function updateUserDataFailed(error) {
        return {
            type: actionTypes.PUT_USER_DATA_FAILED,
            putDataFailed: error
        };
    };
};

export const AssignGroupsToUser = (obj) => {
    return dispatch => (
        API.AssignGroupsToUser(obj).then(response => {
            dispatch(assignGroupsData(response));
            dispatch(Notification.showNotification(SuccessNotificationComponent('Grupos associados ao usuário!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Você não possui permissão para alterar dados de usuários!')))
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao associar os grupos!')))
            }
            dispatch(assignGroupsDataFailed(error));
        })
    );

    function assignGroupsData(data) {
        return {
            type: actionTypes.POST_USER_ASSIGN_GROUPS_DATA,
            assignGroupsData: data
        };
    };

    function assignGroupsDataFailed(error) {
        return {
            type: actionTypes.POST_USER_ASSIGN_GROUPS_DATA_FAILED,
            assignGroupsFailed: error
        };
    };
};

export const DeleteUser = (id) => {
    return dispatch => (
        API.DeleteUser(id).then(response => {
            dispatch(deleteUserData(response));
            dispatch(Loader.hideLoader());
            dispatch(Notification.showNotification(SuccessNotificationComponent('Usuário removido da base de dados!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Você não possui permissão para excluir usuários!')))
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao apagar o usuário!')))
            }
            dispatch(deleteUserDataFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function deleteUserData(data) {
        return {
            type: actionTypes.DELETE_USER_DATA,
            deleteData: data
        }
    }

    function deleteUserDataFailed(error) {
        return {
            type: actionTypes.DELETE_USER_DATA_FAILED,
            deleteDataFailed: error
        };
    }

};

export const ResetUserPassword = (id) => {
    return dispatch => (
        API.ResetUserPassword(id).then(response => {
            dispatch(resetUserPassword(response));
            dispatch(Loader.hideLoader());
            dispatch(Notification.showNotification(SuccessNotificationComponent('Senha temporária enviada ao e-mail do usuário!')))
            return response;
        }).catch(error => {
            console.error(error);
            if (error.response.status === 403 || error.response.status === 401) {
                dispatch(Notification.showNotification(FailedNotificationComponent('Você não possui permissão para alterar dados de usuários!')))
            } else {
                dispatch(Notification.showNotification(FailedNotificationComponent('Ocorreu um erro ao alterar a senha do usuário!')))
            }
            dispatch(resetUserPasswordFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function resetUserPassword(data) {
        return {
            type: actionTypes.POST_RESET_USER_PASSWORD,
            resetPsswd: data
        }
    }

    function resetUserPasswordFailed(error) {
        return {
            type: actionTypes.POST_RESET_USER_PASSWORD_FAILED,
            resetPsswdFailed: error
        };
    }

};