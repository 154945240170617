import React, { useState } from "react";
import CustomTable, {
	initialPagination,
	initialSort,
} from "components/CustomTable";
import useSWR from "swr";
import CustomIcon from "components/CustomIcon";
import {
	FailedNotificationComponent,
	SuccessNotificationComponent,
} from "redux/react-notifications";
import { useDispatch } from "react-redux";
import { showNotification } from "redux/actions/notification";
import { GET_ALL, getAll } from "services/api/WeatherExport/api";

function ExportList({ id }) {
	const [pagination, setPagination] = useState(initialPagination);
	const [loading, setLoading] = useState(true);
	const [sort, setSort] = useState(initialSort);
	const dispatch = useDispatch();

	const { data } = useSWR(
		`${GET_ALL}?$skip=${pagination.page}&$top=${pagination.pageSize}&$filter=stationId eq '${id}'`,
		(url) => getAll(url),
		{ refreshInterval: 1000 }
	);

	function handlewDownloadExport(rowData) {
		const link = document.createElement("a");
		link.href = rowData.file.uri;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const actions = [
		{
			icon: (event, rowData) => (
				<span className="button-span">
					<CustomIcon className="icon-importar" color="#fff" size="14px" />
				</span>
			),
			tooltip: "Download",
			onClick: (event, rowData) => {
				if (rowData.status === 3) {
					handlewDownloadExport(rowData);
					dispatch(
						showNotification(
							SuccessNotificationComponent("Dados baixados com sucesso!")
						)
					);
				} else {
					dispatch(
						showNotification(
							FailedNotificationComponent(
								"Falha no download dos dados! arquivo ainda não disponivel!"
							)
						)
					);
				}
			},
		},
	];

	const columns = [
		{
			title: "Status",
			field: "status",
			// cellStyle: { maxWidth: "300px", minWidth: "300px" },
			// headerStyle: { maxWidth: "300px", minWidth: "300px" },
			render: function (rowData) {
				// eslint-disable-next-line default-case
				switch (rowData.status) {
					case 1:
						return (
							<>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-start",
									}}
								>
									<span
										style={{
											backgroundColor: "#F4BE51",
											borderRadius: "50%",
											padding: "4px 7px",
											color: "white",
										}}
									>
										...
									</span>
								</div>
							</>
						);
					case 2:
						return (
							<>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-start",
									}}
								>
									<span
										style={{
											backgroundColor: "#F4BE51",
											borderRadius: "50%",
											padding: "4px 7px",
											color: "white",
										}}
									>
										...
									</span>
								</div>
							</>
						);
					case 3:
						return (
							<>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-start",
									}}
								>
									<span
										style={{
											backgroundColor: "#23AB3A",
											borderRadius: "50%",
											padding: "4px",
										}}
									>
										<CustomIcon className="icon-check" />
									</span>
								</div>
							</>
						);
					case 4:
						return (
							<>
								<CustomIcon className="icon-offline" color="red" size="25px" />
							</>
						);
				}
			},
		},
		{
			title: "De",
			field: "start",
			// cellStyle: { maxWidth: "300px", minWidth: "300px" },
			// headerStyle: { maxWidth: "300px", minWidth: "300px" },
			type: "date",
		},
		{
			title: "Até",
			field: "end",
			// cellStyle: { maxWidth: "300px", minWidth: "300px" },
			// headerStyle: { maxWidth: "300px", minWidth: "300px" },
			type: "date",
		},
	];

	return (
		<CustomTable
			actions={actions}
			columns={columns}
			pagination={pagination}
			setPagination={setPagination}
			sort={sort}
			setSort={setSort}
			data={data?.items}
			loading={!data}
		/>
	);
}

export default ExportList;
