import axios from 'axios';

// GET
export const GetGroupsData = (_filter, _page, _itemsPerPage) => {
    let page = "";
    let count = (_page - 1) * _itemsPerPage;
    if (_filter && _page) {
        page = `&$skip=${count}&$top=${_itemsPerPage}`
    } else if (!_filter && _page) {
        page = `?$skip=${count}&$top=${_itemsPerPage}`
    }

    const url = `/api/CGroup/GetAll${_filter}${page}`;
    return axios.get(url).then(response => response.data);
}

export const GetGroupById = (id) => {
    return axios.get(`/api/CGroup/GetById?id=${id}`).then(response => response.data);
}

export const CreateGroup = (obj) => {
    return axios.post(`/api/CGroup/Create`, obj).then(response => response.data);
}

export const UpdateGroup = (obj) => {
    return axios.put(`/api/CGroup/Update`, obj).then(response => response.data);
}

export const DeleteGroup = (id) => {
    return axios.delete(`/api/CGroup/Delete?id=${id}&softDelete=false`).then(response => response.data);
}

