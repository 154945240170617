import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, withStyles } from '@material-ui/core';
import { drawerWidth } from "assets/jss/material-dashboard-pro-react.jsx";
import { AdminContext } from 'pages/Admin/Admin';
import { drawerMiniWidth } from 'assets/jss/material-dashboard-pro-react';
import cx from "classnames";

const styles = theme => ({
    content: {
        //paddingBottom: '50px'
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        zIndex: "1029",
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        boxShadow: 'none'
    },
    mini: {
        width: `calc(100% - ${drawerMiniWidth}px)`
    }
});

const CommonPage = ({ children, classes, bottomAppBar }) => {
    return (
        <AdminContext.Consumer>
            {
                ({ sidebarMini }) => (
                    <React.Fragment>
                        <div className={bottomAppBar ? classes.content : null}>
                            {children}
                        </div>
                        {bottomAppBar &&
                            <AppBar style={{ minHeight: '40px' }} position="fixed" color="primary" className={cx({ [classes.appBar]: true, [classes.mini]: sidebarMini })}>
                                {bottomAppBar}
                            </AppBar>
                        }
                        <div style={{ zIndex: '5', float: 'left', marginBottom: bottomAppBar ? '65px' : 0 }} />
                    </React.Fragment>
                )

            }

        </AdminContext.Consumer>
    )
}

CommonPage.propTypes = {
    bottomAppBar: PropTypes.element
}

export default withStyles(styles)(CommonPage);
