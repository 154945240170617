import axios from 'axios'

export const GET_USER_MANAGER_ALL = '/api/UserManager/GetAllUser'
export const GET_USER_MANAGER_ALL_BY_GROUP =
	'/api/UserManager/GetAllUserByGroup'
export const GET_ALL = '/api/UserManager/GetAll'
export const GET_BY_ID = '/api/UserManager/GetById'
export const ATTACH_STATION = '/api/UserManager/AttachStation'
export const CREATE = '/api/UserManager/Create'
export const UPSERT = '/api/UserManager/Upsert'
export const GET_ALL_USER_BY_FILTER = '/api/UserManager/GetAllUserByFilter'

export const getUsersManager = async (url, setLoading) => {
	try {
		const { data } = await axios.get(url ?? GET_USER_MANAGER_ALL)
		return data
	} catch (error) {}
}

export const getUsersManagerByGroup = async (url, setLoading) => {
	try {
		const { data } = await axios.get(url ?? GET_USER_MANAGER_ALL)
		return data
	} catch (error) {}
}

export const getAll = async (url, body) => {
	try {
		const { data } = await axios.post(url, body)
		return data
	} catch (error) {}
}

export const getById = async (id) => {
	try {
		const { data } = await axios.get(`${GET_BY_ID}/${id}`)
		return data
	} catch (error) {}
}

export const AttachStation = async (body) => {
	let res = ''
	try {
		console.log(`${ATTACH_STATION}`)
		console.log(JSON.stringify(body))
		res = await axios.post(`${ATTACH_STATION}`, body)
		console.log(res)
		return res
	} catch (error) {
		console.log(res)
		return res
	}
}

export const Create = async (body) => {
	try {
		const res = await axios.post(`${CREATE}`, body)
		return res
	} catch (error) {
		return error
	}
}

export const Upsert = async (body) => {
	try {
		const res = await axios.post(`${UPSERT}`, body)
		return res
	} catch (error) {}
}

export const GetAllUserByFilter = async (url) => {
	try {
		const { data } = await axios.get(url)
		return data
	} catch (error) {}
}
