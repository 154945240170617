import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "moment/locale/pt-br";
import "moment/locale/es";
import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import "utils/i18n";
import "assets/icons/style.css";
import React, { Suspense, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";
import axios from "axios";
import WorkspaceBar from "react-workspace-shell";
import { CssBaseline, createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
//
import * as serviceWorker from "./utils/serviceWorker";
import AuthLayout from "pages/Auth";
import ErrorPage from "pages/Error/404.jsx";
import AdminLayout from "pages/Admin";
import { PrivateRoute } from "components/PrivateRoute.jsx";
import ForgetPassword from "pages/Auth/ForgetPassword/ForgetPassword.jsx";
import store from "./redux/store";
import { logout, HideAlert, HideLoader, HideNotification } from "redux/actions";
import { history } from "./utils";
import { isAllowed } from "utils/auth";

import {
	primaryColor,
	successColor,
} from "assets/jss/material-dashboard-pro-react.jsx";
import GlobalStyle from "components/GlobalStyle";

axios.defaults.headers.common["Authorization"] =
	"Bearer " + localStorage.getItem("token");

axios.defaults.headers.common["x-apikey"] =
	"2cdc569b-dbd7-4813-8f41-bd82f6b1bd81";

axios.defaults.baseURL = process.env.REACT_APP_B2KWEB_API_URL;

/******************
 * MOMENT CONFIG
 ******************/
// Sets the moment instance to use.
Moment.globalMoment = moment;

// Set the locale for every react-moment instance to French.
if (localStorage.getItem("i18nextLng") != null) {
	Moment.globalLocale = localStorage.getItem("i18nextLng").toLowerCase();
} else {
	Moment.globalLocale = "pt-BR";
}

// Set the output format for every react-moment instance.
Moment.globalFormat = "DD MM YYYY";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Sao_Paulo";

// Use a <span> tag for every react-moment instance.
Moment.globalElement = "span";

// Upper case all rendered dates.
Moment.globalFilter = (d) => {
	return d.toUpperCase();
};

/* It needs to find a better way as soon as possible */

window.onbeforeunload = function () {
	if (localStorage.getItem("i18next_pt-BR-common") != null) {
		localStorage.removeItem("i18next_pt-BR-common");
	}
	if (localStorage.getItem("i18next_es-common") != null) {
		localStorage.removeItem("i18next_es-common");
	}
	if (localStorage.getItem("i18next_en-common") != null) {
		localStorage.removeItem("i18next_en-common");
	}
};

axios.defaults.headers.common["Authorization"] =
	"Bearer " + localStorage.getItem("token");

const theme = createMuiTheme({
	palette: {
		primary: {
			// light: will be calculated from palette.primary.main,
			main: primaryColor[0],
			// dark: will be calculated from palette.primary.main,
			contrastText: "#fff",
		},
		secondary: {
			// light: will be calculated from palette.primary.main,
			main: successColor[0],
			// dark: will be calculated from palette.primary.main,
			contrastText: "#fff",
		},
	},
});

function useInterval(callback, delay) {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

const App = () => (
	<React.Fragment>
		<CssBaseline />
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<Suspense fallback={<div />}>
					<ConnectedRouter history={history}>
						<Switch>
							<Route path="/auth" component={AuthLayout} />
							<Route
								path="/forgetpassword"
								component={ForgetPassword}
							/>
							<Route path="/404" component={ErrorPage} />
							<PrivateRoute
								path="/admin"
								component={AdminLayout}
							/>
							<Redirect from="/" to="/admin/dashboard" />
						</Switch>
					</ConnectedRouter>
				</Suspense>
			</ThemeProvider>
			<GlobalStyle />
		</Provider>
	</React.Fragment>
);

function Root() {
	let state = store.getState();
	const [authState, setAuthState] = React.useState(state.authentication);
	const [showBar, setShowBar] = React.useState(false);
	const [showLoader, setShowLoader] = React.useState(false);
	const [user, setUser] = React.useState(null);

	useEffect(() => {
		if (authState.loggedIn === true && showBar === false) {
			setShowBar(true);
			setUser(authState.user);
		}
		if (authState.logginIn === true && showLoader === false) {
			setShowLoader(true);
		}
		if (authState.logginIn === false && showLoader === true) {
			setShowLoader(false);
		}
		if (authState.loggedIn === false && showBar === true) {
			setShowBar(false);
		}
	}, [authState, showBar, showLoader]);

	useInterval(() => {
		state = store.getState();
		setAuthState(state.authentication);
	}, 500);

	const reduxLogout = () => {
		store.dispatch(HideNotification);
		store.dispatch(HideAlert);
		store.dispatch(HideLoader);
		store.dispatch(logout);
		state = store.getState();
		setAuthState({
			loggedIn: false,
			logginIn: false,
			user: null,
		});
	};
	return (
		<WorkspaceBar
			user={{
				...authState.user,
				avatarUri: "sa",
			}}
			logoutFunction={reduxLogout}
			editProfileFunction={() => {
				history.push("/admin/my_profile");
			}}
			groupPageFunction={() => {
				history.push("/admin/access_control/groups");
			}}
			userPageFunciton={() => {
				history.push("/admin/access_control/users");
			}}
			showBar={showBar}
			showLoader={showLoader}
			apiKey={"fAxoUkqowRCkkr6ydI6drze3jXI021KqAtdxAt0SijU="}
			content={<App />}
			hasUserPermission={
				authState.user === undefined ||
				authState.user === null ||
				authState.user.claims === undefined
					? false
					: isAllowed(authState.user, [
							"beWeather.user.create",
							"beWeather.user.write",
							"beWeather.user.delete",
							"beWeather.user.read",
					  ])
			}
			hasGroupPermission={
				authState.user === undefined ||
				authState.user === null ||
				authState.user.claims === undefined
					? false
					: isAllowed(authState.user, [
							"beWeather.group.create",
							"beWeather.group.write",
							"beWeather.group.delete",
							"beWeather.group.read",
					  ])
			}
		/>
	);
}
ReactDOM.render(<Root />, document.getElementById("root"));

//#region serviceWorker

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

//#endregion
