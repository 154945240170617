import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    notification: null
};

const sweetAlert = (state, action) => {
    return updateObject(state, {
        notification: action.notification
    });
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_NOTIFICATION:
        case actionTypes.HIDE_NOTIFICATION:
            return sweetAlert(state, action);
        default:
            return state;
    }
};

export default dashboardReducer;