import * as Yup from 'yup'

const msg = 'obrigatório'
const schema = Yup.object().shape({
	fullName: Yup.string().required(msg),
	email: Yup.string().required(msg),
	// phoneNumber: Yup.string().required(msg),
})

export default schema
