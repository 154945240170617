import { FormControlLabel } from "@material-ui/core";
import styled from "styled-components";

export const LabelForm = styled(FormControlLabel)`
  margin-right: 0;
  align-items: ${({ align }) => align};

  .MuiButtonBase-root {
    align-items: ${({ align }) => align};
    padding: ${({ padding }) => padding};
  }
`;
