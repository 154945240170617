import React from "react";
// react plugin for browser tab title
import DocumentTitle from "components/Pages/DocumentTitle";

import pageInConstruction from 'assets/img/Ilustracao_Germinando.png';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

class Construction extends React.Component {
    render() {
        return (
            <Card>
                <DocumentTitle pageTitle={"Working in progress"} />

                <CardBody>
                    <GridContainer justify="center">
                        <img alt="Em construção" src={pageInConstruction} style={{ maxWidth: '100%' }} />
                    </GridContainer>
                    <GridContainer justify="center">
                        <label style={{
                            fontSize: "28px",
                            textAlign: "center",
                            width: "100%"
                        }}>
                            <strong>ESTAMOS CONSTRUINDO</strong><br />
                            <strong>ESTA PÁGINA PARA VOCÊ!</strong>
                        </label>
                    </GridContainer>
                </CardBody>
            </Card>
        );
    }
}

export default Construction;
