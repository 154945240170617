import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cx from "classnames";
import * as _ from "lodash";

// REDIX INIT
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";

// layout components
import Table from "components/Table/Table.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FieldInput from "components/FieldForm/FieldInput.jsx";
import CommonPage from "components/Pages/CommonPage";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.jsx";
import Section from "components/Section";
import DocumentTitle from "components/Pages/DocumentTitle";
import Loader from "components/Loader/Loader.jsx";
import NoContentAlert from "components/NoContentAlert/NoContentAlert.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomPagination from "components/CustomPagination/CustomPagination.jsx";
import { deleteCustomerGroup } from "services/api/ControlAccess/api";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

//utils
import compose from "utils/compose";
import { isAllowed } from "utils/auth";
import { connectRouterState } from "utils/connectRouterState";

import { Icon } from "@material-ui/core";

import {
	GetGroups,
	ShowLoader,
	HideLoader,
	SweetAlert,
	HideAlert,
	DeleteGroup,
} from "redux/actions";

class Groups extends Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumbItems: [
				{ name: "Home" },
				{ name: "Controle de acesso" },
				{ name: "Grupos", last: true },
			],

			groups: [],
			hasWritePermission: false,
			hasCreatePermission: false,
			hasDeletePermission: false,

			filterName: "",
			filterDescription: "",

			pageSize: 20,
			page: 1,
			recordCount: 0,
		};
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		this.setState({ ...this.props.urlState });

		this.props.GetGroups(
			this.stringifyFilter(this.props.urlState),
			1,
			"20"
		);

		let user = this.props.user;
		if (user) {
			if (isAllowed(user, ["beWeather.group.create"])) {
				this.setState({ hasCreatePermission: true });
			}
			if (isAllowed(user, ["beWeather.group.write"])) {
				this.setState({ hasWritePermission: true });
			}
			if (isAllowed(user, ["beWeather.group.delete"])) {
				this.setState({ hasDeletePermission: true });
			}
		} else {
			this.props.history.push("/404");
		}
	}

	componentDidUpdate(prevProps, prevState) {
		let prevUrlState = _.get(prevProps, "urlState");
		let urlState = _.get(this.props, "urlState");
		if (!_.isEqual(prevUrlState, urlState)) {
			this.props.GetGroups(this.stringifyFilter(urlState), 1, 20);
		}

		if (prevProps.lsGroups !== this.props.lsGroups) {
			this.setState({ groups: this.props.lsGroups.items });
			this.setState({ recordCount: this.props.lsGroups.count });
			this.props.HideLoader();
		}
	}

	handleFilter = () => {
		const { filterName, filterDescription } = this.state;
		// this.props.ShowLoader(<Loader />);
		this.props.setURLState({ filterName, filterDescription });
	};

	handleClearFilter = () => {
		this.setState(
			{
				filterName: "",
				filterDescription: "",
			},
			() => {
				this.handleFilter();
			}
		);
	};

	handleChange = (event) => {
		const id = event.target.id || event.target.name;
		const value = event.target.value;
		this.setState({ [id]: value });
	};

	handleNew = () => {
		this.props.history.push("/admin/access_control/group/new");
	};

	handleGroupDetails = (id) => {
		this.props.history.push(`/admin/access_control/group/details/${id}`);
	};

	handleEditGroup = (id) => {
		this.props.history.push(`/admin/access_control/group/edit/${id}`);
	};

	handlePaginate = (page) => {
		// this.props.ShowLoader(<Loader />);
		this.props.setURLState({ page });
	};

	handleDelete = async (item) => {
		await deleteCustomerGroup(item.id)
			.then(() => {
				window.location.reload();
			})
			.catch(() => {});
	};

	render() {
		const { classes } = this.props;
		const {
			breadcrumbItems,
			groups,
			hasCreatePermission,
			hasDeletePermission,
			hasWritePermission,
			filterName,
			filterDescription,
			pageSize,
			recordCount,
			page,
		} = this.state;
		return (
			<CommonPage
				bottomAppBar={
					<CustomPagination
						pageSize={pageSize}
						recordCount={recordCount}
						offset={pageSize * (page - 1)}
						clickEvent={(e, offset) =>
							this.handlePaginate(
								Math.round(offset / pageSize) + 1
							)
						}
					/>
				}
			>
				<DocumentTitle pageTitle={"Grupos"} />
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<Breadcrumbs items={breadcrumbItems} />
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<Section
											title="GRUPOS CADASTRADOS"
											icon="icon-ico_acesso_grupo"
										/>
									</GridItem>
									<GridItem
										xs={12}
										sm={6}
										md={6}
										lg={6}
										style={{ textAlign: "right" }}
										className={classes.noPaddingRight}
									>
										<Button
											size="sm"
											color="primary"
											disabled={!hasCreatePermission}
											onClick={
												hasCreatePermission
													? () => this.handleNew()
													: () =>
															console.log(
																"Sem permissão"
															)
											}
										>
											<Icon
												style={{
													fontSize: "17px",
													width: "35px",
												}}
												className={
													"icon-ico_grupo_novo"
												}
											/>
											Novo
										</Button>
									</GridItem>
								</GridContainer>
								<GridContainer className={classes.filterForm}>
									<GridItem xs={12} sm={12} md={12} lg={12}>
										<h5>
											<b>Opções de busca:</b>
										</h5>
									</GridItem>
									<GridItem xs={12} sm={12} md={12} lg={12}>
										<GridContainer>
											<GridItem xs={12} sm={6} md={6}>
												<CustomInput
													id="filterName"
													name="filterName"
													labelText="Nome"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														onChange:
															this.handleChange,
														value: filterName,
													}}
												/>
											</GridItem>

											<GridItem xs={12} sm={6} md={6}>
												<CustomInput
													id="filterDescription"
													name="filterDescription"
													labelText="Descrição"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														onChange:
															this.handleChange,
														value: filterDescription,
													}}
												/>
											</GridItem>

											<GridContainer>
												<GridItem
													xs={10}
													sm={9}
													md={4}
													lg={4}
													xl={4}
													style={{
														textAlign: "left",
														marginTop: "35px",
													}}
												>
													<Button
														size="sm"
														color="primary"
														style={{
															marginRight: "10px",
														}}
														onClick={() =>
															this.handleFilter()
														}
													>
														Pesquisar
													</Button>
													<Button
														size="sm"
														color="greenBorderButtonColor"
														onClick={() =>
															this.handleClearFilter()
														}
													>
														Limpar
													</Button>
												</GridItem>
											</GridContainer>
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GridContainer style={{ margin: "30px 0" }}>
									<GridItem
										xs={12}
										sm={12}
										md={12}
										lg={12}
										className={classes.noPaddingLeft}
									>
										<Section title="RESULTADOS DA PESQUISA" />
									</GridItem>
									<GridItem
										xs={12}
										className={classes.noPaddingLeft}
									>
										<Table
											hover
											tableHead={[
												"Nome",
												"Descrição",
												"Ações",
											]}
											tableData={groups.map((item) => [
												item.name,
												item.description,
												<div
													style={{
														textAlign: "left",
													}}
												>
													<span
														className={cx(
															"icon-bot_edit",
															classes.actionButton,
															{
																[classes.greyButton]:
																	!hasWritePermission
																		? true
																		: false,
															}
														)}
														title="Ver mais"
														onClick={
															hasWritePermission
																? () =>
																		this.handleEditGroup(
																			item.id
																		)
																: () =>
																		console.log(
																			"Sem permissão"
																		)
														}
													></span>
													<span
														className={cx(
															"icon-bot_busca",
															classes.actionButton
														)}
														title="Ver mais"
														onClick={() =>
															this.handleGroupDetails(
																item.id
															)
														}
													></span>
													<span
														className={cx(
															"icon-bot_close",
															classes.actionButton,
															{
																[classes.redButton]:
																	hasDeletePermission
																		? true
																		: false,
																[classes.greyButton]:
																	!hasDeletePermission
																		? true
																		: false,
															}
														)}
														title="Excluir"
														onClick={
															hasDeletePermission
																? () =>
																		this.handleDelete(
																			item
																		)
																: () =>
																		console.log(
																			"Sem permissão"
																		)
														}
													></span>
												</div>,
											])}
										/>
										{!groups.length > 0 && (
											<NoContentAlert alertText="Nenhum grupo cadastrado" />
										)}
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</CommonPage>
		);
	}

	stringifyFilter = (filterData) => {
		const { filterName, filterDescription } = filterData;
		if (_.isEmpty(filterName) && _.isEmpty(filterDescription)) {
			return "";
		}
		let filter = "?$filter=";
		let fName = "";
		let fDescription = "";

		// Name
		fName = filterName
			? "contains(Name, '" + _.trim(filterName) + "') and "
			: "";

		// Description
		fDescription += filterDescription
			? "contains(Description, '" + _.trim(filterDescription) + "') and "
			: "";

		//
		filter += fName + fDescription;
		filter = filter.substring(0, filter.lastIndexOf(" and "));
		return filter;
	};
}

Groups.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		lsGroups: state.group.lsGroups,
		groupsDataFailed: state.group.groupsDataFailed,

		user: state.authentication.user,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetGroups,
			ShowLoader,
			HideLoader,
			SweetAlert,
			HideAlert,
			DeleteGroup,
		},
		dispatch
	);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	connectRouterState({
		filterName: "",
		filterDescription: "",
		currentPage: 1,
	}),
	withStyles(sweetAlertStyle),
	withTranslation()
)(Groups);
