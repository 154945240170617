import React from 'react';
import PropTypes from "prop-types";
import cx from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const ColoredCircle = ({ size, color, className, classes, ...props }) => {

    const componentClasses = cx({
        [classes[color]]: color,
        [className]: className
    });

    return (
        <>
            <div style={{ width: size + 'px', height: size + 'px', borderRadius: '50%' }} className={componentClasses}></div>
        </>
    );
};

ColoredCircle.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose",
        "white",
        "green",
        "wine",
        "twitter",
        "facebook",
        "google",
        "linkedin",
        "pinterest",
        "youtube",
        "tumblr",
        "github",
        "behance",
        "dribbble",
        "reddit",
        "transparent",
        "waterButtonColor",
        "greenButtonColor",
        "blueButtonColor",
        "whiteButtonColor",
        "greenBorderButtonColor"
    ]),
};

export default withStyles(buttonStyle)(ColoredCircle);
